import * as TYPES from '../types/branch';
import * as API from '../api/branch';

export const getBranch = () => async (dispatch) => {
    dispatch({ type: TYPES.GET_BRANCH_REQ });
    try {
        const response = await API.getBranch();
        if (response.status === 200) {
            dispatch({ type: TYPES.GET_BRANCH_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.GET_BRANCH_FAIL, payload: response });
        }
    } catch (error) {
        dispatch({ type: TYPES.GET_BRANCH_FAIL, payload: error });
    }
};

export const getBranchSpecific = () => async (dispatch) => {
    dispatch({ type: TYPES.GET_BRANCH_SPECIFIC_REQ});
    try {
        const response = await API.getBranchSpecific();
        if (response.status === 200) {
            dispatch({ type: TYPES.GET_BRANCH_SPECIFIC_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.GET_BRANCH_SPECIFIC_FAIL, payload: response });
        }
    } catch (error) {
        dispatch({ type: TYPES.GET_BRANCH_SPECIFIC_FAIL, payload: error });
    }
};


