import {useState} from 'react';
import {Input} from 'formik-antd';
import {useFormikContext} from 'formik';
import {getCustomerInfoWP} from './api';
import {Button, ButtonWrap} from './styled-component';
import Loading from '../../components/loading/Loading';

const getDegree = (rawDegree) => {
  if (['ม.ปลาย', 'ปวช', 'ปวส', 'ป.ตรี', 'สูงกว่าป.ตรี'].includes(rawDegree)) {
    return rawDegree;
  } else {
    if (rawDegree.includes('ตรี')) return 'ป.ตรี';
    else if (rawDegree.includes('มัธยม')) return 'ม.ปลาย';
    else if (rawDegree.includes('ปวช')) return 'ปวช';
    else if (rawDegree.includes('ปวส')) return 'ปวส';
    else return 'สูงกว่าป.ตรี';
  }
};

const getError = (error) => {
  if (error === 'customer not found') {
    return 'ไม่พบข้อมูลลงทะเบียน';
  } else {
    return error;
  }
};

const getIncome = (income) => {
  let textnum = income.includes('-') ? income.split('-')[0] : income;
  let num = textnum.replace(/,/g, '');
  let isnum = /^\d+$/.test(num);
  return isnum ? num : income;
};

const CustomerInfo = (props) => {
  const ctx = useFormikContext();
  const [tel, setTel] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);


  const searchByTel = () => {
    if (tel !== '') search(tel);
  };

  const searchByIdCard = () => {
    const idcard = ctx.getFieldProps('customer2.id_card').value;

    if (idcard !== '') search(idcard);
  };

  const search = async (searchStr) => {
    try {
      setLoading(true);
      setSuccess('');
      setError('');
      let ans = await getCustomerInfoWP(searchStr);



      // ctx.setFieldValue('customer2.firstname', ans?.data?.fname);
      // ctx.setFieldValue('customer2.lastname', ans?.data?.lname);
      // ctx.setFieldValue('customer2.general.fullname', ans?.data?.fname + ' ' + ans?.data?.lname);
      ctx.setFieldValue(
        'customer2.address2.full',
        `${ans?.data?.address} ${ans?.data?.subDistrict} ${ans?.data?.district} ${ans?.data?.province} ${ans?.data?.postalCode}`,
      );

     
      // ctx.setFieldValue('customer2.id_card', ans?.data?.cardId);
      ctx.setFieldValue('customer2.tel', ans?.data?.tel);
      setTel(ans?.data?.tel);
      ctx.setFieldValue('customer2.social_media.line', ans?.data?.line);
      ctx.setFieldValue('customer2.social_media.facebook', ans?.data?.facebook);

      ctx.setFieldValue('customer2.job_cat', ans?.data?.university ? 'student' : 'employed');
      if (ans?.data?.university) {
        ctx.setFieldValue('customer2.education.degree', getDegree(ans?.data?.degree));
        ctx.setFieldValue('customer2.education.year', ans?.data?.grade);
        ctx.setFieldValue('customer2.education.college', ans?.data?.university);
        ctx.setFieldValue('customer2.education.faculty', ans?.data?.major);
        ctx.setFieldValue('customer2.education.branch', ans?.data?.bachelor);

        ctx.setFieldValue('customer2.work.career_name', null);
        ctx.setFieldValue('customer2.work.position', null);
        ctx.setFieldValue('customer2.work.experience', null);
        ctx.setFieldValue('customer2.work.salary', null);
        ctx.setFieldValue('customer2.work.company_name', null);
        ctx.setFieldValue('customer2.work.work_tel', null);
        ctx.setFieldValue('customer2.work.work_address.full', null);
      } else {
        ctx.setFieldValue('customer2.education.degree',null);
        ctx.setFieldValue('customer2.education.year', 1);
        ctx.setFieldValue('customer2.education.college',null);
        ctx.setFieldValue('customer2.education.faculty', null);
        ctx.setFieldValue('customer2.education.branch', null);


        ctx.setFieldValue('customer2.work.career_name', ans?.data?.occupation);
        ctx.setFieldValue('customer2.work.position', ans?.data?.position);
        ctx.setFieldValue('customer2.work.experience', ans?.data?.experience);
        ctx.setFieldValue('customer2.work.salary', getIncome(ans?.data?.income));
        ctx.setFieldValue('customer2.work.company_name', ans?.data?.companyName);
        ctx.setFieldValue('customer2.work.work_tel', ans?.data?.companyPhone);
        ctx.setFieldValue('customer2.work.work_address.full', ans?.data?.companyLocation);
      }

      ctx.setFieldValue('customer2.contact_points.person1.firstname', ans?.data?.fnameRelation);
      ctx.setFieldValue('customer2.contact_points.person1.lastname', ans?.data?.lnameRelation);
      ctx.setFieldValue('customer2.contact_points.person1.tel', ans?.data?.telRelation);
      ctx.setFieldValue('customer2.contact_points.person1.relationship', ans?.data?.relationship);

      ctx.setFieldValue('customer2.contact_points.person2.firstname', ans?.data?.fnameRelation2);
      ctx.setFieldValue('customer2.contact_points.person2.lastname', ans?.data?.lnameRelation2);
      ctx.setFieldValue('customer2.contact_points.person2.tel', ans?.data?.telRelation2);
      ctx.setFieldValue('customer2.contact_points.person2.relationship', ans?.data?.relationship2);

      ctx.setFieldValue('customer2.contact_points.person3.firstname', ans?.data?.fnameRelation3);
      ctx.setFieldValue('customer2.contact_points.person3.lastname', ans?.data?.lnameRelation3);
      ctx.setFieldValue('customer2.contact_points.person3.tel', ans?.data?.telRelation3);
      ctx.setFieldValue('customer2.contact_points.person3.relationship', ans?.data?.relationship3);
      
      ctx.setFieldValue('channel', ans?.data?.channel)
      ctx.setFieldValue('chatcone', ans?.data?.chatcone)
      
      setSuccess('ซิงค์ข้อมูลลงทะเบียนสำเร็จ');
    } catch (err) {
      // const fields = [
      //     'customer.firstname', 'customer.lastname', 'customer.general.fullname', 'customer.address2.full', 'customer.tel', 'customer.social_media.line', 'customer.social_media.facebook'
      //     , 'customer.job_cat', 'customer.education.degree', 'customer.education.year', 'customer.education.college', 'customer.education.faculty', 'customer.education.branch'
      //     , 'customer.work.career_name', 'customer.work.position', 'customer.work.experience', 'customer.work.salary', 'customer.work.company_name', 'customer.work.work_tel', 'customer.work.work_address'
      //     , 'customer.contact_points.person1.firstname', 'customer.contact_points.person1.lastname', 'customer.contact_points.person1.tel', 'customer.contact_points.person1.relationship'
      //     , 'customer.contact_points.person2.firstname', 'customer.contact_points.person2.lastname', 'customer.contact_points.person2.tel', 'customer.contact_points.person2.relationship'
      //     , 'customer.contact_points.person3.firstname', 'customer.contact_points.person3.lastname', 'customer.contact_points.person3.tel', 'customer.contact_points.person3.relationship'
      // ];
      // for (let field of fields) {
      //     ctx.setFieldValue(field, null);
      // }
      setError(getError(err.message));
      setSuccess('');
      // setTel("");
    }
    setLoading(false);
  };

  const onChange = (e) => {
    // let id = e.target.value;
    // if (!/^\d{13}$/.test(id)) {
    //     setError('ต้องเป็นตัวเลข 13 หลักไม่มีขีดคั่น');
    // } else {
    //     var digits = [];
    //     for (let i = 0; i < 13; i++) digits.push(parseInt(id[i]));
    //     var checksum = 0;
    //     for (let i = 0; i < 12; i++) checksum += (13 - i) * digits[i];
    //     var digit13 = checksum % 11;
    //     digit13 = digit13 > 1 ? 11 - digit13 : 1 - digit13;
    //     if (digit13 !== digits[12]) {
    //         setError('เลขประชาชนไม่ถูกต้อง');
    //     } else {
    //         setError('');
    //     }
    // }
    setTel(e.target.value);
  };

  return (
    <>
      <Input {...props} onChange={onChange} />
      <ButtonWrap>
        {/* <Button className="search" type="button" onClick={() => searchByTel()}>
          ซิงค์เบอร์โทร
        </Button> */}
        <Button className="search" type="button" onClick={() => searchByIdCard()}>
          ซิงค์บัตรปชช
        </Button>
      </ButtonWrap>
      {loading && <Loading inline />}
      {error && <li style={{color: '#ff4d4f'}}>{error}</li>}
      {success && <li style={{color: '#73d13d'}}>{success}</li>}
    </>
  );
};

export default CustomerInfo;
