export const FGFApproveCommissionSearchForm = () => {
  return [
    {
      key: '1',
      span: 6,
      name: 'name',
      label: 'ชื่อ-นามสกุลลูกค้า',
      placeholder: 'กรุณากรอกชื่อ-นามสกุลลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 6,
      name: 'id_card',
      label: 'เลขที่บัตรประชาชน',
      placeholder: 'กรุณากรอกเลขที่บัตรประชาชน',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      type: 'INPUT',
      value: '',
    },
  ];
};
