import React, {useState, useEffect, useRef} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin, Row, Space} from 'antd';
import {BookOutlined} from '@ant-design/icons';
import {
  GET,
  GET_ICLOUD_TRACKING_STATUS,
  GET_EXPORT_AUTO_CHECK_ICLOUD,
  GET_ICLOUD_FILTER_OPTION,
} from '../../../services/index';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {IcloudTrackingStatusLogColumn} from '../../../constants/global/columnTableForm';
import {IcloudTrackingStatusLogSearchForm} from '../../../constants/global/SearchForm';
import moment from 'moment';
import {convertStrToFormat} from '../../../functions/fn';
import {ButtonTheme} from '../../../components/buttons';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import ExcelJS from 'exceljs';

const IcloudTrackingStatusLogScreen = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [jobTypeOption, setJobTypeOption] = useState([]);
  const [icloudStatusOption, setIcloudStatusOption] = useState([]);
  const pageRef = useRef(1);
  const sizeRef = useRef(10);
  const [totalSize, setTotalSize] = useState(0);
  const [nameState, setNameState] = useState(null);
  const [telState, setTelState] = useState(null);
  const [contractNoState, setContractNoState] = useState(null);
  const [emailState, setEmailState] = useState(null);
  const [jobTypeState, setJobTypeState] = useState(null);
  const [icloudStatusState, setIcloudStatusState] = useState(null);
  const [startDateState, setStartDateState] = useState(null);
  const [endDateState, setEndDateState] = useState(null);
  const [hideTable, setHideTable] = useState(true);
  const [dataExport, setDataExport] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);


  useEffect(() => {
    getFilterOption();
  }, []);

  const createParams = (value) => {
    let params = [];
    let result = '';

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        if (key === 'start_date') {
          params.push('start_date=' + convertStrToFormat(value.start_date, 'dateAD'));
        } else if (key === 'end_date') {
          if (value[key]) {
            params.push('end_date=' + convertStrToFormat(value.end_date, 'dateAD'));
          } else if (!value[key]) {
            params.push('end_date=' + convertStrToFormat(new Date(), 'dateAD'));
          }
        } else {
          params.push(key + '=' + value[key]);
        }
      }
    });

    if (params.length !== 0) {
      result += params.join('&');
    }
    return result;
  };

  const getIcloudTrackingLogData = async (value) => {
    value.page = value?.page || 1;
    value.size = value?.size || 10;
    value.logs = true;

    pageRef.current = value.page;
    sizeRef.current = value.size;
    value.name = value.name || '';
    value.tel = value.tel || '';
    value.contract_no = value.contract_no || '';
    value.email = value.email || '';
    value.job_type = value.job_type || '';
    value.icloud_status = value.icloud_status || '';
    value.start_date = value.start_date || '';
    value.end_date = value.end_date || '';
    let params = createParams(value);
    const {page, size, name, tel, contract_no, email, job_type, icloud_status, start_date, end_date} = value;

    if (!name && !tel && !contract_no && !email && !job_type && !icloud_status && !start_date && !end_date) {
      Modal.error({
        title: 'ไม่สามารถค้นหาข้อมูลได้',
        content: 'จำเป็นต้อง filter อย่างน้อย 1 ฟิลด์ก่อนทำการค้นหา',
      });
    } else {
      setLoading(true);
      setNameState(name);
      setTelState(tel);
      setContractNoState(contract_no);
      setEmailState(email);
      setJobTypeState(job_type);
      setIcloudStatusState(icloud_status);
      setStartDateState(start_date);
      setEndDateState(end_date);

      try {
        const res = await GET(GET_ICLOUD_TRACKING_STATUS(params));
        const {success, data, total_item, total_page} = res;

        if (success) {
          setTotalSize(total_item);
          // setTotalPage(total_page);
          const result = data.map((el, i) => {
            return {
              row_no: i + 1,
              date: el?.transaction_date ? moment(el?.transaction_date).format('DD/MM/YYYY') : '',
              ...el,
              created_date: el?.created_date ? moment(el?.created_date).format('DD/MM/YYYY HH:mm:ss') : '',
              updated_date: el?.updated_date ? moment(el?.updated_date).format('DD/MM/YYYY HH:mm:ss') : '',
            };
          });
          setDataSource(result);
          setHideTable(false);
        }
      } catch (err) {
        const {error} = err;
        Modal.error({
          title: error || '',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const getFilterOption = async () => {
    setLoading(true);
    try {
      const res = await GET(GET_ICLOUD_FILTER_OPTION);
      const {success, message, job_type, icloud_status} = res;
      if (success) {
        setJobTypeOption(job_type);
        setIcloudStatusOption(icloud_status);
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      pageRef.current = 1;
    } else {
      pageRef.current = pageData;
    }
    sizeRef.current = sizeData;

    let name = nameState;
    let tel = telState;
    let contract_no = contractNoState;
    let email = emailState;
    let job_type = jobTypeState;
    let icloud_status = icloudStatusState;
    let start_date = startDateState;
    let end_date = endDateState;

    const params = {
      page: pageRef.current,
      size: sizeRef.current,
      name: name || '',
      tel: tel || '',
      contract_no: contract_no || '',
      email: email || '',
      job_type: job_type || '',
      icloud_status: icloud_status || '',
      start_date: start_date,
      end_date: end_date,
    };

    getIcloudTrackingLogData(params);
  };

  const fetchDataForExport = async () => {
    setLoading(true);

    let params = ``;

    if (
      nameState === '' &&
      telState === '' &&
      contractNoState === '' &&
      emailState === '' &&
      jobTypeState === '' &&
      icloudStatusState === '' &&
      startDateState === '' &&
      endDateState === ''
    ) {
      params = ``;
    } else {
      // params += `?`
      if (nameState) {
        params += `&name=${nameState}`;
      }
      if (telState) {
        params += `&tel=${telState}`;
      }
      if (contractNoState) {
        params += `&contract_no=${contractNoState}`;
      }
      if (emailState) {
        params += `&email=${emailState}`;
      }
      if (jobTypeState) {
        params += `&job_type=${jobTypeState}`;
      }
      if (icloudStatusState) {
        params += `&icloud_status=${icloudStatusState}`;
      }
      if (startDateState) {
        params += `&start_date=${startDateState}`;
      }
      if (endDateState) {
        params += `&end_date=${endDateState}`;
      }
      params = `?` + params.substring(1);
    }

    try {
      let res = await GET(GET_EXPORT_AUTO_CHECK_ICLOUD(params));
      const {success, data} = res;

      if (success) {
        // สร้าง Workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // กำหนดค่าสำหรับ Header
        const headers = ['ลำดับ', 'เลขที่สัญญา', 'ชื่อ-นามสกุล', 'เบอร์โทร', 'วันที่ทำสัญญา', 'Job type', 'เกินกำหนด (วัน)', 'อีเมล', 'สถานะ icloud', 'ถนน', "แขวง", "เขต", "จังหวัด", "รหัสไปรษณีย์", "วันที่ Check Icloud", "อัพเดทล่าสุด"];
        worksheet.addRow(headers);

        // กำหนดรูปแบบของเบอร์โทรเป็น "Text"
        const telColumn = worksheet.getColumn(4);
        telColumn.eachCell((cell) => {
          cell.numFmt = '0000000000';
        });

        // กำหนดข้อมูลลงในแต่ละแถวของ Worksheet
        data.forEach((el, i) => {
          const row = [
            i + 1,
            el?.contract_no,
            el?.name.trim(),
            el?.tel,
            el?.transaction_date ? moment(el?.transaction_date).format('DD/MM/YYYY') : '',
            el?.job_type,
            el?.overdue,
            el?.email,
            el?.icloud_status,
            el?.road,
            el?.subdistrict,
            el?.district,
            el?.province,
            el?.postcode,
            el?.created_date ? moment(el?.created_date).format('DD/MM/YYYY HH:mm:ss') : null,
            el?.updated_date ? moment(el?.updated_date).format('DD/MM/YYYY HH:mm:ss') : null
          ];
          worksheet.addRow(row);
        });

        // สร้างไฟล์ Excel
        const buffer = await workbook.xlsx.writeBuffer();

        // เปิดหน้าต่างดาวน์โหลด
        setVisibleModal(true);

        // สร้างลิงก์สำหรับดาวน์โหลดไฟล์ Excel
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = URL.createObjectURL(blob);
        setDataExport(url);
      }
    } catch (err) {
      const {message, error} = err;

      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false);
      setVisibleModal(true);
    }
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.forEach((val) => setValue(val.name, null));
    setNameState('');
    setTelState('');
    setContractNoState('');
    setEmailState('');
    setJobTypeState('');
    setIcloudStatusState('');
    setStartDateState('');
    setEndDateState('');
    pageRef.current = 1;
    sizeRef.current = 10;
    setHideTable(true);
  };

  const onClose = () => {
    setVisibleModal(false);
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'Log สถานะการติดตาม icloud',
              icon: <BookOutlined />,
              style: {display: 'flex', justifyContent: 'center', alignItems: 'center'},
              spanSearch: 24,
              formSearch: IcloudTrackingStatusLogSearchForm({option: {jobTypeOption, icloudStatusOption}}),
              onSearch: (data) => {
                getIcloudTrackingLogData(data);
              },
              onClearCustom,
            }}
            tableLayout={{
              columns: IcloudTrackingStatusLogColumn(),
              dataSource: dataSource,
              hidden: hideTable,
              rowKey: (record) => record.id,
              pagination: {
                showSizeChanger: true,
                current: pageRef.current,
                pageSize: sizeRef.current,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef.current,
                defaultCurrent: 1,
              },
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="end"
                  >
                  <Space>
                    <ContainerButton right>
                      <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => fetchDataForExport()} />
                    </ContainerButton>
                  </Space>
                </Row>
              ),
            }}
          />
        </Spin>
      </Card>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <a href={dataExport} download="รายงานสถานะการติดตาม icloud">
            <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={() => onClose()} />
          </a>
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default IcloudTrackingStatusLogScreen;
