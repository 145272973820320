

import React, { useState } from 'react';
import Example from '../../assets/images/example_detail.jpg'
import { Modal, Button } from 'antd';

 const messageMachineDetailImage = (prop)=>{
  return (
    <>
  <div>  <p  className='text-remark'  >{"เข้าไปที่ การตั้งค่า > เกี่ยวกับ"}</p></div></>
  );
} 

export default messageMachineDetailImage