import noImage from '../../../assets/images/no_image.jpeg';

export const FGFApproveCommissionVerifyModalForm = ({data}) => {
  const {book_bank_image, id_card_image, rename_image} = data;

  return [
    {
      key: '2',
      span: 22,
      name: 'full_name',
      label: 'ชื่อลูกค้า',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '3',
      span: 11,
      name: 'id_card',
      label: 'เลขบัตรประชาชาน',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '4',
      span: 11,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '5',
      span: 11,
      name: 'commission_amount',
      label: 'ค่าคอมมิชชั่น',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '6',
      span: 11,
      name: 'charge_amount',
      label: 'ค่าธรรมเนียม',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '7',
      span: 22,
      name: 'net_amount',
      label: 'จำนวนคงเหลือที่ถอนได้',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '8',
      span: 22,
      name: 'approve_paid_amount',
      label: 'วงเงินที่ทำรายการหลังหักค่าธรรมเนียม',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '9',
      span: 11,
      name: 'book_bank_no',
      label: 'เลขที่บัญชี',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '10',
      span: 11,
      name: 'book_bank_name',
      label: 'ชื่อบัญชี',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '11',
      span: 11,
      name: 'bank_name',
      label: 'ธนาคาร',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '12',
      span: 11,
      name: 'book_bank_branch',
      label: 'สาขา',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '13',
      span: 7,
      name: 'book_bank_image',
      label: 'รูป bookbank',
      type: 'VIEWIMAGE',
      disabled: true,
      defaultValue: book_bank_image !== '-' ? book_bank_image : noImage,
    },
    {
      key: '14',
      label: '',
      span: 1,
    },
    {
      key: '15',
      span: 7,
      name: 'id_card_image',
      label: 'รูปคู่บัตรประชาชน',
      type: 'VIEWIMAGE',
      disabled: true,
      defaultValue: id_card_image !== '-' ? id_card_image : noImage,
    },
    {
      key: '16',
      label: '',
      span: 1,
    },
    {
      key: '17',
      span: 7,
      name: 'rename_image',
      label: 'รูปใบเปลี่ยนชื่อ',
      type: 'VIEWIMAGE',
      disabled: true,
      defaultValue: rename_image !== '-' ? rename_image : noImage,
    },
    {
      key: '18',
      span: 22,
      align: 'right',
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
    },
  ];
};
