import React from 'react';
import { Input, Select, } from 'antd';

const { Option } = Select;


export const EditableAdjustCell = ({
  title, editable, children, dataIndex, record, handleChange, dataOption, ...restProps
}) => {

  let options
  if (dataIndex === 'serial_number') {
    options = record['serial_option']
  } else {
    options = dataOption
  }


  const renderInputType = (dataIndex) => {

    if (["type", "item_code"].includes(dataIndex) || (dataIndex === "serial_number" && record["type"] === "NEG") || (dataIndex === "branch" && record["type"] === "POS") || ((dataIndex === "serial_status" && record["type"] === "POS" && record['item_name']?.includes('(พิเศษ)')))) {
      return (
        <Select
          style={{ width: '100%' }}
          showSearch
          allowClear={["type", "item_code"].includes(dataIndex)}
          name={dataIndex}
          placeholder={`กรุณาเลือก ${title}`}
          optionFilterProp="children"
          onChange={(e) => handleChange(e, dataIndex, record.key)}
          value={record[dataIndex]}
        >
          {options.map((el, k) => {
            return <Option value={el.value} key={`option_${k}`}>{el.label}</Option>
          })}

        </Select>
      )
    } else if ((dataIndex === "serial_number" && record["type"] === "POS")|| (dataIndex === "serial_number2" && record["type"] === "POS")  || (dataIndex === "cost" && record["type"] === "POS")) {
      return <Input defaultValue={record && record[dataIndex] || ''} type={dataIndex === "cost" ? "NUMBER" : "TEXT"} style={{ margin: '5px 0px', borderRadius: '10px' }} onChange={(e) => handleChange(e.target.value, dataIndex, record.key)} />;
    } else {
      return <p>{children}</p>
    }
  }

  return <td {...restProps}>

    {editable ? renderInputType(dataIndex) : children}
  </td>;
};