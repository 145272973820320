const MessageNoPrice2 = (props) => {
  return (
    <>
      <div>
        <br />
        <p className="text-remark">{'สินค้านี้ไม่มีการระบุราคาที่2'}</p>
      </div>
    </>
  );
};

export default MessageNoPrice2;
