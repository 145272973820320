import * as TYPES from './type';
import * as API from './api';
import * as PAY_API from '../api/payment';
import * as TRANS_TYPES from '../types/transaction';
import * as TRANS_API from '../api/transaction';
import * as message from '../utils/alert-message';
import * as keyM from '../utils/key-alert-message';
import * as errorStore from '../utils/error-store';
import * as RECEIPT_ACTIONS from '../../redux/receipt-payment/action';

export const receiptPaymentUpdateFilter = (payload) => async (dispatch) => {
  dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_FILTER_REQ });
  if (payload?.clear) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_FILTER_CLEAR });
    return;
  }
  dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_FILTER_SUCCESS, payload });
};

export const receiptPaymentUpdatePaidParams = (payload) => async (dispatch) => {
  dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_PAID_PARAMS_REQ });
  if (payload?.clear) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_PAID_PARAMS_CLEAR });
    return;
  }
  dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_PAID_PARAMS_SUCCESS, payload });
};

export const getPaymentSummaryInfo = (contractNumber, params, payload) => async (dispatch) => {
  dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_REQ, payload: params?.transaction_date });
  if (payload?.clear) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_CLEAR });
    return;
  }
  try {
    const response = await API.getPaymentSummaryInfo(contractNumber, params);
    if (response.status === 200) {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_SUCCESS, payload: response });
    } else {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_FAIL, payload: response.response.data.error });
    }
  } catch (error) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_FAIL, payload: error });
  }
};

export const getPaymentSummaryAmount = (contractNumber, data, payload) => async (dispatch) => {
  dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_REQ });
  if (payload?.clear) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_CLEAR });
    return;
  }
  try {
    if (!payload?.isInit) {
      message.setMessageLoading(keyM.MESSAGE_UPDATE_PAID_AMOUNT, 'กำลังดึงข้อมูลใหม่');
    }
    const response = await API.getPaymentSummaryAmount(contractNumber, data);
    if (response.status === 200) {
      if (!payload?.isInit) {
        message.setMessageSuccess(keyM.MESSAGE_UPDATE_PAID_AMOUNT, 'ดึงข้อมูลใหม่สำเร็จ');
      }
      dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_SUCCESS, payload: response });
    } else {
      if (!payload?.isInit) {
        message.setMessageError(keyM.MESSAGE_UPDATE_PAID_AMOUNT, 'ดึงข้อมูลใหม่ไม่สำเร็จ');
      }
      dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_FAIL, payload: response.response.data.error });
    }
  } catch (error) {
    if (!payload?.isInit) {
      message.setMessageError(keyM.MESSAGE_UPDATE_PAID_AMOUNT, 'ดึงข้อมูลใหม่ไม่สำเร็จ');
    }
    dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_FAIL, payload: error });
  }
};

const onErrorPayByContract = (error) => {
  message.setFocusAlertWithIcon({ message: error, icon: 'error' });
};

export const payBycontract =
  (contractNumber, data, payload = {}) =>
    async (dispatch) => {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_PAY_REQ });
      if (payload?.clear) {
        dispatch({ type: TYPES.RECEIPT_PAYMENT_PAY_CLEAR });
        return;
      }
      try {
        if (payload?.fileUpload) {
          const { params, mimetype, file, setFileState } = payload.fileUpload;
          const responseUrl = await PAY_API.getUploadSlipUrl(params);
          if (responseUrl?.status === 200) {
            await PAY_API.uploadSlip(responseUrl.data.data.url, mimetype, file);
            data.slip_id = responseUrl.data.data.slip_id;
            const response = await API.payByContract(contractNumber, data);
            if (response.status === 200) {
              // setFileState(null);
              if (response.data.status === 'PENDING') {
                dispatch({ type: TYPES.RECEIPT_PAYMENT_PAY_LOADING_CLEAR });
                data.check_slip = true
                message.setAlertConfirmAutoCheckSlip({
                  confirmFn: () => {
                    setFileState(null);
                    dispatch(RECEIPT_ACTIONS.payBycontract(contractNumber, data, payload));
                  },
                  message: response.data.message || ''
                })
              } else {
                setFileState(null);
                if (payload?.callbackSuccess) {
                  payload.callbackSuccess();
                }
                message.setFocusAlertWithIcon({ message: 'บันทึกรายการชำระเงินเรียบร้อย' });
                dispatch({ type: TYPES.RECEIPT_PAYMENT_PAY_SUCCESS, payload: response });
              }
            } else {
              onErrorPayByContract(response.response.data.error || '');
              dispatch({ type: TYPES.RECEIPT_PAYMENT_PAY_FAIL, payload: response.response.data.error });
            }
          } else {
            onErrorPayByContract(responseUrl.response.data.error);
            dispatch({ type: TYPES.RECEIPT_PAYMENT_PAY_FAIL, payload: responseUrl.response.data.error });
          }
        } else {
          const response = await API.payByContract(contractNumber, data);
          if (response.status === 200) {
            if (payload?.callbackSuccess) {
              payload.callbackSuccess();
            }
            message.setFocusAlertWithIcon({ message: 'บันทึกรายการชำระเงินเรียบร้อย' });
            dispatch({ type: TYPES.RECEIPT_PAYMENT_PAY_SUCCESS, payload: response });
          } else {
            onErrorPayByContract(response.response.data.error || '');
            dispatch({ type: TYPES.RECEIPT_PAYMENT_PAY_FAIL, payload: response.response.data.error });
          }
        }
      } catch (error) {
        onErrorPayByContract(error);
        dispatch({ type: TYPES.RECEIPT_PAYMENT_PAY_FAIL, payload: error });
      }
    };

export const updatePaymentSummaryInfo = (contractNumber, params, reqSummaryAmount) => async (dispatch) => {
  dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_REQ });
  try {
    const response = await API.getPaymentSummaryInfo(contractNumber, params);
    if (response.status === 200) {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_SUCCESS, payload: response });
      if (reqSummaryAmount) {
        const { contractNumber, data } = reqSummaryAmount;
        dispatch(getPaymentSummaryAmount(contractNumber, data, { init: true }));
      }
    } else {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_FAIL, payload: response.response.data.error });
    }
  } catch (error) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_FAIL, payload: error });
  }
};

export const updateTransactionStatus = (id, body, payload) => async (dispatch) => {
  dispatch({ type: TRANS_TYPES.UPDATE_TRANSACTION_REQ });
  message.setMessageLoading(keyM.MESSAGE_CANCEL_TRANSACTION, 'กำลังยกเลิกรายการ');
  try {
    const response = await TRANS_API.updateTransactionStatus2(id, body);

    message.setMessageSuccess(keyM.MESSAGE_CANCEL_TRANSACTION, 'ยกเลิกรายการสำเร็จ');
    dispatch({ type: TRANS_TYPES.UPDATE_TRANSACTION_SUCCESS, payload: response });

    if (payload) {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_CLEAR });
      const { contractNumber, params, reqSummaryAmount } = payload;
      dispatch(updatePaymentSummaryInfo(contractNumber, params, reqSummaryAmount));
    }
  } catch (error) {
    dispatch({ type: TRANS_TYPES.UPDATE_TRANSACTION_FAIL, payload: error });
    message.desTroyMessage(keyM.MESSAGE_CANCEL_TRANSACTION);
    const responseError = error?.response?.data?.error;
    if (responseError === 'user is not blocked payment') {
      const { callToFn } = payload;
      message.setAlertConfirmDeleteTrans({
        confirmFn: () => {
          body.ignore_block = true;
          dispatch(updateTransactionStatus(id, body, payload));
        },
        cancelFn: () => { },
        goToFn: () => {
          callToFn();
        },
      });
      return;
    }
    if (responseError === 'must cancel latest confirmed transaction' && payload?.additionalMessage) {
      message.setFocusAlert(payload.additionalMessage);
      return;
    }
    message.setFocusAlert(errorStore.getErrorMessage(responseError));
  }
};

export const getAmountContract = (contractNumber, data, payload) => async (dispatch) => {
  dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_REQ });
  if (payload?.clear) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_CLEAR });
    return;
  }
  try {
    const response = await API.getAmountContract(contractNumber, data);
    if (response.status === 200) {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_SUCCESS, payload: response });
    } else {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_FAIL, payload: response.response.data.error });
    }
  } catch (error) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_FAIL, payload: error });
  }
};

export const getCheckRedeemStatusData = (payload) => async (dispatch) => {
  dispatch({ type: TYPES.RECEIPT_PAYMENT_CHECK_REDEEM_DATA_REQ });
  if (payload?.clear) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_CHECK_REDEEM_DATA_CLEAR });
    return;
  }
  try {
    const response = await API.checkRedeemStatusData(payload);
    if (response.status === 200) {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_CHECK_REDEEM_DATA_SUCCESS, payload: response });
    } else {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_CHECK_REDEEM_DATA_FAIL, payload: response.response.data.error });
    }
  } catch (error) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_CHECK_REDEEM_DATA_FAIL, payload: error });
  }
};

export const updateRemarkDeviceReturn = (payload) => async (dispatch) => {
  dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_REQ });
  if (payload?.clear) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_CLEAR });
    return;
  }
  try {
    const response = await API.updateRemarkDeviceReturn(payload);
    if (response.status === 200) {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_SUCCESS, payload: response });
    } else {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_FAIL, payload: response.response.data.error });
    }
  } catch (error) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_FAIL, payload: error });
  }
};

export const getCouponDetail = (payload) => async (dispatch) => {
  dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_COUPON_DETAIL_REQ });
  if (payload?.clear) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_COUPON_DETAIL_CLEAR });
    return;
  }
  try {
    const response = await API.getCouponData(payload);
    if (response.status === 200) {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_COUPON_DETAIL_SUCCESS, payload: response });
    } else {
      dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_COUPON_DETAIL_FAIL, payload: response?.response?.data });
    }
  } catch (error) {
    dispatch({ type: TYPES.RECEIPT_PAYMENT_GET_COUPON_DETAIL_FAIL, payload: error });
  }
};