import { useDispatch, useSelector } from 'react-redux';
import * as todoActions from '../redux/actions/statement';

export const useStatement = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.statement_info.loading);
    const error = useSelector((state) => state.statement_info.error);

    async function dispatchExportStatement(data) {
        dispatch(todoActions.exportStatement(data));
    }

    return {
        loading,
        error,
        dispatchExportStatement
    };
};
