import React, {useEffect} from 'react';
import {Modal} from 'antd';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import moment from 'moment';
import {saveAs} from 'file-saver';
import qr_payment from '../../../assets/images/qr-payment.jpg';
import {TextSmall} from '../../../components/forms/text';
import {convertFormatToStr} from '../../../functions/fn';
export const QrCodePaymentModal = ({title, visible, onClose, data}) => {
  useEffect(() => {}, [data]);

  return (
    <Modal title={title} open={visible} width={480} onCancel={onClose} footer={null} destroyOnClose={true} maskClosable={false}>
      <div style={{textAlign: 'center', marginBottom: '12px'}}>
        <img src={data?.data_url} width={'70%'} />
        <TextSmall text={data?.merchant} />
        <TextSmall text={`${convertFormatToStr(data?.amount, 'money_digit')} บาท`} />
      </div>
      <div style={{textAlign: 'center', marginTop: '12px'}}>
        <TextSmall text={'QRCode ใช้สำหรับการชำระเงินของ'} color="red" />
        <TextSmall text={data?.name} color="red" />
      </div>
      <div style={{textAlign: 'center', marginTop: '12px',marginBottom: '12px',marginLeft:'50px'}}>
        <TextSmall text={`เลขอ้างอิง Ref2 : ${data?.ref2}`} align={'left'}/>
        <TextSmall text={`สร้างเมื่อ : ${moment(data?.created_date).format('DD/MM/YYYY HH:mm')}`} align={'left'}/>
        <TextSmall text={'หมายเหตุ : QRCode นี้สามารถใช้ได้แค่ครั้งเดียวเท่านั้น'} color="red" align={'left'}/>
      </div>

      <ContainerButton center>
        <ButtonTheme useFor="SUBMIT" onClick={() => saveAs(data?.data_url, 'image.png')} />
      </ContainerButton>
    </Modal>
  );
};
