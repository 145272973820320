import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Input } from 'antd'
import { getAgeMonth } from '../../functions/fn';

const CalculateAge = (props) => {
  const [error, setError] = useState(null)
  const [value, setValue] = useState(null)
  const ctx = useFormikContext();
  const { customer } = ctx.values;

  useEffect(() => {
    handleCalAge(customer?.general?.dob)
  }, [ctx.values])

  const handleCalAge = (dob) => {
    const age = getAgeMonth(dob)
    if (!isNaN(age.years) || !isNaN(age.months)) {
      setValue(`${age.years} ปี ${age.months} เดือน`)
    }
  }

  return (
    <>
      <>
        <Input {...props} value={value}></Input>
        <span style={{ color: 'red' }}><small>{error}</small></span>
      </>
    </>
  );
};

export default CalculateAge;
