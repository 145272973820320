// /* eslint no-use-before-define: 0 */
import React from 'react';
import {Row, Col, Table} from 'antd';
import {columnsProductPickStock} from './columnsTable';
import moment from 'moment';
import Barcode from 'react-barcode';
import {StockProductsPickMainColumn, StockProductsPickPrintColumn} from '../column';
import _ from 'lodash';

const TemplatePrintProductPick = React.forwardRef((props, ref) => {
  const {branch_from_name, branch_to_name, document_date_start, document_date_end, remark, items, amount, branch} = props.data;

  const header = (
    <>
      {/* <Row gutter={16} className='margin-row'>
      <Col className="gutter-row" span={24} style={{ textAlign: "right" }}>
        <Barcode value={documentNo} />
        <span className='text-print'>{`เลขที่เอกสาร : ${documentNo}`}</span>
      </Col>
    </Row> */}
      <h2 style={{textAlign: 'center', marginBottom: '30px'}}>{'ใบเช็คสินค้าจอง'}</h2>
      <Row gutter={16} className="margin-row">
        {/* <Col className="gutter-row" span={12}>
        <span className='text-print'>{`ผู้จัดของ : ${updateUser}`}</span>
      </Col> */}
        <Col className="gutter-row" span={12}>
          <span className="text-print">{`สาขาต้นทาง: ${branch_from_name}`}</span>
        </Col>
        <Col className="gutter-row" span={12}>
          <span className="text-print">{`สาขาปลายทาง: ${branch_to_name}`}</span>
        </Col>
      </Row>
      {/* <Row gutter={16} className='margin-row'>
      <Col className="gutter-row" span={12}>
        <span className='text-print'>{`ผู้ตรวจสอบ : ${updateUser}`}</span>
      </Col> 
      <Col className="gutter-row" span={12}>
        <span className='text-print'>{`สาขาปลายทาง: ${branch_to_name}`}</span>
      </Col> 
    </Row> */}
      <Row gutter={16} className="margin-row">
        <Col className="gutter-row" span={24}>
          <span className="text-print">{`วันที่จองสินค้า: ${moment(document_date_start).format('YYYY-MM-DD')} - ${moment(document_date_end).format(
            'YYYY-MM-DD',
          )}`}</span>
        </Col>
      </Row>
      <Row gutter={16} style={{marginBottom: '20px'}}>
        <Col className="gutter-row" span={24}>
          <span className="text-print">{`จำนวน: ${amount} รายการ`}</span>
        </Col>
      </Row>
      <Row gutter={16} style={{marginBottom: '80px'}}>
        <Col className="gutter-row" span={24}>
          <span className="text-print">{`หมายเหตุ: ${remark}`}</span>
        </Col>
      </Row>
    </>
  );

  // const expandedRowRender = (value) => {
  //   let dataSc = [];
  //   if (branch && branch.length > 0) {
  //     if (items[value?.branch]) {
  //       const dt = items[value?.branch].map((el, i) => {
  //         return {
  //           ...el,
  //           rowNo: i + 1,
  //         };
  //       });
  //       dataSc = _.cloneDeep(dt);
  //     }
  //   }

  //   return <Table className="sub-nested-table" columns={StockProductsPickPrintColumn()} dataSource={dataSc} pagination={false} />;
  // };

  return (
    <div ref={ref}>
      <div className="parent">
        {header}
        <Table columns={columnsProductPickStock} dataSource={items} pagination={false} />
        {/* <Table
          className="main-nested-table"
          columns={StockProductsPickMainColumn()}
          expandable={{
            expandedRowRender,
            onExpand: () => {},
            defaultExpandAllRows: true,
            indentSize: 5,
          }}
          dataSource={branch}
        /> */}
      </div>
      {/* <hr />
      <div className="break" />
      <div className="parent">
        {header}
      </div> */}
    </div>
  );
});

export default TemplatePrintProductPick;
