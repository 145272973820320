import { Select } from 'formik-antd';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { getOption } from './api';

const PeriodsOption = (props) => {
	const [choices, setChoices] = useState([]);

	// useEffect(() => {
	// 	(async () => {
	// 		const data = await getOption('refinance_installments');
	// 		setChoices(data?.refinance_installments)
	// 	})();
	// }, []);

	const refinanceStore = JSON.parse(localStorage.getItem('refinancePeriod')) || []
	const refinancePeriodOption = refinanceStore[1]?.refinance_installments

	const filterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	// return <Select {...props} showSearch optionFilterProp="label" options={choices} filterOption={filterOption}></Select>;
	return <Select {...props} showSearch optionFilterProp="label" options={refinancePeriodOption} filterOption={filterOption}></Select>;
};

PeriodsOption.propTypes = {
	name: PropTypes.string.isRequired,
};

export default PeriodsOption;
