import axios from 'axios';
import {API_AUTHEN_SERVICE, APP_API_KEY} from '../../env';

export const getAccessToken = async (token, clientKey) => {
  const response = await axios.post(
    API_AUTHEN_SERVICE,
    {
      query: `
        mutation AdminLoginWithToken {
          adminLoginWithToken {
            accessToken
            refreshToken
          }
        }
      `,
    },
    {
      headers: {
        Authorization: token,
        'x-api-key': APP_API_KEY,
        'client-key': clientKey,
      },
    },
  );

  return response;
};
