// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect, useRef } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { FileOutlined } from '@ant-design/icons';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { MovementReportSearchForm } from '../../../constants/global/SearchForm';
import { MovementReportColumn } from '../../../constants/global/columnTableForm';
import { GET, GET_BRANCH, GET_MOVEMENT_REPORT, GET_MOVEMENT_REPORT_EXPORT, POST } from '../../../services/index';
import { CSVLink } from "react-csv";
import moment from 'moment'
import { convertStrToFormat } from '../../../functions/fn';

const MovementReportScreen = () => {

    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [dataExport, setDataExport] = useState([])
    const [optionBranch, setOptionBranch] = useState([])

    const [branchState, setBranchState] = useState(null)
    const [productNameState, setProductNameState] = useState(null)
    const [serialNumberState, setSerialNumberState] = useState(null)
    const [typeState, setTypeState] = useState(null)
    const [dataStartState, setDateStartState] = useState(null)
    const [dateEndState, setDateEndState] = useState(null)

    const pageRef = useRef(1)
    const sizeRef = useRef(10)
    const [totalSize, setTotalSize] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [visibleModal, setVisibleModal] = useState(false)
  

    useEffect(() => {
        getOptionBranch()
        getMovementReport({})
    }, []);

    const optionType = [
        {value: 'PURCHASE', label: 'PURCHASE'},
        {value: 'TRANSFER', label: 'TRANSFER'},
        {value: 'SALE', label: 'SALE'},
    ]

    const getOptionBranch = async () => {
        try {
            const res = await GET(GET_BRANCH);
            const { success, result } = res;
            if (success) {

                const option = result.branch.map((el) => {
                    return {
                        label: el.label,
                        value: el.value
                    }
                })
                setOptionBranch(option);
            }
        } catch (err) {
            const { message } = err;
            Modal.error({
                title: message,
            });
        }
    };

    const getMovementReport = async (value) => {

        value.page = value?.page || 1
        value.size = value?.size || 10
    
        pageRef.current = value.page
        sizeRef.current = value.size

        value.branch = value?.branch || ""
        value.name = value?.name ||''
        value.serial_number = value?.serial_number || ""
        value.type = value?.type || ''
        value.start_date = value.start_date ? moment(value.start_date).format('YYYY-MM-DD') : '';
        value.end_date = value.end_date ? moment(value.end_date).format('YYYY-MM-DD') : '';

        const {  branch, name, serial_number, type, start_date, end_date } = value

        if (start_date !== '' && end_date === '') {
            return Modal.warning({
                title: 'กรุณาเลือกวันที่สิ้นสุด'
            })
        } else if (start_date === '' && end_date !== '') {
            return Modal.warning({
                title: 'กรุณาเลือกวันที่เริ่ม'
            })
        }

        setBranchState(branch)
        setProductNameState(name)
        setSerialNumberState(serial_number)
        setTypeState(type)
        setDateStartState(start_date)
        setDateEndState(end_date)

        setLoading(true)
        try {

            let obj = {
                page: pageRef.current,
                size: sizeRef.current,
                branch: branch,
                name: name,
                serial_number: serial_number,
                type: type,
                start_date: start_date,
                end_date: end_date
            }

            const res = await POST(GET_MOVEMENT_REPORT, obj)
            const { success, data, total_item,total_page,  } = res;
            if (success) {
                setTotalPage(total_page)
                setTotalSize(total_item)
      
                const result = data.map((el, i) => {
                    return {
                        rowNo: i + 1,
                        ...el,
                        userCreate: el.user_create.name,
                    }
                })

                setDataSource(result)
            }

        } catch (err) {
            const { message } = err;
            Modal.error({
                title: message,
            });

        } finally {
            setLoading(false)
        }
    }

    const convertBranchName = (value) => {
        let branch_name = optionBranch.filter((el) => el.value === value)
        return branch_name[0].label
    }

    const fetchDataForExport = async () => {
        setLoading(true)
    
        let branch = branchState
        let name = productNameState
        let serial_number = serialNumberState
        let type = typeState
        let start_date = dataStartState
        let end_date = dateEndState
    
        const obj = {
          branch: branch || '',
          name: name || '',
          serial_number: serial_number || '',
          type: type || '',
          start_date: start_date || '',
          end_date: end_date || '',
        };
    
        try {
            const res = await POST(GET_MOVEMENT_REPORT_EXPORT, obj)
          const { success, data } = res
    
          if (success) {
            const result = data.map((el, i) => {
              return {
                '#': i + 1,
                'เลขที่เอกสาร': el.document_no,
                'ผู้ทำรายการ': el.user_create && el.user_create.name || '',
                'วันที่ทำรายการ': el.create_date !== '' ?moment(el.create_date).format('DD/MM/YYYY HH:mm') : '' ,
                'ประเภท': el.type || '',
                'จำนวน(+/-)': el.qty || '',
                'สาขา': el.branch && convertBranchName(el.branch) || '',
                'วันที่รับ': el.received_date !== '' ?moment(el.received_date).format('DD/MM/YYYY HH:mm') : '' ,
                'รหัสสินค้า': el.item_code || '',
                'ชื่อสินค้า': el.item_name || '',
                'Serial Number': el.serial_number || '',
                'หมายเหตุ': el.remark || '',
                'เกรด': el.sale_grade || '',
              }
            })
            setDataExport(result)
          }
    
        } catch (err) {
          const { message, error } = err;
    
          Modal.error({
            title: message || '',
            content: error || '',
          });
        } finally {
          setLoading(false)
          setVisibleModal(true)
        }
    }

    const onPageSizeChangeCustom = (pageData, sizeData) => {
        const sizeChange = sizeRef.current !== sizeData;
        if (sizeChange) {
          // setPageState(1)
          pageRef.current = 1
        } else {
          // setPageState(pageData)
          pageRef.current = pageData
        }
        sizeRef.current = sizeData
    
        let branch = branchState
        let name = productNameState
        let serial_number = serialNumberState
        let type = typeState
        let start_date = dataStartState
        let end_date = dateEndState
    
        const body = {
          page: pageRef.current,
          size: sizeRef.current,
          branch: branch || '',
          name: name || '',
          serial_number: serial_number || '',
          type: type || '',
          start_date: start_date || '',
          end_date: end_date || '',
        };
    
        getMovementReport(body);
    };

    const onClose = () => {
    setVisibleModal(false)
    }
    

    return (
        <Wrapper>
            <Card>
                <Spin tip="Loading..." spinning={loading}>
                    <PageLayout
                        searchLayout={{
                            title: 'รายงาน Movement',
                            icon: <FileOutlined />,
                            spanSearch: 24,
                            formSearch: MovementReportSearchForm({ options: {optionBranch, optionType  } }),
                            onSearch: (data) => getMovementReport(data),
                        }}
                        tableLayout={{
                            // title: 'รายงาน Movement',
                            // icon: <FileOutlined />,
                            columns: MovementReportColumn({ options: {optionBranch } }),
                            dataSource: dataSource,
                            extraContent: (
                                <ContainerButton right>
                                    <ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} onClick={() => fetchDataForExport()} />
                                    {/* <CSVLink data={dataExport} filename={"รายงานปรับปรุงสต็อก"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink> */}
                                </ContainerButton>
                            ),
                            pagination: {
                                showSizeChanger: true,
                                current: pageRef.current,
                                pageSize: sizeRef.current,
                                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                                total: totalSize || 0,
                                showTotal: (totalSize, range) => `${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                                defaultPageSize: sizeRef.current,
                                defaultCurrent: 1,
                            },
                        }}
                    />
                </Spin>
            </Card>
            <Modal title={'ต้องการดาวน์โหลด Excel?'} open={visibleModal} width={550} onCancel={onClose} footer={null} destroyOnClose={true} maskClosable={false}>
                <ContainerButton right>
                    <CSVLink data={dataExport} filename={"รายงาน Movement"}><ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={() => onClose()} /></CSVLink>
                    <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
                </ContainerButton>
            </Modal>
        </Wrapper>
    );
};

export default MovementReportScreen;
