import React, { Fragment } from 'react';
import { Button, Space, Upload, message } from 'antd';
import { compareRender } from '../../functions/fn';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { TextXSMall } from './text';

export const UploadTheme = ({ control, item, handleChange, handleOnRemove }) => {
  const { ref, rules, name, defaultValue, label, disabled, accept, onUpload, onPreview, onRemove, fileList, maxCount, urlUpload, multiple } =
    item;

  // const [url, setUrl] = React.useState('');
  // const [fileData, setFileData] = React.useState({});

  const props = {
    // headers: {
    //   authorization: `Bearer ${localStorage.getItem('token')}`,
    //   'content-type': fileData.type,
    // },

    beforeUpload: (file) => {
      // setFileData(file)
      let isJpgOrPng = true;
      if (accept === 'image/*') {
        isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';

      }

      if (!isJpgOrPng) {
        message.error('กรุณาอัพโหลดไฟล์ png, jpg, jpeg เท่านั้น');
      }

      return isJpgOrPng || Upload.LIST_IGNORE;
    },
  };

  return (
    <div direction="vertical" style={{ width: '100%', textAlign: 'left' }} size="large">
      <Upload
        disabled={disabled}
        headers={{
          authorization: `Bearer ${localStorage.getItem('token')}`,
          // 'content-type': fileData.type,
        }}
        {...props}
        // id={ref}
        // name={"file"}
        accept={accept || '*'}
        action={urlUpload}
        mode={'multiple' || false}

        listType="picture"
        defaultFileList={fileList}
        // fileList={fileList}
        onChange={onUpload ? (e) => onUpload(e, item.name) : (e) => handleChange(e, item)}
        onPreview={onPreview}
        onRemove={onRemove ? (e) => onRemove(e, item.name) : {}}
        maxCount={maxCount || 1}>
        <Button icon={<UploadOutlined />}>อัพโหลดไฟล์</Button>
      </Upload>
    </div>
  );
};

export const MemoizedUploadTheme = React.memo(UploadTheme, compareRender);


export const UploadThemeWithHeader = ({ control, item, handleChange, handleOnRemove }) => {
  const { ref, rules, name, defaultValue, label, disabled, accept, onUpload, onPreview, onRemove, fileList, maxCount, urlUpload, page, imageCat, multiple } =
    item;

  const [url, setUrl] = React.useState('');
  const [fileData, setFileData] = React.useState({});

  const props = {
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`,
      'content-type': fileData.type,
    },

    beforeUpload: (file) => {

      if (imageCat) {
        setFileData(file)
        var extension = null;
        file.name.replace(/\.[^.]{3,4}$/, (m) => (extension = m));
        if (!extension) throw new Error('extension not found');
        var qs = new URLSearchParams({ extension, imageCat });
        setUrl(`${urlUpload}${qs}`);
      } else {
        setUrl(urlUpload);
      }

      let isJpgOrPng = true;
      if (accept === 'image/*') {
        isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      }

      if (!isJpgOrPng) {
        message.error('กรุณาอัพโหลดไฟล์ png, jpg, jpeg เท่านั้น');
      }

      return isJpgOrPng || Upload.LIST_IGNORE;
    },
  };

  return (
    <Fragment direction="vertical" style={{ width: '100%', textAlign: 'center' }} size="large">
      <Upload
        disabled={disabled}
        {...props}
        // id={ref}
        // name={"file"}
        accept={accept || '*'}
        action={url}
        // headers={{
        //   authorization: `Bearer ${localStorage.getItem('token')}`,
        //   'content-type': fileData.type,
        // }}
        listType="picture"
        defaultFileList={fileList}
        multiple={multiple || false}
        // fileList={fileList}
        onChange={onUpload ? (e) => onUpload(e, item.name) : (e) => handleChange(e, item)}
        onPreview={onPreview}
        onRemove={onRemove ? (e) => onRemove(e, item.name) : undefined}
        maxCount={maxCount || 1}>
        <Button icon={<UploadOutlined />}>อัพโหลดไฟล์</Button>
      </Upload>
    </Fragment>
  );
};

export const MemoizedUploadThemeWithHeader = React.memo(UploadThemeWithHeader, compareRender);

export const UploadFilelist = ({ control, item, handleChange, handleOnRemove }) => {
  const { ref, rules, name, defaultValue, label, disabled, accept, onUpload, onPreview, onRemove, fileList, maxCount, urlUpload, multiple } =
    item;

  // const [url, setUrl] = React.useState('');
  // const [fileData, setFileData] = React.useState({});

  const props = {
    // headers: {
    //   authorization: `Bearer ${localStorage.getItem('token')}`,
    //   'content-type': fileData.type,
    // },

    beforeUpload: (file) => {
      // setFileData(file)
      let isJpgOrPng = true;
      if (accept === 'image/*') {
        isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';

      }

      if (!isJpgOrPng) {
        message.error('กรุณาอัพโหลดไฟล์ png, jpg, jpeg เท่านั้น');
      }

      return isJpgOrPng || Upload.LIST_IGNORE;
    },
  };

  return (
    <Fragment direction="vertical" style={{ width: '100%', textAlign: 'center' }} size="large">
      <Upload
        disabled={disabled}
        headers={{
          authorization: `Bearer ${localStorage.getItem('token')}`,
          // 'content-type': fileData.type,
        }}
        {...props}
        // id={ref}
        // name={"file"}
        accept={accept || '*'}
        action={urlUpload}
        mode={'multiple' || false}

        listType="picture"
        defaultFileList={fileList}
        fileList={fileList}
        onChange={onUpload ? (e) => onUpload(e, item.name) : (e) => handleChange(e, item)}
        onPreview={onPreview}
        onRemove={onRemove ? (e) => onRemove(e, item.name) : {}}
        maxCount={maxCount || 1}>
        <Button icon={<UploadOutlined />}>อัพโหลดไฟล์</Button>
      </Upload>
    </Fragment>
  );
};

export const MemoizedUploadFilelist = React.memo(UploadFilelist, compareRender);