import axios from 'axios';
import _ from 'lodash';
import {auth} from '../utils/firebase';
import {encryptString, decryptString} from '../functions/fn';
import {APP_API_KEY, API_AUTHEN_SERVICE} from '../env';

export const decodeUserAdmin = async () => {
  const userAdminV2 = localStorage.getItem('userAdminV2');
  if (!userAdminV2) return;

  const decodeUserAdminv2 = await decryptString(userAdminV2);
  const parseUserAdminV2 = JSON.parse(decodeUserAdminv2);

  return parseUserAdminV2;
};

const createAxiosInstance = () => {
  const instance = axios.create();

  instance.interceptors.request.use(
    async (config) => {
      const response = await decodeUserAdmin();
      if (!response) return config;

      if (response) {
        const {accessToken, clientKey} = response;

        config.headers['Authorization'] = accessToken;
        config.headers['x-api-key'] = APP_API_KEY;
        config.headers['client-key'] = clientKey;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  /* Add a response interceptor to handle token expiration and refresh the access token */
  instance.interceptors.response.use(
    async (response) => {
      // Handle GraphQL errors
      const graphqlErrors = response?.data?.errors;
      if (graphqlErrors) {
        const code = graphqlErrors[0]?.code;
        if (code === 'AUTH_TOKEN_EXPIRED') {
          return handleGetRefreshToken(response);
        }
        if (['AUTH_REFRESH_TOKEN_EXPIRED', 'AUTH_SESSION_EXPIRED'].includes(code)) {
          return handleRefreshTokenExpiration();
        }
      }
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error?.response && error?.response?.status === 401 && !originalRequest?._retry) {
        originalRequest._retry = true;

        /* Call your refresh token function here */
        const refreshedToken = await refreshToken();

        const dtRefreshToken = refreshedToken?.data?.data;
        const errRefreshToken = _.head(refreshedToken?.data?.errors);

        /* Update the access token and retry the original request */
        if (dtRefreshToken) {
          await setAccessToken(dtRefreshToken?.adminGetRefreshToken);
          return instance(originalRequest);
        } else {
          switch (errRefreshToken?.status) {
            case 401:
              if (errRefreshToken?.code === 'AUTH_REFRESH_TOKEN_EXPIRED' || errRefreshToken?.code === 'AUTH_SESSION_EXPIRED') {
                await handleRefreshTokenExpiration();
              }
              break;
            default:
              await handleRefreshTokenExpiration();
              break;
          }
        }
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

/* Function to refresh the access token */
const refreshToken = async () => {
  try {
    const userAdminV2 = await decodeUserAdmin();
    if (!userAdminV2) return;

    const {accessToken, refreshToken, clientKey} = userAdminV2;

    const response = await axios.post(
      API_AUTHEN_SERVICE,
      {
        query: `
          mutation AdminGetRefreshToken($input: AuthenInput!) {
            adminGetRefreshToken(input: $input) {
              accessToken
              refreshToken
              clientKey
            }
          }
        `,
        variables: {input: {refreshToken}},
      },
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': APP_API_KEY,
          'client-key': clientKey,
        },
      },
    );

    return response;
  } catch (error) {
    return null;
  }
};

/* Function to handle refresh token expiration */
const handleRefreshTokenExpiration = async () => {
  // Logic to handle refresh token expiration, e.g., redirect to login page or display a message to the user
  await auth.signOut();

  localStorage.removeItem('token');
  localStorage.removeItem('menu_user');
  localStorage.removeItem('branchReportMonitorStock');
  localStorage.removeItem('checkStockAppleStatus');
  localStorage.removeItem('contractNo');
  localStorage.removeItem('userAdmin'); // For Adminv2 Web
  localStorage.removeItem('menuUser'); // For Adminv2 Web
  localStorage.removeItem('userAdminV2'); // oauth version 2
};

/* Function to store the refreshed access token */
const setAccessToken = (dt) => {
  const setDt = {accessToken: dt?.accessToken, refreshToken: dt?.refreshToken, clientKey: dt?.clientKey};

  const enCryptUserAdmin = encryptString(JSON.stringify(setDt));
  localStorage.setItem('userAdminV2', enCryptUserAdmin);
};

const handleGetRefreshToken = async (response) => {
  const originalRequest = response.config;
  if (!originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const refreshedToken = await refreshToken();
      const dtRefreshToken = refreshedToken?.data?.data;
      const errRefreshToken = _.head(refreshedToken?.data?.errors);

      if (dtRefreshToken) {
        await setAccessToken(dtRefreshToken.adminGetRefreshToken);
        originalRequest.headers.Authorization = `Bearer ${dtRefreshToken.adminGetRefreshToken.accessToken}`;
        return axios.request(originalRequest);
      } else if (errRefreshToken) {
        if (['AUTH_REFRESH_TOKEN_EXPIRED', 'AUTH_SESSION_EXPIRED'].includes(errRefreshToken.code)) {
          return handleRefreshTokenExpiration();
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
};

export const axiosInstance = createAxiosInstance();
