import { useDispatch, useSelector } from 'react-redux';
import * as todoActions from '../redux/actions/product';

export const useProduct = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.product.loading);
    const error = useSelector((state) => state.product.error);
    const products = useSelector((state) => state.product.products);
    const defaultPage = useSelector((state) => state.product.page);
    const defaultSize = useSelector((state) => state.product.size);
    const totalItem = useSelector((state) => state.product.totalItem);
    const totalPage = useSelector((state) => state.product.totalPage);

    const infoLoading = useSelector((state) => state.product_info.loading);
    const productInfo = useSelector((state) => state.product_info.productInfo);
    const infoError = useSelector((state) => state.product_info.error);

    async function dispatchGet(params) {
        dispatch(todoActions.getProducts(params));
    }

    async function dispatchGetInfo(code) {
        dispatch(todoActions.getProductInfo(code));
    }

    async function dispatchUpdate(code, data) {
        dispatch(todoActions.updateProductInfo(code, data));
    }

    return {
        defaultPage,
        defaultSize,
        totalItem,
        totalPage,
        loading,
        error,
        products,
        infoLoading,
        productInfo,
        infoError,
        dispatchGet,
        dispatchGetInfo,
        dispatchUpdate
    };

}