import React, { useState, useEffect } from 'react';
import { Modal, Col, Form, Row, Input } from 'antd';
import { ContainerButton } from '../../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../../components/buttons';
import { Icon } from '../../../../resources/icon';
import { useGetHistorySocialId, useSaveSocialId } from '../../../../hook/chatcone';
import { SelectChatconeSocial } from '../../../../components/inputfromapi';
import { DatatableLog, Spinners } from '../../../../components';
import { TextSmall } from '../../../../components/forms/text';
import { EditSocialIdHistoryColumn } from '../columns/EditSocialIdHistoryColumn';

export const EditSocailIdModal = ({ title, visible, onClose, data }) => {
  const onCloseMain = () => {
    data.reApi(data.valueSearch);
    onClose();
  };

  const [dataBefore, setDataBefore] = useState(null);
  const [saveSocialIdFn, saveSocialIdLoading] = useSaveSocialId({ ishowModal: true, onCloseMain: onCloseMain });
  const [getHistorySocialIdFn, getHistorySocialIdData, getHistorySocialIdLoading] = useGetHistorySocialId();

  useEffect(() => {
    const tmpData = { social_id: data?.chatcone?.social_id, channel_id: data?.chatcone?.channel_id };

    if (visible && data?.ref_id) {
      getHistorySocialIdFn({ ref_id: data?.ref_id, ref_name: data?.ref_name });
    }

    form.setFieldsValue(tmpData);
    setDataBefore(tmpData);
  }, [data?.ref_id, visible]);

  const onFinish = async (params) => {
    const setInput = { ...params, ref_id: data?.ref_id, ref_name: data?.ref_name, dataBefore: dataBefore };
    await saveSocialIdFn(setInput);
  };

  const RESPONSIVE_VERTICAL = { xs: 24, md: 24, lg: 12, xl: 12, xxl: 12 };
  const [form] = Form.useForm();
  return (
    <>
      {(saveSocialIdLoading || getHistorySocialIdLoading) && <Spinners />}
      <Modal
        title={title || 'modal'}
        open={visible}
        width={1200}
        onCancel={onCloseMain}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        style={{ border: 'unset' }}>
        <Form
          form={form}
          scrollToFirstError={true}
          autoComplete="off"
          labelAlign="left"
          layout="vertical"
          onFinish={(value) => {
            Modal.confirm({
              title: 'ยืนยัน',
              icon: <Icon.warningAlert />,
              content: 'ยืนยันการแก้ไข Social Id',
              onOk() {
                onFinish(value);
              },
              okText: 'ยืนยัน',
              cancelText: 'ยกเลิก',
            });
          }}>
          <Row gutter={[12, 12]}>
            <Col {...RESPONSIVE_VERTICAL}>
              <Form.Item label={<TextSmall bold={true} text={'Channel'} />} name={'channel_id'} rules={[{ required: true, message: 'กรุณาระบุ' }]}>
                <SelectChatconeSocial
                  handleChange={(e) => {
                    form.setFieldValue('channel_id', e?.value);
                  }}
                  defaultValue={data?.chatcone?.channel_id}
                />
              </Form.Item>
            </Col>
            <Col {...RESPONSIVE_VERTICAL}>
              <Form.Item label={<TextSmall bold={true} text={'Social id'} />} name={'social_id'} rules={[{ required: true, message: 'กรุณาระบุ' }]}>
                <Input style={{ borderRadius: '10px' }} defaultValue={data?.chatcone?.social_id} />
              </Form.Item>
            </Col>
          </Row>

          <ContainerButton right>
            <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={saveSocialIdLoading} />
            <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
          </ContainerButton>
        </Form>

        <DatatableLog
          style={{ marginTop: '20px' }}
          columns={EditSocialIdHistoryColumn()}
          data={getHistorySocialIdData}
          pagination={{ pageSize: 5 }}
          showDivider={false}
        />
      </Modal>
    </>
  );
};
