import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  min-width: 1000px;
  // height: calc(100vh - 105px);
`;

export const Background = styled.div`
  background-color: ${(props) => (props.color ? props.color : 'white')};
  padding: 25px;
  border-radius: 0;
  width: 100%;
  height: 100%
`;

export const Button = styled.button`
  padding: 5px 15px 5px 15px;
  border: 0;
  border-radius: 3px;
  margin-right:10px;
  background-color: transparent;
  &:focus {
    outline: 0;
  }

  &.save {
    background-color: #01D827;
    color: #fff;
  }

`

export const TextLabel = styled.span`
  font-weight: bold;
`

export const TextValue = styled.span`
  color: #555;
`

export const Td = styled.td`
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding: 5px;
`;
