/* eslint-disable no-console */
import { useDispatch, useSelector } from 'react-redux';
import * as todoActions from '../redux/actions/transaction';

export const useTransaction = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.transaction.loading);
    const error = useSelector((state) => state.transaction.error);
    const transactions = useSelector((state) => state.transaction.transactions);
    const defaultPage = useSelector((state) => state.transaction.page);
    const defaultSize = useSelector((state) => state.transaction.size);
    const totalItem = useSelector((state) => state.transaction.totalItem);
    const totalPage = useSelector((state) => state.transaction.totalPage);

    const infoLoading = useSelector((state) => state.transaction_info.loading);
    const transactionInfo = useSelector((state) => state.transaction_info.transactionInfo);
    const infoError = useSelector((state) => state.transaction_info.error);

    async function dispatchGet(params) {
        dispatch(todoActions.getTransaction(params));
    }

    async function dispatchGetInfo(id) {
        dispatch(todoActions.getTransactionInfo(id));
    }

    async function dispatchUpdate(id, data) {
        dispatch(todoActions.updateTransactionStatus(id, data));
    }

    async function dispatchExport(params) {
        dispatch(todoActions.exportTransaction(params));
    }


    async function dispatchUpdateInfoAndGet(id, data, params) {
        await dispatch(todoActions.updateTransaction(id, data));
        dispatch(todoActions.getTransaction(params));
    }

    return {
        defaultPage,
        defaultSize,
        totalItem,
        totalPage,
        loading,
        error,
        transactions,
        infoLoading,
        transactionInfo,
        infoError,
        dispatchGet,
        dispatchGetInfo,
        dispatchUpdate,
        dispatchExport,
        dispatchUpdateInfoAndGet
    };
};