

export const SEARCH_FORM = ({ options }) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'contract_no',
      label: 'เลขที่สัญญา',
      placeholder: 'กรอกเลขที่สัญญา',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 6,
      name: 'name',
      label: 'ชื่อ-นามสกุล',
      placeholder: 'กรอกชื่อ-นามสกุล',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'terminate_export_status',
      label: 'สถานะการออกใบตอบรับสัญญา',
      placeholder: 'เลือกสถานะการออกใบตอบรับสัญญา',
      type: 'SELECT',
      options: options?.export,
      value: '',
    },
    {
      key: '4',
      span: 6,
      name: 'terminate_contract_status',
      label: 'สถานะจัดส่ง',
      placeholder: 'เลือกสถานะจัดส่ง',
      type: 'SELECT',
      options: options?.send,
      value: '',
    },
  ];
};

export const TERMINATED_SEARCH_FORM = ({ options, defaultSearch }) => {

  return [
    {
      key: '1',
      span: 6,
      name: 'contract_no',
      label: 'เลขที่สัญญา',
      placeholder: 'กรอกเลขที่สัญญา',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 6,
      name: 'name',
      label: 'ชื่อลูกค้า',
      placeholder: 'กรอกชื่อลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'terminate_contract_status',
      label: 'สถานะการออกหนังสือยกเลิกสัญญา',
      placeholder: 'เลือกสถานะการออกหนังสือยกเลิกสัญญา',
      type: 'SELECT',
      options: options?.export,
      allowClear: true,
      value: defaultSearch.terminate_contract_status !== null ? defaultSearch.terminate_contract_status : null,
      // onClear: () => on
    },
    {
      key: '4',
      span: 6,
      name: 'terminate_export_status',
      label: 'สถานะการส่งออกหนังสือยกเลิกสัญญา',
      placeholder: 'เลือกสถานะการส่งออกหนังสือยกเลิกสัญญา',
      type: 'SELECT',
      options: options?.send,
      allowClear: true,
      value: '',
    },
    {
      key: '5',
      span: 6,
      name: 'condition',
      label: 'ประเภทหนังสือ',
      placeholder: 'เลือกประเภทหนังสือ',
      type: 'SELECT',
      options: options?.condition,
      allowClear: true,
      value: '',
    },
    {
      key: '6',
      span: 6,
      name: 'start_date',
      label: 'เริ่มวันที่ค้นหา',
      placeholder: 'เลือกวันที่เริ่มต้น',
      type: 'DATE_PICKER',
    },
    {
      key: '7',
      span: 6,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
    },
  ];
};

