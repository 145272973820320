import React, { useState, useEffect } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Space, Row, Spin, Button } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { auth } from '../../utils/firebase';
import { useCurrentState } from './../../utils/hook';
import { color } from '../../resources/color';
import { Icon } from '../../resources/icon';
import moment from 'moment';
import { DIFInvoiceSearchForm } from './components/searchForm';
import { DIFInvoiceColumn } from './components/columnTableForm';
import { GET, GET_DIFINVOICE, PUT, EDIT_IMPORT_DIFINVOICE } from '../../services';

const DIFInvoice = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useCurrentState(auth);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    getDataDIFInvoice({ DocNo: '', DocDate: '', BillType: '', ArCode: '', IsImport: '' });
  }, []);

  const optionBillType = [
    { value: '0', label: 'ขายสด' },
    { value: '1', label: 'ขายเชื่อ' },
  ];

  const optionImportStatus = [
    { value: '0', label: 'รอ Import' },
    { value: '1', label: 'Import เสร็จสิ้น' },
    { value: '2', label: 'Import ไม่สำเร็จ' },
  ];

  const getDataDIFInvoice = async (data) => {
    data.DocNo = data?.DocNo || '';
    data.DocDate = data.DocDate ? moment(data.DocDate).format('YYYY-MM-DD') : '';
    data.BillType = data?.BillType || '';
    data.ArCode = data?.ArCode || '';
    data.IsImport = data.IsImport || '';

    const { DocNo, DocDate, BillType, ArCode, IsImport } = data;
    setLoading(true);
    try {
      const res = await GET(GET_DIFINVOICE(DocNo, DocDate, BillType, ArCode, IsImport));
      const { success, data } = res;
      if (success) {
        data.map((item, index) => {
          item.key = index + 1;
          item.IsImport.code = item.IsImport.code.toString();
        });
        setDataSource(data);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const openConfirm = () => {
    if (selected && selected.length !== 0) {
      Modal.confirm({
        title: 'ยืนยัน',
        icon: <Icon.warningAlert />,
        content: 'ยืนยันการเปลี่ยนสถานะเป็น รอ Import ข้อมูล',
        onOk() {
          reImportData();
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    } else {
      Modal.warning({
        title: 'กรุณาเลือกข้อมูลที่ต้องการ Import ใหม่',
      });
    }
  };

  const reImportData = async () => {
    setLoading(true);
    try {
      const res = await PUT(EDIT_IMPORT_DIFINVOICE, { DocNos: selected });
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            getDataDIFInvoice({});
            clearCheckbox()
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const clearCheckbox = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายการบิลขาย',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: DIFInvoiceSearchForm({ options: { optionBillType, optionImportStatus } }),
              onSearch: (data) => getDataDIFInvoice(data),
            }}
            tableLayout={{
              columns: DIFInvoiceColumn({ options: { optionBillType, optionImportStatus } }),
              dataSource: dataSource,
              rowSelection: {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(
                    selectedRows.map((row) => {
                      return row.DocNo;
                    }),
                  );
                  setSelectedRowKeys(selectedRowKeys)
                },
                getCheckboxProps: (record) => {
                  if (record.IsImport.code !== '2') {
                    return {
                      disabled: true,
                    };
                  }
                },
              },
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="space-between">
                  <Space>
                    <Button
                      type="primary"
                      style={{ background: color.tranfer, borderColor: color.tranfer }}
                      size="large"
                      icon={<Icon.tranfer />}
                      onClick={() => openConfirm()}>
                      Import ข้อมูล
                    </Button>
                  </Space>
                </Row>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default DIFInvoice;
