import {Select} from 'formik-antd';
import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getAdminMasterData, getAdminMDMDefault} from '../../apiv2';
import _ from 'lodash';
import {useFormikContext} from 'formik';

const MDMProviderOption = (props) => {
  const [data, setData] = useState([]);
  const ctx = useFormikContext();
  const typeLock = ctx.getFieldProps('contract.contract.type_lock').value;

  useEffect(() => {
    (async () => {
      const res = await getAdminMasterData('MDM_PROVIDER');

      if (res) {
        let data = _.map(res, (dt) => {
          return {
            label: dt.name,
            value: dt.code,
            source: dt,
          };
        });
        setData(data);
      }
    })();
  }, [typeLock]);

  /* for on change type_lock to set default mdm provider and mdm site */
  useEffect(() => {
    (async () => {
      const res = await getAdminMDMDefault();
      const MDMDefaultKey = _.keyBy(res, 'type_lock');
      let mdmProviderCode = '';
      let mdmSiteCode = '';

      if (res) {
        mdmProviderCode = MDMDefaultKey[typeLock]?.mdm_provider_code || '';
        mdmSiteCode = MDMDefaultKey[typeLock]?.mdm_site_code || '';
      }

      ctx.setFieldValue('contract.contract.mdm_provider_code', mdmProviderCode, false);
      ctx.setFieldValue('contract.contract.mdm_site_code', mdmSiteCode, false);
    })();
  }, [typeLock]);

  const filterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return <Select {...props} showSearch optionFilterProp="value" options={data} filterOption={filterOption}></Select>;
};

MDMProviderOption.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MDMProviderOption;
