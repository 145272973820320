import React, {useState} from 'react';
import {Grid, TextField} from '@material-ui/core';
import {Cancel} from '@material-ui/icons';
import Wrapper from '../../views/wrapper/Wrapper';
import {Div, Background, Button, Remark, TextError, FileItem, InputFileNoText} from './styled-component';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/modal';
import {useStatement} from '../../hook/useStatement';

const getError = (error) => {
  switch (error) {
    case 'no statement were uploaded':
      return 'กรุณาเลือกไฟล์';
    case 'Incorrect Password':
      return 'รหัสผ่านไม่ถูกต้อง';
    case 'No password given':
      return 'กรุณาใส่รหัสผ่าน';
    default:
      return 'รหัสผ่านไม่ถูกต้อง';
  }
};

const getFileExtension = (filename) => {
  return filename.split('.').pop();
};

const StatementChecker = () => {
  const {loading, error, dispatchExportStatement} = useStatement();
  const [statements, setStatement] = useState([]);
  const [password, setPassword] = useState('');
  const [fileKey, setFileKey] = useState(1);

  const onInputFileChange = (event) => {
    Object.keys(event.target.files).forEach((file) => {
      const extension = getFileExtension(event.target.files[file].name);
      setStatement([...statements, {file: event.target.files[file], name: `${fileKey}-${event.target.files[file].name}.${extension}`, key: fileKey}]);
      setFileKey(fileKey + 1);
    });
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const exportStatement = () => {
    const data = new FormData();
    if (statements.length > 0) {
      data.append('password', password);
      Object.keys(statements).forEach((s) => {
        data.append('statement', statements[s].file, statements[s].name);
      });
      dispatchExportStatement(data);
    }
  };

  const removeStatement = (key) => {
    setStatement(statements.filter((s) => s.key !== key));
  };

  return (
    <Wrapper>
      <h1>ตรวจสอบรายการเดินบัญชี</h1>
      <Div>
        <Grid container item>
          <Background>
            <div>
              <div>
                {statements.map((statement, index) => (
                  <FileItem key={statement.key}>
                    <p key={statement.key}>
                      {index + 1} : {statement.file.name}
                    </p>
                    <Button type="button" onClick={() => removeStatement(statement.key)}>
                      <Cancel />
                    </Button>
                  </FileItem>
                ))}
                <InputFileNoText>
                  Browse file
                  <input type="file" onChange={onInputFileChange} />
                </InputFileNoText>

                <Div style={{marginTop: '1rem'}}>
                  <TextField onChange={onPasswordChange} label="รหัส PDF" />
                </Div>
                <div style={{marginTop: '1rem', marginBottom: '10px'}}>
                  <Button className="submit" onClick={() => exportStatement()}>
                    แปลงเป็น Excel
                  </Button>
                </div>
              </div>
              {error && <TextError>{getError(error)}</TextError>}
              <Remark>รองรับ ธนาคารไทยพาณิชย์, ธนาคารกสิกรไทย, ธนาคารกรุงไทย, ธนาคารทหารไทย, ธนาคารกรุงศรีอยุธยา</Remark>
            </div>
          </Background>
        </Grid>
        <Modal open={loading} noButton heightAuto style={{backgroundColor: 'unset', boxShadow: 'unset'}}>
          <Loading />
        </Modal>
      </Div>
    </Wrapper>
  );
};

export default StatementChecker;
