import React, { useState } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { CreateBlacklistCustomerForm } from '../../../constants/global/actionform';
import { POST, INSERT_BLACKLIST_CUSTOMER } from '../../../services/index';

export const CreateBlacklistCustomer = ({ title, visible, onClose, data }) => {
	const {
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		control,
		clearErrors,
	} = useForm();

	const [loading, setLoading] = useState(false);

	const onCloseMain = () => {
		clearErrors();
		setValue('tel', '');
		setValue('id_card', '');
		setValue('name', '');
		setValue('description', '');
		onClose();
	};

	const onSubmit = async (params) => {
		setLoading(true);
		try {
			let res = await POST(INSERT_BLACKLIST_CUSTOMER, { ...params, type: "MANUAL", ref: params.ref && params.ref.split(',').length > 0 ? params.ref.split(',') : [] });
			const { success, message } = res;
			if (success) {
				Modal.success({
					title: message,
					afterClose: () => {
						onCloseMain();
						data.reApi({});
					},
				});
			}
		} catch (err) {
			const { message } = err;
			Modal.error({
				title: message,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={480} footer={null} destroyOnClose={true} maskClosable={false}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<RenderForm
					control={control}
					setValue={setValue}
					getValues={getValues}
					errors={errors}
					formList={CreateBlacklistCustomerForm()}
					renderButton={
						<ContainerButton right>
							<ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
							<ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
						</ContainerButton>
					}
				/>
			</form>
		</Modal>
	);
};
