import React, { useState, useEffect } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Space, Row, Spin, Button } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { auth } from '../../utils/firebase';
import { useCurrentState } from './../../utils/hook';
import { color } from '../../resources/color';
import { Icon } from '../../resources/icon';
import { DIFReceiptDetailColumn } from './components/columnTableForm';
import { DIFReceiptDetailSearchForm } from './components/searchForm';
import { GET, GET_DIFRECEIPT_DETAIL, PUT, EDIT_IMPORT_DIFRECEIPT_DETAIL } from '../../services';

const DIFReceiptDetail = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useCurrentState(auth);
  const [selected, setSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getDataDIFReceiptDetail({ DocNo: '', InvoiceNo: '', BillType: '', IsImport: '' });
  }, []);

  const optionBillType = [
    { value: '0', label: 'ใบส่งของ' },
    { value: '1', label: 'เอกสารยกมา' },
    { value: '2', label: 'ใบลดหนี้' },
    { value: '3', label: 'ใบเพิ่มหนี้' },
    { value: '4', label: 'ลูกหนี้อื่นๆ' },
    { value: '8', label: 'รับเงินล่วงหน้า' },
  ];

  const optionImportStatus = [
    { value: '0', label: 'รอ Import' },
    { value: '1', label: 'Import เสร็จสิ้น' },
    { value: '2', label: 'Import ไม่สำเร็จ' },
  ];

  const getDataDIFReceiptDetail = async (data) => {
    data.DocNo = data?.DocNo || '';
    data.InvoiceNo = data?.InvoiceNo || '';
    data.BillType = data?.BillType || '';
    data.IsImport = data.IsImport || '';

    const { DocNo, InvoiceNo, BillType, IsImport } = data;
    setLoading(true);
    try {
      const res = await GET(GET_DIFRECEIPT_DETAIL(DocNo, InvoiceNo, BillType, IsImport));
      const { success, data } = res;
      if (success) {
        data.map((item, index) => {
          item.key = index + 1;
          item.IsImport.code = item.IsImport.code.toString();
        });
        setDataSource(data);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const openConfirm = () => {
    if (selected && selected.length !== 0) {
      Modal.confirm({
        title: 'ยืนยัน',
        icon: <Icon.warningAlert />,
        content: 'ยืนยันการเปลี่ยนสถานะเป็น รอ Import ข้อมูล',
        onOk() {
          reImportData();
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    } else {
      Modal.warning({
        title: 'กรุณาเลือกข้อมูลที่ต้องการ Import',
      });
    }
  };

  const reImportData = async () => {
    if (selected && selected.length !== 0) {
      setLoading(true)
      try {
        const res = await PUT(EDIT_IMPORT_DIFRECEIPT_DETAIL, { DocNoAndLineNumber: selected });
        const { success, message } = res;
        if (success) {
          Modal.success({
            title: message,
            afterClose: () => {
              getDataDIFReceiptDetail({});
              clearCheckbox()
            },
          });
        }
      } catch (err) {
        const { message, error } = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setLoading(false);
      }
    } else {
      Modal.warning({
        title: 'กรุณาเลือกข้อมูลที่ต้องการ Import ใหม่',
      });
    }
  };

  const clearCheckbox = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายการรับชำระเงิน',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: DIFReceiptDetailSearchForm({ options: { optionBillType, optionImportStatus } }),
              onSearch: (data) => getDataDIFReceiptDetail(data),
            }}
            tableLayout={{
              columns: DIFReceiptDetailColumn({ options: { optionBillType, optionImportStatus } }),
              dataSource: dataSource,
              rowSelection: {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(
                    selectedRows.map((row) => {
                      return { DocNo: row.DocNo, LineNumber: row.LineNumber };
                    }),
                  );
                  setSelectedRowKeys(selectedRowKeys)
                },
                getCheckboxProps: (record) => {
                  if (record.IsImport.code !== '2') {
                    return {
                      disabled: true,
                    };
                  }
                },
              },
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="space-between">
                  <Space>
                    <Button
                      type="primary"
                      style={{ background: color.tranfer, borderColor: color.tranfer }}
                      size="large"
                      icon={<Icon.tranfer />}
                      onClick={() => openConfirm()}>
                      Import ข้อมูล
                    </Button>
                  </Space>
                </Row>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default DIFReceiptDetail;
