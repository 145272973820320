import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { EditAccountForm } from '../../../constants/global/actionform';
import { GET, POST, EDIT_ACCOUNT, SORT_MAIL_TEL } from '../../../services/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { auth } from '../../../utils/firebase';
import { useAuthState } from '../../../utils/hook';
import { checkPassword } from '../../../functions/fn';

export const EditAccountModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
  } = useForm();

  const { user } = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [confirmUseTel, setConfirmUseTel] = useState(false);
  const [countRandomTel, setCountRandomTel] = useState(1)
  //const dataLogin = JSON.parse(localStorage.getItem('dataLogin'))[0];

  useEffect(() => {
    if (JSON.stringify(data.record) != '{}') {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setRecord(data.record);
    }
  }, [data]);

  // const getInitRandomTelMail = async () => {
  //   try {
  //     let res = await GET(SORT_MAIL_TEL);
  //     const { success, result } = res;
  //     if (success) {

  //       setValue('telMail', result['tel'])

  //     }
  //   } catch (err) {
  //     const { message, error } = err;
  //     Modal.error({
  //       title: message,
  //       content: error || '',
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const onCloseMain = () => {
    setValue('remark', '');
    setValue('confirmUseTel', false);
    setConfirmUseTel(false);
    clearErrors();
    setRecord({});
    onClose('edit');
  };

  const onChangeCustom = async (value, ref) => {
    if (ref === 'confirmUseTel') {
      if (value) {
        setValue('telMail', getValues('telIcloud'));
      } else {
        setValue('telMail', '');
      }
      setConfirmUseTel(value);
      setValue('confirmUseTel', value);
    }
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันแก้ไขข้อมูล',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการแก้ไขข้อมูล ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    const { passIcloud, telIcloud, passMail, telMail, pin, remark, confirmUseTel } = params;
    const obj = {
      userLogin: user.email,
      _id: data.record._id,
      passMail,
      telIcloud,
      passIcloud,
      telMail: confirmUseTel ? telMail : '',
      pin,
      remark,
    };

    try {
      let res = await POST(EDIT_ACCOUNT, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi();
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onRandom = async (ref) => {
    if (ref === 'telMail') {
      try {
        // let res = await GET(RANDOM_TEL);
        let res = await POST(SORT_MAIL_TEL);
        const { success, result } = res;
        if (success) {
          if (ref === 'telMail') {
            setValue('telMail', result.tel);
            record.telMail = result.tel;
          }
        }
      } catch (err) {
        const { message, error } = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setCountRandomTel(countRandomTel + 1)
        setLoading(false);
      }
    }
  };

  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={720} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={EditAccountForm({
            data: { ...record, confirmUseTel },
            onChange: onChangeCustom,
            onRandom,
          })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
