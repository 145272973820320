import PropTypes from 'prop-types';
import { Table as TableAntd, Tooltip, Button, Modal, Image } from 'antd'
import { useState } from 'react';
import { color } from '../../resources/color';
import { EyeOutlined } from '@ant-design/icons';

const ActionLogTable = (props) => {
  const {logs} = props;

  const convertDate = (time) => {
    const date = new Date(time);
    date.setUTCHours(date.getUTCHours() + 7);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear() % 100;
    const hours = date.getUTCHours();
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds = date.getUTCSeconds();
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [imageVisible, setImageVisible] = useState(false);

  const openModalImage = () => {
    setImageVisible(true);
  };

  const closeModalImage = () => {
    setImageVisible(false);
  };

  const convertType = (type, count) => {
    switch (type) {
      case 'sound':
        return `Play sound ${count} Times`;
      case 'lost':
        return `Lost Mode iCloud`;
      case 'unlock':
        return `Disable Lost Mode`;
      default:
        return type;
    }
  };

  const convertStatus = (status) => {
    switch (status) {
      case 'done':
      case 'Completed':
        return `สำเร็จ`;
      case 'Failed':
      case 'failed':
        return `ไม่สำเร็จ`;
      case 'inprogress':
      case 'Inprogress':
        return `กำลังดำเนินการ`;
      case 'pending':
      case 'Pending':
        return `อยู่ใน Queue`;
      default:
        return status;
    }
  };

  const column = [
    {
      title: 'วันที่ทำรายการ',
      dataIndex: 'createDate',
      width: '40px',
      align: 'center',
      render: (text, record) => {
        return convertDate(record?.createDate);
      },
    },
    {
      title: 'Action',
      dataIndex: 'type',
      width: '40px',
      align: 'center',
      render: (text, record) => {
        return convertType(record?.type, record?.count);
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '40px',
      align: 'center',
      render: (text, record) => {
        return record?.status ? convertStatus(record?.status) : '';
      },
    },
    {
      title: 'รูปภาพ',
      dataIndex: 'image',
      width: '10px',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <Tooltip placement="topLeft" title={'ดูรูปภาพ'} arrowPointAtCenter>
              <Button
                style={{ backgroundColor: color.view, color: color.white }}
                className="ant-btn-customize"
                icon={<EyeOutlined />} onClick={() => openModalImage()}
              >
              </Button>
            </Tooltip>
            <Modal
              width={1000}
              open={imageVisible}
              footer={null}
              onCancel={closeModalImage}
              maskClosable={true}
              destroyOnClose={true}
              maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.075)' }}
            >
              <Image
                alt="example"
                src={record.url}
                style={{ width: '100%' }}
              />
            </Modal>
          </>

        )
      }
    },
  ]

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: logs?.length, // Adjust according to your total data count
    showSizeChanger: false,
    // pageSizeOptions: ['10', '20'],
    onChange: handlePageChange,
    showTotal: (totalSize, range) => `รายการทั้งหมด ${logs?.length} รายการ`,
  };
  return <TableAntd columns={column} dataSource={logs} pagination={paginationConfig} />;
};

ActionLogTable.propTypes = {
  logs: PropTypes.array,
};

ActionLogTable.defaultProps = {
  logs: [],
};

export default ActionLogTable;
