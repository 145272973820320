import axios from 'axios';
import { API_URL } from '../../env.js';

export const importData = async (body) => {
    try {
        return await axios.post(`${API_URL}/admin/tradesys/import/contract`, body, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};
export const importProduct = async () => {
    try {
        return await axios.post(`${API_URL}/admin/tradesys/import/product`, {}, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};