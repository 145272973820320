import React from 'react';
import { AutoComplete } from 'antd';
import { Controller } from 'react-hook-form';
import { compareRender } from '../../functions/fn';
export const AutoCompleteForm = React.memo(({ item, control }) => {
  return (
    <>
      <Controller
        name={item.name}
        control={control}
        rules={item.rules}
        value={item.value}
        defaultValue={item.defaultValue}
        render={({ field }) => (
          <>
            <AutoComplete
              placeholder={item.placeholder || ''}
              disabledDate={item.disabledDate || ''}
              disabled={item.disabled}
              options={item.options || ''}
              filterOption={(inputValue, option) =>
                // option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                option.value.indexOf(inputValue) !== -1
              }
              value={field.value ? field.value : null}
              style={{ width: '100%', marginTop: 5 }}
              onChange={item.onChange ? item.onChange : field.onChange}
              format={item.format}
              allowClear={item.allowClear}
              onClear={item.onClear}
            // onChange={(errors[item.name]) ? clearErrors(item.name) && onChange : onChange}
            />
          </>
        )}
      />

    </>
  );
}, compareRender);
