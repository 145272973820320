import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { compareRender } from '../../../functions/fn';
import { Modal, Tooltip, Typography } from 'antd'
import { ButtonTheme } from '../../buttons';
import { EllipsisOutlined, } from '@ant-design/icons';
import CustomerOtherTelLogTable from '../../table/CustomerOtherTelLog';

const { Text, Title, Paragraph } = Typography

export const ModalOtherTel = React.memo(({ item, control }) => {
  // export const SyncIDcard = ({ item, control }) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [logsOtherTel, setLogOtherTel] = useState([])

  useEffect(() => {
    if (JSON.stringify(item && item.passValue) !== '{}' && item.passValue) {
      setLogOtherTel(item.passValue)
    }
  }, [item.passValue])

  const contentOtherTel = (
    <Paragraph style={{ color: 'white' }}>ดูเบอร์โทรศัพท์อื่นๆ ของลูกค้า</Paragraph>
  );

  const openModal = () => {
    setVisibleModal(true)
  }

  const onClose = () => {
    setVisibleModal(false)
    // setCustomerData(null)
  }

  const onCloseMain = () => {
    onClose();
  };

  return (
    <>
      <Controller
        render={() => (
          <>
            <Tooltip
              title={contentOtherTel}
            >
              <ButtonTheme useFor="CUSTOM" style={{ color: '#4B4B4B', borderColor: '#d9d9d9', borderRadius: '10px', marginTop: '27px', marginLeft: '0px' }} bgcolor="white" icon={<EllipsisOutlined />} title="" onClick={() => openModal('', '', 'edit_customer')} />
            </Tooltip>
            <Modal title={'เบอร์โทรศัพท์อื่นๆ'}
              open={visibleModal}
              width={600}
              onCancel={onCloseMain}
              footer={null}
              destroyOnClose={true}
              maskClosable={false}
            // style={{ height: 'calc(100vh - 200px)' }}
            // bodyStyle={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 200px)' }}
            >
              {JSON.stringify(logsOtherTel) !== '[]' ?
                <CustomerOtherTelLogTable logs={logsOtherTel} />
                : <p style={{ textAlign: 'center', fontSize: 18 }}>ไม่พบข้อมูลเบอร์โทรเก่า</p>
              }

            </Modal>
          </>
        )
        }
        name="TextField"
        control={control}
        rules={item.rules}
      />
    </>

  );
}
  , (prev, next) =>
    compareRender(prev, next) &&
    JSON.parse(JSON.stringify(prev.item)) ===
    JSON.parse(JSON.stringify(next.item)) &&
    compareRender(prev, next, 'item')
)

// export default CustomerInfo;
