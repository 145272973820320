import { useState } from 'react'
import axios from 'axios'
import { Modal } from 'antd'
import { API_CHATCONE_SERVICE } from '../../env.js'

export const useSendChatcone = ({ ishowModal, onCloseMain }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const sendChatcone = async (input) => {
    try {
      if (input?.chatcone?.social_id) {
        setSuccess(false)
        setLoading(true)

        const response = await axios.post(`${API_CHATCONE_SERVICE}/admin/send-chatcone`, input, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            'content-type': 'application/json'
          }
        })

        if (response.status === 200 && ishowModal && onCloseMain) {
          Modal.success({
            title: 'ส่งแจ้งเตือนสำเร็จ',
            afterClose: () => {
              onCloseMain()
            }
          })
        }
      }
    } catch (err) {
      ishowModal &&
        Modal.error({
          title: 'ส่งแจ้งเตือนไม่สำเร็จ'
        })
    } finally {
      setLoading(false)
    }
  }

  return [sendChatcone, loading, success]
}
