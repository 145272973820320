export const optionCondition = [
    {
        value: "CHANGE_PASSWORD",
        label: "ผิดเงื่อนไขเปลี่ยนรหัส"
    },
    {
        value: "SALE_PHONE",
        label: "ผิดเงื่อนไขนำเครื่องไปขาย"
    },
    {
        value: "OTHER",
        label: "ผิดเงื่อนไขอื่นๆ"
    }
]

export const optionAcknowledgement = [
    {
        value: "YES",
        label: "ออกใบตอบรับสัญญาแล้ว"
    },
    {
        value: "NO",
        label: "ยังไม่ได้ออกใบตอบรับสัญญา"
    },
]

export const optionExportTerminate = [
    {
        value: "YES",
        label: "ออกหนังสือยกเลิกสัญญาแล้ว"
    },
    {
        value: "NO",
        label: "ยังไม่ได้ออกหนังสือยกเลิกสัญญา"
    },
]

export const optionSendTerminate = [
    {
        value: "NOT_SEND",
        label: "ยังไม่ได้จัดส่ง"
    },
    {
        value: "WAIT_SENDING",
        label: "รอจัดส่ง"
    },
    {
        value: "SENDING",
        label: "กำลังจัดส่ง"
    },
    {
        value: "ARRIVED",
        label: "จัดส่งเสร็จสิ้น"
    },
    {
        value: "FAILED",
        label: "จัดส่งไม่สำเร็จ"
    },
    {
        value: "RETURNING",
        label: "กำลังจัดส่งคืน"
    },
    {
        value: "RETURN_SUCCESS",
        label: "จัดส่งคืนสำเร็จ"
    },
]