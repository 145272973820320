import React, { useEffect, useState} from 'react';
import { Tag } from 'antd';
import { TagLabelStyle } from './TagLabelStyle'

const TagLabel = React.memo(({item}) => {

  const [value, setValue] = useState()

  useEffect(() => {
    if(item.value){
      setValue(item.value)
    }
  }, [item.value]);

  return <>
    <TagLabelStyle>
      <Tag color={item.color || null}>
        {value || '-'}
      </Tag>
    </TagLabelStyle> 
  </>
})

export default TagLabel;