import {useState} from 'react';
import {Modal} from 'antd';
import axios from 'axios';
import {API_CHATCONE_SERVICE} from '../../env.js';

export const useGetHistoryConfirmAppointmentContract = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getHistoryConfirmAppointmentContract = async (params) => {
    try {
      if (params?.ref_id) {
        setLoading(true);
        const response = await axios.get(`${API_CHATCONE_SERVICE}/admin/history-confirm-appointment-contract`, {
          params,
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            'content-type': 'application/json',
          },
        });

        if (response.status === 200) {
          setData(response.data.data);
        }
      } else {
        setData([]);
      }
    } catch (err) {
      Modal.error({
        title: 'ดึงข้อมูลไม่สำเร็จ',
      });
    } finally {
      setLoading(false);
    }
  };

  return [getHistoryConfirmAppointmentContract, data, loading];
};
