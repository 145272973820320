import { useState } from 'react';
import { Input } from 'formik-antd';
import { useFormikContext } from 'formik';
import { getCustomerInfoWP } from './api';
import { Button, ButtonWrap } from './styled-component';
import Loading from '../../components/loading/Loading';
import { Modal } from 'antd';
import { ExclamationCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../components/buttons';
import { FlashOnRounded } from '@material-ui/icons';

const getDegree = (rawDegree) => {
  if (['ม.ปลาย', 'ปวช', 'ปวส', 'ป.ตรี', 'สูงกว่าป.ตรี'].includes(rawDegree)) {
    return rawDegree;
  } else {
    if (rawDegree.includes('ตรี')) return 'ป.ตรี';
    else if (rawDegree.includes('มัธยม')) return 'ม.ปลาย';
    else if (rawDegree.includes('ปวช')) return 'ปวช';
    else if (rawDegree.includes('ปวส')) return 'ปวส';
    else return 'สูงกว่าป.ตรี';
  }
};

const getError = (error) => {
  if (error === 'customer not found') {
    return 'ไม่พบข้อมูลลงทะเบียน';
  } else {
    return error;
  }
};

const getIncome = (income) => {
  let textnum = income.includes('-') ? income.split('-')[0] : income;
  let num = textnum.replace(/,/g, '');
  let isnum = /^\d+$/.test(num);
  return isnum ? num : income;
};

const CustomerInfo = (props) => {
  const ctx = useFormikContext();
  const [tel, setTel] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [ansData, setAnsData] = useState();
  const idcard = ctx.getFieldProps('customer.id_card').value;
  const fullName = ctx.getFieldProps('customer.general.fullname').value;
  const searchByTel = () => {
    if (tel !== '') search(tel);
  };

  const searchByIdCard = () => {
    if (idcard !== '') search(idcard);
  };

  const search = async (searchStr) => {
    try {
      setLoading(true);
      setSuccess('');
      setError('');
      let ans = await getCustomerInfoWP(searchStr);
      setAnsData(ans)
      if (ans?.data?.fname && ans?.data?.lname) {
        if (fullName !== ans?.data?.fname + ' ' + ans?.data?.lname) {
          setVisible(true)
        } else {
          syncData(ans)
        }
      }
    } catch (err) {
      // const fields = [
      //     'customer.firstname', 'customer.lastname', 'customer.general.fullname', 'customer.address2.full', 'customer.tel', 'customer.social_media.line', 'customer.social_media.facebook'
      //     , 'customer.job_cat', 'customer.education.degree', 'customer.education.year', 'customer.education.college', 'customer.education.faculty', 'customer.education.branch'
      //     , 'customer.work.career_name', 'customer.work.position', 'customer.work.experience', 'customer.work.salary', 'customer.work.company_name', 'customer.work.work_tel', 'customer.work.work_address'
      //     , 'customer.contact_points.person1.firstname', 'customer.contact_points.person1.lastname', 'customer.contact_points.person1.tel', 'customer.contact_points.person1.relationship'
      //     , 'customer.contact_points.person2.firstname', 'customer.contact_points.person2.lastname', 'customer.contact_points.person2.tel', 'customer.contact_points.person2.relationship'
      //     , 'customer.contact_points.person3.firstname', 'customer.contact_points.person3.lastname', 'customer.contact_points.person3.tel', 'customer.contact_points.person3.relationship'
      // ];
      // for (let field of fields) {
      //     ctx.setFieldValue(field, null);
      // }
      setError(getError(err.message));
      setSuccess('');
      setVisible(false)
      // setTel("");
    }
    setLoading(false);
  };

  const syncData = (ans) => {
    ctx.setFieldValue(
      'customer.address2.full',
      `${ans?.data?.address} ${ans?.data?.subDistrict} ${ans?.data?.district} ${ans?.data?.province} ${ans?.data?.postalCode}`,
    );
    // ctx.setFieldValue('customer.firstname', ans?.data?.fname);
    //  ctx.setFieldValue('customer.lastname', ans?.data?.lname);
    // ctx.setFieldValue('customer.general.fullname', ans?.data?.fname + ' ' + ans?.data?.lname);
    // ctx.setFieldValue('customer.id_card', ans?.data?.cardId);
    ctx.setFieldValue('customer.tel', ans?.data?.tel);
    setTel(ans?.data?.tel);
    ctx.setFieldValue('customer.social_media.line', ans?.data?.line);
    ctx.setFieldValue('customer.social_media.facebook', ans?.data?.facebook);
    ctx.setFieldValue('customer.grade_before', ans?.data?.customer_grade);

    ctx.setFieldValue('customer.job_cat', ans?.data?.university ? 'student' : 'employed');
    if (ans?.data?.university) {
      ctx.setFieldValue('customer.education.degree', getDegree(ans?.data?.degree));
      ctx.setFieldValue('customer.education.year', ans?.data?.grade);
      ctx.setFieldValue('customer.education.college', ans?.data?.university);
      ctx.setFieldValue('customer.education.faculty', ans?.data?.major);
      ctx.setFieldValue('customer.education.branch', ans?.data?.bachelor);
    } else {
      ctx.setFieldValue('customer.work.career_name', ans?.data?.occupation);
      ctx.setFieldValue('customer.work.position', ans?.data?.position);
      ctx.setFieldValue('customer.work.experience', ans?.data?.experience);
      ctx.setFieldValue('customer.work.salary', getIncome(ans?.data?.income));
      ctx.setFieldValue('customer.work.company_name', ans?.data?.companyName);
      ctx.setFieldValue('customer.work.work_tel', ans?.data?.companyPhone);
      ctx.setFieldValue('customer.work.work_address.full', ans?.data?.companyLocation);
    }

    ctx.setFieldValue('customer.contact_points.person1.firstname', ans?.data?.fnameRelation);
    ctx.setFieldValue('customer.contact_points.person1.lastname', ans?.data?.lnameRelation);
    ctx.setFieldValue('customer.contact_points.person1.tel', ans?.data?.telRelation);
    ctx.setFieldValue('customer.contact_points.person1.relationship', ans?.data?.relationship);

    ctx.setFieldValue('customer.contact_points.person2.firstname', ans?.data?.fnameRelation2);
    ctx.setFieldValue('customer.contact_points.person2.lastname', ans?.data?.lnameRelation2);
    ctx.setFieldValue('customer.contact_points.person2.tel', ans?.data?.telRelation2);
    ctx.setFieldValue('customer.contact_points.person2.relationship', ans?.data?.relationship2);

    ctx.setFieldValue('customer.contact_points.person3.firstname', ans?.data?.fnameRelation3);
    ctx.setFieldValue('customer.contact_points.person3.lastname', ans?.data?.lnameRelation3);
    ctx.setFieldValue('customer.contact_points.person3.tel', ans?.data?.telRelation3);
    ctx.setFieldValue('customer.contact_points.person3.relationship', ans?.data?.relationship3);

    ctx.setFieldValue('customer.channel', ans?.data?.channel)
    ctx.setFieldValue('customer.chatcone', ans?.data?.chatcone);
    ctx.setFieldValue('customer.walkin', ans?.data?.walkin);
    ctx.setFieldValue('customer.amplitudeId', ans?.data?.amplitudeId);
    ctx.setFieldValue('customer.platform', ans?.data?.platform);
    setSuccess('ซิงค์ข้อมูลลงทะเบียนสำเร็จ');
  }
  const onChange = (e) => {
    // let id = e.target.value;
    // if (!/^\d{13}$/.test(id)) {
    //     setError('ต้องเป็นตัวเลข 13 หลักไม่มีขีดคั่น');
    // } else {
    //     var digits = [];
    //     for (let i = 0; i < 13; i++) digits.push(parseInt(id[i]));
    //     var checksum = 0;
    //     for (let i = 0; i < 12; i++) checksum += (13 - i) * digits[i];
    //     var digit13 = checksum % 11;
    //     digit13 = digit13 > 1 ? 11 - digit13 : 1 - digit13;
    //     if (digit13 !== digits[12]) {
    //         setError('เลขประชาชนไม่ถูกต้อง');
    //     } else {
    //         setError('');
    //     }
    // }
    setTel(e.target.value);
  };

  const handleOk = () => {
    syncData(ansData)
    setVisible(false)
  }

  return (
    <>
      <Input {...props} onChange={onChange} />
      <ButtonWrap>
        {/* <Button className="search" type="button" onClick={() => searchByTel()}>
          ซิงค์เบอร์โทร
        </Button> */}
        <Button className="search" type="button" onClick={() => searchByIdCard()}>
          ซิงค์บัตรปชช
        </Button>
      </ButtonWrap>
      {loading && <Loading inline />}
      {error && <li style={{ color: '#ff4d4f' }}>{error}</li>}
      {success && <li style={{ color: '#73d13d' }}>{success}</li>}

      <Modal
        open={visible}
        onCancel={() => { setVisible(false) }}
        width={450}
        closable={false}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }}>
          <ExclamationCircleOutlined style={{ color: '#ff4d4f', fontSize: 70 }} />
          <div style={{ paddingTop: 8 }}>
            ข้อมูลที่ลงทะเบียนไม่ตรงกับชื่อในบัตรประชาชน
          </div>
          <div>
            ยืนยันการซิงก์ข้อมูล?
          </div>
        </div>
        <div style={{ paddingLeft: 16 }}>
          <div>
            เลขบัตรประชาชน {idcard || ''}
          </div>
          <div >
            ชื่อที่อ่านได้จากบัตร {fullName || ''}
          </div>
          <div >
            ชื่อที่ลงทะเบียน {ansData?.data?.fname + ' ' + ansData?.data?.lname || ''}
          </div>
          <div >
            เบอร์โทร {ansData?.data?.tel}
          </div>
          <ContainerButton right>
            <ButtonTheme useFor="CUSTOM" title={'ยืนยัน'} onClick={async () => handleOk()} />
            <ButtonTheme useFor="CANCEL" onClick={() => setVisible(false)} />
          </ContainerButton>
        </div>
      </Modal>
    </>
  );
};

export default CustomerInfo;
