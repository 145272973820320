
import moment from 'moment';

export const DEVICE_RETURN_SEARCH_FORM = ({options}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'start_date',
      label: 'เริ่มวันที่ค้นหา',
      placeholder: 'เลือกวันที่เริ่มต้น',
      type: 'DATE_PICKER',
    },
    {
      key: '2',
      span: 6,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
    },
    {
      key: '3',
      span: 6,
      name: 'contract_no',
      label: 'เลขที่สัญญา',
      type: 'INPUT',
      placeholder: 'กรอกเลขที่สัญญา',
    },
    {
      key: '4',
      span: 6,
      name: 'cus_name',
      label: 'ชื่อลูกค้า',
      type: 'INPUT',
      placeholder: 'กรอกชื่อลูกค้า',
    },
    {
      key: '5',
      span: 6,
      name: 'cus_tel',
      label: 'เบอร์โทร',
      type: 'INPUT',
      placeholder: 'กรอกเบอร์โทร',
    },
    {
      key: '6',
      span: 6,
      name: 'trackerID',
      label: 'ผู้ติดตาม',
      placeholder: 'เลือกผู้ติดตาม',
      type: 'SELECT',
      options: options?.trackers,
      allowClear:true,
      value: '',
    },
    {
      key: '7',
      span: 6,
      name: 'status',
      label: 'สถานะคืนเครื่อง',
      placeholder: 'เลือกสถานะคืนเครื่อง',
      type: 'SELECT',
      options: options?.deviceReturn,
      allowClear:true,
      value: '',
    },
  ];
};

export const EDIT_REMARK_FORM = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        required: true,
      },
    },
  ];
};

export const REDEEM_PERIOD_FORM = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'return_cus_date',
      label: 'วันที่ไถ่ถอน',
      placeholder: 'เลือกไถ่ถอน',
      type: 'DATE_PICKER',
      format: 'DD/MM/YYYY',
      disabledDate: (d) => !d || d.isSameOrBefore(moment().add(-1, 'days')),
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
    },
  ];
};

export const DEVICE_RECHECK_FORM = ({images,options}) => {
  return [
    {
      key: '1',
      span: 12,
      name: 'name',
      label: 'ชื่อลูกค้า',
      type: 'INPUT',
      disabled:true
    },
    {
      key: '2',
      span: 12,
      name: 'tel',
      label: 'เบอร์โทร',
      type: 'INPUT',
      disabled:true
    },
    {
      key: '3',
      span: 12,
      name: 'product_name',
      label: 'ชื่อสินค้า',
      type: 'INPUT',
      disabled:true
    },
    {
      key: '4',
      span: 12,
      name: 'sn',
      label: 'Serial Number',
      type: 'INPUT',
      disabled:true
    },
    {
      key: '5',
      span: 12,
      name: 'tracker_name',
      label: 'ชื่อผู้ติดตาม',
      type: 'INPUT',
      disabled:true
    },
    {
      key: '6',
      span: 12,
      name: 'hold_date',
      label: 'วันที่คืนเครื่อง',
      type: 'DATE_PICKER',
      format:'DD/MM/YYYY',
      disabled:true
    },
    {
      key: '7',
      span: 12,
      name: 'cus_picture',
      label: 'รูปถ่ายลูกค้า/สถานที่',
      type: 'VIEWIMAGE',
      defaultValue: images?.customer|| '',
    },
    {
      key: '7',
      span: 12,
      name: 'cus_picture',
      label: 'รูปถ่ายลูกค้า/สถานที่',
      type: 'VIEW_IMAGE_GROUP',
      imageList: images?.product || [],
    },
    {
      key: '8',
      name: 'product_condition',
      label: 'สภาพเครื่อง',
      type: 'RADIO',
      span: 12,
      options: options?.productCondition,
      defaultValue: 'GOOD',
      // onChangeRadio: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
      // rules: {
      //   required: true,
      // },
    },
  ];
};


