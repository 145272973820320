// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Wrapper from '../../views/wrapper/Wrapper';
import {Modal, Card, Spin, Row, Col, Input, Table} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import {SearchLayout} from '../../components/pageLayout/SearchLayout';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../components/buttons';
import {useAuthState} from '../../utils/hook';
import {auth} from '../../utils/firebase';
import {POST, GET, GET_OPTION_BRANCH, INSERT_JOURNAL_ITEM, GET_STOCK_RESERVATION, GET_STOCK_AND_RESERVATION} from '../../services';
import moment from 'moment';
import {CreatePickSearchForm} from './form';
import {StockProductsPickColumn, StockProductsPickMainColumn} from './column';
import {optionProductHandPick} from './utils/constants';
import _ from 'lodash';
import {CardTheme} from '../../components/card/CardTheme';
import {TextLarge} from '../../components/forms/text';
import {TableLayout} from '../../components/pageLayout/TableLayout';

const ProductsPick = () => {
  const [loading, setLoading] = useState(false);
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const [optionBranch, setOptionBranch] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceBranch, setDataSourceBranch] = useState([]);
  const [dataHead, setDataHead] = useState({});
  const [branchToState, setBranchToState] = useState(null);
  const [remark, setRemark] = useState('');
  const history = useHistory();
  const {user} = useAuthState(auth);

  useEffect(() => {
    getOptionBranch();
  }, []);

  const getOptionBranch = async () => {
    try {
      let res = await GET(GET_OPTION_BRANCH);
      const {success, result} = res;
      if (success) {
        setOptionBranch([...result, {value: 'ALL', label: 'ทุกสาขา'}]);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getStock = async (data) => {
    setLoading(true);
    setBranchToState(data.branchTo);
    if (data.branchTo === 'ALL') {
      data.branchTo = '';
    }

    const {branchFrom, branchTo, rangDateTime, productHand} = data;
    const obj = {
      branchFrom: branchFrom || 'CENTER',
      branchTo: branchTo,
      startDateTime: moment(rangDateTime[0]).format('YYYY-MM-DD'),
      endDateTime: moment(rangDateTime[1]).format('YYYY-MM-DD'),
      productHand: productHand,
      // startDateTime: moment(rangDateTime[0]).format('YYYY-MM-DD HH:mm'),
      // endDateTime: moment(rangDateTime[1]).format('YYYY-MM-DD HH:mm'),
    };
    setDataSearch(data);

    try {
      let res = await POST(GET_STOCK_AND_RESERVATION, obj);
      const {success, result} = res;
      if (success) {
        const dataSet = result.stockReserve.items.map((el, i) => {
          return {
            rowNo: i + 1,
            ...el,
          };
        });
        // const dataSet = result.stockReserve.items;
        setDataSource(dataSet);

        // const branch = result.stockReserve.branch.map((item, index) => ({
        //   label: item.value,
        //   branch: item.code,
        //   key: index,
        // }));
        // setDataSourceBranch(branch);

        const {branch_from_name, branch_to_name, document_date_start, document_date_end} = result.stockReserve.header;
        const dataSetSearch = [
          {label: 'สาขาต้นทาง', value: branch_from_name},
          {label: 'สาขาปลายทาง', value: branch_to_name},
          {
            label: 'วันที่ลูกค้าจอง',
            value: `${moment(document_date_start).format('YYYY-MM-DD')} - ${moment(document_date_end).format('YYYY-MM-DD')}`,
          },
          // { label: "วันที่ลูกค้าจอง", value: `${moment(document_date_start).format("YYYY-MM-DD HH:mm")} - ${moment(document_date_end).format("YYYY-MM-DD HH:mm")}` },
        ];
        setDataSearch(dataSetSearch);
        setDataHead({...result.stockReserve.header, productHand: productHand});
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onNextStep = async () => {
    setLoadingNextStep(true);
    const {document_date_end, document_date_start, branchFrom, branchTo} = dataHead;
    const dataItem = dataSource.map((val) => {
      return {
        itemCode: val.item_code,
        itemName: val.product_name,
        countReserve: val.countReserve,
        countStock: val.countStock,
        countPick: val.countPick,
      };
    });
    const obj = {
      startDateTime: moment(document_date_start).format('YYYY-MM-DD HH:mm'),
      endDateTime: moment(document_date_end).format('YYYY-MM-DD HH:mm'),
      branchFrom,
      branchTo,
      remark: remark,
      userLogin: user.email,
      items: dataItem,
    };

    try {
      const res = await POST(INSERT_JOURNAL_ITEM, obj);
      const {success, result} = res;
      if (success) {
        window.open(`/create-pick?document_no=${result.document_no}`, '_blank');
        // history.push({
        //   pathname: `/create-pick`,
        //   state: {
        //     data: {
        //       document_no: result.document_no
        //     },
        //   },
        // });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoadingNextStep(false);
    }
  };

  //   const onChange = (value,ref)=>{
  //     setRemark(value)
  //   }

  const printProductPick = () => {
    const {document_date_end, document_date_start, branchFrom, branchTo, productHand} = dataHead;
    // history.push({
    //   // pathname: '/print-pick',
    //   state: { data: { items: dataSource, page: 'productPick', ...dataHead, remark: remark, amount: dataSource.length } },
    // });
    window.open(
      `/print-pick?page=productsPick&branchFrom=${branchFrom}&branchTo=${branchTo}&dateStart=${document_date_start}&dateEnd=${document_date_end}&remark=${remark}&productHand=${productHand}`,
      '_blank',
    );
  };

  // const expandedRowRender = (value) => {
  //   let dataSc = [];
  //   if (dataSourceBranch.length > 0) {
  //     const dt = dataSource[value?.branch].map((el, i) => {
  //       return {
  //         ...el,
  //         rowNo: i + 1,
  //       };
  //     });
  //     dataSc = _.cloneDeep(dt);
  //   }

  //   return <Table className="sub-nested-table" columns={StockProductsPickColumn()} dataSource={dataSc} pagination={false} />;
  // };

  return (
    <Wrapper>
      <Card>
        <SearchLayout
          props={{
            title: 'เช็คสินค้าจอง',
            icon: <SettingOutlined />,
            spanSearch: 24,
            formSearch: CreatePickSearchForm({options: {optionBranch, optionProductHandPick}}),
            onSearch: (data) => getStock(data),
          }}
        />
        <Spin tip="Loading..." spinning={loading}>
          {dataSource.length > 0 && dataSearch.length > 0 ? (
            <div style={{marginTop: '20px', padding: '12px'}}>
              <Row gutter={[16, 16]} style={{marginBottom: '12px', fontSize: '16px', fontWeight: '800'}} justify="center">
                {dataSearch.map((el, k) => {
                  return (
                    <Col xs={{span: 24}} md={{span: 24}} xl={{span: k == 2 ? 8 : 6}} lg={{span: k == 2 ? 8 : 6}} key={k}>
                      {`${el.label} : `}
                      <br />
                      {`${el.value}`}
                    </Col>
                  );
                })}
              </Row>
              <Row gutter={[16, 16]} justify="center">
                <Col xs={{span: 24}} md={{span: 24}} xl={{span: 22}} lg={{span: 22}}>
                  <Input.TextArea
                    maxLength={500}
                    rows={5}
                    placeholder="ระบุข้อความไม่เกิน 500 ตัวอักษร"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </Col>
              </Row>
              {/* <CardTheme
                style={{marginTop: '2%'}}
                title={
                  <div style={{display: 'flex', alignItems: 'center', color: '#0052BA'}}>
                    &nbsp;
                    <TextLarge text={'สินค้าที่ต้องจัด'} color={'#0052BA'} />
                  </div>
                }
                content={
                  <>
                    <Table
                      className="main-nested-table"
                      columns={StockProductsPickMainColumn()}
                      expandable={{
                        expandedRowRender,
                        defaultExpandAllRows: true,
                        indentSize: 5,
                      }}
                      dataSource={dataSourceBranch}
                    />
                    <ContainerButton center>
                      <ButtonTheme useFor="CONFIRM" title={'พิมพ์เอกสาร'} onClick={() => printProductPick()} />
                      <ButtonTheme
                        useFor="NEXT"
                        title={'ถัดไป'}
                        onClick={() => onNextStep()}
                        disabled={branchToState === 'ALL' ? true : false}
                        loading={loadingNextStep}
                      />
                    </ContainerButton>
                  </>
                }
              /> */}

              <TableLayout
                props={{
                  title: 'สินค้าที่ต้องจัด',
                  columns: StockProductsPickColumn(),
                  dataSource: dataSource,
                  footerContent: (
                    <ContainerButton center>
                      <ButtonTheme useFor="CONFIRM" title={'พิมพ์เอกสาร'} onClick={() => printProductPick()} />
                      <ButtonTheme
                        useFor="NEXT"
                        title={'ถัดไป'}
                        onClick={() => onNextStep()}
                        disabled={branchToState === 'ALL' ? true : false}
                        loading={loadingNextStep}
                      />
                    </ContainerButton>
                  ),
                }}
              />
            </div>
          ) : null}
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default ProductsPick;
