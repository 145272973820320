import React from 'react';
import {Controller} from 'react-hook-form';
import {compareRender} from '../../functions/fn';
import {TextLarge} from './text';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {Row, Col} from 'antd';

const getIcon = (icon, style) => {
  switch (icon) {
    case 'CHECK':
      return <CheckOutlined style={{color: 'green', ...style}} />;
    case 'CLOSE':
      return <CloseOutlined style={{color: 'red', ...style}} />;
    default:
      return;
  }
};

export const IconTextForm = React.memo(
  ({item, control}) => {
    const {icon, size, color: iconColor, iconSpan} = item.iconProps;
    const {text, color: textColor, bold, marginTop, align, textSpan} = item.textStyle;
    const style = {
      fontSize: size ? size : 22,
      ...(iconColor ? {color: iconColor} : {}),
    };

    return (
      <>
        <Controller
          value={item.value}
          render={() => (
            <>
              <Row style={{width: '100%', paddingLeft: '8px'}}>
                <Col span={iconSpan}>{getIcon(icon, style)}</Col>
                <Col span={textSpan}>
                  <TextLarge text={text} color={textColor} align={align} bold={bold} marginTop={marginTop} />
                </Col>
              </Row>
            </>
          )}
          name="IconTextForm"
          control={control}
          rules={item.rules}
        />
      </>
    );
  },
  (prev, next) =>
    compareRender(prev, next) && JSON.parse(JSON.stringify(prev.item)) === JSON.parse(JSON.stringify(next.item)) && compareRender(prev, next, 'item'),
);
