import React from 'react';
import PropTypes from 'prop-types';
import {WrapperLoading, LoadingItem} from './LoadingStyle';

const Wsloading = (props) => {
  const {inline} = props;
  return (
    <WrapperLoading inline={inline}>
      <LoadingItem inline={inline} />
    </WrapperLoading>
  );
};

Wsloading.propTypes = {
  inline: PropTypes.bool,
};

Wsloading.defaultProps = {
  inline: false,
};

export default Wsloading;
