import React from 'react';
import { DatePicker } from 'antd';
import { Controller } from 'react-hook-form';
import { compareRender } from '../../functions/fn';
import moment from 'moment';
const { RangePicker } = DatePicker;
export const RangeDatePickerForm = React.memo(({ item, control }) => {

  return (
    <>
      <Controller
        name={item.name}
        control={control}
        rules={item.rules}
        value={item.value}
        defaultValue={item.defaultValue}
        render={({ field }) => (
          <>
            <RangePicker
              allowEmpty={[true, true]}
              placeholder={item.placeholder || ''}
              disabledDate={item.disabledDate}
              disabled={item.disabled}
              value={field.value ? field.value : null}
              style={{ width: '100%', marginTop: 5 }}
              onChange={item.onChange ? item.onChange : field.onChange}
              format={item.format}
            // onChange={(errors[item.name]) ? clearErrors(item.name) && onChange : onChange}
            />
          </>
        )}
      />

    </>
  );
}, compareRender);
