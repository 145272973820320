import * as TYPES from '../types/contract';
import * as API from '../api/contract';

export const getContracts = (params) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_LIST_REQ });
    try {
        const response = await API.getContracts(params);
        if (response.status === 200) {
            dispatch({ type: TYPES.CONTRACT_LIST_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.CONTRACT_LIST_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_LIST_FAIL, payload: error });
    }
};

export const getContractPeriods = (contractNumber) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_PERIOD_LIST_REQ });
    try {
        const response = await API.getContractPeriods(contractNumber);
        if (response.status === 200) {
            dispatch({ type: TYPES.CONTRACT_PERIOD_LIST_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.CONTRACT_PERIOD_LIST_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_PERIOD_LIST_FAIL, payload: error });
    }
};

export const clearContractPeriods = () => (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_PERIOD_LIST_CLEAR });
};

export const exportContract = (contractNumber) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_EXPORT_REQ });
    try {
        const response = await API.exportContract(contractNumber);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = `${contractNumber}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            dispatch({ type: TYPES.CONTRACT_EXPORT_SUCCESS, payload: url });
        } else {
            dispatch({ type: TYPES.CONTRACT_EXPORT_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_EXPORT_FAIL, payload: error });
    }
};

export const exportReciveContract = (data) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_RECIVE_EXPORT_REQ });
    try {
        const response = await API.exportReciveContract(data);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = `ใบส่งสินค้า.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            dispatch({ type: TYPES.CONTRACT_RECIVE_EXPORT_SUCCESS, payload: url });
        } else {
            dispatch({ type: TYPES.CONTRACT_RECIVE_EXPORT_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_RECIVE_EXPORT_FAIL, payload: error });
    }
};

export const getContractList = (params) => async (dispatch) => {

 
    dispatch({ type: TYPES.CONTRACT_LIST_TRADESYS_REQ });
    try {
        const response = await API.getContractList(params);
        if (response.status === 200) {
            dispatch({ type: TYPES.CONTRACT_LIST_TRADESYS_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.CONTRACT_LIST_TRADESYS_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_LIST_TRADESYS_FAIL, payload: error });
    }
};

export const getContractInfo = (contractNumber) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_INFO_REQ });
    try {
        const response = await API.getContractInfo(contractNumber);
        if (response.status === 200) {
            dispatch({ type: TYPES.CONTRACT_INFO_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.CONTRACT_INFO_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_INFO_FAIL, payload: error });
    }
};

export const updateContractInfo = (contractNumber, data) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_UPDATE_REQ });
    try {
        const response = await API.updateContractInfo(contractNumber, data);
        if (response.status === 200) {
            dispatch({ type: TYPES.CONTRACT_UPDATE_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.CONTRACT_UPDATE_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_UPDATE_FAIL, payload: error });
    }
};

export const updateContractGradeInfo = (contractNumber, data) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_GRADE_UPDATE_REQ });
    try {
        const response = await API.updateContractGradeInfo(contractNumber, data);
        if (response.status === 200) {
            dispatch({ type: TYPES.CONTRACT_GRADE_UPDATE_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.CONTRACT_GRADE_UPDATE_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_GRADE_UPDATE_FAIL, payload: error });
    }
};

export const terminatedContract = (contractNumber,data) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_TERMINATED_REQ });
   
    try {
        const response = await API.terminatedContract(contractNumber,data);
        if (response.status === 200) {
            dispatch({ type: TYPES.CONTRACT_TERMINATED_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.CONTRACT_TERMINATED_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_TERMINATED_FAIL, payload: error });
    }
};

export const impoundedContract = (contractNumber) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_IMPOUNDED_REQ });
    try {
        const response = await API.impoundedContract(contractNumber);
        if (response.status === 200) {
            dispatch({ type: TYPES.CONTRACT_IMPOUNDED_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.CONTRACT_IMPOUNDED_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_IMPOUNDED_FAIL, payload: error });
    }
};

export const exportContractTerminated = (contractNumber,misTaskId) => async (dispatch) => {
    dispatch({ type: TYPES.CONTRACT_EXPORT_TERMINATED_REQ });
    try {
        const response = await API.exportContractTerminated(contractNumber,misTaskId);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = `หนังสือบอกเลิกสัญญา ${contractNumber}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            dispatch({ type: TYPES.CONTRACT_EXPORT_TERMINATED_SUCCESS, payload: url });
        } else {
            dispatch({ type: TYPES.CONTRACT_EXPORT_TERMINATED_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.CONTRACT_EXPORT_TERMINATED_FAIL, payload: error });
    }
};