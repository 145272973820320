import React, { useEffect, useState } from 'react';
import { Card ,Spin,Row,Col,Modal,Form} from 'antd';
import Wrapper from '../../../views/wrapper/Wrapper';
import {TableLayout} from '../../../components/pageLayout/TableLayout'
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {LineAdjustStockNonSerialColumn} from '../../../constants/global/columnTableForm'
import {useAuthState} from '../../../utils/hook';
import {auth} from '../../../utils/firebase';
import {EditableCellStockNonSerial} from '../form/editCellAdjustStockNonSerial'
import moment from 'moment';
import {GET,GET_ITEMS_NON_SERIAL,GET_NO_ADJUST_NON_SERIAL,INSERT_ADJUST_NON_SERIAL, POST,GET_BRANCH,GET_ITEMS_NON_SERIAL_INFO} from '../../../services/index'
import {ExclamationCircleOutlined} from '@ant-design/icons';

const AdjustStockNonSerial = () => {
	const [loading,setLoading] = useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [optionBranch,setOptionBranch] = useState([])
  const [docNoAdjust,setDocNoAdjust] = useState("")
  const [optionItems,setOptionItems] = useState([])

  useEffect(()=>{
    getNoAdjust()
    getItemsNonSerial()
    getOptionBranch()
  },[])

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const {success, result} = res;
      if (success) {
        const option = result.branch.map((el)=>{
          return {
            label:el.label,
            value:el.value
          }
        })
        setOptionBranch(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  };

  const getNoAdjust = async()=>{
    try {
      const res = await GET(GET_NO_ADJUST_NON_SERIAL)
      const {success,result}= res
      if(success){
        setDocNoAdjust(result.document_no)
      }
      
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  }

  const getItemsNonSerial = async()=>{
    setLoading(true)
    try {
      const res = await GET(GET_ITEMS_NON_SERIAL)
     const {success,result} = res
      if(success){
        setOptionItems(result.items)
      } 
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }finally{
      setLoading(false)
    }
  }


  const handleAdd = () => { 
    const newData = {
      key: dataSource.length +1,
      item_code: null,
      stock: 0,
      cost:0,
      item_name:'',
      branch:null,
      branch_name:''
    };
    setDataSource([...dataSource, newData]);
  };

  const getInfoItemNonSerial = async(code,branch,key)=>{
      try {
        const res = await GET(GET_ITEMS_NON_SERIAL_INFO(code,branch))
        const {success,result} = res
        if(success){
          dataSource.forEach((el)=>{
            if(el.key === key){
             
              el.cost = result.item.cost && result.item.cost.toString() || ''
              el.stock = result.item.stock && result.item.stock.toString() || ''
            }
          })

          setDataSource([...dataSource])
        }
        
      } catch (err) {
         const {message} = err;
          Modal.error({
            title: message,
          });
      }
  }

  const onChange = (value,ref,key)=>{

      dataSource.forEach((el)=>{
        if(el.key === key){
          if(ref ==="item_code"){
            el.item_code = value
            const dataFilter = optionItems.filter((el)=>el.value === value)
            el.item_name = dataFilter.length > 0 ? dataFilter[0].label : ''
           
             if(el.branch && value){
              getInfoItemNonSerial(value,el.branch,key)
            }

          }else if(ref ==="branch"){
            el.branch = value || null
            el.branch_name = value && optionBranch.filter((el)=>el.value === value)[0].label || ""
            if(el.item_code && value){
              getInfoItemNonSerial(el.item_code,value,key)
            }
          }else{
            el[ref] = value
          }
        }
      })
  }

  const onRemove = (key)=>{
    const data = dataSource.filter((i)=>i.key !== key)
    setDataSource(data)

  }

 const handleInsertAdjust = async()=>{

  const data_items = dataSource.map((el)=>{
    const {item_code,item_name,branch,cost,stock} = el
    return {item_code,item_name,branch,cost:cost && +cost,qty:+stock}
  })
      
    const datasSend = {
        documentNo:docNoAdjust,
        items:data_items
      }
    

    setLoading(true)
    try {
      const res = await POST(INSERT_ADJUST_NON_SERIAL,datasSend)
      const {success,message} = res
      if(success){
        Modal.success({
          title: message,
        });
        setDataSource([])
        getNoAdjust()
      }

     
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }finally{
      setLoading(false)
    }
  
 }

 const validateDataCell = ()=>{
  let item_code = []
  let branch = []
  let cost = []
  let stock = []

  dataSource.forEach((el)=>{
      if(!el.item_code){
        item_code.push({key:el.key})
      }else if(!el.cost){
        cost.push({key:el.key})
      }else if(!el.branch){
        branch.push({key:el.key})
      }else if(!el.stock){
        stock.push({key:el.key})
      }
  })

  if(item_code.length > 0 || branch.length > 0 || cost.length > 0|| stock.length > 0){
    Modal.error({
      title:"กรุณากรอกข้อมูลให้ครบถ้วน"
    })
  }else{
    onConfirm()
  }
}

 const onConfirm = ()=>{
  Modal.confirm({
    title: 'ยืนยันปรับปรุงสต็อก',
    icon: <ExclamationCircleOutlined />,
    content: 'ต้องการการปรับปรุงสต็อก ใช่หรือไม่',
    onOk() {
      handleInsertAdjust();
    },
    okText: 'ยืนยัน',
    cancelText: 'ยกเลิก',
  });
 }


 const components = {
  body: {
    cell: EditableCellStockNonSerial,
  },
};
  return (
	<Wrapper>
	<Card >
		<Spin tip="Loading..." spinning={loading}>
				
				<TableLayout 
					props={{
						title: 'ปรับปรุงสต็อกสินค้า Non Serial',
						columns: LineAdjustStockNonSerialColumn({onChange,onRemove,options:{optionItems,optionBranch}}),
						dataSource: dataSource,
						components:components,
						extraContent:(
							<>
							<Row gutter={[16, 16]} justify="right" style={{marginBottom:"20px"}}>
								<Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}}>
									{`วันที่ทำรายการ ${moment().format("DD-MM-YYYY")}`}
								</Col>
								<Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}}>
									{`เลขที่เอกสาร : ${docNoAdjust}`}
								</Col>
							</Row>
								<ContainerButton left>
								<ButtonTheme useFor="CREATE" title={'เพิ่มสินค้า'} onClick={handleAdd}/>
								</ContainerButton>
							</>
						),
						footerContent: dataSource.length > 0?(
							<ContainerButton center>
							  <ButtonTheme useFor="SUBMIT" title={'บันทึก'} onClick={validateDataCell}/>
							</ContainerButton>
						  ):''
					}}
					/>
				
		</Spin>
    </Card>
	</Wrapper>
  );
};

export default AdjustStockNonSerial;