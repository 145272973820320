import {Modal} from '@mui/material';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

export const createStockBranchExcelWorkBook = async (data, sumAll, branch) => {
  const sum = [sumAll][0];

  var i3, i4;
  var text = '';
  let cellGen = [];

  for (i3 = 9; i3 < 26; i3++) {
    text = String.fromCharCode(97 + i3).toUpperCase() + '1';
    cellGen.push(text);
  }
  for (i3 = 0; i3 < 26; i3++) {
    for (i4 = 0; i4 < 26; i4++) {
      text = String.fromCharCode(97 + i3).toUpperCase() + String.fromCharCode(97 + i4).toUpperCase() + '1';
      cellGen.push(text);
    }
  }
  let branchTotal = branch.length * 4;
  let cellGenCut = cellGen.slice(0, branchTotal);

  let collGenCut2 = cellGenCut.map((el) => {
    return el.split('1')[0];
  });

  let dynamicBranchColumnIntransit = [];
  let dynamicBranchColumnNotIntransit = [];

  let index2 = 0;

  while (index2 < branchTotal) {
    let col = collGenCut2.slice(index2, index2 + 4);
    let index = collGenCut2.indexOf(col[1]);

    let lastColumn = collGenCut2[index + 3];
    if (lastColumn === undefined) {
      lastColumn = collGenCut2[index + 2];
    }

    dynamicBranchColumnIntransit.push(`${col[1]}2`);
    dynamicBranchColumnNotIntransit.push(`${col[2]}2:${lastColumn}2`);

    index2 += 4;
  }

  const keyStockStatus = [
    {value: 'total', label: 'จำนวน'},
    {value: 'inTransit', label: 'ระหว่างส่ง'},
    {value: 'reserve', label: 'จอง'},
    {value: 'remain', label: 'คงเหลือ'},
  ];

  const workBook = new ExcelJS.Workbook();
  let date = new Date();
  workBook.creator = 'Ufriend Admin';
  workBook.created = date;
  workBook.modified = date;
  workBook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 0,
      visibility: 'visible',
    },
  ];

  const sheet1 = workBook.addWorksheet('รายงานสต็อกแยกตามสาขา', {
    properties: {tabColor: {argb: '00B050'}},
    views: [{state: 'frozen', ySplit: 3, xSplit: 1}],
  });

  let columnsHeader = [];

  branch.map((el) => {
    columnsHeader.push(
      {header: `${el.label}`, key: `${el.value}_total`, width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
      {key: `${el.value}_inTransit`, width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
      {key: `${el.value}_reserve`, width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
      {key: `${el.value}_remain`, width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    );
  });

  sheet1.columns = [
    {header: 'ชื่อสินค้า', key: 'name', width: 50, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    {header: 'ทุกสาขา', key: 'total', width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    {header: 'รอขาย', key: 'CENTER_waitSell', width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    {header: 'On Hold', key: 'ON_HOLD_total', width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    {key: 'ON_HOLD_inTransit', width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    {key: 'ON_HOLD_remain', width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    {header: 'CENTER: วงเวียนใหญ่', key: 'CENTER_total', width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    {key: 'CENTER_inTransit', width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    {key: 'CENTER_reserve', width: 19, style: {font: {name: 'Calibri', bold: true, underline: false, size: 12}}},
    // { key: 'CENTER_waitSell', width: 19, style: { font: { name: 'Calibri', bold: true, underline: false, size: 12 } } },
    ...columnsHeader,
  ];

  sheet1.eachRow({includeEmpty: false}, function (dataRow, dataRowNumber) {
    dataRow.eachCell(function (dataCell, dataCellNumber) {
      dataCell.style = Object.create(dataCell.style);
      dataCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'ff666666'}}; // Set background
    });
  });

  sheet1.getRow(1).alignment = {horizontal: 'center', vertical: 'center'};
  sheet1.getRow(1).border = {bottom: {style: 'thick', color: '#000000'}};

  sheet1.getColumn('A').alignment = {horizontal: 'left'};
  sheet1.getColumn('A').border = {right: {style: 'thick', color: '#000000'}};

  let columnsSumHeaderTemp = [];

  branch.map((el) => {
    let keyArr = [];
    keyStockStatus.map((k) => {
      keyArr.push(`${el.value}_${k.value}`);
    });
    let obj = {
      [keyArr[0]]: sum[`${el.value}`].sumTotal,
      [keyArr[1]]: sum[`${el.value}`].sumInTransit,
      [keyArr[2]]: sum[`${el.value}`].sumReserve,
      [keyArr[3]]: sum[`${el.value}`].sumRemain,
    };
    columnsSumHeaderTemp.push(obj);
  });

  let columnsSumHeader = columnsSumHeaderTemp.reduce(function (acc, x) {
    for (var key in x) acc[key] = x[key];
    return acc;
  }, {});

  sheet1.addRow({
    name: '',
    total: '',
    CENTER_waitSell: '',
    ON_HOLD_total: sum.ON_HOLD.sumTotal,
    ON_HOLD_inTransit: sum.ON_HOLD.sumInTransit,
    ON_HOLD_remain: sum.ON_HOLD.sumRemain,
    CENTER_total: sum.CENTER.sumTotal,
    CENTER_inTransit: sum.CENTER.sumInTransit,
    CENTER_reserve: sum.CENTER.sumReserve,
    // CENTER_waitSell: sum.CENTER.sumWaitSell,
    ...columnsSumHeader,
  });

  sheet1.getRow(2).alignment = {horizontal: 'center', vertical: 'center'};

  let columnsLabelHeaderTemp = [];

  branch.map((el) => {
    let keyArr = [];

    keyStockStatus.map((k) => {
      keyArr.push(`${el.value}_${k.value}`);
    });
    let obj = {
      [keyArr[0]]: 'จำนวน',
      [keyArr[1]]: 'ระหว่างส่ง',
      [keyArr[2]]: 'จอง',
      [keyArr[3]]: 'คงเหลือ',
    };

    columnsLabelHeaderTemp.push(obj);
  });

  let columnsLabelHeader = columnsLabelHeaderTemp.reduce(function (acc, x) {
    for (var key in x) acc[key] = x[key];
    return acc;
  }, {});

  sheet1.addRow({
    name: '',
    total: sum.sumTotal,
    CENTER_waitSell: sum.CENTER.sumWaitSell,
    ON_HOLD_total: 'จำนวน',
    ON_HOLD_inTransit: 'ระหว่างส่ง',
    ON_HOLD_remain: 'คงเหลือ',
    CENTER_total: 'จำนวน',
    CENTER_inTransit: 'Ship',
    CENTER_reserve: 'จอง',
    // CENTER_waitSell: 'รอขาย',
    ...columnsLabelHeader,
  });

  sheet1.getRow(3).alignment = {horizontal: 'center', vertical: 'center'};
  sheet1.getRow(3).border = {bottom: {style: 'thick', color: '#000000'}};
  sheet1.getRow(3).eachCell(function (cell, colNumber) {
    cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'ff666666'}};
  });

  // sheet1.autoFilter = {
  //     from: 'A4',
  //     to: 'MK'
  // }

  let index = 0;

  sheet1.mergeCells('A1:A3');
  sheet1.mergeCells('B1:B2');
  sheet1.mergeCells('C1:C2');
  sheet1.mergeCells('D1:F1');
  sheet1.mergeCells('G1:I1');

  while (index < branchTotal) {
    let cell = cellGenCut.slice(index, index + 4);
    sheet1.mergeCells(`${cell[0]}:${cell[3]}`);
    index += 4;
  }

  let columnsFirstRowDataTemp = [];

  branch.map((el) => {
    let keyArr = [];

    keyStockStatus.map((k) => {
      keyArr.push(`${el.value}_${k.value}`);
    });

    let obj = {
      [keyArr[0]]: parseInt(data[0][`${el.value}`].total),
      [keyArr[1]]: parseInt(data[0][`${el.value}`].inTransit),
      [keyArr[2]]: parseInt(data[0][`${el.value}`].reserve),
      [keyArr[3]]: parseInt(data[0][`${el.value}`].remain),
    };

    columnsFirstRowDataTemp.push(obj);
  });

  let columnsFirstRowData = columnsFirstRowDataTemp.reduce(function (acc, x) {
    for (var key in x) acc[key] = x[key];
    return acc;
  }, {});

  if (!!data && data.length > 0) {
    if (data[0].name) {
      sheet1.addRow({
        name: data[0].name,
        total: parseInt(data[0].total),
        CENTER_waitSell: parseInt(data[0].CENTER.waitSell),
        ON_HOLD_total: parseInt(data[0].ON_HOLD.total),
        ON_HOLD_inTransit: parseInt(data[0].ON_HOLD.inTransit),
        ON_HOLD_remain: parseInt(data[0].ON_HOLD.remain),
        CENTER_total: parseInt(data[0].CENTER.total),
        CENTER_inTransit: parseInt(data[0].CENTER.inTransit),
        CENTER_reserve: parseInt(data[0].CENTER.reserve),
        // CENTER_waitSell: parseInt(data[0].CENTER.waitSell),
        ...columnsFirstRowData,
      });

      // sheet1.getRow(4).font = {name: 'Calibri', bold: true, underline: false, size: 11};
      sheet1.getRow(4).alignment = {horizontal: 'center', vertical: 'center'};
      sheet1.getColumn('A').alignment = {horizontal: 'left'};
    }

    let columnsRowDataTemp = [];

    const renderRowData = (i) => {
      branch.map((el) => {
        let keyArr = [];

        keyStockStatus.map((k) => {
          keyArr.push(`${el.value}_${k.value}`);
        });
        let obj = {
          [keyArr[0]]: parseInt(data[i][`${el.value}`].total),
          [keyArr[1]]: parseInt(data[i][`${el.value}`].inTransit),
          [keyArr[2]]: parseInt(data[i][`${el.value}`].reserve),
          [keyArr[3]]: parseInt(data[i][`${el.value}`].remain),
        };

        columnsRowDataTemp.push(obj);
      });

      let rowData = columnsRowDataTemp.reduce(function (acc, x) {
        for (var key in x) acc[key] = x[key];
        return acc;
      }, {});

      return rowData;
    };

    for (let i = 1; i < data.length; i++) {
      let item = data[i];
      let columnsRowData = renderRowData(i);

      if (item.name) {
        sheet1.addRow(
          {
            name: item.name,
            total: parseInt(item.total),
            CENTER_waitSell: parseInt(item.CENTER.waitSell),
            ON_HOLD_total: parseInt(item.ON_HOLD.total),
            ON_HOLD_inTransit: parseInt(item.ON_HOLD.inTransit),
            ON_HOLD_remain: parseInt(item.ON_HOLD.remain),
            CENTER_total: parseInt(item.CENTER.total),
            CENTER_inTransit: parseInt(item.CENTER.inTransit),
            CENTER_reserve: parseInt(item.CENTER.reserve),
            // CENTER_waitSell: parseInt(item.CENTER.waitSell),
            ...columnsRowData,
          },
          'i',
        );
      }
    }
  }

  sheet1.addConditionalFormatting({
    ref: 'D2:G2',
    rules: [
      {
        type: 'expression',
        formulae: ['D2:G2>0'],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ff93c47d'},  
          },
          font: {
            bold: true,
            color: {argb: 'FFFFFFFF'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: 'D2:G2',
    rules: [
      {
        type: 'expression',
        formulae: ['D2:G2=0'],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffefefef'},  
          },
          font: {
            bold: true,
            color: {argb: 'ffdce0e8'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: 'D2:G2',
    rules: [
      {
        type: 'expression',
        formulae: ['D2:G2<0'],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffff0000'},  
          },
          font: {
            bold: true,
            color: {argb: 'ff000000'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: 'H2',
    rules: [
      {
        type: 'expression',
        formulae: ['H2>0'],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffffd966'},  
          },
          font: {
            bold: true,
            color: {argb: 'ff000000'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: `I2:J2`,
    rules: [
      {
        type: 'expression',
        formulae: [`I2:J2>0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ff93c47d'},  
          },
          font: {
            bold: true,
            color: {argb: 'FFFFFFFF'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: `I2:J2`,
    rules: [
      {
        type: 'expression',
        formulae: [`I2:J2=0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffefefef'},  
          },
          font: {
            bold: true,
            color: {argb: 'ffdce0e8'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: `I2:J2`,
    rules: [
      {
        type: 'expression',
         
        formulae: [`I2:J2<0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffff0000'},  
          },
          font: {
            bold: true,
            color: {argb: 'ff000000'},  
          },
        },
      },
    ],
  });

  
  sheet1.addConditionalFormatting({
    ref: `H4:H${data.length + 3}`,
    rules: [
      {
        type: 'expression',
        formulae: [`H4:H${data.length + 3}>0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffffd966'},  
          },
          font: {
            bold: true,
            color: {argb: 'ff000000'},  
          },
        },
      },
    ],
  });

  for (let i = 0; i < dynamicBranchColumnIntransit.length; i++) {
    sheet1.addConditionalFormatting({
      ref: `${dynamicBranchColumnIntransit[i]}`,
      rules: [
        {
          type: 'expression',
          formulae: [`${dynamicBranchColumnIntransit[i]}>0`],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: {argb: 'ffffd966'},  
            },
            font: {
              bold: true,
              color: {argb: 'ff000000'}, // Dark Red Text
            },
          },
        },
      ],
    });
    sheet1.addConditionalFormatting({
      ref: `${dynamicBranchColumnIntransit[i]}`,
      rules: [
        {
          type: 'expression',
          formulae: [`${dynamicBranchColumnIntransit[i]}=0`],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: {argb: 'ffefefef'},  
            },
            font: {
              bold: true,
              color: {argb: 'ffdce0e8'},  
            },
          },
        },
      ],
    });
    sheet1.addConditionalFormatting({
      ref: `${dynamicBranchColumnNotIntransit[i]}`,
      rules: [
        {
          type: 'expression',
          formulae: [`${dynamicBranchColumnNotIntransit[i]}>0`],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: {argb: 'ff93c47d'},  
            },
            font: {
              bold: true,
              color: {argb: 'FFFFFFFF'},  
            },
          },
        },
      ],
    });
    sheet1.addConditionalFormatting({
      ref: `${dynamicBranchColumnNotIntransit[i]}`,
      rules: [
        {
          type: 'expression',
          formulae: [`${dynamicBranchColumnNotIntransit[i]}=0`],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: {argb: 'ffefefef'},  
            },
            font: {
              bold: true,
              color: {argb: 'ffdce0e8'},  
            },
          },
        },
      ],
    });
    sheet1.addConditionalFormatting({
      ref: `${dynamicBranchColumnNotIntransit[i]}`,
      rules: [
        {
          type: 'expression',
          formulae: [`${dynamicBranchColumnNotIntransit[i]}<0`],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: {argb: 'ffff0000'},  
            },
            font: {
              bold: true,
              color: {argb: 'ff000000'},  
            },
          },
        },
      ],
    });
  }

  sheet1.addConditionalFormatting({
    ref: `B3:C3`,
    rules: [
      {
        type: 'expression',
        formulae: [`B3:C3>0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ff93c47d'},  
          },
          font: {
            bold: true,
            color: {argb: 'FFFFFFFF'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: `B3:C3`,
    rules: [
      {
        type: 'expression',
        formulae: [`B3:C3=0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffefefef'},  
          },
          font: {
            bold: true,
            color: {argb: 'ffdce0e8'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: `B3:C3`,
    rules: [
      {
        type: 'expression',
        formulae: [`B3:C3<0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffff0000'},  
          },
          font: {
            bold: true,
            color: {argb: 'ff000000'},  
          },
        },
      },
    ],
  });

  let index4 = 0;

  while (index4 < branchTotal) {
    let col = collGenCut2.slice(index4, index4 + 4);
    sheet1.addConditionalFormatting({
      ref: `${col[1]}4:${col[1]}${data.length + 3}`,
      rules: [
        {
          type: 'expression',
          formulae: [`${col[1]}4:${col[1]}${data.length + 3}>0`],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: {argb: 'ffffd966'},  
            },
            font: {
              bold: true,
              color: {argb: 'ff000000'},  
            },
          },
        },
      ],
    });
    index4 += 4;
  }

  sheet1.addConditionalFormatting({
    ref: `B4:${collGenCut2[collGenCut2.length - 1]}${data.length + 3}`,
    rules: [
      {
        type: 'expression',
        formulae: [`B4:${collGenCut2[collGenCut2.length - 1]}${data.length + 3}>0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ff93c47d'},  
          },
          font: {
            bold: true,
            color: {argb: 'FFFFFFFF'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: `B4:${collGenCut2[collGenCut2.length - 1]}${data.length + 3}`,
    rules: [
      {
        type: 'expression',
        formulae: [`B4:${collGenCut2[collGenCut2.length - 1]}${data.length + 3}=0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffefefef'},  
          },
          font: {
            bold: true,
            color: {argb: 'ffdce0e8'},  
          },
        },
      },
    ],
  });

  sheet1.addConditionalFormatting({
    ref: `B4:${collGenCut2[collGenCut2.length - 1]}${data.length + 3}`,
    rules: [
      {
        type: 'expression',
        formulae: [`B4:${collGenCut2[collGenCut2.length - 1]}${data.length + 3}<0`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: {argb: 'ffff0000'},  
          },
          font: {
            bold: true,
            color: {argb: 'ff000000'},  
          },
        },
      },
    ],
  });

  try {
    const buffer = await workBook.xlsx.writeBuffer();
    const fileType = 'apPHITSANULOKication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const blob = new Blob([buffer], {type: fileType});

    FileSaver.saveAs(blob, `รายงานสต็อกแยกตามสาขา` + EXCEL_EXTENSION);
  } catch (err) {
    console.error(err);
    const {message, error} = err;
    Modal.error({
      title: message,
      content: error || '',
    });
  }
};
