export const optionDeviceReturnStatus = [
    {
        value:"WAIT_REDEEM",
        label:"รอไถ่ถอน"
    },
    {
        value:"RETURN_CUS",
        label:"คืนเครื่องลูกค้า"
    },
    {
        value:"RETURN_STORE",
        label:"คืนเครื่อง"
    },
    {
        value:"OVERDUE_REDEEM",
        label:"เกินเวลาไถ่ถอน"
    },
    {
        value:"RETURN_STORE_SUCCESS",
        label:"รับคืนเครื่องสำเร็จ"
    }
]

export const optionProductCondition = [
    {
        value:"GOOD",
        label:"สมบูรณ์"
    },
    {
        value:"NOT_GOOD",
        label:"ไม่สมบูรณ์"
    }
    
]