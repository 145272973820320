import { useDispatch, useSelector } from 'react-redux';
import * as todoActions from '../redux/actions/contract';

export const useContract = () => {
    const dispatch = useDispatch();
    const loadingContractList = useSelector((state) => state.contract_list.loading);
    const errorContractList = useSelector((state) => state.contract_list.error);
    const contractList = useSelector((state) => state.contract_list.contracts);

    const loadingPeriodList = useSelector((state) => state.period_list.loading);
    const errorPeriodList = useSelector((state) => state.period_list.error);
    const periodList = useSelector((state) => state.period_list.periods);

    async function dispatchGetContracts(params) {
        dispatch(todoActions.clearContractPeriods());
        dispatch(todoActions.getContracts(params));
    }

    async function dispatchClearContractPeriods(params) {
        dispatch(todoActions.clearContractPeriods());
    }


    async function dispatchGetContractPeriods(contractNumber) {
        dispatch(todoActions.getContractPeriods(contractNumber));
    }

    const contractExport = {
        loading: useSelector((state) => state.contract_export.loading),
        error: useSelector((state) => state.contract_export.error),
        file: useSelector((state) => state.contract_export.file)
    };

    const contractReciveExport = {
        loading: useSelector((state) => state.contract_recive_export.loading),
        error: useSelector((state) => state.contract_recive_export.error),
        file: useSelector((state) => state.contract_recive_export.file)
    };

    const contractExportTerminated = {
        loading: useSelector((state) => state.contract_export_terminated.loading),
        error: useSelector((state) => state.contract_export_terminated.error),
        file: useSelector((state) => state.contract_export_terminated.file)
    };



    const contractListTradesys = {
        loading: useSelector((state) => state.contract_list_tradesys.loading),
        error: useSelector((state) => state.contract_list_tradesys.error),
        size: useSelector((state) => state.contract_list_tradesys.size),
        page: useSelector((state) => state.contract_list_tradesys.page),
        // contracts: useSelector((state) => state.contract_list_tradesys.contracts),
        contractInfo: useSelector((state) => state.contract_list_tradesys.contractInfo),
        totalItem: useSelector((state) => state.contract_list_tradesys.totalItem),
        totalPage: useSelector((state) => state.contract_list_tradesys.totalPage)
    };

    const contractInfo = {
        loading: useSelector((state) => state.contract_info_tradesys.loading),
        error: useSelector((state) => state.contract_info_tradesys.error),
        info: useSelector((state) => state.contract_info_tradesys.info)
    };

    const contractUpdate = {
        loading: useSelector((state) => state.contract_update_tradesys.loading),
        error: useSelector((state) => state.contract_update_tradesys.error),
        message: useSelector((state) => state.contract_update_tradesys.message)
    };

    async function dispatchExportContract(contractNumber) {
        dispatch(todoActions.exportContract(contractNumber));
    }

    async function dispatchExportReciveContract(data) {
        dispatch(todoActions.exportReciveContract(data));
    }


    async function dispatchGetContractList(params) {
        dispatch(todoActions.getContractList(params));
    }

    async function dispatchGetContractInfo(contractNumber) {
        dispatch(todoActions.getContractInfo(contractNumber));
    }

    async function dispatchUpdateContract(contractNumber, data) {
        await dispatch(todoActions.updateContractInfo(contractNumber, data));
        dispatch(todoActions.getContractInfo(contractNumber));
    }

    async function dispatchUpdateContractGrade(contractNumber, data) {
        await dispatch(todoActions.updateContractGradeInfo(contractNumber, data));
        dispatch(todoActions.getContractInfo(contractNumber));
    }

    async function dispatchTerminatedContract(contractNumber, data) {
        await dispatch(todoActions.terminatedContract(contractNumber, data));
        dispatch(todoActions.getContractInfo(contractNumber));
    }

    async function dispatchImpoundedContract(contractNumber) {
        await dispatch(todoActions.impoundedContract(contractNumber));
        dispatch(todoActions.getContractInfo(contractNumber));
    }

    async function dispatchExportContractTerminated(contractNumber, misTaskId) {
        dispatch(todoActions.exportContractTerminated(contractNumber, misTaskId));
    }

    return {
        loadingContractList,
        errorContractList,
        contractList,
        loadingPeriodList,
        errorPeriodList,
        periodList,
        dispatchGetContracts,
        dispatchGetContractPeriods,
        contractExport,
        contractReciveExport,
        contractListTradesys,
        contractInfo,
        contractUpdate,
        dispatchExportContract,
        dispatchExportReciveContract,
        dispatchGetContractInfo,
        dispatchGetContractList,
        dispatchUpdateContract,
        dispatchTerminatedContract,
        dispatchImpoundedContract,
        dispatchExportContractTerminated,
        contractExportTerminated,
        dispatchUpdateContractGrade,
        dispatchClearContractPeriods
    };
};