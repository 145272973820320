// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Table, Modal, Card, Button, Spin } from 'antd';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { DollarOutlined } from '@ant-design/icons';
import { RefinanceSearchForm } from '../../constants/global/SearchForm'
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { RefinanceColumn } from '../../constants/global/columnTableForm'
import { dataRefinance } from './constant'
import { RecheckRefinanceModal } from '../../components/modal/Refinance/RecheckRefinanceModal'
import { GET, POST, GET_CONTRACT_REFINANCE_WAIT_APPROVE, GET_LOOKUP_REFINANCE } from '../../services/index';
import { convertBaht } from "../../functions/fn"

const Refinance = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [valueDefault, setValueDefault] = useState({});
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getDataRefinanceWaitApprove()
  }, []);


  const getDataRefinanceWaitApprove = async (data) => {

    try {
      setLoading(true);
      let res = await GET(GET_CONTRACT_REFINANCE_WAIT_APPROVE(data && data.contractNo || ''));
      const { success, result } = res;

      if (success) {
        const resultData = result.refinancePreApprove.map((val, i) => {
          return {
            rowNo: i + 1,
            ...val,
            lease_amount: convertBaht(val.lease_amount) || '',
            productName: val.product?.name || '',
            customerName: val.user.general?.fullname || '',
            imageFrontIdCard: val.image?.url || ''
          }
        })
        setDataSource(resultData)
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };


  const closeModal = () => {
    setValueDefault({});
    setVisible(false);
  };
  const openModal = (record) => {

    setValueDefault(record);
    setVisible(true);
  };
  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'ไอโฟนแลกเงิน',
              icon: <DollarOutlined />,
              spanSearch: 24,
              formSearch: RefinanceSearchForm(),
              onSearch: (data) => getDataRefinanceWaitApprove(data),
            }}
            tableLayout={{
              columns: RefinanceColumn({ openModal }),
              dataSource: dataSource
            }}
          />
        </Spin>
      </Card>
      <RecheckRefinanceModal visible={visible} title={'ตรวจสอบข้อมูล'} onClose={closeModal} data={{ record: valueDefault, reApi: getDataRefinanceWaitApprove }} />
    </Wrapper>
  );
};

export default Refinance;
