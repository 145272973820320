import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { compareRender } from '../../functions/fn';
import { TextSmall } from './text';

export const TextForm = React.memo(({ item, control }) => {
  // export const SyncIDcard = ({ item, control }) => {
  return (
    <>
      <Controller
        value={item.value}
        render={() => (
          <>
            <TextSmall
              text={item.textStyle.text}
              color={item.textStyle.color}
              align={item.textStyle.align}
              bold={item.textStyle.bold}
              marginTop={item.textStyle.marginTop}
            />
          </>
        )
        }
        name="TextForm"
        control={control}
        rules={item.rules}
      />
    </>

  );
}
  , (prev, next) =>
    compareRender(prev, next) &&
    JSON.parse(JSON.stringify(prev.item)) ===
    JSON.parse(JSON.stringify(next.item)) &&
    compareRender(prev, next, 'item')
)

// export default CustomerInfo;
