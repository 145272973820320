// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect, useRef } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Space, Button, Table, Spin, Row } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { POST, POST_SECOND_HAND, POST_SECOND_HAND_VERIFY } from '../../../services/index';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { convertStrToFormat } from '../../../functions/fn';
import { SecondHandVerifyModal } from '../modal/SecondHandVerify';
import { SecondHandApproveSearchForm } from '../form';
import { SecondHandApproveColumn } from '../columns';

const ApproveSecondHandScreen = () => {
    const [loading, setLoading] = useState(false);
    const [visibleVerifyModal, setVisibleVerifyModal] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const pageRef = useRef(1)
    const sizeRef = useRef(10)
    const [totalSize, setTotalSize] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [userNameState, setUserNameState] = useState('')
    const [userTelState, setUserTelState] = useState('')
    const [defaultValue, setDefaultValue] = useState({});
    const [valueSearch, setValueSearch] = useState({})

    useEffect(() => {
        getApproveSecondHandData({ page: pageRef.current, size: sizeRef.current, name: '', tel: '' })
    }, []);

    const createParams = (value) => {
        let params = []
        let result = ''

        Object.keys(value).forEach((key) => {
            if (value[key]) {
                params.push(key + '=' + value[key]);
            }
        });
        if (params.length !== 0) {
            result += params.join('&');
        }
        // }

        return result
    }

    const getApproveSecondHandData = async (value) => {

        value.page = value?.page || 1
        value.size = value?.size || 10

        pageRef.current = value.page
        sizeRef.current = value.size

        value.name = value.name || ''
        value.tel = value.tel || ''

        let params = createParams(value)
        const { page, size, name, tel } = value;

        setLoading(true)
        setUserNameState(name)
        setUserTelState(tel)
        setValueSearch(value)

        try {
            const res = await POST(POST_SECOND_HAND(params))
            const { success, data } = res
            const { secondHandList, total_item, total_page } = data

            if (success) {
                setTotalSize(total_item)
                setTotalPage(total_page)
                const result = secondHandList.map((el) => {
                    const newData = {
                        key: el?._id || '',
                        branch: `${el?.branchName || ''}`,
                        product: el?.productName ? el.productName.replace("(พิเศษ) ", "") : '',
                        ...el,
                    };
                    return newData;
                });

                setDataSource(result)
            }

        } catch (err) {
            const { error } = err;
            Modal.error({
                title: error || '',
            });
        } finally {
            setLoading(false)
        }
    }

    const onPageSizeChangeCustom = (pageData, sizeData) => {
        const sizeChange = sizeRef.current !== sizeData;
        if (sizeChange) {
            // setPageState(1)
            pageRef.current = 1
        } else {
            // setPageState(pageData)
            pageRef.current = pageData
        }
        sizeRef.current = sizeData

        let name = userNameState

        let tel = userTelState

        const params = {
            page: pageRef.current,
            size: sizeRef.current,
            name: name || '',
            tel: tel || '',
        };

        getApproveSecondHandData(params);
    };

    const openModal = (record, name) => {
        if (record) {
            setDefaultValue(record);
            if (name == 'verify') {
                getDataVerify(record.docNo);
            }
        }
    };

    const getDataVerify = async (value) => {
        setLoading(true);
        if (value === undefined) {
            Modal.error({
                title: 'ไม่พบเลขที่เอกสารจากรายงานฉบับนี้',
                content: 'ไม่สามารถดึงรายละเอียดข้อมูลมาแสดงได้',
            });
            setLoading(false);
        } else {
            try {
                const res = await POST(POST_SECOND_HAND_VERIFY(value));
                const { success, result } = res;

                if (success) {
                    const newResult = {
                        key: result?.id,
                        document_no: result?.documentNo,
                        name: result?.userName,
                        product_name: result?.productName,
                        approve_paid_amount: result?.approve_paid_amount ? convertStrToFormat(result?.approve_paid_amount, 'money') : 0,
                        book_bank_no: result?.book_bank_number,
                        book_bank_name: result?.book_bank_name,
                        bank_name: result?.bank_name,
                        book_bank_branch: result?.book_bank_branch,
                        rename_image: result?.change_name_image || "",
                        profile_image: result?.imageFrontIdCard || "",
                        book_bank_image: result?.bookBankImage || "",
                        user_created_email: result?.user_created_email,
                        remark: result?.remark,
                    };

                    setDefaultValue(newResult);
                    setVisibleVerifyModal(true);
                }
            } catch (err) {
                const { message, error } = err;
                Modal.error({
                    title: message,
                    content: error || '',
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Wrapper>
            <Card>
                <Spin tip="Loading..." spinning={loading}>
                    <PageLayout searchLayout={{
                        title: 'อนุมัติจ่ายเงินรับซื้อสินค้ามือสอง',
                        icon: <BookOutlined />,
                        spanSearch: 24,
                        formSearch: SecondHandApproveSearchForm(),
                        onSearch: (data) => getApproveSecondHandData(data),
                        // onClearCustom
                    }}
                        tableLayout={{
                            columns: SecondHandApproveColumn({ openModal }),
                            dataSource: dataSource,
                            rowKey: (record) => record.key,
                            pagination: {
                                showSizeChanger: true,
                                current: pageRef.current,
                                pageSize: sizeRef.current,
                                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                                total: totalSize || 0,
                                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                                defaultPageSize: sizeRef.current,
                                defaultCurrent: 1,
                            },
                        }}
                    />
                </Spin>
            </Card>
            <SecondHandVerifyModal
                visible={visibleVerifyModal}
                title={'ตรวจสอบ'}
                onClose={() => setVisibleVerifyModal(false)}
                data={{ reApi: getApproveSecondHandData, record: defaultValue, valueSearch }}
            />

        </Wrapper>
    );
};

export default ApproveSecondHandScreen;