import axios from 'axios';
import {API_URL} from '../../env.js';

export const getTransaction = async (params) => {
  try {
    return await axios.get(`${API_URL}/admin/transaction${params}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};
export const getTransactionInfo = async (id) => {
  try {
    return await axios.get(`${API_URL}/admin/transaction/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};
export const updateTransactionStatus = async (id, body) => {
  try {
    return await axios.put(`${API_URL}/admin/transaction/${id}/update-status`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};

export const updateTransactionStatus2 = async (id, body) => {
  return await axios.put(`${API_URL}/admin/transaction/${id}/update-status`, body, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`,
      'content-type': 'application/json',
    },
  });
};

export const exportTransaction = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/admin/transaction/export${params}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'รายการชำระ.xlsx');
    document.body.appendChild(link);
    link.click();
    return response;
  } catch (error) {
    return error;
  }
};

export const updateTransaction = async (id, body) => {
  try {
    return await axios.put(`${API_URL}/admin/transaction/${id}/update`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};
