import { createContext } from 'react';

const EditCustomerContext = createContext({
    dataCustomer: {},
});

export const EditCustomerContextProvider = (props) => {

    const context = {
        dataCustomer: props?.children?.props?.data?.record,
    };

    return <EditCustomerContext.Provider value={context}>{props.children}</EditCustomerContext.Provider>;
};

export default EditCustomerContext;