import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../../constants/global/RenderForm';
import { ContainerButton } from '../../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../../components/buttons';
import { GET, POST, PUT, CREATE_UPDATE_APPOINTMENT, DELETE, DELETE_APPOINTMENT, CREATE_FOLLOWUP } from '../../../../services/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { auth } from '../../../../utils/firebase';
import { useAuthState } from '../../../../utils/hook';
import moment from 'moment';
import { CallMerge } from '@material-ui/icons';
import {
  ProcessedEvent,
  Scheduler,
  SchedulerHelpers
} from "@aldabil/react-scheduler";
import { Icon } from '../../../../resources/icon';
import { DatatableLog } from '../../../../components/index'
import { ConfirmAppointmentContractColumn } from '../columns/ConfirmAppointmentContractColumn'
import _ from 'lodash'
import { FORM_APPOINTMENT } from '../form';

export const AppointmentModal = ({ title, visible, onClose, data, historyConfirmAppointmentContractData }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const { user } = useAuthState(auth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    let dataValue = Object.keys(data.defaultValue);
    dataValue.forEach((val) => setValue(val, data.defaultValue[val]));
  }, [data.defaultValue]);


  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันการบันทึก',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการบันทึกข้อมูล ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };


  const onSubmit = async (params) => {
    let api = getValues("type") === "FOLLOWUP" ? CREATE_FOLLOWUP : CREATE_UPDATE_APPOINTMENT
    await onSendApi(api, params)
  };

  const onSendApi = async (api, params) => {

    try {
      setLoading(true);

      const { branch, considerId, registerId, endDate, endTime, remark, remark_appointment, startDate, startTime, show_installment, skip_check_consider } = params
      const obj = {
        branch,
        startDate: `${moment(startDate).format("YYYY-MM-DD")} ${moment(startTime).format("HH:mm")}`,
        endDate: `${moment(endDate).format("YYYY-MM-DD")} ${moment(endTime).format("HH:mm")}`,
        remark: remark || "",
        remark_appointment: remark_appointment || "",
        skip_check_consider: skip_check_consider,
        show_installment: show_installment === 'SHOW' ? true : false
      }
      setLoading(true);

      let res = await PUT(api(registerId, considerId), obj);

      const { success, message, status } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi("selected");
          },
        });
      } else if (status === "PENDING") {
        Modal.confirm({
          title: message,
          icon: <Icon.warningAlert />,
          okText: 'ยืนยัน',
          cancelText: 'แก้ไข',
          onOk() {
            onSubmit({ ...params, skip_check_consider: true })
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
        afterClose: () => {
          onCloseMain();
        },
      });
    } finally {
      setLoading(false);
    }
  };


  const onChange = (value, ref) => {
    setValue(ref, value)
    if (ref === "startDate") {
      setValue("endDate", value)
    } else if (ref === "startTime") {
      setValue("endTime", moment(value).add(1, 'hours'))
    }
  }




  const onCloseMain = () => {
    clearErrors();
    onClose();
  };

  return (
    <Modal title={title || 'modal'} open={visible} width={560} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          clearErrors={clearErrors}
          errors={errors}
          formList={FORM_APPOINTMENT({ options: data.options, defaultValue: data.defaultValue, fn: { onChange } })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>

      {!_.isEmpty(data?.defaultValue?.chatcone) ? (

        <DatatableLog
          style={{ marginTop: '20px' }}
          columns={ConfirmAppointmentContractColumn()}
          data={historyConfirmAppointmentContractData}
          pagination={{ pageSize: 2 }}
        />
      ) : null}
    </Modal>
  );
};
