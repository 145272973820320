import Wrapper from '../../views/wrapper/Wrapper';
import Button from '@material-ui/core/Button';
import { openFormModal, Joi } from 'joi-auto-admin';
import { customer, contract } from '../../utils/auto-admin/schema';
import { createCustomer, createCustomerAndContract, resetCouponData, resetProductCode } from '../../utils/auto-admin/api';
import { Modal, Button as AntButton, Card } from 'antd';
import { useContract } from '../../hook/useContract';





const POS = (props) => {
  const { dispatchExportContract } = useContract();
  resetCouponData()
  resetProductCode()

  const props1 = {
    onSubmit: async (formData) => {
      var newValue = await createCustomerAndContract(formData);
      Modal.success({
        content: (
          <div>
            สร้างข้อมูลลูกค้าและสัญญาเรียบร้อย
            <div style={{ position: 'relative', height: 32, marginBottom: -32, top: 24 }}>
              <AntButton onClick={() => dispatchExportContract(newValue.contract_no)}>ดาวน์โหลดสัญญา</AntButton>
            </div>
          </div>
        ),
      });
    },
    schema: Joi.object({ customer, contract }),
    steps: ['ข้อมูลบัตรปชช', 'ข้อมูลลูกค้า', 'ผู้ติดต่อได้', 'ทำสัญญา'],
    disableSuccessModal: true,
    devMode: false,
  };

  const props2 = {
    onSubmit: createCustomer,
    schema: Joi.object({ customer }),
    steps: ['ข้อมูลบัตรปชช', 'ข้อมูลลูกค้า', 'ผู้ติดต่อได้'],
    devMode: false,
  };

  const onClick1 = () => openFormModal(props1);
  const onClick2 = () => openFormModal(props2);
  const style = { background: '#1877F2', color: 'white' };

  return (
    <Wrapper>
      <Card style={{ paddingBottom: 50 }}>
        <center className="prompt-regular" style={{ width: '900px', margin: "auto", marginTop: 40 }}>
          <h2>เปิดบัญชีลูกค้า</h2>
          {/* <br />
        <Button variant="contained" onClick={onClick1} style={style}>
          เปิดบัญชีลูกค้า + ทำสัญญา
        </Button>
        <br /> */}
          <br />
          <Button variant="contained" onClick={onClick2} style={style}>
            เปิดบัญชีลูกค้าอย่างเดียว
          </Button>
        </center>
      </Card>
    </Wrapper>
  );
};

export default POS;
