import React from "react";
import { TimePicker } from "antd";
import { Controller } from "react-hook-form";
import {compareRender} from '../../functions/fn';
import moment from "moment";

export const TimePickerForm = React.memo(
  ({ item, errors, clearErrors, control ,handleChange}) => {

  
    return (
      <Controller
        name={item.name}
        control={control}
        rules={item.rules}
        value={item.value}
        defaultValue={item.defaultValue ||""}
        render={({field}) => (
          
          <TimePicker
            defaultValue={item.defaultValue ||""}
            value={field.value}
            placeholder={item.placeholder || ""}
            format={item.format}
            style={{ width: "100%", marginTop: 5 }}
            onChange={item.onChange ? (e)=>item.onChange(e,item.name) : (e)=>handleChange(e,item)}
          />
        )}
      />
    );
  }, compareRender);
