import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { RamdomIcloudAccountForm } from '../../../constants/global/actionform';
import { GET, POST, RAMDOM_ACCOUNT_ICLOUD } from '../../../services/index';

import { auth } from '../../../utils/firebase';
import { useAuthState } from '../../../utils/hook';
// import {useCountRandom} from '../../../hook/useCountRandom';

export const RandomIcloudAccountModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
  } = useForm();

  const { user } = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});

  useEffect(() => {
    if (JSON.stringify(data.record) != '{}') {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setRecord(data.record);
    }
  }, [data]);

  const onCloseMain = () => {
    setValue('remark', '');
    clearErrors();
    onClose();
  };

  // const onConfirm = (params) => {
  //   Modal.confirm({
  //     title: 'ยืนยันสุ่มบัญชีไอคลาวน์ใหม่',
  //     icon: <ExclamationCircleOutlined />,
  //     content: 'ต้องการสุ่มบัญชีไอคลาวน์ใหม่ ใช่หรือไม่',
  //     onOk() {
  //       onSubmit(params);
  //     },
  //     okText: 'ยืนยัน',
  //     cancelText: 'ยกเลิก',
  //   });
  // };

  const onSubmit = async (params) => {
    setLoading(true);

    const { contractNo, remark } = params;
    const obj = {
      userLogin: user.email,
      contractNo,
      remark,
    };

    try {
      let res = await POST(RAMDOM_ACCOUNT_ICLOUD, obj);
      const { success, message, result } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            data.onSet(result);
            onCloseMain();
            data.reApi({});
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
      onCloseMain();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={480} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={RamdomIcloudAccountForm({ data: record })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="ยืนยันสุ่มใหม่" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
