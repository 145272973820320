import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { AddPacakagesByProductsForm } from '../../../constants/global/actionform';
import { POST, UPSERT_PRODUCT_PACKAGE } from '../../../services/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const AddPackagesProductModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);


  useEffect(() => {

  }, [data?.value]);



  const onChangeCustom = (value, ref) => {
    setValue(ref, value)
  };


  const onSubmit = async (params) => {
    setLoading(true);

    const dataSend = {
      product_package_id: "",
      product_code: data?.value?.product_code,
      package_id: params.package_id,
      periods: params.periods.map(i => Number(i)),
      status_active: params.status_active ? "ACTIVE" : "INACTIVE"
    }



    try {
      let res = await POST(UPSERT_PRODUCT_PACKAGE, dataSend);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            data.reApi(data?.value?.product_code);
            onCloseMain();
          },
        });
        onCloseMain();
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }

  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันการเพิ่มแพคเก็จ',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการเพิ่มแพคเก็จ ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onClear = () => {
    setValue('package_id', "");
    setValue('periods', []);
    //setValue('status_active', false);
  };

  const onCloseMain = () => {
    onClear();
    clearErrors();
    onClose()
  };
  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={360} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={AddPacakagesByProductsForm({ options: { packages: data?.options?.optionPackages, periods: data?.options?.optionPeriods }, onChange: onChangeCustom })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};