// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin} from 'antd';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {FileTextOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
 import {ReportAdjustStockNonSerialSearchForm} from '../../../constants/global/SearchForm';
import {ReportAdjustStockNonserialColumn} from '../../../constants/global/columnTableForm';
import {GET, GET_BRANCH,GET_ADJUST_STOCK_NON_SERIAL_REPORT} from '../../../services/index';
import { CSVLink } from "react-csv";
import {exportDefault} from "../../../functions/fn"
import moment from 'moment'


const AdjustStockNonSerial = () => {

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport,setDataExport] = useState([])
  const [optionBranch,setOptionBranch] = useState([])

  useEffect(() => {
    getOptionBranch()
    getAdjustStockReport({})
  }, []);

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const {success, result} = res;
      if (success) {

        const option = result.branch.map((el)=>{
          return {
            text:el.label,
            value:el.value
          }
        })
        setOptionBranch(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  };


  const getAdjustStockReport = async(data)=>{
    setLoading(true)
    try {

      const startDate = data.startDate && moment(data.startDate).format('YYYY-MM-DD')
      const endDate = data.endDate && moment(data.endDate).format('YYYY-MM-DD')

      const res = await GET(GET_ADJUST_STOCK_NON_SERIAL_REPORT(data.name || '',startDate ||'',endDate ||''))
      const {success, result} = res;
      if (success) {
        const data = result.report.map((el,i)=>{
          return {
            no:i+1,
            ...el,
            userCreate:el.user_create.name,
          }
        })

        const exportData = result.report.map((val)=>{
          return {
            'ประเภท':val.type || '',
            'รหัสสินค้า':val.item_code || '',
            'ชื่อสินค้า':val.item_name || '',
            'ต้นทุน':val.cost|| '',
            'สาขา':val.branch_name || '',
            'วันที่เวลาที่ทำรายการ': moment(val.create_date).format("YYYY-MM-DD hh:mm") || '',
            'ผู้ทำรายการ':val.user_create.name || '',
          }
        })

        const dataExportDefault = exportDefault(['ประเภท','รหัสสินค้า','ชื่อสินค้า','ต้นทุน','สาขา','วันที่เวลาที่ทำรายการ','ผู้ทำรายการ'])
        setDataSource(data)
        setDataExport(exportData.length > 0 && exportData || dataExportDefault)
      }
      
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
      
    }finally{
    setLoading(false)
    }
  }


  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงานปรับปรุงสต็อก Non Serial',
              icon: <FileTextOutlined />,
              spanSearch: 24,
              formSearch: ReportAdjustStockNonSerialSearchForm(),
              onSearch: (data) => getAdjustStockReport(data),
            }}
            tableLayout={{
              columns: ReportAdjustStockNonserialColumn({options:{branch:optionBranch}}),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                    <CSVLink data={dataExport} filename={"รายงานปรับปรุงสต็อก"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default AdjustStockNonSerial;
