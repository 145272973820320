// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin} from 'antd';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {AuditOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {GET, GET_OPTION_BY_KEY, GET_PICK_NON_SERIAL_REPORT, GET_BRANCH} from '../../../services/index';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {exportDefault} from '../../../functions/fn';
import {PickReportColumn} from '../column';
import {ReportPickSearchForm} from '../form';

const PickNonSerialReport = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [optionJournalStatus, setOptionJournalStatus] = useState([]);
  const [optionBranch, setOptionBranch] = useState([]);
  const [dataExport, setDataExport] = useState([]);

  useEffect(() => {
    getReportPickNonSerial({document_no: '', status: ''});
    getOptionStatus();
    getOptionBranch();
  }, []);

  const getOptionStatus = async () => {
    try {
      const res = await GET(GET_OPTION_BY_KEY('journalHeadStatus'));
      const {success, result} = res;
      if (success) {
        const option = result.journalHeadStatus.map((el) => {
          return {...el, text: el.label};
        });
        setOptionJournalStatus(option);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const {success, result} = res;
      if (success) {
        const option = result.branch.map((el) => {
          return {
            text: el.label,
            value: el.value,
          };
        });
        setOptionBranch(option);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getReportPickNonSerial = async (data) => {
    setLoading(true);
    try {
      let res = await GET(GET_PICK_NON_SERIAL_REPORT(data.document_no || '', data.status || ''));
      const {success, result} = res;
      if (success) {
        const resultData = result.picks.map((val, i) => {
          return {
            no: i + 1,
            ...val,
          };
        });
        const dataEx = result.picks.map((el, i) => {
          return {
            เลขที่เอกสาร: el.document_no,
            ผู้สร้าง: el.user_create,
            วันที่สร้าง: moment(el.create_date).format('YYYY-MM-DD HH:mm'),
            ปลายทาง: el.branch_to_name,
            ผู้รับ: el.user_update,
            วันที่รับ: moment(el.update_date).format('YYYY-MM-DD HH:mm'),
            สถานะ: el.status_name,
          };
        });
        const dataExportDefault = exportDefault(['เลขที่เอกสาร', 'ผู้สร้าง', 'วันที่สร้าง', 'ปลายทาง', 'ผู้รับ', 'วันที่รับ', 'สถานะ']);
        setDataExport((dataEx.length > 0 && dataEx) || dataExportDefault);
        setDataSource(resultData);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงานการจัดสินค้า Non Serial',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: ReportPickSearchForm({option: {optionJournalStatus}}),
              onSearch: (data) => getReportPickNonSerial(data),
            }}
            tableLayout={{
              columns: PickReportColumn({options: {optionBranch, optionJournalStatus}}),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <CSVLink data={dataExport} filename={'รายงานสต็อค non serial'}>
                    <ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} />
                  </CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default PickNonSerialReport;
