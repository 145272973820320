import {ButtonTheme} from '../../../components/buttons';
import moment from 'moment/moment';
import {Tag, Tooltip, Button,Space} from 'antd';
import {color} from '../../../resources/color';
import {
  SwapOutlined,
  FileSearchOutlined
} from '@ant-design/icons';
import { statusTagStyle } from '../../../functions/fn';

export const TrackingTaskManageColumn = (openModal) => {
  return [
    {
      title: '#',
      dataIndex: 'no',
      align: 'end',
      width: '80px',
    },
    {
      title: 'ประเภทการติดตาม',
      dataIndex: 'tracking_status',
      width: '200px',
      align: 'left',
    },
    {
      title: 'รูปแบบงาน',
      dataIndex: 'status',
      width: '100px',
      align: 'left',
      render: (text, _record) => {
        return statusTagStyle(text, 'status');
      },
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '150px',
      align: 'left',
    },
    {
      title: 'ชื่อ-นามสกุลผู้ทำสัญญา',
      dataIndex: 'name',
      width: '200px',
      align: 'left',
    },
    {
      title: 'สถานะสัญญา',
      dataIndex: 'contract_status',
      width: '150px',
      align: 'center',
      render: (text, _record) => {
        return statusTagStyle(text, 'contractStatus');
      },
    },
    {
      title: 'ประเภทหนังสือยกเลิกสัญญา',
      dataIndex: 'terminate_contract_status',
      width: '200px',
      align: 'left',
    },
    {
      title: 'ชื่อผู้ติดตาม',
      dataIndex: 'tracker_name',
      width: '200px',
      align: 'left',
    },
    {
      title: 'จ่ายรอบที่',
      dataIndex: 'tracker_count',
      width: '120px',
      align: 'right',
    },

    {
      title: 'วันที่จ่ายงาน',
      dataIndex: 'tracking_created_date',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text ? moment(text).format('DD/MM/YYYY') : '';
      },
    },
    {
      title: 'ระยะเวลา(วัน)',
      dataIndex: 'duration_after_task_created',
      width: '120px',
      align: 'right',
    },
    {
      title: 'ติดตาม(ครั้ง)',
      dataIndex: 'track_follow_count',
      width: '120px',
      align: 'right',
      sorter: (a, b) => a.track_follow_count - b.track_follow_count,
    },
    {
      title: 'วันที่ติดตาม(ล่าสุด)',
      dataIndex: 'recent_tracking_date',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text ? moment(text).format('DD/MM/YYYY') : '';
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: '200px',
      align: 'center',
      fixed: 'right',
      render: (_text, record) => {
        return (
          <Space>
            <Tooltip title="ดูรายะเอียด">
              <Button
                style={{backgroundColor: color.edit}}
                onClick={() => openModal('details', record)}
                icon={<FileSearchOutlined style={{fontSize: 20}} />}
              />
            </Tooltip>
            <Tooltip title="เปลี่ยนผู้ติดตาม">
              <Button
                style={{backgroundColor: color.remove, color: color.white}}
                onClick={() => openModal('change', record)}
                icon={<SwapOutlined style={{fontSize: 20}} />}
              />
            </Tooltip>
          </Space>
        );
      }
    },
  ];
};
