import React, { createRef } from 'react';
import { Tag } from 'antd';
import { color } from '../../../resources/color';
import { renderSearchColumn } from '../../../constants/global/renderSearchColumn';
import { convertStrToFormat } from '../../../functions/fn';
import moment from 'moment';

export const DIFItemMasterColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{record.key}</div>;
      },
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'Code',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('Code', searchInput),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'Name1',
      width: '300px',
      align: 'left',
      ...renderSearchColumn('Name1', searchInput),
    },
    {
      title: 'รหัสหน่วยนับ',
      dataIndex: 'UnitCode',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.UnitCode}</div>;
      },
    },
    {
      title: 'รหัสคลังซื้อเริ่มต้น',
      dataIndex: 'DefBuyWH',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.DefBuyWH}</div>;
      },
    },
    {
      title: 'รหัสที่เก็บซื้อเริ่มต้น',
      dataIndex: 'DefBuySH',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.DefBuySH}</div>;
      },
    },
    {
      title: 'รหัสคลังขายเริ่มต้น',
      dataIndex: 'DefSaleWH',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.DefSaleWH}</div>;
      },
    },
    {
      title: 'รหัสที่เก็บขายเริ่มต้น',
      dataIndex: 'DefSaleSH',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.DefSaleSH}</div>;
      },
    },
    {
      title: 'ประเภทสินค้า',
      dataIndex: 'StockType',
      width: '200px',
      align: 'center',
      filters: options.optionStockType.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.StockType.code.toString() === value;
      },
      render: (text, record) => {
        return <div>{record.StockType.text}</div>;
      },
    },
    {
      title: 'รหัส User ที่เอาเข้า',
      dataIndex: 'CreatorCode',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.CreatorCode}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFAPMasterColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.key}</div>;
      },
    },
    {
      title: 'รหัสเจ้าหนี้',
      dataIndex: 'Code',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('Code', searchInput),
    },
    {
      title: 'ชื่อเจ้าหนี้ 1',
      dataIndex: 'Name1',
      width: '300px',
      align: 'left',
      ...renderSearchColumn('Name1', searchInput),
    },
    {
      title: 'ที่อยู่',
      dataIndex: 'Address',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.Address}</div>;
      },
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'Telephone',
      width: '160px',
      align: 'center',
      ...renderSearchColumn('Telephone', searchInput),
      render: (text) => {
        return convertStrToFormat(text, 'phone_number');
      },
    },
    {
      title: 'ประเภทบุคคล',
      dataIndex: 'PersonType',
      width: '200px',
      align: 'center',
      filters: options.optionPersonType.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.PersonType.toString() === value;
      },
      render: (text, record) => {
        return text === 0 ? 'บุคคลธรรมดา' : 'นิติบุคคล';
      },
    },
    {
      title: 'หมายเลขประจำตัวผู้เสียภาษี/หมายเลขบัตรประชาชน',
      dataIndex: 'IDTaxNo',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.IDTaxNo}</div>;
      },
    },
    {
      title: 'ผู้ติดต่อที่ 1',
      dataIndex: 'ContactName1',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactName1}</div>;
      },
    },
    {
      title: 'เบอร์ผู้ติดต่อที่ 1',
      dataIndex: 'ContactTel1',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{convertStrToFormat(record.ContactTel1, 'phone_number')}</div>;
      },
    },
    {
      title: 'ความสัมพันธ์ผู้ติดต่อที่ 1',
      dataIndex: 'ContactPosition1',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactPosition1}</div>;
      },
    },
    {
      title: 'ผู้ติดต่อที่ 2',
      dataIndex: 'ContactName2',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactName2}</div>;
      },
    },
    {
      title: 'เบอร์ผู้ติดต่อที่ 2',
      dataIndex: 'ContactTel2',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{convertStrToFormat(record.ContactTel2, 'phone_number')}</div>;
      },
    },
    {
      title: 'ความสัมพันธ์ผู้ติดต่อที่ 2',
      dataIndex: 'ContactPosition2',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactPosition2}</div>;
      },
    },
    {
      title: 'ผู้ติดต่อที่ 3',
      dataIndex: 'ContactName3',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactName3}</div>;
      },
    },
    {
      title: 'เบอร์ผู้ติดต่อที่ 3',
      dataIndex: 'ContactTel3',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{convertStrToFormat(record.ContactTel3, 'phone_number')}</div>;
      },
    },
    {
      title: 'ความสัมพันธ์ผู้ติดต่อที่ 3',
      dataIndex: 'ContactPosition3',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactPosition3}</div>;
      },
    },
    {
      title: 'รหัส User ที่เอาเข้า',
      dataIndex: 'CreatorCode',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.CreatorCode}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'importStatus',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFARMasterColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{record.key}</div>;
      },
    },
    {
      title: 'รหัสลูกหนี้',
      dataIndex: 'Code',
      width: '140px',
      align: 'left',
      ...renderSearchColumn('Code', searchInput),
    },
    {
      title: 'ชื่อลูกหนี้ 1',
      dataIndex: 'Name1',
      width: '300px',
      align: 'left',
      ...renderSearchColumn('Name1', searchInput),
    },
    {
      title: 'ที่อยู่',
      dataIndex: 'Address',
      width: '400px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.Address}</div>;
      },
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'Telephone',
      width: '160px',
      align: 'center',
      ...renderSearchColumn('Telephone', searchInput),
      render: (text) => {
        return convertStrToFormat(text, 'phone_number');
      },
    },
    {
      title: 'ประเภทบุคคล',
      dataIndex: 'PersonType',
      width: '200px',
      align: 'center',
      filters: options.optionPersonType.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.PersonType === value;
      },
      render: (text, record) => {
        return text === 0 ? 'บุคคลธรรมดา' : 'นิติบุคคล';
      },
    },
    {
      title: 'หมายเลขประจำตัวผู้เสียภาษี/หมายเลขบัตรประชาชน',
      dataIndex: 'IDTaxNo',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.IDTaxNo}</div>;
      },
    },
    {
      title: 'ผู้ติดต่อที่ 1',
      dataIndex: 'ContactName1',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactName1}</div>;
      },
    },
    {
      title: 'เบอร์ผู้ติดต่อที่ 1',
      dataIndex: 'ContactTel1',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{convertStrToFormat(record.ContactTel1, 'phone_number')}</div>;
      },
    },
    {
      title: 'ความสัมพันธ์ผู้ติดต่อที่ 1',
      dataIndex: 'ContactPosition1',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactPosition1}</div>;
      },
    },
    {
      title: 'ผู้ติดต่อที่ 2',
      dataIndex: 'ContactName2',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactName2}</div>;
      },
    },
    {
      title: 'เบอร์ผู้ติดต่อที่ 2',
      dataIndex: 'ContactTel2',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{convertStrToFormat(record.ContactTel2, 'phone_number')}</div>;
      },
    },
    {
      title: 'ความสัมพันธ์ผู้ติดต่อที่ 2',
      dataIndex: 'ContactPosition2',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactPosition2}</div>;
      },
    },
    {
      title: 'ผู้ติดต่อที่ 3',
      dataIndex: 'ContactName3',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactName3}</div>;
      },
    },
    {
      title: 'เบอร์ผู้ติดต่อที่ 3',
      dataIndex: 'ContactTel3',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{convertStrToFormat(record.ContactTel3, 'phone_number')}</div>;
      },
    },
    {
      title: 'ความสัมพันธ์ผู้ติดต่อที่ 3',
      dataIndex: 'ContactPosition3',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ContactPosition3}</div>;
      },
    },
    {
      title: 'รหัส User ที่เอาเข้า',
      dataIndex: 'CreatorCode',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.CreatorCode}</div>;
      },
    },
    {
      title: 'วันเกิด',
      dataIndex: 'BirhtDay', // Birthday
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.BirhtDay}</div>;
      },
    },
    {
      title: 'ที่อยู่บ้าน',
      dataIndex: 'HomeAddr',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.HomeAddr}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFInvoiceColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{i + 1}</div>;
      },
    },
    {
      title: 'เลขที่บิลขาย',
      dataIndex: 'DocNo',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('DocNo', searchInput),
    },
    {
      title: 'วันที่บิลขาย',
      dataIndex: 'DocDate',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        return <div>{moment.utc(record.DocDate).utcOffset(0, true).format('DD/MM/YYYY HH:mm')}</div>;
      },
    },
    {
      title: 'ประเภทภาษี',
      dataIndex: 'TaxType',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.TaxType}</div>;
      },
    },
    {
      title: 'ประเภทบิล',
      dataIndex: 'BillType',
      width: '140px',
      align: 'center',
      filters: options.optionBillType.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.BillType.code.toString() === value;
      },
      render: (text, record) => {
        return <div>{record.BillType.text}</div>;
      },
    },
    {
      title: 'รหัสลูกค้า',
      dataIndex: 'ArCode',
      width: '160px',
      align: 'center',
      ...renderSearchColumn('ArCode', searchInput),
    },
    {
      title: 'ยอดสุทธิ',
      dataIndex: 'NetDebtAmount',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.NetDebtAmount !== 0 ? convertStrToFormat(record.NetDebtAmount, 'money') : record.NetDebtAmount}</div>;
      },
    },
    {
      title: 'รหัส User ที่เอาเข้า',
      dataIndex: 'CreatorCode',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.CreatorCode}</div>;
      },
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'RefPromise',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.RefPromise}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFInvoiceDetailColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{record.key}</div>;
      },
    },
    {
      title: 'เลขที่บิลขาย',
      dataIndex: 'DocNo',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('DocNo', searchInput),
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'ItemCode',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('ItemCode', searchInput),
    },
    {
      title: 'รหัสคลัง',
      dataIndex: 'WHCode',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.WHCode}</div>;
      },
    },
    {
      title: 'รหัสที่เก็บ',
      dataIndex: 'ShelfCode',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ShelfCode}</div>;
      },
    },
    {
      title: 'รหัสหน่วยนับ',
      dataIndex: 'UnitCode',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.UnitCode}</div>;
      },
    },
    {
      title: 'จำนวนขาย',
      dataIndex: 'Qty',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.Qty}</div>;
      },
    },
    {
      title: 'ราคาขาย',
      dataIndex: 'Price',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.Price !== 0 ? convertStrToFormat(record.Price, 'money') : record.Price}</div>;
      },
    },
    {
      title: 'ส่วนลด/รายการสินค้า',
      dataIndex: 'DiscountAmount',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.DiscountAmount}</div>;
      },
    },
    {
      title: 'จำนวนเงินหลังหักส่วนลด',
      dataIndex: 'Amount',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.Amount}</div>;
      },
    },
    {
      title: 'เลขบรรทัดของรายการ',
      dataIndex: 'LineNumber',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.LineNumber}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFSerialTransColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{record.key}</div>;
      },
    },
    {
      title: 'หมายเลข Serial Number',
      dataIndex: 'SerialNo',
      width: '180px',
      align: 'left',
      render: (text, record) => {
        return <div>{record.SerialNo}</div>;
      },
    },
    {
      title: 'วันที่บิลขาย',
      dataIndex: 'DocDate',
      width: '160px',
      align: 'left',
      render: (text, record) => {
        return <div>{moment.utc(record.DocDate).utcOffset(0, true).format('DD/MM/YYYY HH:mm')}</div>;
      },
    },
    {
      title: 'เลขที่บิลขาย',
      dataIndex: 'DocNo',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('DocNo', searchInput),
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'ItemCode',
      width: '180px',
      align: 'left',
      ...renderSearchColumn('ItemCode', searchInput),
    },
    {
      title: 'รหัสคลัง',
      dataIndex: 'WHCode',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.WHCode}</div>;
      },
    },
    {
      title: 'รหัสที่เก็บ',
      dataIndex: 'ShelfCode',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ShelfCode}</div>;
      },
    },
    {
      title: 'ประเภทรายการ',
      dataIndex: 'SaveFrom',
      width: '160px',
      align: 'center',
      filters: options.optionSaveFrom.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.SaveFrom.toString() === value;
      },
      render: (text, record) => {
        return text === 0 ? 'ขาย' : 'ซื้อ';
      },
    },
    {
      title: 'เลขบรรทัดของรายการ',
      dataIndex: 'LineNumber',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.LineNumber}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code.toString() === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFReceiptColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{record.key}</div>;
      },
    },
    {
      title: 'เลขที่ใบรับชำระเงิน',
      dataIndex: 'DocNo',
      width: '180px',
      align: 'left',
      ...renderSearchColumn('DocNo', searchInput),
    },
    {
      title: 'วันที่รับชำระเงิน',
      dataIndex: 'DocDate',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{moment.utc(record.DocDate).utcOffset(0, true).format('DD/MM/YYYY HH:mm')}</div>;
      },
    },
    {
      title: 'รหัสลูกค้า',
      dataIndex: 'ArCode',
      width: '160px',
      align: 'center',
      ...renderSearchColumn('ArCode', searchInput),
    },
    {
      title: 'ยอดตัดชำระทั้งหมด',
      dataIndex: 'NetAmount',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.NetAmount !== 0 ? convertStrToFormat(record.NetAmount, 'money') : record.NetAmount}</div>;
      },
    },
    {
      title: 'ยอดเงินสด',
      dataIndex: 'SumCashAmount',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.SumCashAmount !== 0 ? convertStrToFormat(record.SumCashAmount, 'money') : record.SumCashAmount}</div>;
      },
    },
    {
      title: 'ยอดเช็ค',
      dataIndex: 'SumChqAmount',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.SumChqAmount !== 0 ? convertStrToFormat(record.SumChqAmount, 'money') : record.SumChqAmount}</div>;
      },
    },
    {
      title: 'ยอดบัตรเครดิต',
      dataIndex: 'SumCreditAmount',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.SumCreditAmount !== 0 ? convertStrToFormat(record.SumCreditAmount, 'money') : record.SumCreditAmount}</div>;
      },
    },
    {
      title: 'ยอดเงินโอน',
      dataIndex: 'SumBankAmount',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.SumBankAmount !== 0 ? convertStrToFormat(record.SumBankAmount, 'money') : record.SumBankAmount}</div>;
      },
    },
    {
      title: 'ยอดรายได้อื่นๆ',
      dataIndex: 'OtherIncome',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.OtherIncome !== 0 ? convertStrToFormat(record.OtherIncome, 'money') : record.OtherIncome}</div>;
      },
    },
    {
      title: 'ยอดค่าใช้จ่ายอื่นๆ',
      dataIndex: 'OtherExpense',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.OtherExpense}</div>;
      },
    },
    {
      title: 'ยอดเงินเกินใบเสร็จรับ',
      dataIndex: 'ExcessAmount1',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ExcessAmount1}</div>;
      },
    },
    {
      title: 'ยอดเงินเกินที่หักในใบเสร็จ',
      dataIndex: 'ExcessAmount2',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ExcessAmount2}</div>;
      },
    },
    {
      title: 'งวดที่ชำระ',
      dataIndex: 'Description',
      width: '220px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.Description}</div>;
      },
    },
    {
      title: 'รหัส User ที่เอาเข้า',
      dataIndex: 'CreatorCode',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.CreatorCode}</div>;
      },
    },
    // {
    //   title: 'วันที่ Insert เข้า Table',
    //   dataIndex: 'CreateDateTime',
    //   width: '160px',
    //   align: 'center',
    //   //   ...renderSearchColumn('unitCode', searchInput),
    //   render: (text, record) => {
    //     return <div>{record.CreateDateTime}</div>;
    //   },
    // },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code.toString() === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFReceiptDetailColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{i + 1}</div>;
      },
    },
    {
      title: 'เลขที่ใบรับชำระเงิน',
      dataIndex: 'DocNo',
      width: '180px',
      align: 'left',
      ...renderSearchColumn('DocNo', searchInput),
    },
    {
      title: 'เลขที่ Invoice',
      dataIndex: 'InvoiceNo',
      width: '160px',
      align: 'left',
      render: (text, record) => {
        return <div>{record.InvoiceNo}</div>;
      },
    },
    {
      title: 'ยอดตัดชำระเงิน',
      dataIndex: 'PayAmount',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.PayAmount !== 0 ? convertStrToFormat(record.PayAmount, 'money') : record.PayAmount}</div>;
      },
    },
    {
      title: 'ประเภทบิล',
      dataIndex: 'BillType',
      width: '200px',
      align: 'center',
      filters: options.optionBillType.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.BillType.code.toString() === value;
      },
      render: (text, record) => {
        return <div>{record.BillType.text}</div>;
      },
    },
    {
      title: 'เลขบรรทัดของรายการ',
      dataIndex: 'LineNumber',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.LineNumber}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code.toString() === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFRecMoneyColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{record.key}</div>;
      },
    },
    {
      title: 'เลขที่ใบรับชำระเงิน',
      dataIndex: 'DocNo',
      width: '140px',
      align: 'left',
      ...renderSearchColumn('DocNo', searchInput),
    },
    {
      title: 'ยอดชำระตามประเภทการรับเงิน',
      dataIndex: 'PayAmount',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.PayAmount !== 0 ? convertStrToFormat(record.PayAmount, 'money') : record.PayAmount}</div>;
      },
    },
    {
      title: 'ประเภทการรับเงิน',
      dataIndex: 'PaymentType',
      width: '120px',
      align: 'center',
      filters: options.optionPaymentType.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.PaymentType.code === value;
      },
      render: (text, record) => {
        return <div>{record.PaymentType.text}</div>;
      },
    },
    {
      title: 'รหัสธนาคาร',
      dataIndex: 'BankCode',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.BankCode}</div>;
      },
    },
    {
      title: 'เลข Reference',
      dataIndex: 'RefNo',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.RefNo}</div>;
      },
    },
    {
      title: 'วันที่โอน',
      dataIndex: 'RefDate',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record?.RefDate ? moment.utc(record.RefDate).utcOffset(0, true).format('DD/MM/YYYY HH:mm') : ''}</div>;
      },
    },
    {
      title: 'ประเภทรายวัน',
      dataIndex: 'SaveFrom',
      width: '200px',
      align: 'center',
      filters: options.optionSaveFrom.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.SaveFrom.toString() === value;
      },
      render: (text, record) => {
        return <div>{record.SaveFrom === 0 ? 'ขายสด' : record.SaveFrom === 1 ? 'ซื้อสด' : 'รับชำระหนี้(บิลขายมีเครดิต)'}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code.toString() === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFGPInvoiceColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{i + 1}</div>;
      },
    },
    {
      title: 'เลขที่บิลซื้อ',
      dataIndex: 'DocNo',
      width: '180px',
      align: 'left',
      ...renderSearchColumn('DocNo', searchInput),
    },
    {
      title: 'วันที่บิลซื้อ',
      dataIndex: 'DocDate',
      width: '250px',
      align: 'left',
      render: (text, record) => {
        return <div>{moment.utc(record.DocDate).utcOffset(0, true).format('DD/MM/YYYY HH:mm')}</div>;
      },
    },
    {
      title: 'ประเภทภาษี',
      dataIndex: 'TaxType',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.TaxType}</div>;
      },
    },
    {
      title: 'ประเภทบิล',
      dataIndex: 'BillType',
      width: '200px',
      align: 'center',
      filters: options.optionBillType.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.BillType.code === value;
      },
      render: (text, record) => {
        return <div>{record.BillType.text}</div>;
      },
    },
    {
      title: 'รหัสเจ้าหนี้',
      dataIndex: 'ApCode',
      width: '160px',
      align: 'center',
      ...renderSearchColumn('ApCode', searchInput),
    },
    {
      title: 'ยอดสุทธิ',
      dataIndex: 'NetDebtAmount',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.NetDebtAmount !== 0 ? convertStrToFormat(record.NetDebtAmount, 'money') : record.NetDebtAmount}</div>;
      },
    },
    {
      title: 'รหัส User ที่เอาเข้า',
      dataIndex: 'CreatorCode',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.CreatorCode}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};

export const DIFGPInvoiceDetailColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
      render: (text, record, i) => {
        return <div>{i + 1}</div>;
      },
    },
    {
      title: 'เลขที่บิลซื้อ',
      dataIndex: 'DocNo',
      width: '180px',
      align: 'left',
      ...renderSearchColumn('DocNo', searchInput),
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'ItemCode',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('ItemCode', searchInput),
    },
    {
      title: 'รหัสคลัง',
      dataIndex: 'WHCode',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.WHCode}</div>;
      },
    },
    {
      title: 'รหัสที่เก็บ',
      dataIndex: 'ShelfCode',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.ShelfCode}</div>;
      },
    },
    {
      title: 'รหัสหน่วยนับ',
      dataIndex: 'UnitCode',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.UnitCode}</div>;
      },
    },
    {
      title: 'จำนวนขาย',
      dataIndex: 'Qty',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.Qty}</div>;
      },
    },
    {
      title: 'ราคาขาย',
      dataIndex: 'Price',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.Price !== 0 ? convertStrToFormat(record.Price, 'money') : record.Price}</div>;
      },
    },
    {
      title: 'ส่วนลด/รายการสินค้า',
      dataIndex: 'DiscountAmount',
      width: '160px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.DiscountAmount}</div>;
      },
    },
    {
      title: 'จำนวนเงินหลังหักส่วนลด',
      dataIndex: 'Amount',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.Amount !== 0 ? convertStrToFormat(record.Amount, 'money') : record.Amount}</div>;
      },
    },
    {
      title: 'เลขบรรทัดของรายการ',
      dataIndex: 'LineNumber',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return <div>{record.LineNumber}</div>;
      },
    },
    {
      title: 'สถานะ Import',
      dataIndex: 'IsImport',
      width: '160px',
      align: 'center',
      fixed: 'right',
      filters: options.optionImportStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.IsImport.code === value;
      },
      render: (text, record) => {
        return (
          <Tag color={['0'].includes(record.IsImport.code) ? color.on_process : ['1'].includes(record.IsImport.code) ? color.success : color.danger}>
            {record.IsImport.text}
          </Tag>
        );
      },
    },
  ];
};
