import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Select} from 'antd';
import {getProductFeatureMasterDt} from '../api';

const SelectProductFeature = (props) => {
  const {filter, placeholder, handleChange, dataResponse, multiple, ...otherProp} = props;
  const {code, name, is_active = true, is_visible = true} = filter || {};
  const masterCode = {master_code: 'TYPE_LOCK'};
  const [dataSc, setDataSc] = useState([]);
  const [loading, setLoading] = useState(false);

  const inputOptions = useMemo(() => {
    return {
      input: filter ? {...filter, ...masterCode} : {...masterCode},
    };
  }, [code, name, is_active, is_visible]);

  useEffect(() => {
    fetchData();
  }, [inputOptions]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getProductFeatureMasterDt(inputOptions);

      const result = response;
      setDataSc(
        _.map(result, (dt) => {
          return {
            label: dt.name,
            value: dt.code,
            source: dt,
          };
        }),
      );
      if (dataResponse) {
        dataResponse(result);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      {...otherProp}
      style={{width: '100%'}}
      options={dataSc}
      placeholder={placeholder}
      showSearch
      loading={loading}
      onChange={(value) => (multiple ? handleChange(value) : handleChange(_.find(dataSc, (data) => data?.value === value)))}
      multiple={multiple}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
};

SelectProductFeature.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  dataResponse: PropTypes.func,
};

export default SelectProductFeature;
