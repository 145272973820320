import React from 'react';
import { Input, Select } from 'antd';

const { Option } = Select;

export const EditableCellStockNonSerial = ({
  title, editable, children, dataIndex, record, handleChange, dataOption, ...restProps
}) => {

  const renderInputType = (dataIndex) => {

    if (dataIndex === "item_code") {
      return (
        <>
          <Select
            style={{ width: '100%' }}
            showSearch
            //allowClear
            name={dataIndex}
            placeholder={`กรุณาเลือก ${title}`}
            optionFilterProp="children"
            onChange={(e) => handleChange(e, dataIndex, record.key)}
            value={record && record[dataIndex] || null}

          >
            {dataOption.map((el, k) => {
              return <Option value={el.value} key={`option_${k}`}>{el.label}</Option>;
            })}

          </Select>
          {record && record.errors && <p style={{ color: '#dc3545', fontSize: '12px', padding: '6px', marginBottom: '0px' }}>{record.errors}</p>}
        </>
      );
    } else if (dataIndex === "qty") {
      return <Input value={record && record[dataIndex] || ''} type={dataIndex === "qty" ? "NUMBER" : "TEXT"}
        style={{ margin: '5px 0px', borderRadius: '10px', textAlign: 'right' }} onChange={(e) => handleChange(e.target.value, dataIndex, record.key)} />;
    } else {
      return <p>{children}</p>;
    }

  }


  return <td {...restProps}>
    {editable ? renderInputType(dataIndex) : children}

  </td>;
};