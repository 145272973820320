import Wrapper from '../../views/wrapper/Wrapper';
import { Joi, AutoAdmin } from 'joi-auto-admin';
import { productSerials } from '../../utils/auto-admin/schema';
import { getProductSerialList } from '../../utils/auto-admin/api';

const ProductSerial = () => {

    const props = {
        title: 'สินค้าพร้อมหมายเลขเครื่อง',
        getMany: getProductSerialList,
        schema: productSerials,
        disableExcelDownload: true,
        disableExcelUpload: true,
        querySchema: Joi.object({
            product_name: Joi.string().label('ชื่อสินค้า'),
            serial_number: Joi.string().label('serial number'),
            exclude_sale: Joi.string().label('สถานะ').valid("true", "false").meta({ validLabel: ["ยังไม่ขาย", "ขายแล้ว"], allowClear: true }),
            type: Joi.string().label('ประเภทสินค้า').valid("1", "2", "3").meta({ validLabel: ["มือหนึ่ง", "มือสอง", "พิเศษ"], allowClear: true }),
        }),
    };

    return (
        <Wrapper>
            <center style={{ maxWidth: 1024, margin: 'auto' }}>
                <AutoAdmin {...props} />
            </center>
        </Wrapper>
    );
};

export default ProductSerial;