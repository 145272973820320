import axios from 'axios';
import { API_URL } from '../../env.js';

export const readStatement = async (body) => {
    try {
        const response = await axios.post(`${API_URL}/admin/statement/export-excel`, body, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const time = new Intl.DateTimeFormat("th-TH", { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }).format(new Date()).replace(/ /g,"_");
        link.setAttribute('download', `statement-${time}.xlsx`);
        document.body.appendChild(link);
        link.click();
        return response;
    } catch (error) {
        return error;
    }
};