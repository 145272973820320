import { Button, Modal, Space, Typography, Row, Col, Collapse, Spin, Timeline } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { statusTagStyle, convertStrToFormat } from '../../../functions/fn';
import { GET_TRACKINGNO, GET } from '../../../services/index';


const { Panel } = Collapse;
const { Title } = Typography;

const TrackingNoModal = ({ title, visible, onClose, data }) => {
  const [dataFetch, setDataFetch] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      let res = await GET(`${GET_TRACKINGNO}/${data}/derivery-status`);
      const { success, result } = res;
      if (success) {
        setDataFetch(result);
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (data) {
      fetchData();
    }
  }, [data, fetchData]);
  let titleList = [];
  if (!isLoading && dataFetch) {

    titleList = [
      ['ชื่อ-นามสกุล', dataFetch.name],
      ['เบอร์โทรศัพท์', dataFetch.phoneNumber],
      ['สถานะ', dataFetch.status],
    ];
  }

  return (
    <Modal
      title={<Title level={3}>เลขที่สัญญา {data}</Title>}
      centered
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}
      width="50%">
      {isLoading ? (
        <Row width="100%" align="center">
          <Spin />
        </Row>
      ) : (
        <Space direction="vertical" style={{ fontSize: 16, width: '100%' }}>
          {titleList.map((item, index) => {
            return (
              <Space key={index}>
                {item[0] + ' : '}
                {item[0] === 'สถานะ' ? statusTagStyle(item[1], 'sendingStatus', { fontSize: 14, padding: 6 }) : item[1]}
              </Space>
            );
          })}

          {dataFetch?.parcels?.length !== 0 ? (
            <>
              Tracking No. :
              <Collapse
                ghost
                accordion
                expandIcon={({ isActive }) => (isActive ? <MinusCircleFilled /> : <PlusCircleFilled />)}
                style={{
                  fontSize: 16,
                  width: '100%',
                }}>
                {dataFetch?.parcels?.map((e, _) => {
                  return (
                    <Panel
                      header={
                        e.history.length === 0
                          ? `${e.tracking_no}`
                          : `${e.tracking_no} ${convertStrToFormat(e.history.at(-1).status_date, 'dateFor')} ${e.history.at(-1).status_description}`
                      }
                      key={e.tracking_no}
                      width="100%">
                      {e.history.length !== 0 ? (
                        <Timeline>
                          {e.history.map((item, index) => {
                            return (
                              <Timeline.Item key={index} color={item.status === '401' ? 'red' : '#0050b3'}>
                                <Row style={item.status === '401' ? { color: 'red' } : null}>
                                  <Col>{item.status_description}</Col>
                                  <Col style={{ paddingLeft: 10 }}>{convertStrToFormat(item.status_date, 'dayDatetime')}</Col>
                                </Row>
                                <Row style={{ color: 'gray' }}>{`${item.location} ${item.postcode}`}</Row>
                              </Timeline.Item>
                            );
                          })}
                        </Timeline>
                      ) : (
                        <p style={{ marginLeft: 25 }}>ไม่มีประวัติ</p>
                      )}
                    </Panel>
                  );
                })}
              </Collapse>
            </>
          ) : (
            'ยังไม่มีการจัดส่ง'
          )}
          <Row align="end" width="100%">
            <Button type="primary" size="large" onClick={onClose}>
              ปิด
            </Button>
          </Row>
        </Space>
      )}
    </Modal>
  );
};

export default TrackingNoModal;
