const ColorSchema = {
  primary: '#1877F2',
  danger: '#F34F46',
  blueFade: '#426782',
  blueTine: '#5890FF',
  blue200: 'rgb(237,244,251)',
  gray: '#898F9C',
  gray45: '#8D949E',
  gray70: '#606770',
  gray80: '#444950',
  tinGray: 'rgba(195,197,203, 0.5)',
  black: '#000000',
  white: '#FFFFFF',
  transparent: 'transparent',
  facebookBg: '#efefef',
  facebookCommentBg: '#F2F3F5',
  modalBackdrop: 'rgba(0,0,0,0.5)',
  modalIndicator: 'rgba(0,0,0,0.7)',
  modalRefresh: 'rgba(0,0,0,0.1)',
  settingIcon: '#90A4AE',
  imageBackdrop: '#131317',
  imageBackdropAlpha: 'rgba(19,19,23,0.08)',
  imageBackdropAlphaMore: 'rgba(19,19,23,0.5)',
  snackBar: 'rgba(19,19,23,0.98)',
  success: '#00A400',
  yellowPastel: '#ffde7d',
  greenPastel: '#00b8a9',
  redPastel: '#f6416c',
  bluePastel: '#A2DBFA',
  pinkPastel: '#FFBCBC',
  tableSuccess: '#D8F2DB',
  tableDanger: '#F6D9D4',
};

export default ColorSchema;
