/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-const */
import * as TYPES from '../types/auth';
import * as API from '../api/auth';
import { accessDenied } from '../../utils/tools';
import { auth } from '../../utils/firebase';
import { logout } from '../../apiv2';

export const checkEmail = (email) => async (dispatch) => {
  try {
    dispatch({ type: TYPES.CHECK_EMAIL_REQ });

    const response = await API.checkEmailApi({ email });
    dispatch({ type: TYPES.CHECK_EMAIL_SUCCESS, payload: response.data });
    localStorage.setItem('menu_user', JSON.stringify(response.data.menu_user));
  } catch (err) {
    let error = err.response?.data?.error;
    let errorStatus = err?.response?.status || 400;

    dispatch({ type: TYPES.CHECK_EMAIL_FAIL, payload: { error, errorStatus } });
  }
};

export const adminLogin = (data) => async (dispatch) => {
  try {
    dispatch({ type: TYPES.LOGIN_REQ });

    let response = await API.adminLoginApi(data);

    let token = response.data?.token;
    token && localStorage.setItem('token', token);

    dispatch({ type: TYPES.LOGIN_SUCCESS, payload: response.data?.token });
    return { token };
  } catch (err) {
    let error = err.response?.data?.error;
    let errorStatus = err?.response?.status || 400;

    localStorage.removeItem('token');
    dispatch({ type: TYPES.LOGIN_FAIL, payload: { error, errorStatus } });
    return { error, errorStatus };
  }
};

export const currentUser = (uid) => async (dispatch) => {
  try {
    dispatch({ type: TYPES.VERIFY_USER_REQ });

    let response = await API.currentUserApi(uid);
    dispatch({ type: TYPES.VERIFY_USER_SCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    let error = err.response?.data?.error;
    let errorStatus = err?.response?.status || 400;

    if (errorStatus === 400) {
      dispatch({
        type: TYPES.VERIFY_USER_FAIL,
        payload: { error, errorStatus },
      });
    } else {
      accessDenied(dispatch, { error, errorStatus });
    }
  }
};

export const adminLogout = () => async (dispatch) => {
  try {
    console.log('CALL LOGOUT!!');

    dispatch({ type: TYPES.LOGOUT_REQ });

    await auth.signOut();
    logout()
    localStorage.removeItem('token');
    localStorage.removeItem('menu_user');
    localStorage.removeItem('branchReportMonitorStock');
    localStorage.removeItem('checkStockAppleStatus');
    localStorage.removeItem('contractNo');
    localStorage.removeItem('userAdmin'); // For Adminv2 Web
    localStorage.removeItem('menuUser'); // For Adminv2 Web
    localStorage.removeItem('userAdminV2'); // oauth version 2

    dispatch({ type: TYPES.LOGOUT_SUCCESS });
  } catch (err) {
    dispatch({ type: TYPES.LOGOUT_FAIL, payload: err.message });
  }
};
