import styled from 'styled-components';
import ColorSchema from '../../../styles/colors';

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: 0.5rem;
  ${(props) => props.fullWidth && `width: 100%;`};
`;

export const TableHeads = styled.thead``;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  ${(props) => props.success && `background-color: ${ColorSchema.tableSuccess};`};
  ${(props) => props.danger && `background-color: ${ColorSchema.tableDanger};`};
`;

export const TableHead = styled.th`
  text-align: center;
  font-size: 16px;
  border: 1px solid ${ColorSchema.tinGray};
  padding: 8px;
  ${(props) => props.noBorder && `border: transparent; width: 150px;`};
  ${(props) => props.focus && `font-weight: bold;`};
  ${(props) => props.width && `width: ${props.width}px;`};
  ${(props) => props.strong && `font-size: 18px;`};
`;

export const TableData = styled.td`
  border: 1px solid ${ColorSchema.tinGray};
  padding: 8px;
  text-align: end;
  width: 200px;
  ${(props) => props.danger && `color: ${ColorSchema.danger};`};
  ${(props) => props.primary && `color: ${ColorSchema.primary};`};
  ${(props) => props.noBorder && `border: transparent;  width: 100px;   font-weight: normal;`};
  ${(props) => props.focus && `font-weight: bold; font-size: 18px;`};
  ${(props) => props.focusLess && `font-weight: bold;`};
  ${(props) => props.textLeft && `text-align: start;`};
  ${(props) => props.width && `min-width: ${props.width}px;`};
`;

export const TableContainer = styled.div`
  padding: 1rem 0;
`;

export const TableCol = styled.col``;

export const TableColGroup = styled.colgroup``;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CellRow = styled.div`
  display: flex;
`;
