import { TrackingNoContextProvider } from "./TrackingNoProvider";
import TrackingnoInfo from "./TrackingNoScreen";


const TrackingNoDashBoard = () => {
  return (
    <TrackingNoContextProvider>
      <TrackingnoInfo />
    </TrackingNoContextProvider>
  )
}

export default TrackingNoDashBoard;
