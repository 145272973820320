import styled from 'styled-components';

export const Table = styled.table`
  width: 100%
`;

export const Td = styled.td`
  border: 1px solid #888;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding: 5px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')}
`;

export const Th = styled.th`
  border: 1px solid #888;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding: 5px;
`;

export const Tr = styled.tr`
  background-color: ${(props) => (props.color ? props.color : 'unset')};
  display: ${(props) => (props.hide ? 'none' : 'table-row')};
`;