import moment from 'moment/moment';


export const CheckLocationHistoryColumn = () => {
  return [
    {
      title: 'วันที่ติดตาม',
      dataIndex: 'created_date',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text !== '-' ? moment(text,'YYYY-MM-DD').format('DD/MM/YYYY') : text;
      },
    },
    {
      title: 'ชื่อผู้ติดตาม',
      dataIndex: 'tracker_name',
      width: '200px',
      align: 'left',
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '150px',
      align: 'left',
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'customer_name',
      width: '200px',
      align: 'left',
    },
    {
      title: 'Location ลูกค้า',
      dataIndex: 'checkin',
      key: 'checkin',
      width: '300px',
      align: 'left',
      render: (_text, record) => {
        return (
          <a
            href={`https://maps.google.com/maps?daddr=${record?.customer_location?.lat},${record?.customer_location?.lon}`}
            target="_blank"
            rel="noreferrer">
            {record?.customer_location?.name}
          </a>
        );
      },
    },
    {
      title: 'เวลากดเช็ค',
      dataIndex: 'created_time',
      key: 'created_time',
      width: '100px',
      align: 'right',
    },
    {
      title: 'Location ผู้ติดตาม',
      dataIndex: 'checkin',
      key: 'checkin',
      width: '300px',
      align: 'left',
      render: (_text, record) => {
        return (
          <a
            href={`https://maps.google.com/maps?daddr=${record?.tracker_location?.lat},${record?.tracker_location?.lon}`}
            target="_blank"
            rel="noreferrer">
            {record?.tracker_location?.name}
          </a>
        );
      },
    },
    {
      title: 'รวมระยะห่าง (เมตร)',
      dataIndex: 'distance',
      key: 'distance',
      width: '150px',
      align: 'right',
    },
   
  ];
};
