import * as TYPES from '../types/branch';


const initialStateInfo = {
    loading: false,
    error: null,
    branchInfo: null,
};

const branchSpecificStateInfo = {
    loading: false,
    error: null,
    branchSpecificInfo: null,
};

export const branchInfoReducer = (state = initialStateInfo, { type, payload }) => {
    switch (type) {
        case TYPES.GET_BRANCH_REQ:
            return { ...state, loading: true };
        case TYPES.GET_BRANCH_SUCCESS:
            return { ...state, loading: false, error: null, branchInfo: payload };
        case TYPES.GET_BRANCH_FAIL:
            return { ...state, loading: false, error: payload, branchInfo: null };
        default:
            return state
    }
};

export const branchSpeciifcInfoReducer = (state = branchSpecificStateInfo, { type, payload }) => {
    switch (type) {
        case TYPES.GET_BRANCH_SPECIFIC_REQ:
            return { ...state, loading: true };
        case TYPES.GET_BRANCH_SPECIFIC_SUCCESS:
            return { ...state, loading: false, error: null, branchSpecificInfo: payload };
        case TYPES.GET_BRANCH_SPECIFIC_FAIL:
            return { ...state, loading: false, error: payload, branchSpecificInfo: null };
        default:
            return state
    }
};