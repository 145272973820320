import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal, Button} from '@material-ui/core/';
import PropTypes from 'prop-types';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 'auto',
    backgroundColor: (props) => (props?.backgroundColor ? props.backgroundColor : theme.palette.background.paper),
    // border: '2px solid #000',
    boxShadow: (props) => (props?.boxShadow ? props?.boxShadow : theme.shadows[5]),
    padding: (props) => (props?.padding ? props.padding : 0),
  },
  children: {
    position: 'absolute',
    width: 'auto',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    border: (props) => (props?.border ? props?.border : '2px solid #000'),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'scroll',
  },
  button: {
    '&:focus': {
      outline: 0,
    },
  },
  btnBottomRight: {
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
}));

export default function SimpleModal(props) {
  const {
    title,
    text,
    open,
    setOpen,
    handleSubmit,
    error,
    children,
    noButton,
    heightAuto,
    style,
    labelSubmit,
    onRequestClose,
    labelClose,
    hideSubmit,
    btnBottomRight,
    actions,
  } = props;

  const classes = useStyles(style);
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    if (setOpen) setOpen(false);
    if (typeof onRequestClose === 'function') {
      onRequestClose();
    }
  };

  const body = (
    <div style={modalStyle} className={heightAuto ? classes.paper : classes.children}>
      {title && <h2 id="simple-modal-title">{title}</h2>}
      {text && <p id="simple-modal-description">{text}</p>}

      <SimpleModal />
      {children}
      {error && <p>{error}</p>}
      {noButton ? (
        <></>
      ) : (
        <center className={btnBottomRight ? classes.btnBottomRight : ''}>
          {!hideSubmit && handleSubmit && (
            <Button varint="contained" color="primary" onClick={handleSubmit} className={classes.button}>
              {labelSubmit ? labelSubmit : 'Yes'}
            </Button>
          )}
          {handleSubmit && (
            <Button varint="contained" color="secondary" onClick={handleClose} className={classes.button}>
              {labelClose ? labelClose : 'Cancel'}
            </Button>
          )}
          {actions.map((action) => action)}
        </center>
      )}
    </div>
  );

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div>{body}</div>
      </Modal>
    </div>
  );
}
SimpleModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  children: PropTypes.node,
  noButton: PropTypes.bool,
  heightAuto: PropTypes.bool,
  style: PropTypes.object,
  labelSubmit: PropTypes.string,
  labelClose: PropTypes.string,
  hideSubmit: PropTypes.bool,
  btnBottomRight: PropTypes.bool,
  actions: PropTypes.array,
  onRequestClose: PropTypes.func,
};

SimpleModal.defaultProps = {
  title: '',
  text: '',
  handleSubmit: null,
  setOpen: null,
  open: false,
  children: null,
  noButton: false,
  heightAuto: false,
  style: {},
  labelSubmit: 'YES',
  labelClose: 'CANCEL',
  hideSubmit: false,
  btnBottomRight: false,
  actions: [],
  onRequestClose: null,
};
