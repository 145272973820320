import {useEffect, useState} from 'react';
import {Modal} from 'antd';
import {GET, GET_TRACKERS} from '../../../services';

export const useGetOptions = () => {
  const [optionsTracker, setOptionsTracker] = useState([]);
  useEffect(() => {
    onFetchOptionsTracker();
  }, []);

  const onFetchOptionsTracker = async () => {
    try {
      const res = await GET(GET_TRACKERS);
      const {result, success} = res;
      if (success) {
        const option = result.map((item) => ({
          label: item.name,
          value: item.trackerID,
        }));
        setOptionsTracker(option);
      }
    } catch (error) {
      const {message} = error;
      Modal.error({title: 'เกิดข้อผิดพลาด', content: message});
    }
  };

  return {
    optionsTracker
  };
};
