// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin, Table} from 'antd';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {AuditOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {ReportStockBranchColumn} from '../../../constants/global/columnTableForm';
import {GET, GET_REPORT_STOCK_BRANCH, GET_BRANCH, POST, GET_BRANCH_OPTION_SPECIFIC} from '../../../services/index';
import {createStockBranchExcelWorkBook} from '../export/stockBranchExcel';
import {ReportStockBranchSearchForm} from '../../../constants/global/SearchForm';
import {convertStrToFormat} from '../../../functions/fn';

const StockBranchReport = () => {
  const [loading, setLoading] = useState(false);
  const [optionBranchFilter, setOptionBranchFilter] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [defaultSearch, setDefaultSearch] = useState({type: '1', name: '', typeStockBranch: '1'});
  const [dataSumExport, setDataSumExport] = useState([]);
  const [dataSumTotalAll, setDataSumTotalAll] = useState('');
  const [dataSumCenter, setDataSumCenter] = useState({});
  const [dataSumON_Hold, setDataSumON_Hold] = useState({});
  const [dataSumAll, setDataSumAll] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(async () => {
    await getReportStockBranch({type: '1', name: ''});
    getOptionBranch();
  }, []);

  window.onload = function () {
    // getReportStockBranch({type: '', name: ''})
    setInterval(function () {
      let filterStockBranch = JSON.parse(localStorage.getItem('filterStockBranch'))
      // Invoke function every 10 minutes
      getReportStockBranch(filterStockBranch);
    }, 600000); // 600000 10min
  }

  const optionItemType = [
    {label: 'มือหนึ่ง', value: '1'},
    {label: 'พิเศษ', value: '3'},
  ];

  const getReportStockBranch = async (value) => {
    value.type = value?.type || '';
    value.name = value?.name || '';

    const {type, name} = value;
    localStorage.setItem('filterStockBranch', JSON.stringify(value))
    setDefaultSearch({...value, typeStockBranch: type})

    setLoading(true);
    try {
      const res = await POST(GET_REPORT_STOCK_BRANCH, {name: name, type: type});
      const {success, data} = res;
      if (success) {
        const dataClone = JSON.stringify(data.sumAllItemTypes);

        const dataEx = JSON.parse(dataClone);
        setDataSumExport(dataEx);

        const dataSum = data.sumAllItemTypes.sumTotal;
        setDataSumTotalAll(dataSum);

        const sumCenter = data.sumAllItemTypes.CENTER;
        setDataSumCenter(sumCenter);

        const sumON_HOLD = data.sumAllItemTypes.ON_HOLD;
        setDataSumON_Hold(sumON_HOLD);

        const resultData = data.reportAllItems.map((val, i) => {
          return {
            no: i + 1,
            ...val,
          };
        });

        setDataSource(resultData);

        let sumAll = data.sumAllItemTypes;
        delete sumAll['sumTotal'];
        delete sumAll['CENTER'];
        delete sumAll['ON_HOLD'];
        setDataSumAll([sumAll]);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH_OPTION_SPECIFIC);
      const {success, result} = res;
      if (success) {

        const branchFilter = result.filter((el) => el.value !== 'CENTER' && el.value !== 'ON_HOLD');

        setOptionBranchFilter(branchFilter);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };


  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              icon: <AuditOutlined />,
              title: 'รายงานสต็อกแยกตามสาขา',
              spanSearch: 24,
              formSearch: ReportStockBranchSearchForm({
                options: {
                  optionItemType,
                },
              }),
              onSearch: (data) => getReportStockBranch(data),
              dataSearchDefault: defaultSearch,
              // onClearCustom
            }}
            // tableLayout={{
            //   columns: ReportStockBranchColumn(dataSumTotalAll, dataSumON_Hold, dataSumCenter, optionBranchFilter, dataSumAll),
            //   dataSource: dataSource,
            //   scroll: {x: 1300, y: 450},
            //   extraContent: (
            //     <ContainerButton right>
            //       <ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} onClick={() => createStockBranchExcelWorkBook(dataSource, dataSumExport, optionBranchFilter)} />
            //     </ContainerButton>
            //   ),
            // }}
          />
          <div
            style={{
              marginTop: '2%',
              padding: '1%',
              backgroundColor: 'white',
              width: '100%',
              borderRadius: '15px',
              border: '1px solid #f0f0f0',
            }}>
            <div style={{marginBottom: '1%'}}>
              <ContainerButton right>
                <ButtonTheme
                  useFor="DOWNLOAD"
                  title={'EXPORT'}
                  onClick={() => createStockBranchExcelWorkBook(dataSource, dataSumExport, optionBranchFilter)}
                />
              </ContainerButton>
            </div>
            <Table
              scroll={{x: 1300, y: 600}}
              dataSource={dataSource}
              columns={ReportStockBranchColumn(dataSumTotalAll, dataSumON_Hold, dataSumCenter, optionBranchFilter, dataSumAll, selected)}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100', '200'],
                total: dataSource.length,
                defaultCurrent: 1,
                showTotal: (totalSize, range) => `${convertStrToFormat(dataSource.length, 'number-comma')} รายการ`,
              }}
              rowKey= {(record) => record.itemCode}
              rowSelection={{
                selections: [Table.SELECTION_NONE],
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(
                    selectedRows.map((row) => {
                      return {index: row.no};
                    }),
                  );
                  setSelectedRowKeys(selectedRowKeys);
                },
              }}
            />
          </div>
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default StockBranchReport;
