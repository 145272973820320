import axios from 'axios';
import {API_URL} from '../../env.js';

export const getCustomerList = async (params) => {
    try {
        return await axios.get(`${API_URL}/admin/customer/${params}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const getCustomerInfo = async (id) => {
    try {
        return await axios.get(`${API_URL}/admin/customer/${id}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};