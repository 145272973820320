import React from 'react';
import {compareRender} from '../../functions/fn';
import {Controller} from 'react-hook-form';
import {Radio, Space} from 'antd';

export const RadioTheme = React.memo(({item, control,handleChange}) => {
  const {options, defaultValue, direction, onChangeRadio} = item;



  return (
    <>
      <Controller
        name={item.name}
        control={control}
        rules={item.rules}
        defaultValue={defaultValue}
        //value={value}
        render={({value, onChange}) => (
          <Radio.Group
            defaultValue={defaultValue}
            //value={value}
            onChange={onChangeRadio ? (e) => onChangeRadio(e.target.value, item.name) : (e) => handleChange(e.target.value,item)}>
            <Space direction={direction}>
              {options.map((opt, i) => (
                <Radio key={`RadioTheme_${i}`} value={opt.value} disabled={item.disabled} defaultChecked >
                  {opt.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        )}
      />
    </>
  );
}, (prev, next) =>
compareRender(prev, next) &&
JSON.parse(JSON.stringify(prev.item.options)) ===
  JSON.parse(JSON.stringify(next.item.options)) &&
  compareRender(prev, next, 'item.options')
);
