/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthState, useCurrentState } from '../utils/hook';
import { useBranch } from '../hook/useBranch';
import { auth } from '../utils/firebase';
// import { useContext } from 'react';
// import { socketContext } from '../utils/socketProvider';
import { useEffect } from 'react';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, initializing } = useCurrentState(auth);
  const { user: authUser } = useAuthState(auth)
  const { branchList, dispatchOptionBranch, branchSpecificList, dispatchOptionBranchSpecific } = useBranch()


  // const { initSocketConnection } = useContext(socketContext)
  useEffect(() => {
    // initSocketConnection(authUser)  
    dispatchOptionBranch()
    dispatchOptionBranchSpecific()
    localStorage.setItem('branch', JSON.stringify(branchList))
    localStorage.setItem('branchSpecific', JSON.stringify(branchSpecificList))
  }, [authUser])

  return <Route {...rest} render={(props) => (!initializing && !user ? <Redirect to="/" /> : <Component {...props} />)} />;
};

export default PrivateRoute;
