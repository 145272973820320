import axios from 'axios';
import { API_URL } from '../../env';

export const payManual = async (data) => {
    try {
        return await axios.post(`${API_URL}/admin/payment/pay-manual`, data, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const getUploadSlipUrl = async (params) => {
    try {
        return await axios.get(`${API_URL}/admin/payment/get-url-upload/${params}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const uploadSlip = async (url, mimetype, file) => {
    try {
        return await axios.put(url, file, {
            headers: {
                'content-type': mimetype,
            },
        });
    } catch (error) {
        return error;
    }
};