import styled from 'styled-components';

export const Button = styled.button`
  padding: 5px 15px 5px 15px;
  border: 0;
  border-radius: 3px;
  background-color: transparent;
  &:focus {
    outline: 0;
  }

  &.search {
    border: 1px solid #3443eb;
    color: #3443eb;
    height: 80%;
    font-size: 9px;
  }

  &.search:hover {
    background-color: #303ed9;
    color: #fff;
  }

`

export const ButtonWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
`