/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import Wrapper from '../../views/wrapper/Wrapper';
import { useProduct } from '../../hook/useProduct';
import { Div, Background, Button, ImagePlaceholder, InputFile } from './styled-component';

const ProductInfo = () => {
    const { productInfo, dispatchGetInfo, dispatchUpdate } = useProduct();
    const { code } = useParams();
    const [image, setImage] = useState(null);

    useEffect(() => {
        dispatchGetInfo(code)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onInputChange = (e) => {
        setImage(e.target.files[0]);
    }

    const uploadImage = () => {
        if (image != null) {
            const data = new FormData();
            data.append("image", image, image.name);
            dispatchUpdate(code, data);
            setImage(null);
        }
    }

    return (
        <Wrapper>
            <Div>
                <Grid container item style={{ height: '100%' }}>
                    <Background>
                        <Link to="/product"><ArrowBack /> Back</Link>
                        {
                            productInfo && (
                                <div>
                                    <Div style={{ justifyContent: 'center' }}>
                                        <div>
                                            {
                                                !productInfo?.image?.url ? (
                                                    <ImagePlaceholder>No image</ImagePlaceholder>
                                                ) : (
                                                    <img style={{ width: '400px' }} src={productInfo.image.url} alt="" />
                                                )
                                            }
                                            <div style={{ marginTop: '1rem' }}>
                                                <p>รหัสสินค้า: {productInfo.code}</p>
                                                <p>ชื่อ: {productInfo.name}</p>
                                                <InputFile type="file" name="image" onChange={onInputChange} />
                                                <Button className="upload-btn" type="button" onClick={() => uploadImage()}>Upload</Button>
                                            </div>
                                        </div>
                                    </Div>
                                </div>
                            )
                        }
                    </Background>
                </Grid>
            </Div>
        </Wrapper>
    )
};

export default ProductInfo;