import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
// import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Checkbox, Input, DatePicker, Button, Space, Divider, Tooltip, InputNumber, Modal as ModalAnt } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import lodashGet from 'lodash/get';
import { QrCodePaymentModal } from '../modal/QrCodePaymentModal';
import {
  PayCalContainer,
  PaidMinimumLabel,
  PaidChannelWrapper,
  Column,
  Row,
  InfoGroup,
  InfoLabel,
  Table,
  TableData,
  TableHead,
  TableRow,
  TableBody,
  TableHeads,
  FileInput,
  TextFileName,
  ImgPreview,
  ButtonWrapper,
} from './styled-component';
import { Row as RowAntd, Col, Typography } from 'antd'
import Modal from '../../../components/modal';
import { Modal as ModalAntd } from 'antd'
import { useReceiptPaymentState, useReceiptPaymentDispatch } from '../hooks/useReceiptPayment';
import * as logic from './logic';
import * as number from '../../../utils/number';
import { ButtonTheme } from '../../../components/buttons';
import { GEN_QR_CODE_BBL, POST } from '../../../services';
import _ from 'lodash';
import Swal from 'sweetalert2';

const DATE_FORMAT = 'DD/MM/YYYY';

const { Text } = Typography

const PayCalView = (props) => {
  const { user } = props;
  const refNodeInputFile = useRef(null);

  const { receiptSummary, receiptFilterState, receiptPaidParamsState, receiptCheckRedeemInfo, receiptUpdateRemark, receiptCouponDetail } = useReceiptPaymentState();
  const {
    dispatchGetSummaryAmount,
    dispatchUpdatePaidParams,
    dispatchPayByContract,
    dispatchClearPaidParams,
    dispatchClearSummaryAmount,
    dispatchGetSummaryInfo,
    dispatchUpdateSummaryInfo,
    dispatchGetAmountContract,
    dispatchClearAmountContract,
    dispatchGetCheckRedeemData,
    dispatchClearCheckRedeemData,
    dispatchUpdateRemark,
    dispatchClearUpdateRemark,
    dispatchGetCouponDetail,
    dispatchClearGetCouponDetail,
  } = useReceiptPaymentDispatch();

  const params = new URLSearchParams(window.location.search);
  const getContractNo = params.get('contractNo');
  const paidTypeParams = params.get('paid_type');
  const admin_branch = localStorage.getItem('admin_branch');
  const [fileState, setFileState] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dataTableState, setDataTableState] = useState(null);
  const [fillContract, setFillContract] = useState(true);
  const [visibleQrCodePayment, setVisibleQrCodePayment] = useState(false);
  const [qrCodeData, setQrCodeData] = useState({});
  const [visibleModal, setVisibleModal] = useState(false)
  const [remarkDeviceReturn, setRemarkDeviceReturn] = useState('');
  const [errorRequiredRemark, setErrorRequiredRemark] = useState(false);
  const [errorCoupon, setErrorCoupon] = useState(null)
  const [coupon, setCoupon] = useState({
    coupon_id: "",
    coupon_discount: 0,
  })

  useEffect(() => {
    if (getContractNo && paidTypeParams) {
      const obj = { ...receiptPaidParamsState };
      obj.transaction_date = moment(new Date(), DATE_FORMAT);
      obj.paid_type = paidTypeParams;

      const obj2 = {
        transaction_date: moment(new Date(), DATE_FORMAT) || null,
        type: receiptPaidParamsState?.paid_type,
      };

      const params_contranct = {
        transaction_date: moment(new Date(), DATE_FORMAT),
        type: paidTypeParams,
      };

      dispatchUpdateSummaryInfo(receiptFilterState?.contractNo, obj2);
      // dispatchGetAmountContract(receiptFilterState?.contractNo, params_contranct); //  /close
      dispatchUpdatePaidParams(obj);
    }
    onResetState();
  }, []);

  useEffect(() => {
    if (receiptCheckRedeemInfo.info && receiptCheckRedeemInfo.info?.success) {
      if (receiptCheckRedeemInfo.info.result.status === 'HAVE_REDEEM') {
        if (receiptPaidParamsState?.paid_type === logic.enumTypeStatus.contract) {
          setRemarkDeviceReturn(receiptCheckRedeemInfo.info.result.remark);
          return ModalAnt.confirm({
            title: 'คำเตือน',
            width: 500,
            content: 'เกินกำหนดเวลาไถ่ถอนแล้ว ต้องการปิดสัญญาปกติหรือไม่?',
            okText: 'ตกลง',
            onOk: () => {
              setVisibleModal(true)
            },
            cancelText: 'ยกเลิก',
            onCancel: () => {
              onUpdatePaidParams('paid_type', '', false);
            },
          });
        } else if (receiptPaidParamsState.paid_type === logic.enumTypeStatus.paid) {
          return ModalAnt.warning({
            title: 'คำเตือน',
            width: 700,
            content: 'เกินกำหนดเวลาไถ่ถอนแล้ว หากต้องการปิดสัญญาแบบปกติ ให้ใช้ตัวเลือก “ปิดสัญญา”',
            okText: 'ตกลง',
            onOk: () => {
              onUpdatePaidParams('paid_type', '', false);
            },
          });
        }

      } else {
        onRequestContractInfo();
        if (receiptPaidParamsState?.paid_type !== logic.enumTypeStatus.paid) {
          onRequestCalPaidValue();
        }
      }
    }
  }, [receiptCheckRedeemInfo.info]);

  const handleChangeRemark = (e) => {
    setErrorRequiredRemark(false)
    setRemarkDeviceReturn(e.target.value);
  };

  const handleSubmitRemark = () => {
    if (remarkDeviceReturn === '') {
      setErrorRequiredRemark(true);
    } else {
      try {
        const params = {
          contract_no: receiptFilterState?.contractNo,
          remark: remarkDeviceReturn,
        };
        dispatchUpdateRemark(params);
      } catch (err) {
        const { message } = err;
        ModalAnt.error({
          title: message || err,
        });
      } finally {
        setErrorRequiredRemark(false);
        setVisibleModal(false);
      }
    }
  };

  useEffect(() => {
    if (receiptUpdateRemark.info && receiptUpdateRemark.info?.success) {
      return ModalAnt.success(({
        title: receiptUpdateRemark.info?.message || '',
        afterClose: () => {
          dispatchClearCheckRedeemData();
          dispatchClearUpdateRemark();
          onRequestContractInfo();
          if (receiptPaidParamsState?.paid_type !== logic.enumTypeStatus.paid) {
            onRequestCalPaidValue();
          }
        }
      }))
    }
  }, [receiptUpdateRemark])

  useEffect(() => {
    if (receiptCouponDetail.info) {
      if (receiptCouponDetail.info?.success) {
        const obj = {
          coupon_id: receiptCouponDetail.info?.coupon_id,
          coupon_discount: receiptCouponDetail.info?.coupon_discount,
        }
        setCoupon(obj)
        setErrorCoupon(null)
      }
    }
    if (receiptCouponDetail?.error) {
      const obj = {
        coupon_id: "",
        coupon_discount: 0,
      }
      setCoupon(obj)
      setErrorCoupon(receiptCouponDetail.error?.message)
    }
  }, [receiptCouponDetail])

  const onCloseModalEditRemark = () => {
    setVisibleModal(false)
    onUpdatePaidParams('paid_type', '', false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onRequestCalPaidValue();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [receiptPaidParamsState?.paid_amount, receiptPaidParamsState?.contract_amount]);

  const onChangePaidValue = (value) => {
    if (!value) {
      return '';
    }

    const isValidNumber = /^\d+(\.\d{1,2})?$/.test(value);
    if (!isValidNumber) {
      return null;
    }
    const paidValue = value

    if (lodash.isNaN(paidValue)) {
      return null;
    }

    return paidValue;
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onRequestCalPaidValue2();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);

  }, [receiptPaidParamsState?.paid_amount, receiptSummary?.amount_contract, coupon]);

  const onRequestCalPaidValue2 = () => {
    const { transaction_date, additional_charges, paid_amount, contract_amount, paid_type } = receiptPaidParamsState || {};
    const { amount_contract } = receiptSummary;

    if (!transaction_date) {
      return;
    }

    const paidValue = paid_amount;
    const paidContract = contract_amount;
    const params = {
      transaction_date: logic.getDateQueryFormat(transaction_date),
      paid_amount: paidValue,
      contract_amount: paidContract,
      type: paid_type,
    };

    if (lodash.get(additional_charges, '[0]')) {
      params.additional_charges = [...additional_charges];
    }

    if (paid_type === 'CONTRACT') {
      params.type = 'CLOSED_CONTRACT';
      params.paid_amount = amount_contract;
    }

    if (paid_type === 'REDEEM_CLOSED_CONTRACT') {
      params.paid_amount = amount_contract;
    }
    if (paid_type === 'REDEEM_CLOSED_CONTRACT') {
      params.paid_amount = amount_contract;
    }

    if (receiptCouponDetail?.info && coupon.coupon_discount !== 0) {
      params.coupon_discount = coupon.coupon_discount
    }

    dispatchGetSummaryAmount(receiptFilterState?.contractNo, params);
  }

  const onUpdatePaidParams = (key, value, e) => {
    const params = { ...receiptPaidParamsState };
    if (key === 'transaction_date') {
      let date = '';
      if (!value) {
        date = moment(new Date(), DATE_FORMAT);
      } else {
        date = moment(value, DATE_FORMAT);
      }
      setFillContract(false);
      params.transaction_date = date;
    } else if (key === 'paid_amount' || key === 'contract_amount') {
      const paidValue = onChangePaidValue(value);
      // if (paidValue !== null && key === 'paid_amount') {
      //   params.paid_amount = paidValue;
      // } else if (paidValue !== null && key === 'contract_amount') {
      //   params.contract_amount = paidValue;
      // }

      if (paidValue !== null && key === 'paid_amount') {
        params.paid_amount = paidValue;
      } else if (value !== null && key === 'contract_amount') {
        params.contract_amount = paidValue;
      }
    } else if (key === 'paid_type') {
      if (e === false) {
        params.paid_type = '';
        setFillContract(false);
      } else {
        params.paid_type = value;
      }
    } else {
      params[key] = value;
    }
    dispatchUpdatePaidParams(params);
  };

  const onRequestUploadFile = () => {
    if (!refNodeInputFile.current) {
      return;
    }
    refNodeInputFile.current.click();
  };

  const onChangeFile = (event, clear) => {
    if (clear) {
      setFileState(null);
      return;
    }
    if (!event) {
      return;
    }
    const file = lodashGet(event.target.files, '[0]');
    event.target.value = null;
    setFileState({ file, imageUrl: URL.createObjectURL(file) });
  };

  const onPreviewFile = () => {
    setOpenModal(true);
  };

  const onRequestContractInfo = () => {
    const params = {
      transaction_date: logic.getDateQueryFormat(receiptPaidParamsState?.transaction_date || null),
      type: receiptPaidParamsState?.paid_type,
    };
    dispatchUpdateSummaryInfo(receiptFilterState.contractNo, params);
  };

  const onRequestInitCalPaidValue = (skipCharges) => {
    const isNotFetch = receiptSummary?.summary_amount?.loading || receiptSummary?.summary_amount?.error;
    if (isNotFetch) {
      return;
    }
    const transDate = skipCharges ? logic.getDateQueryFormat() : logic.getDateQueryFormat(receiptPaidParamsState?.transaction_date || null);
    const params = {
      transaction_date: transDate,
      paid_amount: 0,
    };

    const params_contranct = {
      transaction_date: transDate,
      type: receiptPaidParamsState?.paid_type,
    };

    if (params_contranct.type === 'CONTRACT') {
      params_contranct.type = 'CLOSED_CONTRACT';
    }

    if (paidTypeParams) {
      params_contranct.type = paidTypeParams;
    }

    if (!skipCharges && lodash.get(receiptPaidParamsState?.additional_charges, '[0]')) {
      params.additional_charges = [...receiptPaidParamsState.additional_charges];
      params_contranct.additional_charges = [...receiptPaidParamsState.additional_charges];
    }
    if (paidTypeParams === logic.enumTypeStatus.redeemCloseContract) {
      params.paid_amount = receiptSummary?.amount_contract;
    }

    dispatchGetAmountContract(receiptFilterState?.contractNo, params_contranct); //  /close
    if (params.paid_amount) {
      dispatchGetSummaryAmount(receiptFilterState?.contractNo, params); // /amount
    }
    // dispatchGetSummaryAmount(receiptFilterState?.contractNo, params);// /amount
  };

  const onRequestCalPaidValue = () => {
    const { transaction_date, additional_charges, paid_amount, contract_amount, paid_type } = receiptPaidParamsState || {};
    const { amount_contract } = receiptSummary;

    if (!transaction_date) {
      return;
    }

    if (!paid_amount && !amount_contract) {
      onRequestInitCalPaidValue();
      return;
    }
    const paidValue = paid_amount;
    const paidContract = contract_amount;
    // const paidValue = paid_amount.replace(/,/g, '');
    // const paidContract = contract_amount.replace(/,/g, '');
    // const isValidNumber = /^[0-9]*$/.test(paidValue);
    // const isValidContract = /^[0-9]*$/.test(paidContract);
    // if (!isValidNumber || !isValidContract) {
    //   return;
    // }
    const params = {
      transaction_date: logic.getDateQueryFormat(transaction_date),
      paid_amount: paidValue,
      contract_amount: paidContract,
      type: paid_type,
    };
    const params_contranct = {
      transaction_date: logic.getDateQueryFormat(transaction_date),
      type: paid_type,
    };
    if (lodash.get(additional_charges, '[0]')) {
      params.additional_charges = [...additional_charges];
      params_contranct.additional_charges = [...additional_charges];
    }

    if (paid_type === 'CONTRACT') {
      params.type = 'CLOSED_CONTRACT';
      params.paid_amount = amount_contract;
      params_contranct.type = 'CLOSED_CONTRACT';
      params_contranct.paid_amount = 0;
      // dispatchUpdatePaidParams({paid_amount: '0'});
    }

    if (paid_type === 'REDEEM_CLOSED_CONTRACT') {
      params.paid_amount = amount_contract;
      params_contranct.paid_amount = 0;
      // dispatchUpdatePaidParams({paid_amount: '0'});
    }

    if (paid_type !== '') {
      dispatchGetAmountContract(receiptFilterState?.contractNo, params_contranct); //  /close
      // dispatchGetSummaryAmount(receiptFilterState?.contractNo, params);// /amount
    }
  };

  const onResetState = () => {
    setFileState(null);
  };

  const checkIsDisabledButtonSubmit = () => {
    return (receiptPaidParamsState?.transaction_date && receiptPaidParamsState?.paid_type) || receiptPaidParamsState?.paid_amount;
  };

  const checkIsDisabledButtonSubmit2 = () => {
    return (receiptPaidParamsState?.paid_type === logic.enumTypeStatus.redeemCloseContract ? false : !fileState)
  }

  const onFireSubmitTransaction = async () => {
    // ชำระแบบกรอกจำนวนเงิน ส่งค่าชำระที่ไม่ลบส่วนลด เพราะต้องเอาไปตัดชำระจำนวนเต็มที่บวกส่วนลดที่หลังบ้าน

    const dataPayment = {
      transaction_date: logic.getDateQueryFormat(receiptPaidParamsState?.transaction_date),
      paid_amount: logic.transAmountToNumber(receiptPaidParamsState?.paid_amount),
      contract_amount: logic.transAmountToNumber(receiptPaidParamsState?.contract_amount),
      paid_type: receiptPaidParamsState?.paid_type || logic.enumTypeStatus.paid,
      channel: receiptPaidParamsState?.channel || logic.enumPaidChannel.cash,
      type: receiptPaidParamsState?.paid_type || logic.enumTypeStatus.paid,
      branch: admin_branch || '',
      update_by: user?.displayName,
      check_slip: false,
    };

    if (lodash.get(receiptPaidParamsState?.additional_charges, '[0]')) {
      dataPayment.additional_charges = [...receiptPaidParamsState.additional_charges];
    }

    if (receiptPaidParamsState.paid_type === 'CONTRACT') {
      // ชำระแบบปิดสัญญา ส่งค่าชำระที่ไม่ลบส่วนลด เพราะต้องเอาไปตัดชำระจำนวนเต็มที่หลังบ้าน
      dataPayment.type = 'CLOSED_CONTRACT';
      dataPayment.paid_amount = receiptSummary?.amount_contract;
    }

    if (receiptPaidParamsState.paid_type === logic.enumTypeStatus.redeemCloseContract) {
      dataPayment.paid_amount = receiptSummary?.amount_contract;
    }

    if (receiptCouponDetail?.info && coupon.coupon_id !== '') {
      dataPayment.coupon_id = coupon.coupon_id
    }

    const payload = {};
    if (fileState) {
      const mimetype = `image/${fileState.file.name.split('.').pop()}`;
      payload.fileUpload = {
        mimetype,
        params: `?contract_no=${receiptFilterState.contractNo}&mimetype=${mimetype}`,
        file: fileState.file,
        setFileState: setFileState,
      };
    }

    payload.callbackSuccess = () => {
      dispatchClearPaidParams();
      dispatchClearSummaryAmount();
      dispatchClearAmountContract();
      dispatchClearGetCouponDetail()
      onRequestInitCalPaidValue(true);
      const params = {
        transaction_date: logic.getCurrentDate(true),
      };
      dispatchGetSummaryInfo(receiptFilterState.contractNo, params);
    };
    dispatchPayByContract(receiptFilterState.contractNo, dataPayment, payload);
  };

  const onSubmitTransaction = () => {

    if (!_.isEmpty(errorCoupon)) {
      Swal.fire({
        icon: 'error',
        text: errorCoupon,
        confirmButtonText: 'ปิด',
        confirmButtonColor: '#1890ff',
      });
      return;
    }
    // ปุ่มบันทึก
    // const minPaidAmount = lodashGet(receiptSummary, 'summary_amount.summary.min_amount.min', 0);
    // const paidAmount = receiptPaidParamsState.paid_type === 'CONTRACT'? receiptSummary?.amount_contract
    // :logic.transAmountToNumber(receiptPaidParamsState?.paid_amount);
    // if (minPaidAmount && paidAmount < minPaidAmount) {
    //   Swal.fire({
    //     icon: 'warning',
    //     title: `ยอดชำระไม่ถูกต้อง ยอดชำระขั้นต่ำ ${number.formatComma(minPaidAmount)} บาท`,
    //     confirmButtonText: 'ปิด',
    //     confirmButtonColor: '#1890ff',
    //   });
    //   return;
    // }
    onFireSubmitTransaction();
  };

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     onRequestCalPaidValue();
  //   }, 1000);
  // // }, 3000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [receiptPaidParamsState?.paid_type, receiptPaidParamsState?.paid_amount, receiptPaidParamsState?.contract_amount]);
  // }, [receiptPaidParamsState?.transaction_date, receiptPaidParamsState?.paid_amount, receiptPaidParamsState?.contract_amount]);

  useEffect(() => {
    if (receiptSummary?.summary_amount?.summary) {
      const summaryData = logic.createTableSummary(receiptSummary?.summary_amount?.summary);
      setDataTableState(summaryData);
    }
  }, [receiptSummary?.summary_amount]);

  useEffect(() => {
    if (receiptPaidParamsState?.paid_type && receiptPaidParamsState?.paid_type !== '') {
      const params = {
        contract_no: receiptFilterState.contractNo,
        paid_type: receiptPaidParamsState?.paid_type,
      };
      if (params.paid_type === 'CONTRACT') {
        params.paid_type = 'CLOSED_CONTRACT';
      }

      if (params.paid_type !== 'PAID') {
        onUpdatePaidParams('paid_amount', 0);
      }

      if (receiptPaidParamsState.transaction_date !== '') {
        // onRequestContractInfo();
        dispatchGetCheckRedeemData(params);
      } else {
        return ModalAntd.warning({
          title: 'กรุณาเลือกวันที่ชำระ',
          afterClose: () => {
            onUpdatePaidParams('paid_type', '', false);
          },
        });
      }
    }
  }, [receiptPaidParamsState?.paid_type]);

  const onGenerateQRCodeBBL = async () => {
    try {
      let amounts = 0;
      if (receiptPaidParamsState?.paid_type === logic.enumTypeStatus.paid) {
        // Gen QR ชำระแบบกรอกกเงิน ไม่ต้องลบส่วนลดคูปองออกเพราะว่า user ได้กรอกค่่าเงินชำระที่ลบส่วนลดคูปองออกไปแล้ว
        amounts = receiptPaidParamsState?.paid_amount
      }

      if (receiptPaidParamsState?.paid_type === logic.enumTypeStatus.contract) {
        // Gen QR ชำระแบบปิดสัญญา ต้องลบส่วนลดคูปองที่หน้าบ้าน เพื่อให้ Gen QR แบบที่ลดส่วนลดไปแล้ว ให้หลังบ้าน
        amounts = receiptSummary?.amount_contract - coupon.coupon_discount
      }

      if (receiptPaidParamsState?.paid_type === logic.enumTypeStatus.redeemCloseContract) {
        amounts = receiptSummary?.amount_contract;
      }
      const payload = {
        additional_charges: !_.isEmpty(receiptPaidParamsState.additional_charges) ? [...receiptPaidParamsState.additional_charges] : null,
        amount: amounts,
        closed_contract_no: receiptPaidParamsState?.paid_type === logic.enumTypeStatus.contract ? receiptFilterState?.contractNo : '',
        prepaid_contract_no: receiptPaidParamsState?.paid_type === logic.enumTypeStatus.paid ? receiptFilterState?.contractNo : '',
        id_card: receiptSummary?.summary_info?.user?.id_card,
        transaction_date: moment(receiptPaidParamsState?.transaction_date).format('YYYY-MM-DD') || '',
        redeem_closed_contract_no:
          receiptPaidParamsState?.paid_type === logic.enumTypeStatus.redeemCloseContract ? receiptFilterState?.contractNo : '',
      };

      if (coupon.coupon_id !== '') {
        payload.coupon_id = coupon.coupon_id
      }

      const res = await POST(GEN_QR_CODE_BBL, payload);

      const { success, data, message } = res;
      if (success) {
        setQrCodeData(data);
        setVisibleQrCodePayment(true);
      }
    } catch (error) {
      ModalAnt.error({
        title: error.message,
      });
    }
  };

  const TextEditRemark = () => {
    return (
      <div><Text type='danger'>*</Text><Text>กรุณากรอกหมายเหตุ</Text></div>
    )
  }

  const fetchDataCoupon = async (value) => {

    const hasSpace = _.includes(value, ' ');

    let code = value.toUpperCase()

    if (hasSpace) {
      code = value.trim()
    }

    const payload = {
      coupon_code: code,
      contract_no: receiptFilterState?.contractNo,
      transaction_date: logic.getDateQueryFormat(receiptPaidParamsState?.transaction_date),
    }

    dispatchGetCouponDetail(payload);
  };

  const handleFetchCoupon = (e) => {
    if (e.target.value) {
      fetchDataCoupon(e.target.value)
    } else {
      dispatchClearGetCouponDetail()
      setCoupon({
        coupon_id: '',
        coupon_discount: 0
      })
      setErrorCoupon(null)
    }
  }

  const viewPaidAmount = (paidAmount) => {
    // convert to 0 if paidAmount > 0
    return paidAmount > 0 ? paidAmount : 0;
  }

  return (
    <>
      <Modal open={openModal} setOpen={setOpenModal} noButton heightAuto>
        <ImgPreview src={fileState?.imageUrl || null} alt="preview-img" />
      </Modal>
      <Divider />
      <PayCalContainer>
        {lodashGet(receiptSummary, 'summary_amount.summary.min_amount.min', 0) ? (
          <PaidMinimumLabel>{`ชำระขั้นต่ำ : ${number.formatNumber(
            lodashGet(receiptSummary, 'summary_amount.summary.min_amount.min', 0),
          )}`}</PaidMinimumLabel>
        ) : null}
        <Row>
          <Column>
            <Table>
              <TableHeads>
                <TableRow>
                  <TableHead noBorder />
                  <TableHead>ยอดค้างชำระ</TableHead>
                  <TableHead>ตัดชำระ</TableHead>
                  <TableHead>เหลือค้างชำระ</TableHead>
                </TableRow>
              </TableHeads>
              <TableBody>
                <TableRow>
                  <TableData noBorder>เงินต้น</TableData>
                  <TableData>{logic.renderNumber(lodash.get(dataTableState, 'row_1.col_1', 0))}</TableData>
                  <TableData danger>{logic.renderNumber(lodash.get(dataTableState, 'row_1.col_2', 0))}</TableData>
                  <TableData primary focusLess>
                    {logic.renderNumber(lodash.get(dataTableState, 'row_1.col_3', 0))}
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData noBorder>ดอกเบี้ย</TableData>
                  <TableData>{logic.renderNumber(lodash.get(dataTableState, 'row_2.col_1', 0))}</TableData>
                  <TableData danger> {logic.renderNumber(lodash.get(dataTableState, 'row_2.col_2', 0))}</TableData>
                  <TableData primary focusLess>
                    {logic.renderNumber(lodash.get(dataTableState, 'row_2.col_3', 0))}
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData noBorder>ค่าปรับ</TableData>
                  <TableData>{logic.renderNumber(lodash.get(dataTableState, 'row_3.col_1', 0))}</TableData>
                  <TableData danger> {logic.renderNumber(lodash.get(dataTableState, 'row_3.col_2', 0))}</TableData>
                  <TableData primary focusLess>
                    {logic.renderNumber(lodash.get(dataTableState, 'row_3.col_3', 0))}
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData noBorder>ค่าทวงถาม</TableData>
                  <TableData>{logic.renderNumber(lodash.get(dataTableState, 'row_4.col_1', 0))}</TableData>
                  <TableData danger> {logic.renderNumber(lodash.get(dataTableState, 'row_4.col_2', 0))}</TableData>
                  <TableData primary focusLess>
                    {logic.renderNumber(lodash.get(dataTableState, 'row_4.col_3', 0))}
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData noBorder>ค่าใช้จ่ายอื่นๆ</TableData>
                  <TableData>{logic.renderNumber(lodash.get(dataTableState, 'row_7.col_1', 0))}</TableData>
                  <TableData danger> {logic.renderNumber(lodash.get(dataTableState, 'row_7.col_2', 0))}</TableData>
                  <TableData primary focusLess>
                    {logic.renderNumber(lodash.get(dataTableState, 'row_7.col_3', 0))}
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData noBorder>(กรณีปิดสัญญา , คูปอง) ส่วนลด</TableData>
                  <TableData>{logic.renderNumber(lodash.get(dataTableState, 'row_6.col_1', 0))}</TableData>
                  <TableData danger> {logic.renderNumber(lodash.get(dataTableState, 'row_6.col_2', 0))}</TableData>
                  <TableData primary focusLess>
                    {logic.renderNumber(lodash.get(dataTableState, 'row_6.col_3', 0))}
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData noBorder focus>
                    รวมค้างชำระ
                  </TableData>
                  <TableData focus>{logic.renderNumber(lodash.get(dataTableState, 'row_5.col_1', 0))}</TableData>
                  <TableData danger focus>
                    {logic.renderNumber(lodash.get(dataTableState, 'row_5.col_2', 0))}
                  </TableData>
                  <TableData primary focus>
                    {logic.renderNumber(lodash.get(dataTableState, 'row_5.col_3', 0))}
                  </TableData>
                </TableRow>
              </TableBody>
            </Table>
          </Column>
          <Column style={{ marginLeft: '-100px' }}>
            <PaidChannelWrapper>
              <Row style={{ marginBottom: '10px' }}>
                <Column>
                  <InfoLabel style={{ marginBottom: '8px' }}>เลือกวันที่ชำระ</InfoLabel>
                  <DatePicker
                    style={{ width: 400 }}
                    placeholder="เลือกวันที่ชำระ"
                    format={DATE_FORMAT}
                    value={receiptPaidParamsState?.transaction_date || ''}
                    onChange={(date) => onUpdatePaidParams('transaction_date', date)}
                  />
                </Column>
              </Row>
              <Row style={{ marginBottom: '10px' }}>
                <Column edgeInsetsRight={20}>
                  <Checkbox
                    style={{ fontSize: '16px' }}
                    disabled={
                      receiptPaidParamsState?.transaction_date === '' && receiptPaidParamsState?.paid_type === ''
                        ? true
                        : receiptPaidParamsState?.transaction_date !== '' && receiptPaidParamsState?.paid_type === ''
                          ? false
                          : receiptPaidParamsState?.paid_type !== logic.enumTypeStatus.redeemCloseContract
                    }
                    checked={receiptPaidParamsState?.paid_type === logic.enumTypeStatus.redeemCloseContract}
                    onChange={(e) => {
                      setFillContract(true);
                      onUpdatePaidParams('paid_type', logic.enumTypeStatus.redeemCloseContract, e.target.checked);
                      // dispatchUpdatePaidParams({paid_amount: '0'});
                    }}>
                    ปิดสัญญาลูกค้าคืนเครื่อง
                  </Checkbox>
                </Column>
              </Row>
              <Row style={{ marginBottom: '10px' }}>
                <Column edgeInsetsRight={20}>
                  <Checkbox
                    style={{ fontSize: '16px' }}
                    disabled={
                      receiptPaidParamsState?.transaction_date === '' && receiptPaidParamsState?.paid_type === ''
                        ? true
                        : receiptPaidParamsState?.transaction_date !== '' && receiptPaidParamsState?.paid_type === ''
                          ? false
                          : receiptPaidParamsState?.paid_type !== logic.enumTypeStatus.contract
                    }
                    checked={receiptPaidParamsState?.paid_type === logic.enumTypeStatus.contract}
                    onChange={(e) => {
                      setFillContract(true);
                      onUpdatePaidParams('paid_type', logic.enumTypeStatus.contract, e.target.checked);
                      // dispatchUpdatePaidParams({paid_amount: '0'});
                    }}>
                    ปิดสัญญา
                  </Checkbox>
                </Column>
              </Row>
              <Row width={600} between>
                <Column edgeInsetsRight={20}>
                  <Checkbox
                    style={{ fontSize: '16px', marginBottom: '10px' }}
                    disabled={
                      receiptPaidParamsState?.transaction_date === '' && receiptPaidParamsState?.paid_type === ''
                        ? true
                        : receiptPaidParamsState?.transaction_date !== '' && receiptPaidParamsState?.paid_type === ''
                          ? false
                          : receiptPaidParamsState?.paid_type !== logic.enumTypeStatus.paid
                    }
                    checked={receiptPaidParamsState?.paid_type === logic.enumTypeStatus.paid}
                    onChange={(e) => {
                      setFillContract(false);
                      onUpdatePaidParams('paid_type', logic.enumTypeStatus.paid, e.target.checked);
                    }}>
                    ชำระเงิน
                  </Checkbox>
                  <Input
                    id="coupon_code"
                    placeholder="กรอกรหัสคูปอง"
                    style={{ width: 300, marginBottom: 15 }}
                    onBlur={(e) => handleFetchCoupon(e)}
                    disabled={receiptPaidParamsState?.transaction_date === '' || receiptPaidParamsState.paid_type === logic.enumTypeStatus.redeemCloseContract}
                  />
                  {errorCoupon && <span style={{ color: 'red', marginBottom: 15 }}>{errorCoupon}</span>}
                  <InputNumber
                    id="paid-value"
                    placeholder="กรอกยอดชำระ"
                    style={{ textAlign: 'end', width: 300 }}
                    value={
                      receiptPaidParamsState.paid_type === logic.enumTypeStatus.paid
                        ? receiptPaidParamsState?.paid_amount
                        : receiptPaidParamsState.paid_type === logic.enumTypeStatus.contract
                          ? viewPaidAmount(receiptSummary?.amount_contract - coupon.coupon_discount)
                          : receiptPaidParamsState.paid_type === logic.enumTypeStatus.redeemCloseContract
                            ? receiptSummary?.amount_contract
                            : ''
                    }
                    // value={receiptPaidParamsState?.paid_amount | ''}
                    onChange={(e) => onUpdatePaidParams('paid_amount', e)}
                    disabled={fillContract}
                  />
                </Column>
                {/* <Column>
                  <InfoLabel>วันที่ชำระ</InfoLabel>
                  <DatePicker
                    placeholder="เลือกวันที่ชำระ"
                    format={DATE_FORMAT}
                    value={receiptPaidParamsState?.transaction_date || ''}
                    onChange={(date) => onUpdatePaidParams('transaction_date', date)}
                  />
                </Column> */}
              </Row>
              <Row edgeInsetsTop={16}>
                <Checkbox
                  checked={receiptPaidParamsState?.channel === logic.enumPaidChannel.cash}
                  onChange={() => onUpdatePaidParams('channel', logic.enumPaidChannel.cash)}>
                  เงินสด
                </Checkbox>
                <Checkbox
                  checked={receiptPaidParamsState?.channel === logic.enumPaidChannel.transfer}
                  onChange={() => onUpdatePaidParams('channel', logic.enumPaidChannel.transfer)}>
                  เงินโอน
                </Checkbox>
              </Row>
            </PaidChannelWrapper>
            {fileState && (
              <Row edgeInsetsTop={50}>
                <InfoGroup style={{ marginLeft: '160px' }}>
                  <InfoLabel>ไฟล์แนบ : </InfoLabel>
                  <TextFileName>{fileState.file?.name}</TextFileName>
                </InfoGroup>
              </Row>
            )}
            <Row width={600} edgeInsetsTop={fileState ? 10 : 30}>
              <FileInput
                ref={refNodeInputFile}
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(event) => onChangeFile(event, false)}
              />
              <Space>
                <Button block style={{ width: 280, marginLeft: '160px' }} onClick={onRequestUploadFile}>
                  <UploadOutlined />
                  {fileState ? 'เลือกไฟล์แนบใหม่' : 'อัพโหลดไฟล์แนบ'}
                </Button>
                {fileState && (
                  <>
                    <Button onClick={onPreviewFile}>ดูไฟล์</Button>
                    <Button ghost danger onClick={() => onChangeFile(1, true)}>
                      ลบไฟล์
                    </Button>
                  </>
                )}
              </Space>
            </Row>
            <ContainerButton center>
              <div>
                <ButtonTheme useFor="QR_CODE" disabled={!checkIsDisabledButtonSubmit()} onClick={onGenerateQRCodeBBL} title="Generate QR Code" />
                <ButtonTheme useFor="SUBMIT" disabled={checkIsDisabledButtonSubmit2()} onClick={onSubmitTransaction} />
              </div>
              {/* {checkIsDisabledButtonSubmit() && (
                <div style={{ marginLeft: '20px' }}>
                  <Tooltip title="ระบุยอดชำระ และวันที่ชำระ">
                    <ButtonTheme useFor="QR_CODE" disabled onClick={onGenerateQRCodeBBL} title="Generate QR Code" />
                  </Tooltip>
                  <Tooltip title="ระบุยอดชำระ และวันที่ชำระ">
                    <ButtonTheme useFor="SUBMIT" disabled={!fileState} onClick={onSubmitTransaction} />
                  </Tooltip>
                </div>
              )}
              {!checkIsDisabledButtonSubmit() && (
                <div style={{ marginLeft: '20px' }}>
                  <ButtonTheme useFor="QR_CODE" onClick={onGenerateQRCodeBBL} title="Generate QR Code" />
                  <ButtonTheme useFor="SUBMIT" disabled={!fileState} onClick={onSubmitTransaction} />
                </div>
              )} */}
            </ContainerButton>
          </Column>
        </Row>
        <QrCodePaymentModal
          visible={visibleQrCodePayment}
          title={'QR Code สำหรับให้ลูกค้าชำระเงิน'}
          onClose={() => setVisibleQrCodePayment(false)}
          data={{ ...qrCodeData, name: receiptSummary?.summary_info?.user?.general?.fullname }}
        />
        <ModalAnt title={TextEditRemark()} open={visibleModal} onCancel={onCloseModalEditRemark} destroyOnClose={true} width={500} footer={null}>
          {errorRequiredRemark && <Text type='danger'>กรุณากรอกหมายเหตุ</Text>}
          <textarea
            className="ant-input"
            rows={3}
            maxLength={200}
            value={remarkDeviceReturn}
            onChange={(e) => handleChangeRemark(e)}
            placeholder={`กรอกได้สูงสุด 200 ตัวอักษร`}
            style={{ margin: '5px 0px', borderRadius: '10px', width: '100%' }}
          />
          <ContainerButton right>
            <ButtonTheme useFor="CANCEL" onClick={() => onCloseModalEditRemark()} />
            <ButtonTheme useFor="SUBMIT" onClick={() => handleSubmitRemark()} />
          </ContainerButton>
        </ModalAnt>
      </PayCalContainer>
    </>
  );
};

PayCalView.propTypes = {
  user: PropTypes.any,
};

PayCalView.defaultProps = {
  user: null,
};

export default PayCalView;
