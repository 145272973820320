import {createContext} from 'react';
import PropTypes from 'prop-types';

const toastContext = createContext();

const ToastProvider = ({children}) => {
  const config = {
    position: 'top-right',
    autoClose: 1400,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  return <toastContext.Provider value={[config]}>{children}</toastContext.Provider>;
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {ToastProvider, toastContext};
