import * as TYPES from '../types/auth';

const initialAuth = {
  isAuthenticate: false,
  loading: false,
  token: null,
  error: null,
  errorStatus: null,
};

const initialEmail = {
  email: null,
  menu: null,
  loading: false,
  error: null,
  errorStatus: null,
};

export const checkEmailReducer = (state = initialEmail, {type, payload}) => {
  switch (type) {
    case TYPES.CHECK_EMAIL_REQ:
      return {
        ...state,
        email: null,
        loading: true,
        error: null,
        errorStatus: null,
      };
    case TYPES.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        email: payload.isAllow,
        menu: payload.menu_user,
        loading: false,
      };
    case TYPES.CHECK_EMAIL_FAIL:
      return {
        ...state,
        error: payload?.message,
        errorStatus: payload?.status,
      };
    default:
      return state;
  }
};

export const authReducer = (state = initialAuth, {type, payload}) => {
  switch (type) {
    case TYPES.LOGIN_REQ:
    case TYPES.LOGOUT_REQ:
      return {
        ...state,
        ...initialAuth,
        loading: true,
      };
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        token: payload,
        loading: false,
        isAuthenticate: true,
      };
    case TYPES.LOGIN_FAIL:
      return {
        ...state,
        token: null,
        loading: false,
        isAuthenticate: false,
      };
    case TYPES.VERIFY_USER_REQ:
      return {
        ...state,
        loading: true,
      };
    case TYPES.VERIFY_USER_SCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticate: true,
      };
    case TYPES.VERIFY_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticate: false,
        error: payload?.message,
        errorStatus: payload?.status,
      };
    case TYPES.AUTH_ERROR:
      return {
        ...state,
        ...initialAuth,
      };
    case TYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialAuth,
      };
    case TYPES.LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
