import React, {useState, useEffect} from 'react';
import {Modal, Row, Col} from 'antd';
import {useForm} from 'react-hook-form';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import RenderForm from '../../../constants/global/RenderForm';
import {convertStrToFormat} from '../../../functions/fn';
import {useAuthState} from '../../../utils/hook';
import {auth} from '../../../utils/firebase';
import {FGFApproveCommissionVerifyModalForm} from '../form';
import {Spinners} from '../../../components';
import {POST, CANCEL_LOOKUP, POST_FGF_CONFIRM_APPROVE_COMMISSION, GET_FGF_LOOKUP_COMMISSION} from '../../../services/index';

export const FGFApproveCommissionVerifyModal = ({title, visible, onClose, data}) => {
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const {user} = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [statusReject, setStatusReject] = useState(false);

  useEffect(async () => {
    if (JSON.stringify(data && data.record) !== '{}' && data.record) {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setDataSource(data.record);
    }
  }, [data.record]);

  const onSubmit = async (params) => {
    try {
      setLoading(true);
      /* FGF: 3.อนุมัติคอมมิชชั่น LookUp */
      let res = await POST(GET_FGF_LOOKUP_COMMISSION, {id_card: dataSource?.id_card});

      const {success, data} = res;

      if (success) {
        let content = (
          <p>
            {`ชื่อบัญชี: ${data?.result?.receivingAccountName}`}
            <br />
            {`เลขที่บัญชี: ${data?.result?.receivingAccountNumber}`}
            <br />
            {`จำนวนเงิน: ${convertStrToFormat(data?.result?.lease_amount, 'money')}`}
            <br />
            <span style={{color: '#dc3545'}}>{`หลังจากกดยืนยันแล้วจะทำการโอนเงินทันที`}</span>
          </p>
        );

        Modal.confirm({
          title: 'ยืนยันการโอนเงิน',
          icon: <ExclamationCircleOutlined />,
          content: content,
          onOk() {
            onSendApproveCommission(params, data?.result?.txnReferenceNo);
          },
          okText: 'ยืนยัน',
          onCancel() {
            onCancelLookUp(data.result?.txnReferenceNo);
          },
          cancelText: 'ยกเลิก',
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onSendApproveCommission = async (params, ref) => {
    try {
      setLoading(true);
      /* FGF: 3.อนุมัติคอมมิชชั่น / ไม่อนุมัติ */
      const {id_card, remark} = params;

      const obj = {
        id_card: id_card,
        statusApprove: statusReject ? 'N' : 'Y',
        remark: remark,
        userLogin: user.email,
        txnReferenceNo: ref,
      };

      const res = await POST(POST_FGF_CONFIRM_APPROVE_COMMISSION, obj);
      const {success, message} = res;

      if (success) {
        Modal.success({
          title: statusReject ? 'ไม่อนุมัติรายการสำเร็จ' : message,
          afterClose: () => {
            onCloseMain();
            data.reApi({});
          },
        });
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onCancelLookUp = async (ref) => {
    try {
      setLoading(true);
      /* FGF: 3.อนุมัติคอมมิชชั่น Cancel LookUp */
      const obj = {
        txnReferenceNo: ref,
      };

      const res = await POST(CANCEL_LOOKUP, obj);
      const {success, message} = res;

      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi({});
          },
        });
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message || err || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = (params) => {
    if (statusReject) {
      Modal.confirm({
        title: 'ยืนยันไม่อนุมัติ',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onSendApproveCommission(params, '');
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    } else {
      Modal.confirm({
        title: 'ยืนยันอนุมัติจ่ายเงิน',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onSubmit(params);
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    }
  };

  const onCloseMain = () => {
    setStatusReject(false);
    clearErrors();
    setValue('remark', '');
    onClose();
  };

  return (
    <>
      {loading && <Spinners />}
      <Modal title={title || 'modal'} open={visible} width={800} onCancel={onCloseMain} footer={null} maskClosable={false}>
        <div>
          <Row style={{marginBottom: '8px', marginTop: '8px'}}>
            <Col xs={{span: 24}} md={{span: 24}} xl={{span: 24}} lg={{span: 24}}>
              <div>
                <form onSubmit={handleSubmit(onConfirm)}>
                  <RenderForm
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    formList={FGFApproveCommissionVerifyModalForm({data: dataSource})}
                    setValue={setValue}
                    getValues={getValues}
                    renderButton={
                      <>
                        <ContainerButton right>
                          <ButtonTheme useFor="CONFIRM" title="อนุมัติจ่ายเงิน" htmlType="submit" onClick={() => setStatusReject(false)} />
                          <ButtonTheme useFor="REJECT" title="ไม่อนุมัติ" htmlType="submit" onClick={() => setStatusReject(true)} />
                        </ContainerButton>
                      </>
                    }
                  />
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
