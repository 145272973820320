export const EditSocialIdHistoryColumn = () => {
  return [
    {
      title: 'วันที่บันทึก',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '12%',
      render: (item) => {
        return item
      }
    },
    {
      title: 'social id (ก่อน)',
      dataIndex: 'social_id_before',
      key: 'social_id_before',
      width: '35%'
    },
    {
      title: 'social id (หลัง)',
      dataIndex: 'social_id_after',
      key: 'social_id_after',
      width: '35%'
    },
    {
      title: 'บันทึกโดย',
      dataIndex: 'createdName',
      key: 'createdName',
      width: '18%'
    }
  ]
}
