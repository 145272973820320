import React, {useState, useEffect} from 'react';
import {Modal} from 'antd';
import {useForm} from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../buttons';
import {CreateTelephoneForm} from '../../../constants/global/actionform';
import {GET, POST, MANAGE_ACCOUNT, CREATE_TELEPHONE} from '../../../services/index';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import moment from 'moment';
import {auth} from '../../../utils/firebase';
import {useAuthState} from '../../../utils/hook';

export const CreateTelephoneModal = ({title, visible, onClose, data}) => {
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const {user} = useAuthState(auth);
  const [loading, setLoading] = useState(false);

  const onCloseMain = () => {
    clearErrors();
    setValue('tel', '');
    setValue('expireDate', '');
    onClose();
  };

  const onSubmit = async (params) => {

    setLoading(true);
    const {tel, expireDate} = params;
    const obj = {
      userLogin: user.email,
      tel,
      expireDate: moment(expireDate).format('YYYY-MM-DD'),
    };



    try {
      let res = await POST(CREATE_TELEPHONE, obj);
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi({});
          },
        });
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันการเพิ่มเบอร์โทรศัพท์',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการการเพิ่มเบอร์โทรศัพท์ ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  return (
    <Modal title={title || 'modal'} visible={visible} onCancel={onCloseMain} width={480} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={CreateTelephoneForm()}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
