import React, { useEffect } from 'react'
import { Modal } from 'antd'
import { ContainerButton } from '../../../../styles/styledComponentGlobal.js'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { useSendChatcone, useGetChatconeHistory } from '../../../../hook/chatcone/index.js'
import { ButtonTheme, DatatableLog, Spinners } from '../../../../components/index.js'
import { ChatconeFollowContractHistoryColumn } from '../columns/ChatconeFollowContractHistoryColumn.js'
import { APP_URL } from '../../../../env.js'

export const ChatconeFollowContractModal = ({ title, content, visible, onClose, input }) => {
  const { pathname } = useLocation()
  const { registerId: ref_id, chatcone, fullName } = input

  const [getChatconeHistoryFn, getChatconeHistoryData, getChatconeHistoryLoading] = useGetChatconeHistory()
  const [sendChatconeFn, sendChatconeLoading] = useSendChatcone({ ishowModal: true, onCloseMain: onClose })

  useEffect(() => {
    if (ref_id && visible)
      getChatconeHistoryFn({ ref_id: ref_id, type: 'FOLLOW_CONTRACT' })
  }, [ref_id, visible])

  const openConfirm = () => {
    Modal.confirm({
      title: 'ยืนยันการส่งแจ้งเตือน',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการส่งแจ้งเตือนติดตามนัดลูกค้าทำสัญญา?',
      onOk() {
        onSendChatconeFn()
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก'
    })
  }

  // CHATCONE: 3 FOLLOW_CONTRACT
  const onSendChatconeFn = () => {
    const setInput = {
      chatcone: chatcone,
      process_type: 'FOLLOW_CONTRACT',
      register_id: ref_id,
      ref_id: ref_id,
      ref_name: 'user_before_verify',
      route_from: `${APP_URL}${pathname}`,
      inputMessage: {
        full_name: fullName
      }
    }

    sendChatconeFn({ ...setInput })
  }

  return (
    <>
      {getChatconeHistoryLoading || sendChatconeLoading ? <Spinners /> : null}
      <Modal
        title={
          <>
            <ExclamationCircleOutlined style={{ color: '#faad14' }} /> {title || ''}
          </>
        }
        open={visible}
        width={840}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
      >
        <div style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}>{content || ''}</div>
        <ContainerButton center>
          <ButtonTheme useFor="SEND_NOTI" title="ส่งแจ้งเตือนไปยังแชท" onClick={() => openConfirm()} />
        </ContainerButton>

        <DatatableLog
          style={{ marginTop: '20px' }}
          columns={ChatconeFollowContractHistoryColumn()}
          data={getChatconeHistoryData}
          pagination={{ pageSize: 5 }}
        />
      </Modal>
    </>
  )
}
