/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import { Visibility, Pageview, Edit, CheckCircle, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button } from './styled-component';
import ChipStatus from '../../components/chip/ChipStatus';
import { useTransaction } from '../../hook/useTransaction';
import { toastContext } from '../../utils/toastProvider';

const useStyles = makeStyles({
  select: {
    '& > div:focus': {
      background: 'none',
    },
  },
  icon_btn: {
    cursor: 'pointer',
    '&.save': {
      color: '#01D827',
    },
    '&.save:hover': {
      color: '#01B927',
    },
    '&.cancel': {
      color: '#E01E01',
    },
    '&.edit': {
      color: '#999999',
    },
  },
  pagination_btn: {
    '& button:focus': {
      outline: 0,
    },
  },
  input_label: {
    fontSize: '8px',
  },
  icon_column: {
    padding: 0,
  },
});

const createParams = (statusParam, userIdCardParam, pageParam, sizeParam, startDate) => {
  let params = `?size=${sizeParam}&page=${pageParam}`;
  if (userIdCardParam !== null && userIdCardParam !== '') {
    params += `&user_id_card=${userIdCardParam}`;
  }
  if (statusParam !== 'ALL') {
    params += `&status_update_tradesys=${statusParam}`;
  }
  if (startDate) {
    params += `&start_date=${startDate.format("YYYY-MM-DD")}`;
  }
  return params;
};

const TransactionItem = (props) => {
  const { dispatchUpdateInfoAndGet } = useTransaction();
  const {
    id,
    idCard,
    name,
    transactionDate,
    createdDate,
    totalAmount,
    slipUrl,
    canUpdate,
    onClickSlip,
    status,
    statusLabel,
    statusUpdateTradesys,
    statusUpdateTradesysLabel,
    remark,
    onEditTable,
    filterStatus,
    filterUser,
    page,
    size,
    filterStartDate,
    updatedBy
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('DEFAULT');
  const classes = useStyles();
  const [config] = useContext(toastContext);

  const handleEdit = (edit) => {
    setIsEdit(edit);
    onEditTable(edit);
    if (!edit) {
      setUpdateStatus('DEFAULT');
    }
  };

  const showSlip = (url) => {
    onClickSlip(url);
  };

  const changeStatus = (e) => {
    setUpdateStatus(e.target.value);
  };

  const updateTransaction = () => {
    if (statusUpdateTradesys === updateStatus || updateStatus === 'DEFAULT') {
      toast.success('update success', config);
    } else {
      const data = {
        status_update_tradesys: updateStatus,
        updated_by: updatedBy
      };

      const params = createParams(filterStatus, filterUser, page + 1, size, filterStartDate);
      dispatchUpdateInfoAndGet(id, data, params);
      toast.success('update success', config);
      setIsEdit(false);
    }
  };

  return (
    <TableRow key={id}>
      <TableCell>{idCard}</TableCell>
      <TableCell>{name}</TableCell>
      {/* <TableCell align="left">{new Intl.DateTimeFormat('th-TH').format(new Date(transactionDate))}</TableCell>
      <TableCell align="left">{new Intl.DateTimeFormat('th-TH').format(new Date(createdDate))}</TableCell> */}
      <TableCell align="left">{new Intl.NumberFormat('th-TH').format(totalAmount)}</TableCell>
      <TableCell align="left">
        {slipUrl ? (
          <Button type="button" className="slip" onClick={() => showSlip(slipUrl)}>
            <Visibility className={classes.icon_btn} />
          </Button>
        ) : (
          <Visibility style={{ color: '#ccc' }} />
        )}
      </TableCell>
      <TableCell align="center">
          <ChipStatus status={status} />
        </TableCell>
      {!isEdit ? (
        <TableCell align="center">
          <ChipStatus status={statusUpdateTradesys} />
        </TableCell>
      ) : (
        <TableCell align="center" style={{ maxWidth: 200, padding: 0, width: 120 }}>
          <FormControl>
            <InputLabel className={classes.input_label} id={`transaction-status-label-${id}`}>
              {statusUpdateTradesysLabel}
            </InputLabel>
            <Select
              className={classes.select}
              labelId={`transaction-status-label-${id}`}
              id={`transaction-status-${id}`}
              value={updateStatus}
              onChange={changeStatus}>
              <MenuItem value="DEFAULT" disabled>
                เลือก
              </MenuItem>
              <MenuItem value="SUCCESS">ยืนยัน</MenuItem>
              <MenuItem value="REVIEWED">ตรวจสอบแล้ว</MenuItem>
              <MenuItem value="CANCELED">ยกเลิก</MenuItem>
              <MenuItem value="AMOUNT_ERROR">ยอดเงินไม่ถูกต้อง</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      )}

      <TableCell
        size="small"
        align={remark && remark !== '-' ? 'left' : 'center'}
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100 }}>
        {remark || '-'}
      </TableCell>

      <TableCell className={classes.icon_column} style={{ maxWidth: 30 }}>
        <Link style={{ color: "#000" }} to={`/transaction/${id}`}><Pageview className={classes.icon_btn} /></Link>
      </TableCell>
      {!isEdit && (
        <TableCell className={classes.icon_column} colSpan={2}>
          <Button onClick={() => handleEdit(true)}>
            <Edit className={`edit ${classes.icon_btn}`} />
          </Button>
        </TableCell>
      )}

      {isEdit && (
        <TableCell className={classes.icon_column} style={{ maxWidth: 30 }}>
          <Button onClick={() => handleEdit(false)}>
            <Cancel className={`cancel ${classes.icon_btn}`} />
          </Button>
        </TableCell>
      )}

      {isEdit && (
        <TableCell className={classes.icon_column} style={{ maxWidth: 30 }}>
          <Button onClick={() => updateTransaction()}>
            <CheckCircle className={`save ${classes.icon_btn}`} />
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

TransactionItem.propTypes = {
  id: PropTypes.string,
  idCard: PropTypes.string,
  name: PropTypes.string,
  transactionDate: PropTypes.string,
  createdDate: PropTypes.string,
  totalAmount: PropTypes.number,
  slipUrl: PropTypes.string,
  onClickSlip: PropTypes.func,
  status: PropTypes.string,
  statusLabel: PropTypes.string,
  remark: PropTypes.string,
  onEditTable: PropTypes.func,
  filterStatus: PropTypes.string,
  filterUser: PropTypes.string,
  page: PropTypes.number,
  size: PropTypes.number,
  filterStartDate: PropTypes.string,
  canUpdate: PropTypes.bool,
  updatedBy: PropTypes.string,
  statusUpdateTradesys: PropTypes.string,
  statusUpdateTradesysLabel: PropTypes.string
};

TransactionItem.defaultProps = {
  id: '',
  idCard: '',
  name: '',
  transactionDate: '',
  createdDate: '',
  totalAmount: 0,
  slipUrl: null,
  onClickSlip: null,
  status: '',
  statusLabel: '',
  remark: null,
  onEditTable: null,
  filterStatus: '',
  filterUser: '',
  page: 1,
  size: 10,
  filterStartDate: '',
  canUpdate: false,
  updatedBy: '',
  statusUpdateTradesys: '',
  statusUpdateTradesysLabel: ''
};

export default TransactionItem;
