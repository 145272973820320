/* eslint-disable default-case */
import {ButtonTheme} from '../../../components/buttons';
import moment from 'moment/moment';
import {Tag, Tooltip, Button, Popconfirm} from 'antd';
import {color} from '../../../resources/color';
import {EditOutlined} from '@ant-design/icons';
import {TextSmall} from '../../../components/forms/text';
import {Icon} from '../../../resources/icon';
import {statusTagStyle} from '../../../functions/fn';

export const DeviceReturnColumn = (onOpenModal, onConfirmReturn, permissionButtonRedeemCloseContract) => {
  return [
    {
      title: 'วันที่คืนเครื่อง',
      dataIndex: 'hold_date',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text !== '-' ? moment(text).format('DD/MM/YYYY') : text;
      },
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '150px',
      align: 'left',
    },
    {
      title: 'สถานะสัญญา',
      dataIndex: 'contract_status',
      width: '150px',
      align: 'left',
      render: (text, _record) => {
        return statusTagStyle(text, 'contractStatus');
      },
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'name',
      width: '200px',
      align: 'left',
    },
    {
      title: 'เบอรโทรศัพท์',
      dataIndex: 'tel',
      width: '150px',
      align: 'left',
    },
    {
      title: 'ชื่อผู้ติดตาม',
      dataIndex: 'tracker_name',
      width: '200px',
      align: 'left',
    },

    {
      title: 'สถานะเครื่อง',
      dataIndex: 'status',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        let colorStyled = '';
        switch (text) {
          case 'WAIT_REDEEM':
            colorStyled = 'processing';
            break;
          case 'RETURN_STORE':
            colorStyled = 'warning';
            break;
          case 'RETURN_CUS':
            colorStyled = 'default';
            break;
          case 'OVERDUE_REDEEM':
            colorStyled = 'error';
            break;
          case 'RETURN_STORE_SUCCESS':
            colorStyled = 'success';
            break;
        }
        return <Tag color={colorStyled}>{record?.status_th}</Tag>;
      },
    },
    {
      title: 'วันที่ไถ่ถอน',
      dataIndex: 'return_cus_date',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text !== '-' ? moment(text).format('DD/MM/YYYY') : text;
      },
    },
    {
      title: 'ผู้กดรับ',
      dataIndex: 'admin_check_phone',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text || '-';
      },
    },
    {
      title: 'วันที่กดรับ',
      dataIndex: 'admin_check_phone_date',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text !== '-' ? moment(text).format('DD/MM/YYYY') : text;
      },
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      width: '300px',
      align: 'right',
      render: (text, record) => {
        return (
          <TextSmall
            cursor={'pointer'}
            onClick={() => onOpenModal(record, 'REMARK')}
            icon={<EditOutlined style={{borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px'}} />}
            text={text || ''}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: '150px',
      align: 'center',
      fixed: 'right',
      render: (_text, record) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {['RETURN_STORE'].includes(record?.status) && (
              <Tooltip placement="topLeft" title={'ตรวจสอบรับเครื่อง'} arrowPointAtCenter>
                <Button
                  style={{backgroundColor: color.edit, color: color.white}}
                  className="ant-btn-customize"
                  icon={Icon.confirm()}
                  onClick={() => onOpenModal(record, 'RECHECK')}
                />
              </Tooltip>
            )}
            &nbsp;
            {['WAIT_REDEEM', 'RETURN_STORE'].includes(record?.status) && (
              <>
                <Tooltip placement="topLeft" title={'เลื่อนระยะเวลาไถ่ถอน'} arrowPointAtCenter>
                  <Button
                    style={{backgroundColor: color.clear, color: color.white}}
                    className="ant-btn-customize"
                    icon={Icon.clock()}
                    onClick={() => onOpenModal(record, 'REDEEM')}
                  />
                </Tooltip>{' '}
                &nbsp;
                <Tooltip placement="topLeft" title={'ยืนยันการไถ่ถอน'} arrowPointAtCenter>
                  <Button
                    style={{backgroundColor: color.on_process, color: color.white}}
                    className="ant-btn-customize"
                    icon={Icon.mobile()}
                    onClick={() => onConfirmReturn(record)}
                  />
                </Tooltip>
              </>
            )}
            {['OVERDUE_REDEEM'].includes(record?.status) && permissionButtonRedeemCloseContract && (
              <Tooltip placement="topLeft" title={'ชำระสัญญาสินค้าคืนเครื่อง'} arrowPointAtCenter>
                <Button
                  style={{backgroundColor: color.start, color: color.white, width: 180, paddingInline: '5px'}}
                  className="ant-btn-customize"
                  onClick={() => window.open(`/payment?contractNo=${record.contract_no}&paid_type=REDEEM_CLOSED_CONTRACT`, '_blank')}>
                  ชำระสัญญาสินค้าคืนเครื่อง
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];
};
