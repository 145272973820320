import React from 'react';
import {compareRender} from '../../functions/fn';
import {Controller} from 'react-hook-form';
import {Tree, Row} from 'antd';

export const TreeGroupTheme = React.memo(({item,control}) => {
  const {data, defaultValue,onSelectCustom,name,rules} = item;
  return (
    <>
      <Row gutter={[6, 6]} justify="right" >
      <Controller
          name={name}
          control={control}
          rules={rules}
          // value={item.value}
          defaultValue={defaultValue}
          render={({field: {value}}) => (
                    <Tree
                        checkable
                        autoExpandParent={true}
                        onCheck={(e)=>onSelectCustom(e,name)}
                        defaultCheckedKeys={defaultValue}
                        defaultSelectedKeys={defaultValue}
                        // expandedKeys={defaultValue}
                        // selectedKeys={defaultValue}
                        checkedKeys={value}
                        treeData={data}
                    />
               
          )}
          />
      </Row>
    </>
  );
},(prev, next) =>
compareRender(prev, next) &&
JSON.parse(JSON.stringify(prev.item)) ===
  JSON.parse(JSON.stringify(next.item)) &&
  compareRender(prev, next, 'item.defaultValue')
);