import {useState} from 'react';
import axios from 'axios';
import {Modal} from 'antd';
import {API_CHATCONE_SERVICE} from '../../env.js';

export const useSaveSocialId = ({ishowModal, onCloseMain}) => {
  const [loading, setLoading] = useState(false);

  const saveSocialId = async (input) => {
    try {
      if (input?.ref_id) {
        setLoading(true);

        const response = await axios.post(`${API_CHATCONE_SERVICE}/admin/save-social-id`, input, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            'content-type': 'application/json',
          },
        });

        if (response.status === 200 && ishowModal && onCloseMain) {
          Modal.success({
            title: 'บันทึกข้อมูลสำเร็จ',
            afterClose: () => {
              onCloseMain();
            },
          });

          return response;
        }
      }
    } catch (err) {
      ishowModal &&
        Modal.error({
          title: 'บันทึกข้อมูลไม่สำเร็จ',
        });
    } finally {
      setLoading(false);
    }
  };

  return [saveSocialId, loading];
};
