/* eslint-disable no-param-reassign */
/* eslint-disable import/no-useless-path-segments */
import { combineReducers } from 'redux';
import { checkEmailReducer, authReducer } from '../reducer/auth';
import { transactionReducer, transactionInfoReducer } from '../reducer/transaction';
import { productReducer, productInfoReducer } from '../reducer/product';
import { importDataReducer } from '../reducer/import';
import { branchInfoReducer, branchSpeciifcInfoReducer } from '../reducer/branch';
import {
  contractListReducer,
  periodListReducer,
  exportReducer,
  contractListTradesysReducer,
  contractInfoTradesysReducer,
  contractUpdateTradesysReducer,
  exportTerminateReducer,
  exportReciveReducer
} from '../reducer/contract';
import { payManualReducer } from '../reducer/payment';
import { customersReducer, customerInfoReducer } from '../reducer/customer';
import { statementInfoReducer } from '../reducer/statement';
import {
  receiptPaymentUpdateFilterReducer,
  receiptPaymentGetSummary,
  receiptPaymentPay,
  receiptPaymentPaidParamsReducer,
  receiptPaymentCheckRedeemInfoReducer,
  receiptPaymentUpdateRemarkDeviceReturnReducer,
  receiptPaymentGetCouponDetailReducer,
} from '../receipt-payment/reducer';

const appReducer = combineReducers({
  email: checkEmailReducer,
  authenticate: authReducer,
  transaction: transactionReducer,
  transaction_info: transactionInfoReducer,
  product: productReducer,
  product_info: productInfoReducer,
  import_data: importDataReducer,
  contract_list: contractListReducer,
  period_list: periodListReducer,
  payment_pay_manual: payManualReducer,
  customer_list: customersReducer,
  customer_info: customerInfoReducer,
  contract_export: exportReducer,
  contract_recive_export: exportReciveReducer,
  contract_export_terminated: exportTerminateReducer,
  contract_list_tradesys: contractListTradesysReducer,
  contract_info_tradesys: contractInfoTradesysReducer,
  contract_update_tradesys: contractUpdateTradesysReducer,
  statement_info: statementInfoReducer,
  branch_info: branchInfoReducer,
  branch_specific_info: branchSpeciifcInfoReducer,
  receiptPayment: combineReducers({
    paid_params: receiptPaymentPaidParamsReducer,
    filter: receiptPaymentUpdateFilterReducer,
    summary: receiptPaymentGetSummary,
    pay_info: receiptPaymentPay,
    check_redeem: receiptPaymentCheckRedeemInfoReducer,
    coupon_detail: receiptPaymentGetCouponDetailReducer,
    update_remark: receiptPaymentUpdateRemarkDeviceReturnReducer,
  }),
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCES') state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
