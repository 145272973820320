import React, { useState, useEffect } from 'react';
import { Modal, Tag, Row, Col, Image } from 'antd';
import { useForm } from 'react-hook-form';
import { color } from '../../../resources/color';
import noImage from '../../../assets/images/no_image.jpeg'
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { POST, CREATE_SECOND_HAND_PRICE, GET_PRODUCT_ALL, GET } from '../../../services/index';
import { saveAs } from 'file-saver';
import RenderForm from '../../../constants/global/RenderForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AddNewProductAndPriceSecondHandForm } from '../form';

export const AddPriceSecondHandModal = ({ title, visible, onClose, data }) => {
    const {
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        control,
        setError,
        clearErrors,
    } = useForm();

    const [loading, setLoading] = useState(false)
    const [optionProductModelAll, setOptionProductModelAll] = useState([])
    const [optionProductStorageAll, setOptionProductStorageAll] = useState([])
    const [optionProductColorAll, setOptionProductColorAll] = useState([])

    useEffect(() => {
        if (JSON.stringify(data && data.option) !== "[]" && data.option) {
            setOptionProductModelAll(data.option.optionProductModelAll)
            setOptionProductStorageAll(data.option.optionProductStorageAll)
            setOptionProductColorAll(data.option.optionProductColorAll)
        }
    }, [data.option])

    const onCloseMain = () => {
        clearErrors()
        setValue('modal', '')
        setValue('storage', '')
        setValue('color', '')
        setValue('price_condition_1', '')
        setValue('price_condition_2', '')
        setValue('price_condition_3', '')
        setValue('price_condition_4', '')
        onClose();
    };

    const onConfirm = (params) => {
        Modal.confirm({
            title: 'ยืนยันบันทึกข้อมูล',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูล ใช่หรือไม่',
            onOk() {
                onSubmit(params);
            },
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
        });
    };

    const onSubmit = async (params) => {
        setLoading(true);

        const { model, storage, color, price_condition_1, price_condition_2, price_condition_3, price_condition_4 } = params;
        const obj = {
            model: model,
            storage: storage,
            color: color,
            product_condition_1: parseInt(price_condition_1) || 0,
            product_condition_2: parseInt(price_condition_2) || 0,
            product_condition_3: parseInt(price_condition_3) || 0,
            product_condition_4: parseInt(price_condition_4) || 0,
        };

        try {
            let res = await POST(CREATE_SECOND_HAND_PRICE, obj);
            const { success, message } = res;
            if (success) {
                Modal.success({
                    title: message,
                    afterClose: () => {
                        data.reApi({});
                        data.reApi2();
                        onCloseMain();
                    },
                });
            }
        } catch (err) {
            const { message } = err;
            Modal.error({
                title: message,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <Modal title={title || 'modal'} open={visible} width={600} onCancel={onCloseMain} footer={null} maskClosable={false}>
                <form onSubmit={handleSubmit(onConfirm)}>
                    <RenderForm
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        formList={AddNewProductAndPriceSecondHandForm({ options: { optionProductModelAll, optionProductStorageAll, optionProductColorAll } })}
                        renderButton={
                            <>
                                <ContainerButton right>
                                    <ButtonTheme useFor="SUBMIT" title="ยืนยัน" htmlType="submit" loading={loading} />
                                    <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
                                </ContainerButton>
                            </>
                        }
                    />
                </form>
            </Modal>
        </>
    );
};