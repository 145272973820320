import React, { useState } from 'react';
import { Cancel } from '@material-ui/icons';
import { Div, Button, TextError, FileItem, InputFileNoText, TextSuccess } from './styled-component';
import { Modal as ModalAntd, Spin, Typography } from 'antd'
import axios from 'axios';
import { API_URL } from '../../../env.js'
import { useForm } from 'react-hook-form';

const { Title, Text } = Typography;

const getFileExtension = (filename) => {
  return filename.split('.').pop();
};

const ImportExcelFileTrackingNo = ({ title, visible, onClose }) => {
  const [files, setFile] = useState(null);
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [unsuccessList, setUnsuccessList] = useState([])
  const [messageSuccess, setMessageSuccess] = useState(null)

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    getValues,
    control,
  } = useForm();

  const onInputFileChange = (event) => {
    Object.keys(event.target.files).forEach((file) => {
      const extension = getFileExtension(event.target.files[file].name);
      let obj = { file: event.target.files[file], name: `${event.target.files[file].name}`, key: `${event.target.files[file].name}` }
      setFile(obj)
    });
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  }

  const importToServer = async () => {
    const formData = new FormData();
    formData.append("files", files.file);
    formData.append("name", files.name);
    return await axios.post(`${API_URL}/admin/tracking-task/uploadTrackingFromExcel`, formData, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "multipart/form-data"
      },
    }).catch(function (error) {
      if (error?.response) {
        // The request was made and the server responded with a status code
        if (error?.response?.data?.success === false) {
          // setError(error.response.data.message)
          return error?.response
        }
      }
    });
  }

  const importExcelFile = async () => {
    setLoading(true)
    if (files) {
      const res = await importToServer()
      if (res?.status === 200) {
        const { success, message, result } = res.data
        if (success) {
          setMessageSuccess(message)
          setUnsuccessList(result?.productNoAddList || [])
        }
      } else {
        setError(res?.data?.message || '')
      }
      setLoading(false)
    } else {
      ModalAntd.error({
        title: 'กรุณาเลือก File' || ''
      })
      setLoading(false)
    }

  };

  const removeExcelFile = () => {
    setLoading(false)
    setFile(null);
    setError(null)
    setMessageSuccess(null)
    setUnsuccessList([])
  };

  const onCloseMain = () => {
    setLoading(false)
    setFile(null);
    setError(null)
    setMessageSuccess(null)
    setUnsuccessList([])
    onClose()
  }

  return (
    <ModalAntd
      title={<Title level={3}>{`Import Excel เลขพัสดุ`}</Title>}
      open={visible}
      onCancel={onCloseMain}
      width={500}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}>
      <Div>
        <div>
          <div>
            {/* {files.map((file, index) => ( */}
            <FileItem>
              <Spin spinning={loading} />
              <p>
                {files?.name}
              </p>
              {files && <Button type="button" onClick={() => removeExcelFile()}>
                <Cancel />
              </Button>}
            </FileItem>
            {/* ))} */}
            <InputFileNoText>
              Browse file
              <input type="file" onChange={onInputFileChange} onClick={handleClick} />
            </InputFileNoText>
            <div style={{ marginTop: '1rem', marginBottom: '10px' }}>
              <Button className="submit" onClick={() => importExcelFile()}>
                Upload file
              </Button>
            </div>
          </div>
          {messageSuccess && <TextSuccess>{messageSuccess}</TextSuccess>}
          {(messageSuccess && unsuccessList) && <Text strong>{`เลขพัสดุนี้ไม่สามารถ Import ได้`}<br /></Text>}
          {unsuccessList && unsuccessList.map((el) => {
            return <Text key={el}> - {`${el}\n`}<br /></Text>
          })}
          {error && <TextError>{error}</TextError>}
        </div>

      </Div>
    </ModalAntd >
  );
};

export default ImportExcelFileTrackingNo;
