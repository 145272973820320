import {Select} from 'formik-antd';
import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getAdminMasterData} from '../../apiv2';
import _ from 'lodash';

const MDMSiteOption = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await getAdminMasterData('MDM_SITE');

      if (res) {
        let data = _.map(res, (dt) => {
          return {
            label: dt.name,
            value: dt.code,
            source: dt,
          };
        });
        setData(data);
      }
    })();
  }, []);

  const filterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return <Select {...props} showSearch optionFilterProp="value" options={data} filterOption={filterOption}></Select>;
};

MDMSiteOption.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MDMSiteOption;
