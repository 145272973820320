import { useDispatch, useSelector } from 'react-redux';
import * as todoActions from '../redux/actions/import';

export const useImport = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.import_data.loading);
    const error = useSelector((state) => state.import_data.error);
    const response = useSelector((state) => state.import_data.response);

    async function dispatchImport(data) {
        dispatch(todoActions.importData(data));
    }

    async function dispatchImportProduct() {
        dispatch(todoActions.importProduct());
    }

    return {
        loading,
        error,
        response,
        dispatchImport,
        dispatchImportProduct
    };
};