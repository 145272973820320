import * as TYPES from '../types/payment';

const paymentState = {
    loading: false,
    error: null,
    data: null
};

export const payManualReducer = (state = paymentState, { type, payload }) => {
    switch (type) {
        case TYPES.PAY_MANUAL_REQ:
            return { ...state, loading: true };
        case TYPES.PAY_MANUAL_SUCCESS:
            return { ...state, loading: false, error: null, data: payload.data.data };
        case TYPES.PAY_MANUAL_FAIL:
            return { ...state, loading: false, error: payload, data: null };
        default:
            return state;
    }
}
