const companyNameLabel = (prop) => {
  return (
    <>
      <div>
        <p className="text-remark-gray">{"Ex. บริษัท/หน่วยงาน/ร้านที่ทำงาน/กิจการ"}</p>
      </div>
    </>
  );
};

export default companyNameLabel;
