export const optionCondition = [
    {
        value:"CHANGE_PASSWORD",
        label:"ผิดเงื่อนไขเปลี่ยนรหัส"
    },
    {
        value:"SALE_PHONE",
        label:"ผิดเงื่อนไขนำเครื่องไปขาย"
    },
    {
        value:"OTHER",
        label:"ผิดเงื่อนไขอื่นๆ"
    }
]

export const optionExportTerminate = [
    {
        value:"YES",
        label:"ออกหนังสือยกเลิกสัญญาแล้ว"
    },
    {
        value:"NO",
        label:"ยังไม่ได้ออกหนังสือยกเลิกสัญญา"
    },
]

export const optionSendTerminate = [
    {
        value:"YES",
        label:"ส่งหนังสือยกเลิกสัญญาแล้ว"
    },
    {
        value:"NO",
        label:"ยังไม่ได้ส่งหนังสือยกเลิกสัญญา"
    },
]