import {useEffect, useState} from 'react';
import {Modal} from 'antd';
import {GET, GET_BRANCH, GET_TRACKERS} from '../../../services';

export const useGetOptions = () => {
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [optionsTracker, setOptionsTracker] = useState([]);
  useEffect(() => {
    getFetchOptionsBranch();
    onFetchOptionsTracker();
  }, []);

  const getFetchOptionsBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const {success, result} = res;
      if (success) {
        const option = result.branch.map((el) => {
          return {
            label: el.label,
            value: el.value,
          };
        });
        setOptionsBranch(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  };

  const onFetchOptionsTracker = async () => {
    try {
      const res = await GET(GET_TRACKERS);
      const {result, success} = res;
      if (success) {
        const option = result.map((item) => ({
          label: item.name,
          value: item.trackerID,
        }));
        setOptionsTracker(option);
      }
    } catch (error) {
      const {message} = error;
      Modal.error({title: 'เกิดข้อผิดพลาด', content: message});
    }
  };

  return {
    optionsBranch,
    optionsTracker,
  };
};
