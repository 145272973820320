import { useDispatch, useSelector } from 'react-redux';
import * as todoActions from '../redux/actions/customer';

export const useCustomer = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.customer_list.loading);
    const error = useSelector((state) => state.customer_list.error);
    const customers = useSelector((state) => state.customer_list.customers);
    const defaultPage = useSelector((state) => state.customer_list.page);
    const defaultSize = useSelector((state) => state.customer_list.size);
    const totalItem = useSelector((state) => state.customer_list.totalItem);
    const totalPage = useSelector((state) => state.customer_list.totalPage);

    const infoLoading = useSelector((state) => state.customer_info.loading);
    const customerInfo = useSelector((state) => state.customer_info.customerInfo);
    const infoError = useSelector((state) => state.customer_info.error);

    async function dispatchGetCustomerList(params) {
        dispatch(todoActions.getCustomerList(params));
    }

    async function dispatchGetCustomerInfo(id) {
        dispatch(todoActions.getCustomerInfo(id));
    }

    return {
        loading,
        error,
        defaultPage,
        defaultSize,
        totalItem,
        totalPage,
        customers,
        infoLoading,
        infoError,
        customerInfo,
        dispatchGetCustomerList,
        dispatchGetCustomerInfo
    };
};