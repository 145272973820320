/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
    Grid,
    Table,
    TableBody,
    TableRow,
    TablePagination,
    TableFooter,
    TableContainer,
    TableCell,
    TableHead,
} from '@material-ui/core';
import { ArrowBack, Pageview } from '@material-ui/icons';
import Wrapper from '../../views/wrapper/Wrapper';
import { useCustomer } from '../../hook/useCustomer';
import { Div, Background } from './styled-component';

const CustomerInfo = () => {
    const { infoError, infoLoading, customerInfo, dispatchGetCustomerInfo } = useCustomer();
    const { id } = useParams();

    useEffect(() => {
        dispatchGetCustomerInfo(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Div>
                <Grid container item>
                    <Background>
                        <Link to="/customer"><ArrowBack /> Back</Link>
                        {
                            customerInfo && (
                                <div style={{ marginTop: '1rem' }}>
                                    <div>
                                        <h4 style={{ marginBottom: '30px' }}>{customerInfo.info?.general?.fullname}</h4>
                                        <p>เบอร์โทร: {customerInfo.info?.tel ? customerInfo.info.tel : "-"}</p>
                                        <p>เลขบัตรประชาชน: {customerInfo.info?.id_card ? customerInfo.info.id_card : "-"}</p>
                                        <p>ที่อยู่: {customerInfo.info?.address?.full ? customerInfo.info?.address?.full : "-"}</p>
                                        <p>OTP: {customerInfo.otp?.otp_number ? customerInfo.otp?.otp_number : "-"}</p>
                                    </div>
                                </div>
                            )
                        }

                        <div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>เลขสัญญา</TableCell>
                                            <TableCell>จำนวนเงินกู้</TableCell>
                                            <TableCell>เงินดาวน์</TableCell>
                                            <TableCell>จำนวนงวด</TableCell>
                                            <TableCell>วันที่เริ่มผ่อน</TableCell>
                                            <TableCell colSpan={2}>หมายเหตุ</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            customerInfo === null || customerInfo?.contracts.length === 0 ? (
                                                <TableRow>
                                                    <TableCell align="center" colSpan={6}>
                                                        ไม่พบข้อมูล
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                customerInfo.contracts.map((item, index) => (
                                                    <TableRow key={item.contract_no}>
                                                        <TableCell>
                                                            {item.contract_no}
                                                        </TableCell>
                                                        <TableCell>
                                                            {new Intl.NumberFormat('th-TH').format(item.lease_amount)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.down_amount ? new Intl.NumberFormat('th-TH').format(item.down_amount) : "-"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.periods}
                                                        </TableCell>
                                                        <TableCell>
                                                            {new Intl.DateTimeFormat('th-TH').format(new Date(item.first_date))}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.remark}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link style={{ color: "#000" }} to={`/contract/${item.contract_no}`}><Pageview /></Link>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Background>
                </Grid>
            </Div>
        </Wrapper>
    );
};
export default CustomerInfo;