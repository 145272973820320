// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin,Row,Col,Input } from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {SearchLayout} from '../../../components/pageLayout/SearchLayout';
import {TableLayout} from '../../../components/pageLayout/TableLayout'
import {PickNoSearchForm} from '../../../constants/global/SearchForm'
import {LineProductsNonSerialPickColumn} from '../../../constants/global/columnTableForm'
import { CreatePickModal } from '../../../components/modal/Stock/CreatePickModal';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {POST,GET,GET_JOURNAL_ITEM_NON_SERIAL,GET_ITEMS_NON_SERIAL,UPDATE_JOURNAL_ITEMS_NON_SERIAL,CONFIRM_DOC_NON_SERIAL,GET_OPTION_BRANCH} from '../../../services';
import moment from 'moment';
import {useCurrentState} from '../../../utils/hook';
import {auth} from '../../../utils/firebase';
import {useHistory} from 'react-router-dom';
import {EditableCellStockNonSerial} from '../form/editCellPickStockNonSerial'
import { useBranch } from '../../../hook/useBranch';
import { modalClasses } from '@mui/material/Modal/Modal';


const CreatePickNonSerial = () => {
  const { branchList } = useBranch();

    const [loading,setLoading] = useState(false)
    const [dataSearch,setDataSearch] = useState([])
    const [dataSource,setDataSource] = useState([])
    const [disabledBtnConfirm,setDisabledBtnConfirm] = useState(false)
    const [visibleCreatePickModal,setVisibleCreatePickModal] =useState(false)
    const [remarkDefault,setRemark] = useState("")
    const [userCreate,setUserCreate] = useState("")
    const [userUpdate,setUserUpdate] = useState("")
    const [docNo,setDocNo] = useState("")
    const {user: user2} = useCurrentState(auth);
    const history = useHistory();
    const [optionItems,setOptionItems] = useState([])


  useEffect(() => {
    getItemsNonSerial()
  }, []);


  const getItemsNonSerial = async()=>{
    setLoading(true)
    try {
      const res = await GET(GET_ITEMS_NON_SERIAL)
     const {success,result} = res
      if(success){
        setOptionItems(result.items)
      } 
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }finally{
      setLoading(false)
    }
  }
  
  const getJournalItemsNonSerial = async(docNo)=>{
    setLoading(true)
    try {
      const res = await GET(`${GET_JOURNAL_ITEM_NON_SERIAL}?documentNo=${docNo}`)
      const {success,result} = res
      if(success){
        const {branch_from_name,branch_to_name,document_no,remark,user_create,user_update,create_date,update_date} = result.journalItems
        const dataSet = [
          {label:"Pick No.", value: document_no},
          {label:"สาขาต้นทาง", value: branch_from_name},
          {label:"สาขาปลายทาง", value: branch_to_name},
        ]

        const data = result.journalItems.journal_line.map((el,i)=>{return {
          key:i+1,
          // _id:el._id,
          line_no:+el.line_no,
          item_code:el.item_code,
          item_name:el.item_name,
          qty:el.qty,
          }})

        setRemark(remark)
        setDocNo(document_no)
        setUserCreate(`${user_create.name} ${moment(create_date).format("YYYY-MM-DD HH:mm")}`)
        setUserUpdate(`${user_update.name || ""} ${update_date ? moment(update_date).format("YYYY-MM-DD HH:mm"):""}`)
        setDataSearch(dataSet)
        setDataSource(data)
        setDisabledBtnConfirm(data.length === 0)
       
      } 
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }finally{
      setLoading(false)
    }
  }


  const onChange = (value,ref,key)=>{
    setDisabledBtnConfirm(true)
   
    if(ref === "remark"){
      setRemark(value)
    }else{

      let items = []
      dataSource.forEach((el)=>{
        items.push(el.item_code)
        if(el.key === key){
          if(ref ==="item_code"){
           
            if(items.includes(value)){
              Modal.error({
                title:"เลือกสินค้าซ้ำ กรุณาเลือกใหม่"
              })
            }else{
              items.push(value)
              el.item_code = value
              const dataFilter = optionItems.filter((el)=>el.value === value)
              el.item_name = dataFilter.length > 0 ? dataFilter[0].label : ''
            }
          }else if(ref ==="qty"){
            if( value < 0){
              Modal.error({
                title:"จำนวนต้องไม่น้อยกว่า 0"
              })
              el[ref] = 0
            }else{
              el[ref] = value
            }
            
          }
          el.errors = ''
        }
      })

      setDataSource([...dataSource])
    }
  }

  const onRemove = (key)=>{
    const data = dataSource.filter((i)=>i.key !== key)
    setDataSource(data)

  }

const validateDataCell = ()=>{
  let item_code = []
  let qty = []
  dataSource.forEach((el)=>{
      if(!el.item_code){
        item_code.push({key:el.key})
      }else if(!el.qty){
        qty.push({key:el.key})
      }
  })

  if(item_code.length > 0 || qty.length > 0 ){
    Modal.error({
      title:"กรุณากรอกข้อมูลให้ครบถ้วน"
    })
  }else if( dataSource.length === 0){
    Modal.error({
      title:"กรุณาเพิ่มรายการสินค้า"
    })
  }else{
    updateJournalItemsNonSerial()
  }
}


const updateJournalItemsNonSerial = async()=>{

  setLoading(true)
  const data = dataSource.map((el)=>{
    const {line_no,item_code,item_name,qty} = el
    return {line_no:line_no.toString(),item_code,item_name,qty}
  })
  const dataSend = {
    documentNo:docNo,
    remark:remarkDefault,
    items:data
  }


  try {
    const res = await POST(UPDATE_JOURNAL_ITEMS_NON_SERIAL,dataSend)
    const {success,message} = res
    if(success){
      Modal.success({
        title: message,
      });
      dataSource.forEach((el)=>{
        el.errors = ''
      })
      setDisabledBtnConfirm(false)
    }
  } catch (err) {
    const {message, result} = err;
          Modal.error({ title: message});
          result.forEach((vl,i)=>{
            if(dataSource[i].item_code === vl.item_code){
              dataSource[i].errors = vl.error
            }
            
          })
  }finally{
    setLoading(false)
  }
}


const onConfirm = async()=>{
  setLoading(true)
    try {
      const res = await POST(CONFIRM_DOC_NON_SERIAL,{ documentNo: docNo})
      const {success,result} = res
      if(success){
        history.push({
          pathname: '/print-pick',
          state: {data: { ...result.export,page: 'pickNonSerial' }},
        });
      }
      
    } catch (err) {
      const {message, result} = err;
          Modal.error({ title: message});
          result.forEach((vl,i)=>{
            if(dataSource[i].item_code === vl.item_code){
              dataSource[i].errors = vl.error
            }
            
          })
    }finally{
      setLoading(false)
    }
 
}

const handleAdd = () => { 
  const newData = {
    key: dataSource.length +1,
    line_no:dataSource.length > 0 ? dataSource[dataSource.length-1].line_no+1:101,
    item_code: null,
    qty: 0,
    item_name:'',
    errors:''
  };
  setDataSource([...dataSource, newData]);
};

const components = {
  body: {
    cell: EditableCellStockNonSerial,
  },
};

const detailUser = [{ label:"ผู้สร้างเอกสาร",value:userCreate},{label:"แก้ไขล่าสุดโดย",value:userUpdate}]
  
  return (
    <Wrapper>
      <Card>
        <SearchLayout 
          props={{
            title: 'สร้างใบจัดสินค้า',
            icon: <PlusOutlined />,
            spanSearch: 24,
            formSearch: PickNoSearchForm(),
            onSearch: (data)=>getJournalItemsNonSerial(data.document_no),
            extraContent:(
            <ButtonTheme useFor="CREATE" title={'สร้างใบจัดสินค้า'} onClick={()=>setVisibleCreatePickModal(true)}/>
            )
          }}
        />
        <Spin tip="Loading..." spinning={loading}>
        {dataSearch.length > 0?
            <div style={{marginTop:"20px",padding:"12px"}}>
            <Row gutter={[16, 16]} style={{marginBottom:"12px",fontSize:"16px"}} justify="left">
              {
                dataSearch.map((el,k)=>{
                  return <Col xs={{span: 24}} md={{span: 24}} xl={{span:k === 3?8:4}} lg={{span:k === 3?8:4}} key={k}><span style={{fontWeight:"800"}}>{`${el.label} : `}</span><br/>{`${el.value}`}</Col>
                })
              }
            </Row>
            <Row gutter={[16, 16]} justify="left">
              <Col xs={{span: 24}} md={{span: 24}} xl={{span: 24}} lg={{span: 24}}>
              <Input.TextArea maxLength={500} rows={4} placeholder="ระบุข้อความไม่เกิน 500 ตัวอักษร" value={remarkDefault} onChange={(e)=>onChange(e.target.value,"remark",'')}/>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="left" style={{marginTop:"12px"}}>
              {
                detailUser.map((val,i)=>{
                  return (
                    <Col xs={{span: 24}} md={{span: 24}} xl={{span: 8}} lg={{span: 8}} key={i}>
                    <span style={{fontWeight:"800"}}>{`${val.label} : `}</span>{`${val.value}`}
                    </Col>
                    )
                })
              }
            </Row>
              <TableLayout 
                props={{
                    title: 'รายการสินค้า',
                    columns: LineProductsNonSerialPickColumn({onChange,onRemove,options:{optionItems}}),
                    dataSource: dataSource,
                    components:components,
                    extraContent:(
                      <>
                        <ContainerButton left>
                        <ButtonTheme useFor="CREATE" title={'เพิ่มสินค้า'} onClick={handleAdd}/>
                        </ContainerButton>
                      </>
                    ),
                    footerContent: (
                      <ContainerButton center>
                        <ButtonTheme useFor="SUBMIT" title={'บันทึก'}  onClick={()=>validateDataCell()} loading={loading} bgColor='#0052ba'/>
                        <ButtonTheme useFor="CONFIRM" title={'คอนเฟิร์ม'}  onClick={()=>onConfirm()} disabled={disabledBtnConfirm}/>
                      </ContainerButton>
                    )
                }}
              />
            </div>:null}
        </Spin>
      </Card>
    <CreatePickModal
        visible={visibleCreatePickModal}
        title={''}
        onClose={() => setVisibleCreatePickModal(false)}
        options={{branch:branchList}}
        data={{branchDefault:user2 && user2.branch}}
        type={'non_serial'}
       onCallApi={getJournalItemsNonSerial}
      />
    </Wrapper>
  );
};

export default CreatePickNonSerial
