// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Spin, Row, Col, Input } from 'antd';
import { SwapOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { SearchLayout } from '../../components/pageLayout/SearchLayout';
import { TableLayout } from '../../components/pageLayout/TableLayout'
import { PickNoRecieveSearchForm } from '../../constants/global/SearchForm'
import RenderForm from '../../constants/global/RenderForm'
import { LineProductsRecieveColumn } from '../../constants/global/columnTableForm'
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../components/buttons';
import { POST, GET, PUT, GET_JOURNAL_HEADER, GET_ITEM_BY_SN, RECHECK_ITEMS, CONFIRM_RECIEVE_ITEMS, } from '../../services';
import { FORM_SN } from '../../constants/global/actionform'
import moment from 'moment';
import { useAuthState, useCurrentState } from '../../utils/hook';
import { auth } from '../../utils/firebase';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';





const RecieveItems = () => {
  let timeout = null
  const [loading, setLoading] = useState(false)

  const [itemsTotal, setItemTotal] = useState(0)
  const [dataSearch, setDataSearch] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [remarkDefault, setRemark] = useState("")
  const [userCreate, setUserCreate] = useState("")
  const [userUpdate, setUserUpdate] = useState("")
  const [docNo, setDocNo] = useState("")
  const { user } = useAuthState(auth);
  const { user: user2 } = useCurrentState(auth);
  const location = useLocation();
  const { setValue, getValues, control, formState: { errors }, clearErrors, } = useForm();


  // useEffect(() => {
  //   if (location.state) {
  //     const {data} = location.state;
  //     getJournalItemsHeader(data.document_no)
  //   }
  // }, []);

  const getJournalItemsHeader = async (data) => {

    setLoading(true)
    setDataSource([])

    try {
      const res = await GET(GET_JOURNAL_HEADER(data.document_no))
      const { success, result } = res
      if (success) {
        const { branch_from_name, branch_to_name, document_date_start, document_date_end, document_no, remark, user_create, user_update, create_date, update_date } = result.header
        const dataSetSearch = [
          { label: "Pick No.", value: document_no },
          { label: "สาขาต้นทาง", value: branch_from_name },
          { label: "สาขาปลายทาง", value: branch_to_name },
          { label: "วันที่ลูกค้าจอง", value: `${moment(document_date_start).format("YYYY-MM-DD HH:mm")} - ${moment(document_date_end).format("YYYY-MM-DD HH:mm")}` },
        ]
        setRemark(remark)
        setDocNo(document_no)
        setUserCreate(`${user_create.name} ${moment(create_date).format("YYYY-MM-DD HH:mm")}`)
        setUserUpdate(`${user_update.name || ""} ${update_date ? moment(update_date).format("YYYY-MM-DD HH:mm") : ""}`)
        setDataSearch(dataSetSearch)

      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false)
    }
  }

  const onChange = (value, ref) => {

    if (ref === "sn") {
      setValue(ref, value)
      getItemBySn()
    } else if (ref === "remark") {
      setRemark(value)
    }
    else if (ref === "document_no") {
      setDocNo(value)
      if (value.split("").length === 9) {
        getJournalItemsHeader(value)
      }

    }
  }

  const getItemBySn = async () => {
    const sn = getValues("sn")

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {

      if (sn !== "") {

        try {
          const res = await PUT(GET_ITEM_BY_SN, { sn: sn })
          const { success, result } = res
          if (success) {

            const { serial_number } = result.item
            const checkSerial = dataSource.filter((el) => el.serial_number === serial_number)
            if (checkSerial.length > 0) {
              Modal.error({
                title: "S/N ซ้ำ",
              });

            }
            let data = []
            data.push(...dataSource, {
              serial_number: serial_number,
            })

            const dataGroup = [...new Map(data.map((item) => [item["serial_number"], item])).values(),]
            const dataFilter = dataGroup.filter(item => item.serial_number !== "")
            const dataResult = dataFilter.map((el, i) => { return { no: i + 1, ...el } })

            setDataSource(dataResult.sort((a, b) => (a.no > b.no) ? 1 : -1))
            setItemTotal(dataResult.length)
          }
        } catch (err) {
          const { message, error } = err;
          Modal.error({
            title: message,
            content: error || '',
          });
        } finally {
          setValue("sn", "")

        }
      }

    }, 300);

  }

  const reCheckItems = async () => {
    const serialNo = dataSource.map((el) => { return el.serial_number })
    try {
      const res = await POST(RECHECK_ITEMS, { documentNo: docNo, serial: serialNo })
      const { success, result } = res
      if (success) {
        const data = result.items.map((val, k) => {
          return {
            ...val, no: k + 1
          }
        })
        setDataSource(data)
      }

    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  }

  const confirmRecieveItems = async () => {
    setLoading(true)
    const data = {
      documentNo: docNo,
      remark: remarkDefault,
      userLogin: user.email,
      items: dataSource
    }

    try {
      const res = await POST(CONFIRM_RECIEVE_ITEMS, data)
      const { success, message } = res;
      if (success) {

        Modal.success({
          title: message,
          afterClose: () => {
            window.location.reload();
          },
        });

      }

    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false)
    }
  }


  const onConfirm = () => {
    Modal.confirm({
      title: 'ยืนยันการรับสินค้าเข้าสต็อก',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        confirmRecieveItems();
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };





  const detailUser = [{ label: "ผู้สร้างเอกสาร", value: userCreate }, { label: "แก้ไขล่าสุดโดย", value: userUpdate }]
  const formSn = <RenderForm control={control} setValue={setValue} getValues={getValues} clearErrors={clearErrors} errors={errors} formList={FORM_SN({ fn: { onChange } })} />

  const countProducts = [
    { label: "S/N", value: formSn },
    { label: "จำนวนที่ยิงแล้ว", value: itemsTotal },
  ]


  return (
    <Wrapper>
      <Card>
        <SearchLayout
          props={{
            // title: 'ตรวจสอบสินค้าที่รับ',
            title: 'รับสินค้า',
            icon: <SwapOutlined />,
            spanSearch: 24,
            formSearch: PickNoRecieveSearchForm({ data: docNo || "", fn: { onChange } }),
            // onSearch: () => getJournalItemsHeader(docNo),
            onSearch: (data) => getJournalItemsHeader(data),
          }}
        />
        <Spin tip="Loading..." spinning={loading}>
          {dataSearch.length > 0 ?
            <div style={{ marginTop: "20px", padding: "12px" }}>
              <Row gutter={[16, 16]} style={{ marginBottom: "12px", fontSize: "16px" }} justify="left">
                {
                  dataSearch.map((el, k) => {
                    return <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: k === 3 ? 8 : 4 }} lg={{ span: k === 3 ? 8 : 4 }} key={k}><span style={{ fontWeight: "800" }}>{`${el.label} : `}</span><br />{`${el.value}`}</Col>
                  })
                }
              </Row>
              <Row gutter={[16, 16]} justify="left">
                <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 22 }} lg={{ span: 22 }}>
                  <Input.TextArea maxLength={500} rows={5} placeholder="ระบุข้อความไม่เกิน 500 ตัวอักษร" defaultValue={remarkDefault} onChange={(e) => onChange(e.target.value, "remark")} />
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="left" style={{ marginTop: "12px" }}>
                {
                  detailUser.map((val, i) => {
                    return (
                      <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 8 }} lg={{ span: 8 }} key={i}>
                        <span style={{ fontWeight: "800" }}>{`${val.label} : `}</span>{`${val.value}`}
                      </Col>
                    )
                  })
                }
              </Row>
              <Row gutter={[16, 16]} justify="left" className='bg-scan-products'>
                {
                  countProducts.map((val, s) => {
                    return (
                      <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} key={s}>
                        <span style={{ fontWeight: "800" }}>{`${val.label} : `}</span><br />
                        {s > 0 && val.value > 0 ? `${val.value} ชิ้น` : val.value}
                      </Col>
                    )
                  })
                }
              </Row>
              <TableLayout
                props={{
                  title: 'รายการสินค้า',
                  columns: LineProductsRecieveColumn(),
                  dataSource: dataSource,
                  footerContent: (
                    <ContainerButton center>
                      <ButtonTheme useFor="RE_CHECK" title={'ตรวจสอบ'} loading={loading} onClick={() => reCheckItems()} />
                      <ButtonTheme useFor="CONFIRM" title={'คอนเฟิร์ม'} onClick={() => onConfirm()} />
                    </ContainerButton>
                  )
                }}
              />
            </div> : null
          }
        </Spin>
      </Card>

    </Wrapper>
  );
};

export default RecieveItems
