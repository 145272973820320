import { Input } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { decryptString } from '../../functions/fn';
import { GET_AMOUNT_DOWN_PAYMENT, POST } from '../../services/index';
const DownPayment = (props) => {
  const [downpaymentAmount, setDownpaymentAmount] = useState(0);

  const idCard = decryptString(localStorage.getItem('id_card')) || '';

  useEffect(() => {
    getAmountDownPayment(idCard);
  }, [idCard]);
 
  const getAmountDownPayment = async (idCard) => {
    try {
      const res = await POST(GET_AMOUNT_DOWN_PAYMENT, { id_card: idCard });
      const { downpayment_amount, success } = res;
      if (success) {
        setDownpaymentAmount(downpayment_amount);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      <Input {...props} value={downpaymentAmount}></Input>
      <span style={{ color: 'red' }}><small>กรุณาให้ลูกค้าโอนเงินดาวน์เพิ่มให้ครบยอดเงินดาวน์</small></span>
    </>
  );
};

export default DownPayment;
