import React, {useState, useEffect} from 'react';
import {Spin, Card, Modal} from 'antd';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {ButtonTheme} from '../../components/buttons';
import {SolutionOutlined} from '@ant-design/icons';
import {CSVLink} from 'react-csv';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import TrackingHistoryModal from '../../components/modal/TrackingTask/TrackingHistoryModal';
import Wrapper from '../../views/wrapper/Wrapper';
import {TrackingTaskManageColumn} from './columns';
import {TRACKING_TASK_SEARCH_FORM} from './form';
import {optionCondition, optionTrackStatus} from './constant';
import {POST, GET_TRACK_TASK, GET_SORT_BY_OPTION, GET} from '../../services';
import {ChangeTrackerIDModal} from './modal/ChangeTrackerIDModal';
import moment from 'moment';

const TrackingTaskManageScreen = () => {
  const [dataPass, setDataPass] = useState();
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [visibleChangeTracker, setVisibleChangeTracker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataExport, setDataExport] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [title, setTitle] = useState('');
  const [sortByOption, setsortByOption] = useState([]);

  useEffect(() => {
    onGetData({});
    getSortByOption()
  }, []);

  const onGetData = async (params) => {
    try {
      setLoading(true);
      const payload = {
        contract_no: params?.contract_no || '',
        terminate_contract_status: params?.terminate_contract_status || '',
        track_status: params?.track_status || '',
        end_date: (params?.end_date && moment(params?.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')) || '',
        start_date: (params?.start_date && moment(params?.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD')) || '',
        sort_by: params?.sort_by || '',
      };
      const res = await POST(GET_TRACK_TASK, payload);
      const {success, data} = res;
      if (success) {
        setDataSource(
          data.map((el, i) => {
            return {...el, no: i + 1, key: el.terminate_id};
          }),
        );
        setDataExport(
          data.map((el, i) => {
            return {
              ประเภทการติดตาม: el.tracking_status,
              เลขที่สัญญา: el.contract_no,
              'ชื่อ-นามสกุลผู้ทำสัญญา': el.name,
              สถานะสัญญา: el.contract_status_th,
              ประเภทหนังสือยกเลิกสัญญา: el.terminate_contract_status,
              ชื่อผู้ติดตาม: el.tracker_name,
              จ่ายรอบที่: el.tracker_count,
              วันที่จ่ายงาน:
                el.tracking_created_date !== '' ? moment(el.tracking_created_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : el.tracking_created_date,
              'ระยะเวลา(วัน)': el.duration_after_task_created,
              'ติดตาม(ครั้ง)': el.track_follow_count,
              'วันที่ติดตาม(ล่าสุด)':
                el.recent_tracking_date !== '' ? moment(el.recent_tracking_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : el.recent_tracking_date,
            };
          }),
        );
      }
    } catch (error) {
      Modal.error({title: error?.message});
    } finally {
      setLoading(false);
    }
  };

  const openModal = (ref, record) => {
    switch (ref) {
      case 'details':
        setVisibleDetails(true);
        break;
      case 'change':
        setVisibleChangeTracker(true);
        setTitle(`เปลี่ยนผู้ติดตาม ${record?.contract_no}`);
        break;
    }
    setDataPass(record);
  };

  const closeModal = (ref) => {
    switch (ref) {
      case 'details':
        setVisibleDetails(false);
        break;
      case 'change':
        setVisibleChangeTracker(false);
        setTitle('');
        setSelectedRowKeys([]);
        setDataPass({});
        break;
    }
    // onGetData({});
  };

  const getSortByOption = async () => {
    setLoading(true);
    try {
      const res = await GET(GET_SORT_BY_OPTION);
      const {success, result} = res;
      if (success) {
        setsortByOption(result);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'การจัดการงานภาคสนาม',
              icon: <SolutionOutlined />,
              spanSearch: 24,
              formSearch: TRACKING_TASK_SEARCH_FORM({
                options: {
                  condition: optionCondition,
                  trackingType: optionTrackStatus,
                  sort: sortByOption,
                },
              }),
              onSearch: (data) => onGetData(data),
            }}
            tableLayout={{
              columns: TrackingTaskManageColumn(openModal),
              dataSource: dataSource,
              rowSelection: {
                selectedRowKeys,
                onChange: (keys, _record) => {
                  setSelectedRowKeys(keys);
                },
              },
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme
                    useFor="TRANFER"
                    title={'เปลี่ยนผู้ติดตามทั้งหมด'}
                    onClick={() => setVisibleChangeTracker(true)}
                    disabled={selectedRowKeys.length === 0}
                  />
                  <CSVLink filename={'รายการการจัดการภาคสนาม.csv'} data={dataExport}>
                    <ButtonTheme useFor="DOWNLOAD" title={'Export Excel'} />
                  </CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
      {visibleDetails && <TrackingHistoryModal visible={visibleDetails} onClose={() => closeModal('details')} data={dataPass} title={title} />}
      {visibleChangeTracker && (
        <ChangeTrackerIDModal
          visible={visibleChangeTracker}
          onClose={() => closeModal('change')}
          data={dataPass}
          selectedRowKeys={selectedRowKeys}
          title={title}
          reApi={() => onGetData({})}
        />
      )}
    </Wrapper>
  );
};

export default TrackingTaskManageScreen;
