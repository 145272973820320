import { useSelector, useDispatch } from 'react-redux';
import * as RECEIPT_ACTIONS from '../../../redux/receipt-payment/action';

export const useReceiptPaymentState = () => {
  const receiptPaidParamsState = useSelector((state) => state.receiptPayment.paid_params);
  const receiptFilterState = useSelector((state) => state.receiptPayment.filter);
  const receiptSummary = useSelector((state) => state.receiptPayment.summary);
  const receiptPayInfo = useSelector((state) => state.receiptPayment.pay_info);
  const receiptTransactionInfo = useSelector((state) => state.transaction_info);
  const receiptCheckRedeemInfo = useSelector((state) => state.receiptPayment.check_redeem);
  const receiptUpdateRemark = useSelector((state) => state.receiptPayment.update_remark);
  const receiptCouponDetail = useSelector((state) => state.receiptPayment.coupon_detail);

  return { receiptPaidParamsState, receiptFilterState, receiptSummary, receiptPayInfo, receiptTransactionInfo, receiptCheckRedeemInfo, receiptUpdateRemark, receiptCouponDetail };
};

export const useReceiptPaymentDispatch = () => {
  const dispatch = useDispatch();

  const dispatchUpdateFilter = (formFilter) => {
    dispatch(RECEIPT_ACTIONS.receiptPaymentUpdateFilter(formFilter));
  };

  const dispatchClearFilter = () => {
    dispatch(RECEIPT_ACTIONS.receiptPaymentUpdateFilter({ clear: true }));
  };

  const dispatchUpdatePaidParams = (params) => {
    dispatch(RECEIPT_ACTIONS.receiptPaymentUpdatePaidParams(params));
  };

  const dispatchClearPaidParams = () => {
    dispatch(RECEIPT_ACTIONS.receiptPaymentUpdatePaidParams({ clear: true }));
  };

  const dispatchGetSummaryInfo = (contractNumber, params) => {
    dispatch(RECEIPT_ACTIONS.getPaymentSummaryInfo(contractNumber, params));
  };

  const dispatchClearSummaryInfo = () => {
    dispatch(RECEIPT_ACTIONS.getPaymentSummaryInfo(null, null, { clear: true }));
  };

  const dispatchGetSummaryAmount = (contractNumber, data, isInit) => {
    dispatch(RECEIPT_ACTIONS.getPaymentSummaryAmount(contractNumber, data, { isInit }));
  };

  const dispatchClearSummaryAmount = () => {
    dispatch(RECEIPT_ACTIONS.getPaymentSummaryAmount(null, null, { clear: true }));
  };

  const dispatchPayByContract = (contractNumber, data, payload) => {
    dispatch(RECEIPT_ACTIONS.payBycontract(contractNumber, data, payload));
  };

  const dispatchClearPayByContract = () => {
    dispatch(RECEIPT_ACTIONS.payBycontract(null, null, { clear: true }));
  };

  const dispatchUpdateTransactionStatus = (transactionId, data, payload) => {
    dispatch(RECEIPT_ACTIONS.updateTransactionStatus(transactionId, data, payload));
  };

  const dispatchUpdateSummaryInfo = (contractNumber, params) => {
    dispatch(RECEIPT_ACTIONS.updatePaymentSummaryInfo(contractNumber, params));
  };

  const dispatchGetAmountContract = (contractNumber, data, payload) => {
    dispatch(RECEIPT_ACTIONS.getAmountContract(contractNumber, data, payload))
  }

  const dispatchClearAmountContract = () => {
    dispatch(RECEIPT_ACTIONS.getAmountContract(null, null, { clear: true }))
  }

  const dispatchGetCheckRedeemData = (payload) => {
    dispatch(RECEIPT_ACTIONS.getCheckRedeemStatusData(payload))
  }

  const dispatchClearCheckRedeemData = () => {
    dispatch(RECEIPT_ACTIONS.getCheckRedeemStatusData({ clear: true }))
  }

  const dispatchUpdateRemark = (payload) => {
    dispatch(RECEIPT_ACTIONS.updateRemarkDeviceReturn(payload))
  }

  const dispatchClearUpdateRemark = () => {
    dispatch(RECEIPT_ACTIONS.updateRemarkDeviceReturn({ clear: true }))
  }

  const dispatchGetCouponDetail = (payload) => {
    dispatch(RECEIPT_ACTIONS.getCouponDetail(payload))
  }

  const dispatchClearGetCouponDetail = () => {
    dispatch(RECEIPT_ACTIONS.getCouponDetail({ clear: true }))
  }

  return {
    dispatchUpdateFilter,
    dispatchClearFilter,
    dispatchGetSummaryInfo,
    dispatchClearSummaryInfo,
    dispatchGetSummaryAmount,
    dispatchClearSummaryAmount,
    dispatchPayByContract,
    dispatchClearPayByContract,
    dispatchUpdateTransactionStatus,
    dispatchUpdatePaidParams,
    dispatchClearPaidParams,
    dispatchUpdateSummaryInfo,
    dispatchGetAmountContract,
    dispatchClearAmountContract,
    dispatchGetCheckRedeemData,
    dispatchClearCheckRedeemData,
    dispatchUpdateRemark,
    dispatchClearUpdateRemark,
    dispatchGetCouponDetail,
    dispatchClearGetCouponDetail,
  };
};
