import styled from 'styled-components';
import ColorSchema from '../../../styles/colors';

export const RowFilter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const InfoContainer = styled.div``;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  ${(props) => props.width && `width: ${props.width}px`};
`;

export const InfoLabel = styled.span`
  font-weight: bold;
  padding-right: 1rem;
  font-size: 16px;
`;

export const InfoValue = styled.span`
  font-size: 16px;
`;

export const PayCalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 2rem 0 0 0;
`;

export const PaidMinimumLabel = styled.span`
  font-weight: bold;
  color: ${ColorSchema.danger};
  font-size: 20px;
  padding-bottom: 1rem;
`;

export const PaidChannelWrapper = styled.div`
  margin-bottom: 1rem;
  margin-left: 30%
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.width && `width: ${props.width}px;`};
  ${(props) => props.between && `justify-content: space-between;`};
  ${(props) => props.edgeInsetsTop && `margin-top: ${props.edgeInsetsTop}px`};
  ${(props) => props.flexEnd && `justify-content: flex-end;`};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.edgeInsetsRight && `margin-right: ${props.edgeInsetsRight}px;`};
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: 0.5rem;
  ${(props) => props.fullWidth && `width: 100%;`};
`;

export const TableHeads = styled.thead``;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableHead = styled.th`
  text-align: center;
  font-size: 18px;
  border: 1px solid ${ColorSchema.tinGray};
  padding: 8px;
  width: 200px;
  ${(props) => props.noBorder && `border: transparent; width: 200px;`};
  ${(props) => props.focus && `font-weight: bold;`};
`;

export const TableData = styled.td`
  border: 1px solid ${ColorSchema.tinGray};
  padding: 8px;
  text-align: end;
  width: 200px;
  ${(props) => props.danger && `color: ${ColorSchema.danger};`};
  ${(props) => props.primary && `color: ${ColorSchema.primary};`};
  ${(props) => props.noBorder && `border: transparent;  width: 100px;   font-weight: normal;`};
  ${(props) => props.focus && `font-weight: bold; font-size: 18px;`};
  ${(props) => props.focusLess && `font-weight: bold;`};
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 600px;
  height: 40px;
  cursor: pointer;
  background-color: ${ColorSchema.primary};
  border-radius: 6px;
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  max-width: 600px;
`;

export const ButtonPreview = styled(Button)`
  width: 120px;
  margin-top: 0px;
  margin-left: 1rem;
  background-color: ${ColorSchema.gray};
`;

export const ButtonText = styled.span`
  font-size: 16px;
  color: white;
`;

export const FileUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  cursor: pointer;
  background-color: ${ColorSchema.success};
  border-radius: 6px;
  &:hover {
    opacity: 0.8;
  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const TextFileName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px;
  padding-top: 0.2rem;
  display: inline-block;
  text-decoration: underline;
`;

export const ImgPreview = styled.img`
  width: 100%;
  max-width: 800px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  width: 100%;
`;
