// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Button, Table, Spin } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { color } from '../../resources/color';
import { GET, GET_ALL_CONFIG_ID_MIRADORE } from '../../services/index';
// import { socketContext } from '../../utils/socketProvider';
import { auth } from '../../utils/firebase';
import { useCurrentState } from './../../utils/hook';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { MiradoreConfigListColumn } from '../../constants/global/columnTableForm';

const MiradoreConfig = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [totalSize, setTotalSize] = useState(0);

    useEffect(() => {
        getAllConfigProfile()
    }, []);

    const getAllConfigProfile = async () => {
        let count = 0
        setLoading(true)
        try {
            const res = await GET(GET_ALL_CONFIG_ID_MIRADORE)
            const { success, data } = res

            if (success) {
                const result = data.Content.Items.ConfigProfile.map((el) => {
                    count += 1

                    let indexStr = 0
                    let subStr = ''
                    let splitStr = ''
                    if (el.ConfigurationType._text.includes('Tracking')) {
                        indexStr = el.ConfigurationType._text.indexOf('Tracking')
                        subStr = el.ConfigurationType._text.substr(indexStr)
                        splitStr = el.ConfigurationType._text.split('Tracking')[0] + ' ' + subStr
                    } else if (el.ConfigurationType._text.includes('Whitelist')) {
                        indexStr = el.ConfigurationType._text.indexOf('Whitelist')
                        // subStr = el.ConfigurationType._text.substr(indexStr)
                        // splitStr = el.ConfigurationType._text.split('Whitelist')[0] + ' ' + subStr + ' (Supervised)'
                        splitStr = el.ConfigurationType._text.split('Whitelist')[0] + ' allowlist (Supervised)'
                    }

                    return {
                        id: el.ID._text,
                        name: el.Name._text,
                        config_type: (el.ConfigurationType._text.includes('Tracking') || el.ConfigurationType._text.includes('Whitelist')) ? splitStr : el.ConfigurationType._text.includes('Wallpaper') ? el.ConfigurationType._text + ' (Supervised)' : el.ConfigurationType._text,
                        platform: el.OSCategory._text,
                        description: !!el.Description ? el.Description._text : ''
                    }
                })

                setTotalSize(count)
                setDataSource(result)
            }

        } catch (err) {
            const { error } = err;
            Modal.error({
                title: error || '',
            });
        } finally {
            setLoading(false)
        }
    }


    return (
        <Wrapper>
            <Card>
                <Spin tip="Loading..." spinning={loading}>
                    <PageLayout
                        tableLayout={{
                            title: 'Miradore Configuration Profile',
                            icon: <SettingOutlined />,
                            columns: MiradoreConfigListColumn(),
                            dataSource: dataSource,
                            // rowKey: (record) => record.id,
                            // pagination: {
                            //     showSizeChanger: true,
                            //     pageSizeOptions: ['10', '20', '30', '40'],
                            //     total: totalSize || 0,
                            //     showTotal: (totalSize, range) => `รายการทั้งหมด ${totalSize} รายการ`,
                            //     defaultPageSize: 20,
                            //     defaultCurrent: 1,
                            // },
                        }}
                    />
                </Spin>
            </Card>
        </Wrapper>
    );
};

export default MiradoreConfig;