import {Select} from 'formik-antd';
import {useState, useEffect,useRef} from 'react';
import PropTypes from 'prop-types';
import {getOptionPackageByProduct} from './api';
import {useFormikContext} from 'formik';
import _ from 'lodash';

const PackagesOption = (props) => {
	const [choices, setChoices] = useState([]);
	const previousValue = useRef(null);
	const ctx = useFormikContext();

	const doUpdate = () => {
		try {
			const {contract} = ctx.values;
			var newValue = `${contract.product_code}|${contract.contract.periods_fin}`;
			if (newValue === previousValue.current) return;
			previousValue.current = newValue;

			const code = contract.product_code;
			const period = contract.contract.periods_fin
			
			
			if(code && period){
				(async () => {
					const data = await getOptionPackageByProduct(code,period);
					const setOption = data?.packages.map((el)=>{
						return{label:el.label,value:el.id}
					})
					setChoices(setOption)
				})();
			}

			ctx.setFieldValue('contract.contract.package', "");
			ctx.setFieldValue('contract.contract.price_package', 0);
			//ctx.setFieldValue('contract.periods_fin', "");
		} catch (e) {}
	};
	
	useEffect(() => {
		
		var token = setInterval(doUpdate, 300);
		return () => clearInterval(token);
		
		
	}, [ctx]);

	const filterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	return <Select {...props} showSearch optionFilterProp="label" options={choices} filterOption={filterOption}></Select>
};

PackagesOption.propTypes = {
	name: PropTypes.string.isRequired,
};

export default PackagesOption;
