import { useState } from 'react'
import { Modal } from 'antd'
import axios from 'axios'
import { API_CHATCONE_SERVICE } from '../../env.js'

export const useGetChatconeHistory = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const getChatconeHistory = async (params) => {
    try {
      setLoading(true)
      const response = await axios.get(`${API_CHATCONE_SERVICE}/admin/history`, {
        params,
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          'content-type': 'application/json'
        }
      })

      if (response.status === 200) {
        setData(response.data.data)
      }
    } catch (err) {
      Modal.error({
        title: 'ดึงข้อมูลไม่สำเร็จ'
      })
    } finally {
      setLoading(false)
    }
  }

  return [getChatconeHistory, data, loading]
}
