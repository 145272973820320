import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { ContainerButton } from '../../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../../components/buttons';
import { POST, EDIT_CANCEL_CONSIDER } from '../../../../services/index';
import { Icon } from '../../../../resources/icon';

export const CancelConsiderModal = ({ title, visible, onClose, data }) => {

  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});

  useEffect(() => {

    if (JSON.stringify(data && data.record) !== "{}" && data.record) {
      setRecord(data.record)
    }

  }, [data]);

  const openConfirm = (params,) => {
    Modal.confirm({
      title: "ยืนยัน",
      icon: <Icon.warningAlert />,
      content: "ยืนยันการแก้ไข",
      onOk() {
        onSubmit(params);
      },
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
    });
  };


  const onSubmit = async (params) => {
    setLoading(true);

    try {
      let res = await POST(EDIT_CANCEL_CONSIDER, { registerId: record.registerId });
      const { success, message, result } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            data.reApi(data.valueSearch);
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }

  };

  const onCloseMain = () => {
    onClose();
  };
  return (
    <Modal title={title || 'modal'} open={visible} width={480} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={() => openConfirm()} loading={loading} />
        <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
      </ContainerButton>
    </Modal>
  );
};
