import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Divider} from 'antd';
import {StatusContainer} from './styled-component';
import * as logic from './logic';

const ContractStatusView = (props) => {
  const {status} = props;

  if (!status) {
    return null;
  }
  return (
    <>
      <Divider />
      <StatusContainer>
        <Typography.Title level={3}>{logic.getContractStatusLabel(status)}</Typography.Title>
      </StatusContainer>
    </>
  );
};

ContractStatusView.propTypes = {
  status: PropTypes.string,
};

ContractStatusView.defaultProps = {
  status: '',
};

export default ContractStatusView;
