import React, {useState} from 'react';

const messageSerialInput = (prop) => {
  return (
    <>
      <div>
        <br />
        <p className="text-remark">{"ห้ามใส่ '-' และช่องว่าง เมื่อสร้างสัญญาใหม่โดยใช้เลขซีเรียลเครื่องเดิม"}</p>
      </div>
    </>
  );
};

export default messageSerialInput;
