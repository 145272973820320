import React, {useState} from 'react';
import {Modal, Input, Row, Col} from 'antd';
import {useForm} from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../buttons';
import {GET, POST, FIND_NAME_BY_CONTRACT, ADD_SPECIAL_TASK} from '../../../services/index';
import {ADD_SPECIAL_TASK_FORM} from './form';
import {TextSmall} from '../../forms/text';
import {Icon} from '../../../resources/icon';
import {useGetOptions} from './hook/useGetOptions';
const {Search} = Input;
const AddSpecialTaskModal = ({title, visible, onClose}) => {
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [contractNo, setContractNo] = useState('');
  const [terminateId, setTerminateId] = useState([]);
  const [dataContract, setDataContract] = useState([]);
  const {optionsTracker} = useGetOptions();

  const onSubmit = async (params) => {
    try {
      const payload = {
        contractNO: contractNo,
        trackerID: [params?.trackerID],
        trackingFee: params?.trackingFee,
        terminate_id:terminateId
      };
      const res = await POST(ADD_SPECIAL_TASK, payload);
      const {success, message} = res;
      if (success) {
        Modal.success({title: message});
        onClose()
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการจ่ายงานพิเศษ',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };
  const onSearchContract = async () => {
    try {
      setLoading(true);
      const payload = {
        contract_no: contractNo,
      };
      const res = await POST(FIND_NAME_BY_CONTRACT, payload);

      const {success, data} = res;
      if (success) {
        setTerminateId(data?.terminate_id)
        setDataContract([
          {
            label: 'ชื่อลูกค้า : ',
            value: data.name,
          },
        ]);
        setLoading(false);
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }
  };

  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onClose} width={480} footer={null} destroyOnClose={true} maskClosable={false}>
      <div style={{display: 'flex'}}>
        <TextSmall text={'* '} color={'red'} /> <TextSmall text="เลขที่สัญญา" bold={true} />
      </div>

      <Search
        placeholder="กรอกเลขที่สัญญา"
        onSearch={onSearchContract}
        loading={loading}
        enterButton
        autoFocus={true}
        onChange={(e) => {
          setContractNo(e.target.value);
        }}
      />
      {dataContract.length > 0 && (
        <>
          <div style={{marginTop: '12px', marginBottom: '12px'}}>
            {dataContract.map((el, index) => {
              return (
                <Row key={index}>
                  <Col style={{paddingRight: '12px'}}>
                    <TextSmall text={el.label} bold={true} />
                  </Col>
                  <Col>
                    <TextSmall text={el.value} />
                  </Col>
                </Row>
              );
            })}
          </div>
          <form onSubmit={handleSubmit(onConfirm)}>
            <RenderForm
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              formList={ADD_SPECIAL_TASK_FORM({options: {trackers: optionsTracker}})}
              renderButton={
                <>
                  <ContainerButton right>
                    <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                    <ButtonTheme useFor="CANCEL" onClick={onClose} />
                  </ContainerButton>
                </>
              }
            />
          </form>
        </>
      )}
    </Modal>
  );
};
export default AddSpecialTaskModal;
