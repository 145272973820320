import React, { useState } from 'react';
import { Modal, Input, Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { GET, POST, GET_FIND_INFO_CONTRACT, EXPORT_MISTAKE_TERMINATED } from '../../../services/index';
import { TERMINATED_CONTRACT_FORM } from '../../../screens/contract/form';
import { TextSmall } from '../../forms/text';
import { optionCondition } from '../../../screens/contract/constant';
import { Icon } from '../../../resources/icon';
import { openPDF } from '../../../functions/fn';
const { Search } = Input;
export const MistakeTerminateContractModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [contractNo, setContractNo] = useState('');
  const [typeValue, setTypeValue] = useState('');
  const [dataContract, setDataContract] = useState([]);

  const openNewTabPDF = (url) => {
    const win = window.open('', '_blank');
    setTimeout(() => {
      win.document.write(openPDF(url));
    }, 0);
  };

  const onSubmit = async (params) => {
    try {
      const payload = {
        contract_no: contractNo,
        type: params?.type,
        remark: params?.remark,
      };
      const res = await POST(EXPORT_MISTAKE_TERMINATED, payload);
      const { success, message, result } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            data.reApi(data.valueSearch);
            openNewTabPDF(result?.path_file_pdf);
            onClose();
          },
        });
      }
    } catch (error) {
      Modal.error({ title: error?.message });
    }
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันออกหนังสือยกเลิกสัญญา',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };
  const onSearchContract = async () => {
    try {
      setLoading(true);
      const payload = {
        contract_no: contractNo,
      };
      const res = await POST(GET_FIND_INFO_CONTRACT, payload);

      const { success, data } = res;
      if (success) {
        setDataContract([
          {
            label: 'ชื่อลูกค้า : ',
            value: data.name,
          },
          {
            label: 'สถานะ : ',
            value: data.contract_status_th,
          },
          {
            label: 'ชื่อสินค้า : ',
            value: data.product_name,
          },
          {
            label: 'SN : ',
            value: data.sn,
          },
        ]);
      }
    } catch (error) {
      Modal.error({ title: error?.message });
    } finally {
      setLoading(false);
    }
  };

  const onChangeType = (value, name) => {
    setTypeValue(value);
  };

  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onClose} width={480} footer={null} destroyOnClose={true} maskClosable={false}>
      <div style={{ display: 'flex' }}>
        <TextSmall text={'* '} color={'red'} /> <TextSmall text="เลขที่สัญญา" bold={true} />
      </div>

      <Search
        placeholder="กรอกเลขที่สัญญา"
        onSearch={onSearchContract}
        loading={loading}
        enterButton
        autoFocus={true}
        onChange={(e) => {
          setContractNo(e.target.value);
        }}
      />
      {dataContract.length > 0 && (
        <>
          <div style={{ marginTop: '12px', marginBottom: '12px' }}>
            {dataContract.map((el, index) => {
              return (
                <Row key={index}>
                  <Col style={{ paddingRight: '12px' }}>
                    <TextSmall text={el.label} bold={true} />
                  </Col>
                  <Col>
                    <TextSmall text={el.value} />
                  </Col>
                </Row>
              );
            })}
          </div>
          <form onSubmit={handleSubmit(onConfirm)}>
            <RenderForm
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              formList={TERMINATED_CONTRACT_FORM({ options: { condition: optionCondition }, onChangeType, typeValue })}
              renderButton={
                <>
                  <ContainerButton right>
                    <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                    <ButtonTheme useFor="CANCEL" onClick={onClose} />
                  </ContainerButton>
                </>
              }
            />
          </form>
        </>
      )}
    </Modal>
  );
};
