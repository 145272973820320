import { MODE_API,UPLOAD_IMAGE_CUSTOMER_INFO } from "../../../services";
export const UpdateOtherInfoForm = ({data,onChange,onUploadImage, imageUploadListTemp}) => {

  return [
    {
      key: '1',
      span: 24,
      name: 'type',
      label: 'ประเภท',
      type: 'SELECT',
      options: [
        {label: 'ที่อยู่ที่ทำงาน', value: 'WORK_ADDRESS'},
        {label: 'ที่อยู่ปัจจุบัน', value: 'USER_ADDRESS'},
        {label: 'อื่นๆ (โปรดระบุ)', value: 'OTHER'},
      ],
      placeholder: 'กรุณาประเภท',
      onSelect: onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 24,
      name: 'info_image',
      label: 'อัพโหลดรูป:',
      type: 'FILE_LIST_UPLOAD',
      accept: 'image/*',
      maxCount:20,
      onUpload: onUploadImage,
      fileList: imageUploadListTemp,
      // onPreview: onPreview,
      // onRemove: handleRemove,
      urlUpload: `${MODE_API.UAT}${UPLOAD_IMAGE_CUSTOMER_INFO}`,
      // imageCat: 'downPayment_changeName',
      // disabled: true,
      rules: {
        required: false,
      },
    },
    {
        key: '3',
        span: 24,
        name: 'remark',
        label: 'ข้อมูลอื่นๆ',
        type: 'INPUT_AREA',
        rows: 2,
        maxLength: 200,
        rules: {
          required: data?.type === 'OTHER',
          maxLength: 200,
        },
      },
  ];
};
