import React, { useState, useEffect } from 'react';
import { Modal, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { ManageIcloudForm } from '../../../constants/global/actionform';
import { GET, POST, MANAGE_ACCOUNT, RANDOM_KEY_PASS } from '../../../services/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { auth } from '../../../utils/firebase';
import { useAuthState } from '../../../utils/hook';

const { Text } = Typography;

export const ManageIcloudModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
  } = useForm();

  const { user } = useAuthState(auth);

  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [customerType, setCustomerType] = useState('');
  //const dataLogin = JSON.parse(localStorage.getItem('dataLogin'))[0];

  useEffect(() => {
    setRecord(data.record);
    onSetValue()
    onRandomKeyPass()
  }, [data.record]);


  const onSetValue = () => {
    if (data.record) {
      setValue('mail', data.record['mail'])
      setValue('telIcloud', data.record['telIcloud'])
      setValue('passIcloud', data.record['passIcloud'])
      setValue('pin', data.record['pin'])
    }
  };


  const onCloseMain = () => {
    // setValue('passIcloudNew', '');
    // setValue('pinNew', '');
    setValue('confirm', '');
    setValue('confirmManageIcloud', '');
    clearErrors();
    onClose('manageIcloud');
  };
  const onChangeCustom = (value, ref) => {
    if (ref === 'confirm') {
      setValue('confirm', value);
      clearErrors('confirm');
    } else if (ref === 'confirmManageIcloud') {
      setValue('confirmManageIcloud', value);
      clearErrors('confirmManageIcloud');
    }
  };

  const onRandomKeyPass = async () => {
    try {
      let res = await GET(RANDOM_KEY_PASS);
      const { success, result } = res;
      if (success) {
        setValue('passIcloudNew', result.passIcloud);
        setValue('pinNew', result.pin);
      }
    } catch (err) {
      const { error } = err;
      Modal.error({
        title: error,
      });
    }
    finally {
      setLoading(false);
    }
  };

  const onSubmit = async (params) => {
    setLoading(true);
    const { passIcloudNew, pinNew, customerType } = params;
    const obj = {
      userLogin: user.email,
      _id: data.record._id,
      passIcloud: passIcloudNew,
      customerType: 'NEW',
      pin: pinNew,
    };

    try {
      let res = await POST(MANAGE_ACCOUNT, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi();
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันจัดการไอคลาวน์',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการจัดการไอคลาวน์ ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={480} footer={null} destroyOnClose={true} maskClosable={false}>
      {record.contractNo && record.contractDate && record.Dayunlock !== '' ? <div style={{ marginBottom: 20 }}>
        <Text strong type="danger">*** ลูกค้าได้รับรหัสไปแล้ว {record.Dayunlock} วัน ***</Text>
      </div> : <></>}
      <form onSubmit={handleSubmit(onConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={ManageIcloudForm({ data: { record, customerType }, onChangeCustom, onRandomKeyPass })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
