import {Modal, Space, Typography, Row, Col, Spin, Table, Image, Alert} from 'antd';
import {useState, useEffect, useCallback} from 'react';
import {PlusSquareFilled} from '@ant-design/icons';
import {TaskHistoryColumn} from '../../../constants/global/columnTableForm';
import {statusTagStyle, convertStrToFormat} from '../../../functions/fn';
import {GET, GET_TRACKING_TASK} from '../../../services/index';
import {TextSmall} from '../../forms/text';

const {Title} = Typography;

const TrackingHistoryModal = ({visible, onClose, data}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [taskDetails, setTaskDetails] = useState({});
  const [visiblesCostumer, setVisiblesCostumer] = useState(false);
  const [visiblesProduct, setVisiblesProduct] = useState(false);
  const [visibleImage, setVisibleImage] = useState(false);
  const [sourceImage, setSourceImage] = useState('');

  const showImage = (src) => {
    setVisibleImage(true);
    setSourceImage(src);
  };

  const fetchData = useCallback(async (value) => {
    setIsLoading(true);
    try {
      let res = await GET(`${GET_TRACKING_TASK}/${value.contract_no}?terminate_id=${value.terminate_id}`);
      const {success, result} = res;
      if (success) {
        const resultData = result;
        const historyLength = resultData?.history.length;
        resultData.history.map((e, index) => (e.key = historyLength - index));
        // resultData.history.map((e, index) => (e.key = index + 1));
        let temp = {customer: [], phone: []};
        if (resultData.photo) {
          for (let i in resultData.photo) {
            if (i !== 'customer' && i !== 'location') {
              temp.phone.push(resultData.photo[i]);
            } else {
              temp.customer.push(resultData.photo[i]);
            }
          }
        }
        resultData.photo = temp;
        setTaskDetails(resultData);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const convertRemarkType = (type) => {
    switch (type) {
      case 'WORK_ADDRESS':
        return 'ที่อยู่ที่ทำงาน: ';
      case 'USER_ADDRESS':
        return 'ที่อยู่ปัจจุบัน: ';
      case 'OTHER':
        return 'อื่นๆ: ';
      default:
        break;
    }
  };

  let labelList = [];
  if (!isLoading && taskDetails) {
    labelList = [
      {
        key: 'name',
        label: 'ชื่อ-นามสกุล : ',
        value: taskDetails.name,
        span: 12,
      },
      {
        key: 'phoneNumber',
        label: 'เบอร์โทรศัพท์ : ',
        value: taskDetails.phoneNumber,
        span: 12,
      },
      {
        key: 'address',
        label: 'ที่อยู่ตามบัตรประชาชน : ',
        value: taskDetails?.address?.full || '-',
        span: 12,
      },
      {
        key: 'address2',
        label: 'ที่อยู่ปัจจุบัน : ',
        value: taskDetails?.address2?.full || '-',
        span: 12,
      },

      {
        key: 'remark',
        label: 'ข้อมูลอื่นๆ : ',
        value: taskDetails?.customer_log,
        render: () => (
          <>
            ข้อมูลอื่นๆ:&nbsp;
            {taskDetails?.customer_log ? (
              <div style={{background: '#f1f5f9', padding: '15px', borderRadius: '10px', margin: '15px 0'}}>
                {taskDetails?.customer_log[0]?.map((item, index) => (
                  <p key={index}>
                    {convertRemarkType(item.type)}
                    {item.remark}
                  </p>
                ))}
              </div>
            ) : (
              '-'
            )}
          </>
        ),
        span: 12,
      },
      {
        key: 'workaddress',
        label: 'ที่อยู่ที่ทำงาน : ',
        value: taskDetails?.workaddress?.full || '-',
        span: 12,
      },
      {
        key: 'product',
        label: 'ชื่อสินค้า : ',
        value: taskDetails.productName,
        span: 12,
      },
      {
        key: 'serial',
        label: 'Serial No. ของสินค้า : ',
        value: taskDetails.productSerialNo,
        span: 12,
      },
      {
        key: 'amount',
        label: 'ยอดชำระ : ',
        value: taskDetails.leaseAmount.toLocaleString(),
        span: 24,
      },
      {
        key: 'date',
        label: 'วันที่ได้รับหนังสือบอกเลิกสัญญา : ',
        value: convertStrToFormat(taskDetails.arrivedDate, 'dateFor'),
        span: 12,
      },
      {
        key: 'contract_status',
        label: 'ประเภทหนังสือยกเลิกสัญญา : ',
        value: taskDetails.contract_status || '',
        span: 12,
      },
      {
        key: 'status',
        label: 'สถานะการติดตาม : ',
        // value: statusTagStyle(taskDetails.trackingTaskStatus, 'trackingTaskStatus'),
        span: 24,
      },
    ];
  }

  useEffect(() => {
    if (data) {
      fetchData(data);
    }
  }, [data, fetchData]);
  return (
    <Modal
      title={<Title level={3}>{`การติดตามเลขที่สัญญา ${data?.contract_no}`}</Title>}
      open={visible}
      onCancel={onClose}
      width={1300}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}>
      {isLoading ? (
        <Row width="100%" align="center">
          <Spin />
        </Row>
      ) : (
        <>
          <Row gutter={[8, 10]}>
            {labelList.map((item) => {
              return item.key === 'status' ? (
                <Col key={item.key} xl={item.span}>
                  {`${item.label}`} {statusTagStyle(taskDetails.trackingTaskStatus, 'trackingTaskStatus')}
                </Col>
              ) : (
                <Col key={item.key} xl={item.span}>
                  {item.render ? item.render() : `${item.label}${item.value}`}
                </Col>
              );
            })}

            <Col xs={24} xl={12}>
              {taskDetails.photo?.customer?.length === 0 ? (
                <Row> รูปถ่ายลูกค้า/สถานที่ : - </Row>
              ) : (
                <>
                  <Row> รูปถ่ายลูกค้า/สถานที่ : </Row>
                  <Row style={{padding: '10px'}}>
                    <Space>
                      {taskDetails.photo.customer
                        .filter((item, idx) => idx < 3)
                        .map((item, index) => (
                          <Image
                            key={index}
                            preview={{
                              visible: false,
                            }}
                            height={150}
                            src={item}
                            onClick={() => setVisiblesCostumer(true)}
                          />
                        ))}
                      {taskDetails.photo.customer.length > 3 && <PlusSquareFilled style={{fontSize: 30}} onClick={() => setVisiblesCostumer(true)} />}
                    </Space>

                    <div
                      style={{
                        display: 'none',
                      }}>
                      <Image.PreviewGroup
                        preview={{
                          visible: visiblesCostumer,
                          onVisibleChange: (vis) => setVisiblesCostumer(vis),
                        }}>
                        {taskDetails.photo.customer.map((item, index) => (
                          <Image
                            key={index}
                            preview={{
                              visible: false,
                            }}
                            height={150}
                            src={item}
                            onClick={() => setVisiblesCostumer(true)}
                          />
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  </Row>
                </>
              )}
            </Col>
            <Col xs={24} xl={12}>
              {taskDetails?.photo?.phone?.length === 0 ? (
                <Row>รูปถ่ายสินค้า/Serial No. : -</Row>
              ) : (
                <>
                  <Row> รูปถ่ายสินค้า/Serial No. : </Row>
                  <Row style={{padding: '10px'}}>
                    <Space>
                      {taskDetails.photo.phone
                        .filter((item, idx) => idx < 3)
                        .map((item, index) => (
                          <Image
                            key={index}
                            preview={{
                              visible: false,
                            }}
                            height={150}
                            src={item}
                            onClick={() => setVisiblesProduct(true)}
                          />
                        ))}
                      {taskDetails?.photo?.phone?.length > 3 && <PlusSquareFilled style={{fontSize: 30}} onClick={() => setVisiblesProduct(true)} />}
                    </Space>

                    <div
                      style={{
                        display: 'none',
                      }}>
                      <Image.PreviewGroup
                        preview={{
                          visible: visiblesProduct,
                          onVisibleChange: (vis) => setVisiblesProduct(vis),
                        }}>
                        {taskDetails.photo.phone.map((item, index) => (
                          <Image
                            key={index}
                            preview={{
                              visible: false,
                            }}
                            height={100}
                            src={item}
                            onClick={() => setVisiblesProduct(true)}
                          />
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  </Row>
                </>
              )}
            </Col>
            <Col xs={24}>
              {taskDetails?.trackerList.length > 0 && (
                <Alert
                  message=""
                  description={taskDetails?.trackerList.map((el, i) => {
                    return <TextSmall text={el} key={i} />;
                  })}
                  type="info"
                />
              )}
            </Col>
            <Col span={24}>
              <Title level={3}>ประวัติการติดตาม : </Title>
            </Col>
          </Row>

          <Table
            columns={TaskHistoryColumn({showImage})}
            dataSource={taskDetails.history}
            scroll={{
              x: 900,
              y: 300,
            }}></Table>

          <Image.PreviewGroup
            preview={{
              visible: visibleImage,
              onVisibleChange: (vis) => setVisibleImage(vis),
            }}>
            {Object.values(sourceImage).map((img, index) => {
              return (
                <Image
                  style={{
                    display: 'none',
                  }}
                  key={index}
                  src={img}
                />
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
    </Modal>
  );
};

export default TrackingHistoryModal;
