import styled from 'styled-components'

export const TagLabelStyle = styled.span`
  .ant-tag {
    font-size: 16px !important;
    padding: 10px 7px;
  }

  .ant-tag-has-color {
    border-radius: 2px !important
  }
`