import styled from 'styled-components';

export const WrapperLogin = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 82, 186);
  height: 100vh;
  text-align: center;
  font-family: 'Prompt', 'sans-serif';
`;

export const Landing = styled.div`
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 768px) {
    top: 30%;
  }
`;

export const ImgLogo = styled.img`
  margin: auto;
  width: 15%;

  @media (max-width: 768px) {
    width: 35%;
  }
`;

export const NamePage = styled.p`
  color: white;
  font-size: 1.5rem;
  letter-spacing: 2px;
  font-weight: bold;
`;

export const ButtonLogin = styled.button`
  display: flex;
  margin: auto;
  width: auto;
  border: none;
  border-radius: 5px;

  svg {
    width: 25px;
    height: 25px;
    margin: auto 10px;
  }

  p {
    margin: 1rem auto;
    margin-right: 10px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 1px;
  }
`;
