// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import {Modal, Card, Spin} from 'antd';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../components/buttons';
import {GiftOutlined} from '@ant-design/icons';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {ProductsNonStockSearchForm} from '../../constants/global/SearchForm';
import {ProductsNonStockColumn} from '../../constants/global/columnTableForm';
import {GET, GET_PRODUCTS_PACKAGES} from '../../services/index';
import {dataProductsNonserial} from '../../resources/datamock'
import { CSVLink } from "react-csv";
import {useHistory} from 'react-router-dom';

const DashboardProducts = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport,setDataExport] = useState([])

  useEffect(() => {
    getProductsPackages('');
  }, []);

  const getProductsPackages = async(name)=>{
    setLoading(true)
    try {
        const res = await GET(GET_PRODUCTS_PACKAGES(name))
        const {success, result} = res;
        if(success){
          let data = []
          let data_export =[]
          result?.productPackage.forEach((el,i)=>{
            data.push({rowNo:i+1,...el})
            data_export.push({'รหัสสินค้า':el.item_code,'ชื่อสินค้า':el.item_name,'จำนวนแพคเก็จ':el.packageNum})
          })
          setDataSource(data)
          setDataExport(data_export)
        }
    } catch (err) {
    const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }finally{
      setLoading(false)
    }
  }

  const onClickBtn = (ref,data)=>{
      if(ref === 'detailPackage'){
        history.push({pathname: '/manage-package',state:data});
      }
  }


  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'ผูกแพคเก็จสินค้า',
              icon: <GiftOutlined />,
              spanSearch: 24,
              formSearch: ProductsNonStockSearchForm(),
              onSearch: (data) => getProductsPackages(data.item_name || ''),
            }}
            tableLayout={{
              columns: ProductsNonStockColumn(onClickBtn),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                    <CSVLink data={dataExport} filename={"สินค้า non serial"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default DashboardProducts;
