// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect, useRef } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Space, Button, Table, Spin, Row } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { POST, GET_SPECIAL_PRODUCT_DATA, GET_OPTION_BRANCH, GET, EDIT_SPECIAL_PRODUCT, PUT } from '../../services/index';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { ManageSpecialProductColumn } from '../../constants/global/columnTableForm';
import { ManageSpecialProductSearchForm } from '../../constants/global/SearchForm';
import { convertStrToFormat } from '../../functions/fn';
import moment from 'moment';
import { Icon } from '../../resources/icon';
import { logDOM } from '@testing-library/react';


const ManageSpecialProductScreen = () => {
    const [loading, setLoading] = useState(false);
    const [visibleEditModal, setVisibleEditModal] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const pageRef = useRef(1)
    const sizeRef = useRef(10)
    const [totalSize, setTotalSize] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [documentNoState, setDocumentNoState] = useState('')
    const [productState, setProductState] = useState('')
    const [serialNumberState, setSerialNumberState] = useState('')
    const [serialStatusState, setSerialStatusState] = useState('')
    const [branchState, setBranchState] = useState('')
    const [defaultValue, setDefaultValue] = useState({});
    const [valueSearch, setValueSearch] = useState({})
    const [optionBranch, setOptionBranch] = useState([]);
    const [optionSaleGrade, setOptionSaleGrade] = useState([]);
    const [enableEditOneRow, setEnableEditOneRow] = useState(false)
    const [enableEditIndex, setEnableEditIndex] = useState(null)
    const [selected, setSelected] = useState([]);

    useEffect(async () => {
        await getOptionBranch();
        await onFetchSaleGradeOption()
        await getManageSpecialProduct({ page: pageRef.current, size: sizeRef.current, documentNo: '', product: '', serial_number: '', serial_status: '', branch: '' })
    }, []);

    const optionSerialStatus = [
        { value: 'READY', label: 'พร้อมขาย' },
        { value: 'NOT_READY', label: 'ไม่พร้อมขาย' },
    ]


    const onFetchSaleGradeOption = async () => {
        try {
            // const res = await GET(GET_SECOND_HAND_CONDITION_LIST);
            // const { success, result } = res;
            // if (success) {
            if (true) {
                // const option = result.map((el) => {
                //     return {
                //         label: `Condition ${el}`,
                //         value: el,
                //     };
                // });

                const option = [
                    { value: 'A', label: 'A' },
                    { value: 'B', label: 'B' },
                    { value: 'C', label: 'C' },
                    { value: 'D', label: 'D' },
                ]

                setOptionSaleGrade(option);
            }
        } catch (err) {
            const { error } = err;
            Modal.error({
                title: error,
            });
        }
    }

    const createParams = (value) => {
        let params = []
        let result = ''

        Object.keys(value).forEach((key) => {
            if (value[key]) {
                params.push(key + '=' + value[key]);
            }
        });
        if (params.length !== 0) {
            result += params.join('&');
        }

        return result
    }

    const getManageSpecialProduct = async (value) => {

        value.page = value?.page || 1
        value.size = value?.size || 10

        pageRef.current = value.page
        sizeRef.current = value.size

        value.documentNo = value.documentNo || ''
        value.product = value.product || ''
        value.serial_number = value.serial_number || ''
        value.serial_status = value.serial_status || ''
        value.branch = value.branch || ''

        let params = createParams(value)
        const { page, size, documentNo, product, serial_number, serial_status, branch } = value;

        setLoading(true)
        setDocumentNoState(documentNo)
        setProductState(product)
        setSerialNumberState(serial_number)
        setSerialStatusState(serial_status)
        setBranchState(branch)
        setValueSearch(value)

        try {
            const res = await POST(GET_SPECIAL_PRODUCT_DATA(params))
            const { success, data } = res
            const { secondHandList, totalItem, totalPage } = data
            if (success) {
                setTotalSize(totalItem)
                setTotalPage(totalPage)
                const result = secondHandList.map((el) => {

                    const branchCode = el?.branch?.code || '';
                    const branchName = el?.branch?.name || '';
                    const mdm = el?.mdm !== '-' ? moment(el?.mdm).format('DD/MM/YYYY') : '';
                    const newData = {
                        key: el?.id || '',
                        product_branch: `${branchName}`,
                        date: mdm,
                        old_sale_grade: el.sale_grade,
                        old_serial_status: el.serial_status,
                        serial_status: el.serial_status,
                        ...el,
                    };
                    return newData;
                });
                setDataSource(result)
            }

        } catch (err) {
            const { error } = err;
            Modal.error({
                title: error || '',
            });
        } finally {
            setLoading(false)
        }
    }

    const onPageSizeChangeCustom = (pageData, sizeData) => {
        const sizeChange = sizeRef.current !== sizeData;
        if (sizeChange) {
            pageRef.current = 1
        } else {
            pageRef.current = pageData
        }
        sizeRef.current = sizeData

        let documentNo = documentNoState
        let product = productState
        let serial_number = serialNumberState
        let serial_status = serialStatusState
        let branch = branchState

        const params = {
            page: pageRef.current,
            size: sizeRef.current,
            documentNo: documentNo || '',
            product: product || '',
            serial_number: serial_number || '',
            serial_status: serial_status || '',
            branch: branch || '',
        };

        getManageSpecialProduct(params);
    };

    const openModal = (record, name) => {
        if (record) {
            setDefaultValue(record);
            if (name == 'EDIT_DATA') {
                setVisibleEditModal(true)
            }
        }
    };

    const getOptionBranch = async () => {
        try {
            let res = await GET(GET_OPTION_BRANCH);
            const { success, result } = res;
            if (success) {
                const option = result.map((el) => {
                    if (el.value === 'ON_HOLD') {
                        el.label = 'ยังไม่ได้ตัดสินใจ'
                    }
                    return {
                        value: el.value,
                        label: el.label,
                    };
                })
                setOptionBranch(option);
            }
        } catch (err) {
            const { message, error } = err;
            Modal.error({
                title: message,
                content: error || '',
            });
        } finally {
            setLoading(false);
        }
    };

    const upDateRowData = async (record) => {
        setLoading(true);
        try {
            const res = await PUT(EDIT_SPECIAL_PRODUCT, { serial_number: record.serial_number, serial_status: record.serial_status, sale_grade: record.sale_grade });
            const { success, message } = res;
            if (success) {
                Modal.success({
                    title: message,
                    afterClose: () => {
                        getManageSpecialProduct({})
                        setEnableEditOneRow(false)
                        setEnableEditIndex(null)
                    },
                });
            }
        } catch (err) {
            const { message, error } = err;
            Modal.error({
                title: message,
                content: error || '',
            });
        }
        finally {
            setLoading(false);
        }
    };

    const openConfirm = (ref, data) => {
        if (!!ref && !!data) {
            if (ref === 'edit_one') {
                Modal.confirm({
                    title: 'ยืนยัน',
                    icon: <Icon.warningAlert />,
                    content: 'ยืนยันการแก้ไขข้อมูล',
                    onOk() {
                        upDateRowData(data)
                    },
                    okText: 'ยืนยัน',
                    cancelText: 'ยกเลิก',
                });
            }
        }
    };

    const onEditOnRow = (index) => {
        setEnableEditOneRow(true)
        setEnableEditIndex(index)
    }

    const onCancelEditOnRow = (index) => {
        let data = [...dataSource]
        let selectedIndex = data.findIndex((el) => el.key === index)

        data[selectedIndex].serial_status = data[selectedIndex].old_serial_status
        data[selectedIndex].sale_grade = data[selectedIndex].old_sale_grade
        setDataSource(data)
        setEnableEditOneRow(false)
        setEnableEditIndex(null)
    }

    const onChangeSelectedSerialStatus = (value, index) => {
        let data = [...dataSource]
        let selectedIndex = data.findIndex((el) => el.key === index)

        data[selectedIndex].serial_status = value
        setDataSource(data)
    };

    const onChangeSelectedSaleGrade = (value, index) => {
        let data = [...dataSource]
        let selectedIndex = data.findIndex((el) => el.key === index)

        data[selectedIndex].sale_grade = value
        setDataSource(data)
    };

    return (
        <Wrapper>
            <Card>
                <Spin tip="Loading..." spinning={loading}>
                    <PageLayout searchLayout={{
                        title: 'จัดการสินค้าพิเศษ',
                        icon: <SettingOutlined />,
                        spanSearch: 24,
                        formSearch: ManageSpecialProductSearchForm({ options: { optionBranch } }),
                        onSearch: (data) => getManageSpecialProduct(data),
                        // onClearCustom
                    }}
                        tableLayout={{
                            columns: ManageSpecialProductColumn({
                                options: { optionSaleGrade, optionSerialStatus },
                                data: { enableEditOneRow, enableEditIndex, selected },
                                fn: { onChangeSelectedSerialStatus, onChangeSelectedSaleGrade, openModal, onEditOnRow, onCancelEditOnRow, openConfirm }
                            }),
                            dataSource: dataSource,
                            rowKey: (record) => record.key,
                            pagination: {
                                showSizeChanger: true,
                                current: pageRef.current,
                                pageSize: sizeRef.current,
                                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                                total: totalSize || 0,
                                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                                defaultPageSize: sizeRef.current,
                                defaultCurrent: 1,
                            },
                        }}
                    />
                </Spin>
            </Card>
        </Wrapper>
    );
};

export default ManageSpecialProductScreen;