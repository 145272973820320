import { EyeOutlined} from '@ant-design/icons';
import moment from 'moment';

export const OtherPaymentColumn = () => {
  return [
    {
      title: '#',
      dataIndex: 'no',
      width: '80px',
    },
    {
      title: 'วันที่เพิ่ม',
      dataIndex: 'create_date',
      width: '150px',
      align: 'left',
      render: (text) => {
        return text ? moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY'):'-';
      },
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'type',
      width: '200px',
      align: 'left',
    },
    {
      title: 'จำนวนทั้งหมด',
      dataIndex: 'total_amount',
      width: '150px',
      align: 'right',
    },
    {
      title: 'ยอดที่ชำระ',
      dataIndex: 'remain_amount',
      width: '200px',
      align: 'right',
    },
    {
      title: 'ยอดที่ต้องชำระ',
      dataIndex: 'remain_amount',
      width: '150px',
      align: 'right',
    },
  ];
};


export const DetailOtherPaymentColumn = ({onOpenImage}) => {
  return [
    {
      title: 'หลักฐาน',
      dataIndex: 'action',
      width: '80px',
      align: 'center',
      render: (_text, record) => {
        return (
          <>
            <EyeOutlined style={{fontSize: '18px', color: '#007bff'}} onClick={() => onOpenImage(record)} />
          </>
        );
      },
    },
    {
      title: 'เลขที่ใบเสร็จ',
      dataIndex: 'receipt_no',
      width: '200px',
      align: 'left',
    },
    {
      title: 'วันที่ทำรายการ',
      dataIndex: 'transaction_date',
      width: '150px',
      align: 'left',
      render: (text) => {
        return text ? moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY'):'-';
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'transaction_status',
      width: '150px',
      align: 'center',
    },
    {
      title: 'ยอดชำระ',
      dataIndex: 'amount',
      width: '150px',
      align: 'right',
    },
  ];
};