import { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import _, { result } from 'lodash';
const SameContactPoints = (props) => {
  const ctx = useFormikContext();
  const previousValue = useRef(null);
  const [checkFirstName, setCheckFirstName] = useState('');
  const [checkTel, setCheckTel] = useState('');

  const doUpdate = () => {
    setCheckFirstName('');
    setCheckTel('');

    const cutPrefix = (name) => {
      const prefix = ['นาง', 'นาย', 'นางสาว'];
      let noPrefixName;
      for (let i = 0; i < prefix.length; i++) {
        if (name.includes(prefix[i])) {
          noPrefixName = name.replace(prefix[i], '').trim();
        }
      }
      if (!noPrefixName) {
        noPrefixName = name.trim();
      }

      return noPrefixName;
    };

    try {
      let person1FirstName = cutPrefix(_.get(ctx.values, 'customer.contact_points.person1.firstname')) || '';
      let person2FirstName = cutPrefix(_.get(ctx.values, 'customer.contact_points.person2.firstname')) || '';
      let person3FirstName = cutPrefix(_.get(ctx.values, 'customer.contact_points.person3.firstname')) || '';
      let person1Tel = _.get(ctx.values, 'customer.contact_points.person1.tel') || '';
      let person2Tel = _.get(ctx.values, 'customer.contact_points.person2.tel') || '';
      let person3Tel = _.get(ctx.values, 'customer.contact_points.person3.tel') || '';

      switch (true) {
        case person1FirstName === '' || person2FirstName === '' || person3FirstName === '':
          setCheckFirstName('');
          break;
        case person1FirstName !== person2FirstName && person1FirstName !== person3FirstName && person2FirstName !== person3FirstName:
          setCheckFirstName('');// all firstnames are the same
          break;
        case person1FirstName === person2FirstName && person2FirstName === person3FirstName && person1FirstName === person3FirstName:
          setCheckFirstName('ชื่อจริงทั้งสามชื่อซ้ำกัน');
          break;
        case person1FirstName === person2FirstName:
          setCheckFirstName('ชื่อจริง1 ซ้ำกับ ชื่อจริง2');
          break;
        case person1FirstName === person3FirstName:
          setCheckFirstName('ชื่อจริง1 ซ้ำกับ ชื่อจริง3');
          break;
        case person2FirstName === person3FirstName:
          setCheckFirstName('ชื่อจริง2 ซ้ำกับ ชื่อจริง3');
          break;
      }

      switch (true) {
        case person1Tel === '' || person2Tel === '' || person3Tel === '':
          setCheckTel('');
          break;
        case person1Tel !== person2Tel && person1Tel !== person3Tel && person2Tel !== person3Tel:
          setCheckTel(''); // all tels are the same
          break;
        case person1Tel === person2Tel && person2Tel === person3Tel && person1Tel === person3Tel:
          setCheckTel('เบอร์โทรศัพท์ทั้งสามซ้ำกัน');
          break;
        case person1Tel === person2Tel:
          setCheckTel('เบอร์โทรศัพท์1 ซ้ำกับ เบอร์โทรศัพท์2');
          break;
        case person1Tel === person3Tel:
          setCheckTel('เบอร์โทรศัพท์1 ซ้ำกับ เบอร์โทรศัพท์3');
          break;
        case person2Tel === person3Tel:
          setCheckTel('เบอร์โทรศัพท์2 ซ้ำกับ เบอร์โทรศัพท์3');
          break;
      }
    } catch (e) { }
  };

  useEffect(() => {
    var token = setInterval(doUpdate, 200);
    return () => clearInterval(token);
  }, [ctx]);

  return (
    <>
      <div>
        <p className="text-remark">{checkFirstName}</p>
        <p className="text-remark">{checkTel}</p>
      </div>
    </>
  );
};

export default SameContactPoints;
