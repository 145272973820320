import {ButtonTheme} from '../../../../components/buttons';
import {Popconfirm} from 'antd';

export const TrackingNoColumn = (onConfirmRemove) => {
  return [
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'ContractNo',
      width: '120px',
      align: 'center',
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'fullName',
      width: '200px',
      align: 'left',
    },
    {
      title: 'Tracking No.',
      dataIndex: 'TrackingNo',
      width: '120px',
      align: 'center',
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '80px',
      align: 'center',
      render: (_text, record) => {
        return <ButtonTheme useFor="REMOVE" onClick={() => onConfirmRemove(record)} />;
      },
    },
  ];
};

export const ResultTrackingColumn = () => {
  return [
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contractNo',
      width: '120px',
      align: 'center',
    },
    // {
    //   title: 'Tracking No,',
    //   dataIndex: 'trackingNo',
    //   width: '150px',
    //   align: 'left',
    // },
    {
      title: 'ผลลัพธ์',
      dataIndex: 'result',
      width: '250px',
      align: 'center',
    },
  ];
};
