import * as TYPES from '../types/customer';
import * as API from '../api/customer';

export const getCustomerList = (params) => async (dispatch) => {
    dispatch({ type: TYPES.GET_LIST_CUSTOMER_REQ });
    try {
        const response = await API.getCustomerList(params);
        if (response.status === 200) {
            dispatch({ type: TYPES.GET_LIST_CUSTOMER_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.GET_LIST_CUSTOMER_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.GET_LIST_CUSTOMER_FAIL, payload: error });
    }
};

export const getCustomerInfo = (id) => async (dispatch) => {
    dispatch({ type: TYPES.GET_CUSTOMER_INFO_REQ });
    try {
        const response = await API.getCustomerInfo(id);
        if (response.status === 200) {
            dispatch({ type: TYPES.GET_CUSTOMER_INFO_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.GET_CUSTOMER_INFO_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.GET_CUSTOMER_INFO_FAIL, payload: error });
    }
};