export const MISTAKE_TERMINATED_SEARCH_FORM = ({options}) => {
  return [
    {
      key: '0',
      span: 6,
      name: 'contract_no',
      label: 'เลขที่สัญญา',
      placeholder: 'กรอกเลขที่สัญญา',
      type: 'INPUT',
    },
    {
      key: '1',
      span: 6,
      name: 'start_date',
      label: 'เริ่มวันที่ค้นหา',
      placeholder: 'เลือกวันที่เริ่มต้น',
      type: 'DATE_PICKER',
    },
    {
      key: '2',
      span: 6,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
    },
    {
      key: '3',
      span: 6,
      name: 'condition',
      label: 'ประเภทหนังสือ',
      placeholder: 'เลือกประเภทหนังสือ',
      type: 'SELECT',
      options: options?.condition,
      allowClear: true,
      value: '',
    },
  ];
};

export const TERMINATED_CONTRACT_FORM = ({options, onChangeType, typeValue}) => {
  return [
    {
      key: '1',
      span: 24,
      name: 'type',
      label: 'ประเภทหนังสือยกเลิกสัญญา',
      placeholder: 'ประเภทหนังสือยกเลิกสัญญา',
      type: 'SELECT',
      options: options?.condition,
      onChangeCustom: onChangeType,
      allowClear: true,
      value: '',
      rules: {
        required: true,
      },
    },

    {
      key: '2',
      span: 24,
      name: 'remark',
      label: 'ระบุข้อมูล',
      placeholder: 'ระบุข้อมูล',
      type: 'INPUT_AREA',
      hidden: !(typeValue === 'OTHER'),
      value: '',
      rules: {
        required: typeValue === 'OTHER',
      },
    },
  ];
};
