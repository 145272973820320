import {Modal, Table,} from 'antd';
import { ResultTrackingColumn } from '../../../screens/trackingno/add_trackingno/columns';
import { TableLayout } from '../../pageLayout/TableLayout';
import { TextLarge } from '../../forms/text';
import { color } from '../../../resources/color';
import {CheckCircleOutlined} from '@ant-design/icons';

const ResultUploadTrackingNoModal = ({ visible, onClose, data}) => {
  return (
    <Modal title={null} open={visible} onCancel={onClose} width={920} footer={null} destroyOnClose={true} maskClosable={false}>
      <div style={{textAlign:'center'}}> <CheckCircleOutlined style={{fontSize:'42px',color:color.success}}/>
      <TextLarge text='บันทึกสำเร็จ' align='center' color={color.success}/></div>
    
       <TableLayout props={{columns: ResultTrackingColumn(), dataSource: data, title: 'รายละเอียด'}} />
    </Modal>
  );
};

export default ResultUploadTrackingNoModal;
