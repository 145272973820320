export const ContractHistoryTypeLockColumn = () => {
  return [
    {
      title: 'วันที่ทำรายการ',
      dataIndex: 'created_date',
      key: 'created_date',
      width: '15%',
      render: (item) => {
        return item
      }
    },
    {
      title: 'รูปแบบการล็อกเครื่อง',
      dataIndex: 'type_lock_name',
      key: 'type_lock_name',
      width: '20%'
    },
    {
      title: 'ผู้ให้บริการ',
      dataIndex: 'mdm_provider_name',
      key: 'mdm_provider_name',
      width: '20%'
    },
    {
      title: 'Site MDM',
      dataIndex: 'mdm_site_name',
      key: 'mdm_site_name',
      width: '20%'
    },
    {
      title: 'บันทึกโดย',
      dataIndex: 'created_name',
      key: 'created_name',
      width: '25%'
    }
  ]
}
