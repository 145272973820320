import React from 'react';
import {Tag} from 'antd';
import {color} from '../../../resources/color';
import {convertStrToFormat} from '../../../functions/fn';
import {ButtonTheme} from '../../../components/buttons';

export const FGFApproveCommissionListColumn = ({openModal}) => {
  return [
    {
      title: 'เลขบัตรประชาชาน',
      dataIndex: 'id_card',
      width: '100px',
      align: 'center',
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'tel',
      width: '100px',
      align: 'center',
    },
    {
      title: 'วงเงินที่ทำรายการหลังหักค่าธรรมเนียม',
      dataIndex: 'amount',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return convertStrToFormat(record.amount, 'money');
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '150px',
      align: 'center',
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return (
          <Tag color={record.status === 'WAIT_FINANCE_APPROVE' ? color.tranfer : color.disabled}>
            {text === 'WAIT_FINANCE_APPROVE' ? 'รอการเงินอนุมัติ' : text}
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        return (
          <>
            <ButtonTheme
              useFor="VERIFY"
              style={{color: 'black', borderRadius: '5px', width: '130px'}}
              title="ตรวจสอบ"
              onClick={() => openModal(record, 'verify')}
            />
          </>
        );
      },
    },
  ];
};
