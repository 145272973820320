/* eslint-disable no-unused-vars */
import {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Skeleton, Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import {Table, Td, Tr, Th} from './styled-component';
import _ from 'lodash';
import { getContractSummary, getPeriods } from '../../redux/api/contract';

const getAmount = (transactions, paidType) => {
  switch (paidType) {
    case 'CAPITAL_INSTALLMENT': {
      let transaction = _.find(transactions, {paid_type: 'INSTALLMENT'});
      return transaction?.capital_amount ? new Intl.NumberFormat('th-TH').format(transaction?.capital_amount) : 0;
    }
    case 'INTEREST_INSTALLMENT': {
      let transaction = _.find(transactions, {paid_type: 'INSTALLMENT'});
      return transaction?.interest_amount ? new Intl.NumberFormat('th-TH').format(transaction?.interest_amount) : 0;
    }
    case 'CHARGE_OVERDUED': {
      let transaction = _.find(transactions, {paid_type: paidType});
      return transaction?.amount ? new Intl.NumberFormat('th-TH').format(transaction?.amount) : 0;
    }
    case 'CHARGE_WARNING': {
      let transaction = _.find(transactions, {paid_type: paidType});
      return transaction?.amount ? new Intl.NumberFormat('th-TH').format(transaction?.amount) : 0;
    }
    case 'DOWN': {
      let transaction = _.find(transactions, {paid_type: paidType});
      return transaction?.capital_amount ? new Intl.NumberFormat('th-TH').format(transaction?.capital_amount) : 0;
    }
    default:
      return 0;
  }
};

const getRowColor = (status) => {
    switch(status) {
        case "COMPLETED": return "#D8F2DB";
        case "OVERDUED": return "#F6D9D4";
        default: return "unset"
    }
};

const ContractTable = (props) => {
  const {periods, isLoad} = props;
  const [showTrans, setShowTrans] = useState([]);

  const toggleTransactionList = (periodId) => {
    if (showTrans.includes(periodId)) {
      showTrans.splice(showTrans.indexOf(periodId), 1);
      setShowTrans([...showTrans]);
    } else {
      setShowTrans([...showTrans, periodId]);
    }
  };

// setSelectedContract(periods);

  return (
    <Table>
      <thead>
        <tr>
          <Th colSpan="9" textAlign="center">
            ค่างวด/การชำระ
          </Th>
          <Th colSpan="3" textAlign="center">
            ค้างชำระ
          </Th>
        </tr>
        <tr>
          <Th>งวดที่</Th>
          <Th>ครบกำหนดชำระ</Th>
          <Th>ค่างวด</Th>
          <Th>เงินต้น</Th>
          <Th>ดอกเบี้ย</Th>
          <Th>ล่าช้า</Th>
          <Th>ค่าปรับ</Th>
          <Th>ค่าทวงถาม</Th>
          <Th>ยอดชำระ</Th>
          <Th>ค้างชำระ</Th>
          <Th>ค้างเงินต้น</Th>
          <Th>ค้างดอกเบี้ย</Th>
        </tr>
      </thead>
      <tbody>
        {periods && periods.map((period) => {
            let periodRow = [
              <Tr key={period?._id} color={getRowColor(period?.status)}>
                <Td>
                  <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {period?.period}{' '}
                    {period.transaction && isLoad ? <LoadingOutlined />
                    : period.transaction && period.transaction.length > 0 ? ( period?._id ?
                      <Button onClick={() => toggleTransactionList(period?._id)}>{showTrans.includes(period?._id) ? 'ซ่อนรายการ' : 'ดูรายการ'}</Button> : <Skeleton active />
                    ) : ""}
                  </span>
                </Td>
                <Td>{new Intl.DateTimeFormat('th-TH').format(new Date(period?.paid_date))}</Td>
                <Td textAlign="right">{new Intl.NumberFormat('th-TH').format(period?.amount)}</Td>
                <Td textAlign="right">{new Intl.NumberFormat('th-TH').format(period?.capital_amount)}</Td>
                <Td textAlign="right">{new Intl.NumberFormat('th-TH').format(period?.interest_amount)}</Td>
                <Td>{period?.overdued_days}</Td>
                <Td textAlign="right">{new Intl.NumberFormat('th-TH').format(period?.charge_overdue)}</Td>
                <Td textAlign="right">{new Intl.NumberFormat('th-TH').format(period?.charge_warning)}</Td>
                <Td textAlign="right">-</Td>
                <Td textAlign="right" bold>{new Intl.NumberFormat('th-TH').format(period?.remain_amount)}</Td>
                <Td textAlign="right" bold>{new Intl.NumberFormat('th-TH').format(period?.remain_capital_amount)}</Td>
                <Td textAlign="right" bold>{new Intl.NumberFormat('th-TH').format(period?.remain_interest_amount)}</Td>
              </Tr>,
            ];
            period?.transaction.map((t) => {
              return periodRow.push(
                <Tr key={t?._id} period_id={period?._id} hide={!showTrans.includes(period?._id)}>
                  <Td>{t?.status}</Td>
                  <Td>{new Intl.DateTimeFormat('th-TH').format(new Date(t?.transaction_date))}</Td>
                  <Td>{t?.receipt_no}</Td>
                  <Td textAlign="right">{getAmount(t?.transactions, period.period === 0 ? 'DOWN' : 'CAPITAL_INSTALLMENT')}</Td>
                  <Td textAlign="right">{getAmount(t?.transactions, 'INTEREST_INSTALLMENT')}</Td>
                  <Td>{t?.overdued_days}</Td>
                  <Td textAlign="right">{getAmount(t?.transactions, 'CHARGE_OVERDUED')}</Td>
                  <Td textAlign="right">{getAmount(t?.transactions, 'CHARGE_WARNING')}</Td>
                  <Td textAlign="right">{new Intl.NumberFormat('th-TH').format(t?.total_amount_period)}</Td>
                  <Td textAlign="right">-</Td>
                  <Td textAlign="right">-</Td>
                  <Td textAlign="right">-</Td>
                </Tr>,
              );
            });

            return periodRow;
          })}
      </tbody>
    </Table>
  );
};

ContractTable.propTypes = {
  periods: PropTypes.array,
};

ContractTable.defaultProps = {
  periods: [],
};

export default ContractTable;
