import styled from 'styled-components';

export const CheckboxStyle = styled.span`
  .ant-checkbox-wrapper {
    span:not([class]) {
      padding-right: 12px;
    }

    .ant-checkbox {
      &:hover {
        border-color: unset !important;
      }
      .ant-checkbox-checked {
        width: 20px;
        height: 20px;
      }

      /* ขนาดและตวามขอบโค้ง ของ Checkbox  */
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border-radius: 5px;
      }

      /* ตัว Checked true */
      .ant-checkbox-inner::after {
        left: 5px;
      }
    }

    .ant-checkbox-checked:after {
      border: unset;
    }

    .ant-checkbox-disabled {
      .ant-checkbox-inner::after {
        left: 5px;
      }
    }

    .ant-checkbox-disabled + span {
      line-height: 2;
    }
  }
`;
