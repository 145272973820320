import { Select } from 'formik-antd';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getOptionRefinanceInterestRate } from './api';
import { useFormikContext } from 'formik';
import { Input } from 'formik-antd';

const PriceGetPeriod = (props) => {
	// const [choices, setChoices] = useState([]);
	const ctx = useFormikContext();

	useEffect(() => {
		(async () => {
			if (ctx.values.contract?.price.length >= 4) {
				const data = await getOptionRefinanceInterestRate('refinance_interests', ctx.values.contract.price);
				localStorage.setItem('refinancePeriod', JSON.stringify(data))
				ctx.setFieldValue('contract.contract.periods_fin', '');
			}
		})();
	}, [ctx.values.contract.price]);
	return (
		<Input {...props} placeholder={'กรอกราคา'} />
	)
};

PriceGetPeriod.propTypes = {
	name: PropTypes.string.isRequired,
};

export default PriceGetPeriod;
