import React from 'react';
import { Divider } from 'antd';
import { InfoLayout } from './InfoLayout';
import { SearchLayout } from './SearchLayout';
import { TableLayout } from './TableLayout';

export const PageLayout = ({ infoLayout, searchLayout, tableLayout, content, action, marginCustom, paddingCustom }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {infoLayout && (
        <>
          <InfoLayout props={{ ...infoLayout }} />
          <Divider style={{ backgroundColor: '#e8e9eb' }} />
        </>
      )}
      {(searchLayout || content || tableLayout || action) && (
        <div style={{ marginTop: !!marginCustom ? marginCustom : '2%', padding: !!paddingCustom ? paddingCustom : '1%', backgroundColor: 'white', width: '100%', borderRadius: '15px' }}>
          {searchLayout && <SearchLayout props={{ ...searchLayout }} />}
          {content}
          {tableLayout && <TableLayout props={{ ...tableLayout }} />}
          {action}
        </div>
      )}
    </div>
  );
};
