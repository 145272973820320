import axios from 'axios';
import { API_URL } from '../../env.js';

export const getProducts = async (params) => {
    try {
        return await axios.get(`${API_URL}/admin/product/${params}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const getProductInfo = async (code) => {
    try {
        return await axios.get(`${API_URL}/admin/product/${code}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const updateProduct = async (code, body) => {
    try {
        return await axios.put(`${API_URL}/admin/product/${code}/update`, body, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'multipart/form-data',
            },
        });
    } catch (error) {
        return error;
    }
};