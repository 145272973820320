import { Modal, Typography, Row, Spin } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { ButtonTheme } from '../../buttons';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { EditTrackerForm } from '../../../constants/global/actionform';
import { TextSmall } from '../../forms/text';
import { GET, PATCH,POST, GET_TRACKING_TASK,GET_TRACKER_BY_TERMINATE_ID  } from '../../../services/index';
import { convertStatusENtoTH } from '../../../constants/status/status';

const { Title } = Typography;

const EditTrackingTaskModal = ({ visible, onClose, data }) => {
  const [trackers, setTrackers] = useState([]);
  const [jobType, setJobType] = useState("-");
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();

  const fetchOption = useCallback(async () => {
    setIsLoading(true);
    try {
      const payload = {
        terminate_id:data._id
      };
      let res = await POST(GET_TRACKER_BY_TERMINATE_ID,payload);
      const { success, result, jobType, jobTypeRemark } = res;
      if (success) {
        let trackersList = [];
        trackersList.push(result);
        setTrackers(trackersList);
        if(jobType.length > 1){
          setJobType(convertStatusENtoTH(jobType, 'jobType') + " " + jobTypeRemark)
        }

        console.log(res.length)
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  

  const onSubmit = async (params) => {
    setIsLoading(true);
    const obj = {
      contractNO: data.contractNO,
      trackingFee: params.trackingFee,
      terminate_id:data._id
    };
    try {
      let res = await PATCH(GET_TRACKING_TASK, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: 'เปลี่ยนค่าติดตามสำเร็จ',
          afterClose: () => {
            onClose();
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOption();
  }, [data, fetchOption]);

  return (
    <Modal
      title={<Title level={3}>{`แก้ไขค่าติดตาม ${data.contractNO}`}</Title>}
      open={visible}
      onCancel={onClose}
      width={500}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}>
      {isLoading ? (
        <Row width="100%" align="center">
          <Spin />
        </Row>
      ) : (
        <>
          <TextSmall text={`ประเภทงาน : ${jobType}`} />
          <TextSmall text={`ชื่อผู้ติดตาม : ${trackers.join(', ')}`} />
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <RenderForm
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              formList={EditTrackerForm({ defaultValue: { trackingFee: data.trackingFee } })}
              renderButton={
                <>
                  <ContainerButton right>
                    <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" />
                    <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
                  </ContainerButton>
                </>
              }
            />
          </form>
        </>
      )}
    </Modal>
  );
};

export default EditTrackingTaskModal;
