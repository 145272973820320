import { useDispatch, useSelector } from 'react-redux';
import * as todoActions from '../redux/actions/branch';

export const useBranch = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.branch_info.loading);
    const error = useSelector((state) => state.branch_info.branchInfo?.error);
    const branchList = useSelector((state) => state?.branch_info?.branchInfo?.data?.result) || [];

    const loadingBranchSpecific = useSelector((state) => state.branch_specific_info.loading);
    const errorBranchSpecific = useSelector((state) => state.branch_specific_info.branchSpecificInfo?.error);
    const branchSpecificList = useSelector((state) => state?.branch_specific_info?.branchSpecificInfo?.data?.result) || [];

    async function dispatchOptionBranch(data) {
        dispatch(todoActions.getBranch(data));
    }

    async function dispatchOptionBranchSpecific(data) {
        dispatch(todoActions.getBranchSpecific(data));
    }

    return {
        loading,
        error,
        dispatchOptionBranch,
        branchList,
        loadingBranchSpecific,
        errorBranchSpecific,
        dispatchOptionBranchSpecific,
        branchSpecificList,
    };
};
