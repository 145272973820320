import styled from 'styled-components'

export const DatatableLogStyle = styled.div`
  .ant-table-wrapper {
    border-top: 1px solid black;

    table > thead > tr > th,
    td {
      padding: 8px;
      background: white;
      color: black;
      border-right: 1px solid black;
      border-bottom: 1px solid black;

      &:first-child {
        border-left: 1px solid black;
      }
    }
  }
`
