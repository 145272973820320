export const validateFeeValue = (amount) => {
  const isValidNumber = /^[0-9]*$/.test(amount);
  if (!isValidNumber) {
    return 'จำนวนเงินไม่ถูกต้อง';
  }
  if (amount < 0) {
    return 'จำนวนเงินไม่ถูกต้องน้อยที่สุด 0 บาท';
  }
  return null;
};
