// /* eslint no-use-before-define: 0 */
import React from 'react';
import {Row,Col,Table } from 'antd';
import {columnsPickNonSerial} from './columnsTable'
import moment from 'moment';
import Barcode from 'react-barcode';



const TemplatePrintPickNonSerial = React.forwardRef((props, ref) => {

const {documentNo,updateUser,branchFromName,branchToName,updateDate,remark,items,total} = props.data

const header = <>
  <Row gutter={16} className='margin-row'>
    <Col className="gutter-row" span={24} style={{textAlign:"right"}}>
        <Barcode value={documentNo || ''}/>
      {/* <span className='text-print'>{`เลขที่เอกสาร : ${documentNo}`}</span> */}
    </Col>
  </Row>
  <h2 style={{textAlign:"center",marginBottom:"30px"}}>{"ใบส่งสินค้า"}</h2>
  <Row gutter={16} className='margin-row'> 
    <Col className="gutter-row" span={12}>
        <span className='text-print'>{`ผู้จัดของ : ${updateUser}`}</span>
    </Col>
    <Col className="gutter-row" span={12}>
        <span className='text-print'>{`สาขาต้นทาง: ${branchFromName}`}</span>
    </Col>
  </Row>
  <Row gutter={16} className='margin-row'>
    <Col className="gutter-row" span={12}>
        <span className='text-print'>{`ผู้ตรวจสอบ : ${updateUser}`}</span>
    </Col>
    <Col className="gutter-row" span={12}>
        <span className='text-print'>{`สาขาปลายทาง: ${branchToName}`}</span>
    </Col>
  </Row>
  <Row gutter={16} className='margin-row'>
    <Col className="gutter-row" span={24}>
        <span className='text-print'>{`วันเวลาที่แก้ไขล่าสุด: ${moment(updateDate).format('YYYY-MM-DD HH:mm')}`}</span>
    </Col>
  </Row>
  <Row gutter={16} style={{marginBottom:'20px'}}>
    <Col className="gutter-row" span={24}>
        <span className='text-print'>{`จำนวน: ${total} ชิ้น`}</span>
    </Col>
  </Row>
  <Row gutter={16} style={{marginBottom:'20px'}}>
    <Col className="gutter-row" span={24}>
      <span className='text-print'>{`หมายเหตุ: ${remark}`}</span>
    </Col>
  </Row>
</>
  return (
    <div  ref={ref}>
      <div className="parent">
        { header}
        <Table columns={columnsPickNonSerial} dataSource={items} pagination={false}/>
      </div>
       <hr/>
     <div className="break" />
        <div className="parent">
        { header}
        </div>
    </div>
  );
})

export default TemplatePrintPickNonSerial
