import React from 'react';
import { Image } from 'antd';
import noImage from '../../assets/images/no_image.jpeg';

export const ViewImage = ({ item }) => {
  // return <Image
  //   width={item.width ? item.width : 200}
  //   height={item.height ? item.height : 200}
  //   src={item.defaultValue ? item.defaultValue : item.value ? item.value : noImage}
  // />
  return (
    <div style={item.center ? { textAlign: 'center' } : {}}>
      <Image
        width={item.width ? item.width : 200}
        height={item.height ? item.height : 200}
        src={item.defaultValue ? item.defaultValue : item.value ? item.value : noImage}
      />
    </div>
  );
};

export const ViewImageGroup = ({ item }) => {
  return (
    <Image.PreviewGroup>
      {item.imageList.length > 0 && item.imageList.map((el, i) => {
        return <Image height={100} src={el} key={i} />;
      })}
    </Image.PreviewGroup>
  );
};
