import React, {useState, useEffect} from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import {Modal, Card, Space, Row, Spin, Button} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {auth} from '../../utils/firebase';
import {useCurrentState} from './../../utils/hook';
// import {ButtonTheme} from '../../components/buttons';
import {color} from '../../resources/color';
import {Icon} from '../../resources/icon';
import {DIFRecMoneyColumn} from './components/columnTableForm';
import {DIFRecMoneySearchForm} from './components/searchForm';
import {GET, GET_DIFREC_MONEY, PUT, EDIT_IMPORT_DIFREC_MONEY} from '../../services';
import moment from 'moment';

const DIFRecMoney = () => {
  const [loading, setLoading] = useState(false);
  const {user} = useCurrentState(auth);
  const [selected, setSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getDataDIFRecMoney({DocNo: '', PaymentType: '', RefDate: '', IsImport: ''});
  }, []);

  const optionPaymentType = [
    {value: '0', label: 'เงินสด'},
    {value: '1', label: 'บัตร'},
    {value: '2', label: 'เช็ค'},
    {value: '3', label: 'เงินโอน'},
    {value: '4', label: 'เงินสดย่อย'},
    {value: '5', label: 'เงินเกินพัก'},
    {value: '6', label: 'เงินเกินหัก'},
    {value: '7', label: 'รายได้อื่นๆ'},
    {value: '8', label: 'ค่าใช้จ่ายอื่นๆ'},
  ];

  const optionSaveFrom = [
    {value: '0', label: 'ขายสด'},
    {value: '1', label: 'ซื้อสด'},
    {value: '2', label: 'รับชำระหนี้(บิลขายมีเครดิต)'},
  ];

  const optionImportStatus = [
    {value: '0', label: 'รอ Import'},
    {value: '1', label: 'Import เสร็จสิ้น'},
    {value: '2', label: 'Import ไม่สำเร็จ'},
  ];

  const getDataDIFRecMoney = async (data) => {
    data.DocNo = data?.DocNo || '';
    data.PaymentType = data?.PaymentType || '';
    data.RefDate = data.RefDate ? moment(data.RefDate).format('YYYY-MM-DD') : '';
    data.IsImport = data?.IsImport || '';

    const {DocNo, PaymentType, RefDate, IsImport} = data;
    setLoading(true);
    try {
      const res = await GET(GET_DIFREC_MONEY(DocNo, PaymentType, RefDate, IsImport));
      const {success, data} = res;
      if (success) {
        data.map((item, index) => {
          item.key = index + 1;
          item.IsImport.code = item.IsImport.code.toString();
          item.PaymentType.code = item.PaymentType.code.toString();
        });
        setDataSource(data);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const openConfirm = () => {
    if (selected && selected.length !== 0) {
      Modal.confirm({
        title: 'ยืนยัน',
        icon: <Icon.warningAlert />,
        content: 'ยืนยันการเปลี่ยนสถานะเป็น รอ Import ข้อมูล',
        onOk() {
          reImportData();
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    } else {
      Modal.warning({
        title: 'กรุณาเลือกข้อมูลที่ต้องการ Import',
      });
    }
  };

  const reImportData = async () => {
    setLoading(true);
    try {
      const res = await PUT(EDIT_IMPORT_DIFREC_MONEY, {DocNo: selected});
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            getDataDIFRecMoney({});
            clearCheckbox()
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const clearCheckbox = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'ประเภทการรับเงิน',
              icon: <SettingOutlined />,
              spanSearch: 24,
              formSearch: DIFRecMoneySearchForm({options: {optionPaymentType, optionImportStatus}}),
              onSearch: (data) => getDataDIFRecMoney(data),
            }}
            tableLayout={{
              columns: DIFRecMoneyColumn({options: {optionPaymentType, optionSaveFrom, optionImportStatus}}),
              dataSource: dataSource,
              rowSelection: {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(
                    selectedRows.map((row) => {
                      return row.DocNo;
                    }),
                  );
                  setSelectedRowKeys(selectedRowKeys)
                },
                getCheckboxProps: (record) => {
                  if (record.IsImport.code !== '2') {
                    return {
                      disabled: true,
                    };
                  }
                },
              },
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="space-between">
                  <Space>
                    <Button
                      type="primary"
                      style={{background: color.tranfer, borderColor: color.tranfer}}
                      size="large"
                      icon={<Icon.tranfer />}
                      onClick={() => openConfirm()}>
                      Import ข้อมูล
                    </Button>
                  </Space>
                </Row>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default DIFRecMoney;
