import { Table } from "antd";
import { ReportTrackingFeeColumn } from "../../constants/global/columnTableForm";

export const SummaryRow = (props) => {
    const { size, colSpanIndex, data } = props
    let rows = [];
    for (let i = 0; i < size; i++) {
        {
            colSpanIndex ? colSpanIndex.map((el) => {
                if (i === el.index) {
                    rows.push(<Table.Summary.Cell colSpan={el.span} index={i}>{data[i]}</Table.Summary.Cell>);
                } else {
                    rows.push(<Table.Summary.Cell colSpan={1} index={i}>{data[i]}</Table.Summary.Cell>);
                }
            }) : rows.push(<Table.Summary.Cell colSpan={1} index={i}>{data[i]}</Table.Summary.Cell>);
        }

    }
    return (
        <Table.Summary fixed>
            <Table.Summary.Row style={{ textAlign: 'right' }}>
                {rows}
            </Table.Summary.Row>
        </Table.Summary>
    )
}