/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Card, Spin, Modal as ModalAnt, Button, Space, Col, Row, Table, Typography, Divider } from 'antd';
import { getTrackPaymentList, sendNotificationTrack, getTrackDataExport } from '../../utils/auto-admin/api';
import { auth } from '../../utils/firebase';
import { useAuthState } from '../../utils/hook';
import { GET, SUMMARY_TRACK } from '../../services/index';
import { TrackPaymentListSearchForm } from '../../constants/global/SearchForm';
import { TrackPaymentListColumn } from '../../constants/global/columnTableForm';
import { PhoneOutlined, ReloadOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../components/buttons';
import { convertStrToFormat, removeNewlineText } from '../../functions/fn';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/modal';
import { CSVLink } from 'react-csv';
import { getTrackStatus, getStageRange, getContactStatus } from './function';
import moment from 'moment';

const { Title, } = Typography;

const TrackPayment = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [loadingNotification, setLoadingNotification] = useState(false);
  const { user } = useAuthState(auth);
  const [summaryList, setSumarayList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const pageRef = useRef(1);
  const previousPageRef = useRef(1);
  const sizeRef = useRef(10);
  const [idCardState, setIdCardState] = useState(null);
  const [nameState, setNameState] = useState(null);
  const [telState, setTelState] = useState(null);
  const [statusState, setStatusState] = useState(null);
  const [stageState, setStageState] = useState(null);
  const [hasTokenOnlyState, setHasTokenOnlyState] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false)

  const [defaultSearch, setDefaultSearch] = useState({
    page: pageRef.current,
    size: sizeRef.current,
    id_card: '',
    tel: '',
    name: '',
    status: '',
    stage: '',
    has_token_only: '',
    statusTrackPayment: '',
  });

  useEffect(() => {
    setDefaultSearch({
      page: pageRef.current,
      size: sizeRef.current,
      id_card: '',
      tel: '',
      name: '',
      status: 'PENDING',
      stage: '',
      has_token_only: '',
      statusTrackPayment: 'PENDING',
    });
    getSummarytrack();
    getTrackPaymentListData({
      page: pageRef.current,
      size: sizeRef.current,
      id_card: '',
      tel: '',
      name: '',
      status: 'PENDING',
      stage: '',
      has_token_only: '',
    });
  }, []);

  const getSummarytrack = async () => {
    setLoadingSummary(true);
    try {
      const res = await GET(SUMMARY_TRACK);

      const summary = res.data.map((el) => {
        let colorCard = '';
        switch (el.stage) {
          case '60 วันขึ้นไป':
            colorCard = '#dc3545';
            break;
          case '46-60 วัน':
            colorCard = '#0EBE1C';
            break;
          case '15-30 วัน':
            colorCard = '#1890ff';
            break;
          case '31-45 วัน':
            colorCard = '#ffc107';
            break;
          case '1-14 วัน':
            colorCard = '#6c757d';
            break;
        }
        return { label: el.stage, value: el.count, color: colorCard };
      });
      setSumarayList(summary);
    } catch (err) {
      ModalAnt.error({ title: err.message });
    } finally {
      setLoadingSummary(false);
    }
  };

  const optionStage = [
    { label: '1-14', value: '1' },
    { label: '15-30', value: '2' },
    { label: '31-45', value: '3' },
    { label: '46-60', value: '4' },
    { label: '60+', value: '5' },
  ];

  const optionStatus = [
    { label: 'รอการติดตาม', value: 'PENDING' },
    { label: 'ติดตามแล้ว', value: 'TRACKED' },
    { label: 'ไม่มีงวดค้าง', value: 'NO_OVERDUED' },
    { label: 'ทั้งหมด', value: 'ALL' },
  ];

  const optionChannelApp = [
    { label: 'พร้อมแจ้งเตือน', value: 'true' },
    { label: 'ไม่พร้อมแจ้งเตือน', value: 'false' },
  ];

  const getTrackPaymentListData = async (value) => {
    value.page = value?.page || 1;
    value.size = value?.size || 10;

    value.stage = value?.stage || '';
    value.name = value?.name || '';
    value.tel = value?.tel || '';
    value.id_card = value?.id_card || '';
    value.has_token_only = value?.has_token_only || '';

    value.reqfrom = value?.reqfrom || '';

    pageRef.current = value.page;
    sizeRef.current = value.size;

    const { page, size, status, stage, id_card, name, tel, has_token_only } = value;

    setLoading(true);

    setIdCardState(id_card);
    setTelState(tel);
    setNameState(name);
    setStageState(stage);
    setStatusState(status);
    setHasTokenOnlyState(has_token_only);

    if (status === null) {
      value.status = 'PENDING';
      setDefaultSearch({
        page: pageRef.current,
        size: sizeRef.current,
        id_card: '',
        tel: '',
        name: '',
        status: 'PENDING',
        stage: '',
        has_token_only: '',
        statusTrackPayment: 'PENDING',
      });
    } else {
      setDefaultSearch({
        page: pageRef.current,
        size: sizeRef.current,
        id_card: '',
        tel: '',
        name: '',
        status: status,
        stage: '',
        has_token_only: '',
        statusTrackPayment: status,
      });
    }

    let obj = {};
    if (value.reqfrom === 'PAGE_CHANGE') {
      obj = {
        ...value,
        total_item: totalSize,
      };
    } else {
      obj = {
        ...value,
      };
    }

    try {
      const res = await getTrackPaymentList(obj);
      const { message, data } = res;
      if (message === 'get track payment success') {
        setTotalSize(data.total_item);
        setTotalPage(data.total_page);
        const result = data.tracks;
        setDataSource(result);
      }
    } catch (err) {
      const { message, error } = err;
      ModalAnt.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const notificationSMS = async () => {
    setLoadingNotification(true);
    const newData = await sendNotificationTrack(selected, 'SMS', user.displayName);
    setLoadingNotification(false);
    ModalAnt.success({
      content: <div>แจ้งเตือนเรียบร้อย</div>,
    });
    window.location.reload();
  };

  const notificationAPP = async () => {
    const notReadyTrack = selectedRowData.filter((obj) => {
      return obj.has_token_device === false;
    });
    if (notReadyTrack.length > 0) {
      ModalAnt.info({
        content: (
          <div>
            มีลูกค้ายังไม่พร้อมรับการแจ้งเตือนผ่าน app
            <br />
            <br />
            {notReadyTrack.map((t) => (
              <p key={t._id} style={{ marginBottom: '5px' }}>
                {t.user?.general?.fullname}
              </p>
            ))}
          </div>
        ),
      });
    } else {
      setLoadingNotification(true);
      const newData = await sendNotificationTrack(selected, 'APP', user.displayName);
      setLoadingNotification(false);
      ModalAnt.success({
        content: <div>แจ้งเตือนเรียบร้อย</div>,
      });
      window.location.reload();
    }
  };

  const clearCheckbox = () => {
    setLoading(true);
    setLoadingSummary(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setSelected([]);
      setLoading(false);
      setLoadingSummary(false);
    }, 600);
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    previousPageRef.current = pageRef.current;
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      // setPageState(1)
      pageRef.current = 1;
    } else {
      // setPageState(pageData)
      pageRef.current = pageData;
    }
    sizeRef.current = sizeData;
    let id_card = idCardState;
    let name = nameState;
    let tel = telState;
    let status = statusState;
    let stage = stageState;
    let has_token_only = hasTokenOnlyState;
    let reqfrom = 'PAGE_CHANGE';
    const params = {
      page: pageRef.current,
      size: sizeRef.current,
      id_card: id_card || '',
      tel: tel || '',
      name: name || '',
      status: status || '',
      stage: stage || '',
      has_token_only: has_token_only || '',
      reqfrom: reqfrom,
    };
    getTrackPaymentListData(params);
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.forEach((val) => setValue(val.name, null));
    setIdCardState('');
    setNameState('');
    setTelState('');
    setStatusState('');
    setStageState('');
    setHasTokenOnlyState('');
    pageRef.current = 1;
    previousPageRef.current = 1;
    sizeRef.current = 10;
    setDefaultSearch({
      page: pageRef.current,
      size: sizeRef.current,
      id_card: '',
      tel: '',
      name: '',
      status: null,
      stage: '',
      has_token_only: '',
      statusTrackPayment: null,
    });
  };

  const onClearCustomField = () => {
    setDefaultSearch({ ...defaultSearch, status: null, statusTrackPayment: null });
  };

  const reloadData = async () => {
    setReloading(true);
    try {
      await getSummarytrack();
      await getTrackPaymentListData({
        page: pageRef.current,
        size: sizeRef.current,
        id_card: idCardState,
        tel: telState,
        name: nameState,
        status: statusState,
        stage: stageState,
        has_token_only: hasTokenOnlyState,
      });
    } catch (err) {
      ModalAnt.error({
        content: 'ไม่สามารถดึงข้อมูลใหม่ได้',
      });
    }

    if (loading === false && loadingSummary === false) {
      setReloading(false);
    } else if (loading === true && loadingSummary === false) {
      setReloading(true);
    } else {
      setReloading(true);
    }
  };

  const fetchDataForExport = async () => {
    setLoading(true)

    let params = {}
    if (idCardState === '' && statusState === '' && nameState === '' && telState === '' && stageState === '' && hasTokenOnlyState === '') {
      params = {}
    } else {
      // params += `?`
      if (idCardState) {
        params.id_card = idCardState;
      }
      if (statusState) {
        params.status = statusState;
      }
      if (nameState) {
        params.name = nameState;
      }
      if (telState) {
        params.tel = telState;
      }
      if (stageState) {
        params.stage = stageState
      }
      if (hasTokenOnlyState) {
        params.has_token_only = hasTokenOnlyState;
      }
    }

    try {
      const resExp = await getTrackDataExport(params);
      const { message, data } = resExp

      if (message === 'get track payment success') {
        const dataEx = data.map((el) => {
          return {
            'ชื่อ-นามสกุล': el.user.fullname,
            'สถานะ': getTrackStatus(el.status),
            'ยอดชำระ': convertStrToFormat(el.amount, 'money'),
            'เกินกำหนด(วัน)': getStageRange(el.stage),
            'ติดตาม(ครั้ง)': el.action_count,
            'ติดตามล่าสุด': el.action_date ? moment(el.action_date).format('DD/MM/YYYY') : null,
            'ติดตามครั้งถัดไป': el.next_action_date ? moment(el.next_action_date).format('DD/MM/YYYY') : null,
            'เลขที่สัญญา': el.contract_no[0],
            'ประเภทสัญญา': el.contract_type[0] === "REFINANCE" ? "ไอโฟนเเลกเงิน" : "ผ่อนสินค้า",
            'ช่องทางล่าสุด': el.channel,
            'สถานะล่าสุด': getContactStatus(el.contact_status),
            'หมายเหตุ': removeNewlineText(el.remark),
          };
        })
        setDataExport(dataEx)
      }

    } catch (err) {
      const { message, error } = err;

      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false)
      if (params !== '') {
        setVisibleModal(true)
      }
    }
  }


  const onClose = () => {
    setVisibleModal(false)
  }

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingSummary}>
          <PageLayout
            searchLayout={{
              title: 'เร่งรัด/ตามหนี้',
              icon: <PhoneOutlined />,
              spanSearch: 24,
              formSearch: TrackPaymentListSearchForm({ option: { optionStage, optionStatus, optionChannelApp }, onClearCustomField, defaultSearch }),
              onSearch: (data) => getTrackPaymentListData(data),
              dataSearchDefault: defaultSearch,
              onClearCustom: onClearCustom,
              extraContent: (
                <ButtonTheme loading={reloading} useFor="CUSTOM" title={'ดึงข้อมูลใหม่'} icon={<ReloadOutlined />} onClick={() => reloadData()} />
              ),
            }}
            content={
              <Card style={{ marginTop: '2%', borderRadius: '15px' }}>
                {/* <Spin tip="Loading..." spinning={loadingSummary}> */}
                <Title level={5}>จำนวนคนที่เกินกำหนดชำระหนี้</Title>
                <Divider style={{ color: '#f1f2f3' }} />
                <Row>
                  {summaryList.map((item, index) => {
                    return (
                      <Col key={index}>
                        <Card style={{ backgroundColor: item.color }} className="card-summary">
                          <span>{`${item.label} : `}</span>
                          <span style={{ fontSize: '22px' }}>{`${item.value} คน`}</span>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
                {/* </Spin> */}
              </Card>
            }
            tableLayout={{
              columns: TrackPaymentListColumn(),
              dataSource: dataSource,
              rowKey: (record) => record.user_id,
              rowSelection: {
                selections: [Table.SELECTION_NONE],
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(
                    selectedRows.map((row) => {
                      return row._id;
                    }),
                  );
                  setSelectedRowData(selectedRows);
                  setSelectedRowKeys(selectedRowKeys);
                },
              },
              extraContent: (
                <>
                  <ContainerButton right>
                    {selectedRowKeys.length > 0 && (
                      <>
                        <ButtonTheme
                          useFor="CUSTOM"
                          style={{ color: '#4B4B4B', borderColor: '#A1A1A1' }}
                          bgcolor="white"
                          title={'แจ้งเตือนผ่าน sms'}
                          disabled={!selectedRowKeys.length > 0}
                          onClick={() => notificationSMS()}
                        />

                        <ButtonTheme
                          useFor="CUSTOM"
                          style={{ color: '#4B4B4B', borderColor: '#A1A1A1' }}
                          bgcolor="white"
                          title={'แจ้งเตือนผ่าน app'}
                          disabled={!selectedRowKeys.length > 0}
                          onClick={() => notificationAPP()}
                        />
                      </>
                    )}
                    <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => fetchDataForExport()} />
                  </ContainerButton>
                </>
              ),
              pagination: {
                showSizeChanger: true,
                current: pageRef.current,
                pageSize: sizeRef.current,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef.current,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <Modal open={loadingNotification} noButton heightAuto style={{ backgroundColor: 'unset', boxShadow: 'unset' }}>
        <Loading />
      </Modal>
      <ModalAnt title={'ต้องการดาวน์โหลด Excel?'} open={visibleModal} width={550} onCancel={onClose} footer={null} destroyOnClose={true} maskClosable={false}>
        <ContainerButton right>
          <CSVLink data={dataExport} filename={"รายการติดตามหนี้"}><ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={() => onClose()} /></CSVLink>
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </ModalAnt>
    </Wrapper>
  );
};

export default TrackPayment;
