// /* eslint no-use-before-define: 0 */
import React from 'react';
import {Row,Col,Table } from 'antd';
import {columnsCheckStock } from './columnsTable'
import moment from 'moment';
import Barcode from 'react-barcode';

const TemplatePrintCheckStock = React.forwardRef((props, ref) => {

const { document_no,branch_name, all_stock,complete, over, missing, picks } = props.data

const header = <>
  <Row gutter={16} className='margin-row'>
    <Col className="gutter-row" span={24} style={{textAlign:"right"}}>
        <Barcode value={document_no}/>
      {/* <span className='text-print'>{`เลขที่เอกสาร : ${documentNo}`}</span> */}
    </Col>
  </Row>
  <h2 style={{textAlign:"center",marginBottom:"30px"}}>{"ใบสรุปนับสต็อก"}</h2>
  <Row gutter={16} className='margin-row'> 
    <Col className="gutter-row" span={12}>
        <span className='text-print'>{`ผู้สร้างเอกสาร : ${props?.data?.user_create?.name || ""} ${props?.data?.create_date && moment(props?.data?.create_date).format('YYYY-MM-DD HH:mm')}`}</span>
    </Col>
    <Col className="gutter-row" span={12}>
        <span className='text-print'>{`แก้ไขล่าสุด: ${props?.data?.user_update?.name || ""} ${props?.data?.update_date && moment(props?.data?.update_date).format('YYYY-MM-DD HH:mm')}`}</span>
    </Col>
  </Row>
  <Row gutter={16} className='margin-row'>
    <Col className="gutter-row" span={12}>
        <span className='text-print'>{`สาขา: ${branch_name}`}</span>
    </Col>
  </Row>
  <Row gutter={16} className='margin-row'>
    <Col className="gutter-row" span={6}>
        <span className='text-print'>{`จำนวนทั้งหมด : ${all_stock}`}</span>
    </Col>
    <Col className="gutter-row" span={6}>
        <span className='text-print'>{`ตรง: ${complete || 0}`}</span>
    </Col>
	<Col className="gutter-row" span={6}>
        <span className='text-print'>{`ขาด: ${missing || 0}`}</span>
    </Col>
	<Col className="gutter-row" span={6}>
        <span className='text-print'>{`เกิน: ${over || 0}`}</span>
    </Col>
  </Row>
  
</>
  return (
    <div  ref={ref}>
      <div className="parent">
        { header}
        <Table columns={columnsCheckStock} dataSource={picks} pagination={false}/>
      </div>
    </div>
  );
})

export default TemplatePrintCheckStock
