
import React, { useState } from 'react';
import Example from '../../assets/images/example_detail.jpg'
import { Modal, Button } from 'antd';


const ModalDetail = (props) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div>  <p className='text-link' onClick={showModal} >ดูรูปตัวอย่าง</p></div>
      <Modal title="ตัวอย่างรูปรายละเอียดเครื่อง" open={isModalVisible} onCancel={handleCancel} width={380} footer={null}>
        <img src={Example} style={{ width: '100%' }} />
      </Modal>
    </>
  );
};
export default ModalDetail;