import PropTypes from 'prop-types';
import {Table, Td, Th} from './styled-component';
import { convertStrToFormat } from '../../functions/fn';

const TrackLogTable = (props) => {
  const {logs} = props;
  return (
    <>
      <Table style={{width: '100%'}}>
        <thead>
          <tr>
            <Th>วันที่บันทึก</Th>
            <Th>ข้อความ</Th>
            <Th>ช่องทาง</Th>
            <Th>วันนัดชำระ</Th>
            <Th>ยอดชำระ</Th>
            <Th>บันทึกโดย</Th>
            <Th>สถานะ</Th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log?._id}>
              <Td>{new Intl.DateTimeFormat('th-TH', {dateStyle: 'short', timeStyle: 'short'}).format(new Date(log?.action_date))}</Td>
              <Td>{log?.remark}</Td>
              <Td>{log?.channel}</Td>
              <Td>{log?.next_action_date ? new Intl.DateTimeFormat('th-TH').format(new Date(log?.next_action_date)) : '-'}</Td>
              <Td>{convertStrToFormat(log?.amount,'money')}</Td>
              <Td>{log?.action_by}</Td>
              <Td>{log?.status_th}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

TrackLogTable.propTypes = {
  logs: PropTypes.array,
};

TrackLogTable.defaultProps = {
  logs: [],
};

export default TrackLogTable;
