import { Modal, Typography, Row, Spin } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { ButtonTheme } from '../../buttons';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { AddTrackerForm } from '../../../constants/global/actionform';
import { GET, PATCH, GET_TRACKERS, GET_TRACKING_TASK, GET_JOBTYPE } from '../../../services';

const { Title } = Typography;

const ChangeTrackerModal = ({ title, visible, onClose, data, options }) => {
  const [typeOtherJob, setTypeOtherJob] = useState()
  const [isLoading, setIsLoading] = useState(false);

  const {optionTracker, jobTypeOption} = options

  useEffect(() => {
    if(data) setTypeOtherJob(data?.job_type)
  }, [data])

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,

  } = useForm();

  const onSubmit = async (params) => {  
    setIsLoading(true);
    const obj = {
      contractNO: data.contractNO,
      jobType: params.jobType,
      jobTypeRemark: params.jobTypeRemark,
      trackerID: [params.trackerID], 
      trackingFee: params.trackingFee,
      terminate_id:data._id
    };
    try {
      let res = await PATCH(GET_TRACKING_TASK, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: 'เปลี่ยนผู้ติดตามสำเร็จ',
          afterClose: () => {
            onClose();
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (value, name) => {
    setTypeOtherJob(value);
  };

  useEffect(() => {
    if(getValues('jobType') !== "OTHERS") setValue('jobTypeRemark',"")
  }, [getValues('jobType')]);

  return (
    <Modal
      title={<Title level={3}>{`เปลี่ยนผู้ติดตาม ${data.contractNO}`}</Title>}
      open={visible}
      onCancel={onClose}
      width={500}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}>
      {isLoading ? (
        <Row width="100%" align="center">
          <Spin />
        </Row>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <RenderForm
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            formList={AddTrackerForm({ options: { optionTracker, contractType: jobTypeOption }, onChange, data: {type: typeOtherJob}, defaultValue: { trackingFee: data.trackingFee, tracker: data.trackers[0].trackerID, jobType: data.job_type, jobTypeRemark: data.job_type_remark } })}
            renderButton={
              <>
                <ContainerButton right>
                  <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" />
                  <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
                </ContainerButton>
              </>
            }
          />
        </form>
      )}
    </Modal>
  );
};

export default ChangeTrackerModal;
