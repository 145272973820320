export const LIST_PRODUCT_REQ = "LIST_PRODUCT_REQ";
export const LIST_PRODUCT_SUCCESS = "LIST_PRODUCT_SUCCESS";
export const LIST_PRODUCT_FAIL = "LIST_PRODUCT_FAIL";

export const GET_PRODUCT_REQ = "GET_PRODUCT_REQ";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL"

export const UPDATE_PRODUCT_REQ = "UPDATE_PRODUCT_REQ";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";