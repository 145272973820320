import lodashGet from 'lodash/get';

const errorMap = {
  'token is not valid': '',
  'permission denied': '',
  'user transaction not found': '',
  'update nothing': '',
  'status can be one of CONFIRMED, CANCELED': '',
  'transaction CONFIRMED already': '',
  'transaction CANCELED already': '',
  'discount must be number': '',
  'user is not blocked payment': 'ลูกค้ายังไม่ได้ถูกล็อคการชำระ ต้องการดำเนินการต่อหรือไม่?',
};

export const getErrorMessage = (key) => {
  return lodashGet(errorMap, key, key);
};
