import moment from 'moment';

export const DIFItemMasterSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'Code',
      label: 'รหัสสินค้า',
      placeholder: 'กรุณากรอกรหัสสินค้า',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 7,
      name: 'Name1',
      label: 'ชื่อสินค้า',
      placeholder: 'กรุณากรอกชื่อสินค้า',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '3',
      span: 5,
      name: 'StockType',
      label: 'ประเภทสินค้า',
      placeholder: 'เลือกประเภทสินค้า',
      type: 'SELECT',
      options: options.optionStockType,
      allowClear: true,
    },
    {
      key: '4',
      span: 5,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFAPMasterSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'Code',
      label: 'รหัสเจ้าหนี้',
      placeholder: 'กรุณากรอกรหัสเจ้าหนี้',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 5,
      name: 'Telephone',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร๋โทรศัพท์',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '3',
      span: 5,
      name: 'PersonType',
      label: 'ประเภทบุคคล',
      placeholder: 'เลือกประเภทบุคคล',
      type: 'SELECT',
      options: options.optionPersonType,
      allowClear: true,
    },
    {
      key: '4',
      span: 5,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFARMasterSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'Code',
      label: 'รหัสลูกหนี้',
      placeholder: 'กรุณากรอกรหัสลูกหนี้',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 5,
      name: 'Telephone',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร๋โทรศัพท์',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '3',
      span: 5,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFInvoiceSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'DocNo',
      label: 'เลขที่บิลขาย',
      placeholder: 'กรุณากรอกเลขที่บิลขาย',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 5,
      name: 'DocDate',
      label: 'วันที่บิลขาย',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      allowClear: true,
    },
    {
      key: '3',
      span: 4,
      name: 'BillType',
      label: 'ประเภทบิล',
      placeholder: 'เลือกประเภทบิล',
      type: 'SELECT',
      options: options.optionBillType,
      allowClear: true,
    },
    {
      key: '4',
      span: 5,
      name: 'ArCode',
      label: 'รหัสลูกค้า',
      placeholder: 'กรุณากรอกรหัสลูกค้า',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '5',
      span: 4,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFInvoiceDetailSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'DocNo',
      label: 'เลขที่บิลขาย',
      placeholder: 'กรุณากรอกเลขที่บิลขาย',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 5,
      name: 'ItemCode',
      label: 'รหัสสินค้า',
      placeholder: 'กรุณากรอกรหัสสินค้า',
      type: 'INPUT',
      allowClear: true,
    },
    {
      key: '3',
      span: 5,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFSerialTransSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'SerialNo',
      label: 'หมายเลข Serial Number',
      placeholder: 'กรุณากรอกหมายเลข Serial Number',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 4,
      name: 'DocNo',
      label: 'เลขที่บิลขาย',
      placeholder: 'กรุณากรอกเลขที่บิลขาย',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '3',
      span: 3,
      name: 'DocDate',
      label: 'วันที่บิลขาย',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    {
      key: '4',
      span: 4,
      name: 'SaveFrom',
      label: 'ประเภทรายการ',
      placeholder: 'เลือกประเภทรายการ',
      type: 'SELECT',
      options: options.optionSaveFrom,
      allowClear: true,
    },
    {
      key: '5',
      span: 4,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFReceiptSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'DocNo',
      label: 'เลขที่ใบรับชำระเงิน',
      placeholder: 'กรุณากรอกเลขที่ใบรับชำระเงิน',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 4,
      name: 'DocDate',
      label: 'วันที่รับชำระเงิน',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      allowClear: true,
    },
    {
      key: '3',
      span: 4,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFReceiptDetailSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'DocNo',
      label: 'เลขที่ใบรับชำระเงิน',
      placeholder: 'กรุณากรอกเลขที่ใบรับชำระเงิน',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 4,
      name: 'InvoiceNo',
      label: 'เลขที่ Invoice',
      placeholder: 'กรุณากรอกเลขที่ Invoice',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '3',
      span: 4,
      name: 'BillType',
      label: 'ประเภทบิล',
      placeholder: 'เลือกประเภทบิล',
      type: 'SELECT',
      options: options.optionBillType,
      allowClear: true,
    },
    {
      key: '4',
      span: 4,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFRecMoneySearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'DocNo',
      label: 'เลขที่ใบรับชำระเงิน',
      placeholder: 'กรุณากรอกเลขที่ใบรับชำระเงิน',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 4,
      name: 'PaymentType',
      label: 'ประเภทการรับเงิน',
      placeholder: 'เลือกประเภทการรับเงิน',
      type: 'SELECT',
      options: options.optionPaymentType,
      allowClear: true,
    },
    {
      key: '3',
      span: 4,
      name: 'RefDate',
      label: 'วันที่โอน',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      allowClear: true,
    },
    {
      key: '4',
      span: 4,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFGPInvoiceSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'DocNo',
      label: 'เลขที่บิลซื้อ',
      placeholder: 'กรุณากรอกเลขที่บิลซื้อ',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 5,
      name: 'DocDate',
      label: 'วันที่บิลซื้อ',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      allowClear: true,
    },
    {
      key: '3',
      span: 4,
      name: 'BillType',
      label: 'ประเภทบิล',
      placeholder: 'เลือกประเภทบิล',
      type: 'SELECT',
      options: options.optionBillType,
      allowClear: true,
    },
    {
      key: '4',
      span: 4,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};

export const DIFGPInvoiceDetailSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'DocNo',
      label: 'เลขที่บิลซื้อ',
      placeholder: 'กรุณากรอกเลขที่บิลซื้อ',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '2',
      span: 5,
      name: 'ItemCode',
      label: 'รหัสสินค้า',
      placeholder: 'กรุณากรอกรหัสสินค้า',
      type: 'INPUT',
      allowClear: true,
    },
    {
      key: '3',
      span: 5,
      name: 'IsImport',
      label: 'สถานะ Import',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionImportStatus,
      allowClear: true,
    },
  ];
};
