export const CreatePickSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'branchFrom',
      label: 'สาขาต้นทาง',
      placeholder: 'เลือกสาขาต้นทาง',
      options: options.optionBranch,
      defaultValue: 'CENTER',
      type: 'SELECT',
    },
    {
      key: '2',
      span: 6,
      name: 'branchTo',
      label: 'สาขาปลายทาง',
      placeholder: 'เลือกสาขาปลายทาง',
      options: options.optionBranch,
      type: 'SELECT',
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 6,
      name: 'rangDateTime',
      label: 'วันที่จองสินค้า',
      placeholder: ['เลือกวันที่', 'ถึงวันที่'],
      // format: 'YYYY-MM-DD HH:mm',
      format: 'YYYY-MM-DD',
      // showTime: { defaultValue: [moment('00:00', 'HH:mm'), moment('12:00:00', 'HH:mm')] },
      // showTime: { defaultValue: [moment('00:00'), moment('12:00:00')] },
      // type: 'RANG_PICKER',
      type: 'RANGE_DATE_PICKER',
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 6,
      name: 'productHand',
      label: 'ลักษณะสินค้า',
      placeholder: 'เลือกลักษณะสินค้า',
      options: options.optionProductHandPick,
      type: 'SELECT',
      // rules: {
      //   required: true,
      // },
    },
  ];
};

export const ReportPickSearchForm = ({option}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'document_no',
      label: 'เลขที่เอกสาร',
      placeholder: 'กรอกเลขเอกสาร',
      type: 'INPUT',
    },
    {
      key: '2',
      span: 6,
      name: 'status',
      label: 'สถานะ',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: option.optionJournalStatus,
      allowClear: true,
    },
    {
      key: '3',
      span: 6,
      name: 'productHand',
      label: 'ลักษณะสินค้า',
      placeholder: 'เลือกลักษณะสินค้า',
      options: option.optionProductHand || [],
      type: 'SELECT',
      allowClear: true,
    },
  ];
};

export const CheckStockSearchForm = () => {
  return [
    {
      key: '1',
      span: 6,
      name: 'document_no',
      label: 'เลขที่เอกสาร',
      placeholder: 'กรอกเลขเอกสาร',
      type: 'INPUT',
    },
  ];
};

export const ReserveReportStockSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'เลือกสาขา',
      type: 'SELECT',
      options: options.optionBranch,
    },
    {
      key: '2',
      span: 6,
      name: 'item_name',
      label: 'ชื่อสินค้า',
      placeholder: 'กรอกชื่อสินค้า',
      type: 'INPUT',
    },
    {
      key: '3',
      span: 6,
      name: 'user_name',
      label: 'ชื่อ-นามสกุล',
      placeholder: 'กรอกชื่อ - นามสกุล',
      type: 'INPUT',
    },
    {
      key: '4',
      span: 6,
      name: 'admin_name',
      label: 'ผู้นัดทำสัญญา',
      placeholder: 'กรอกชื่อ - นามสกุล',
      type: 'INPUT',
    },
    {
      key: '5',
      span: 6,
      name: 'appointment_date_start',
      label: 'วันที่นัดทำสัญญา',
      placeholder: 'เลือกวันที่เริ่ม',
      type: 'DATE_PICKER',
    },
    {
      key: '6',
      span: 6,
      name: 'appointment_date_end',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
    },
    {
      key: '7',
      span: 6,
      name: 'create_date_start',
      label: 'วันที่กดนัดทำสัญญา',
      placeholder: 'เลือกวันที่เริ่ม',
      type: 'DATE_PICKER',
    },
    {
      key: '8',
      span: 6,
      name: 'create_date_end',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
    },
    {
      key: '9',
      span: 6,
      name: 'product_hand',
      label: 'ลักษณะสินค้า',
      placeholder: 'เลือกลักษณะสินค้า',
      type: 'SELECT',
      options: options.optionProductHandPick,
    },
    {
      key: '11',
      span: 6,
      name: 'user_remark',
      label: 'หมายเหตุ',
      placeholder: 'กรุณากรอกหมายเหตุ',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
  ];
};
