export const OTHER_PAYMENT_FORM = ({ options, onChange, data }) => {
  return [
    {
      key: '1',
      span: 24,
      name: 'type',
      label: 'ประเภท',
      placeholder: 'เลือกประเภท',
      type: 'SELECT',
      options: options?.otherFee,
      onChangeCustom: onChange,
      allowClear: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 24,
      name: 'remark',
      label: 'โปรดระบุ',
      placeholder: 'กรอกโปรดระบุ',
      type: 'INPUT',
      hidden: data?.type !== 'OTHER',
      rules: {
        required: data?.type === 'OTHER',
      },
    },
    {
      key: '3',
      span: 24,
      name: 'amount',
      label: 'จำนวน',
      placeholder: 'กรอกจำนวน',
      type: 'INPUT_NUMBER',
      rules: {
        required: true,
      },
    },
  ];
};




