import React from 'react';
import { compareRender } from '../../functions/fn';
import { Controller } from 'react-hook-form';
import { Switch } from 'antd';
import { convertArray } from '../../functions/fn';

export const SwitchTheme = React.memo(({ item, handleChange, control }) => {
  const { defaultValue, disabled, onChangeCustom, rules, name, value } = item;

  // let tempCode = defaultValue.split('-')[1] ? defaultValue.split('-')[1] : defaultValue;

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        //value={value}
        // render={({ field: { onChange, value } }) => (
        render={({ onChange, value }) => (
          <Switch
            disabled={disabled}
            //value={value}
            defaultChecked={value || defaultValue}
            onChange={onChangeCustom ? (e) => onChangeCustom(e, item.name) : onChangeCustom}
          />
        )}
      />
    </>
  );
}, compareRender);
