import React, {useEffect} from 'react';
import {Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useLocation} from 'react-router-dom';
import {useSendChatcone, useGetChatconeHistory} from '../../../hook/chatcone';
import {DatatableLog, Spinners, ButtonTheme} from '../../../components';
import {ChatconeHistoryColumn} from './columns/ChatconeHistoryColumn';
import {APP_URL} from '../../../env.js';
import {ContainerButton} from '../../../styles/styledComponentGlobal';

export const ChatconeHistotyModal = ({title, content, visible, onClose, input}) => {
  const {pathname} = useLocation();
  const {chatcone, user_name, userId, grade, _id, type_lock} = input;

  const [getChatconeHistoryFn, getChatconeHistoryData, getChatconeHistoryLoading] = useGetChatconeHistory();
  const [sendChatconeFn, sendChatconeLoading] = useSendChatcone({ishowModal: true, onCloseMain: onClose});

  useEffect(() => {
    if (userId && visible) getChatconeHistoryFn({ref_id: userId, type: 'SEND_ICLOUD_PASSWORD'});
  }, [userId, visible]);

  const openConfirm = () => {
    Modal.confirm({
      title: 'ยืนยันการส่งแจ้งเตือน',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการส่งแจ้งเตือนส่งรหัสปลดล็อก iClound?',
      onOk() {
        onSendChatconeFn();
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSendChatconeFn = () => {
    // CHATCONE: 6 SEND_ICLOUD_PASSWORD
    const processType = type_lock === 'ICLOUD' ? 'SEND_ICLOUD_PASSWORD_ICLOUD' : type_lock === 'MDMICLOUD' ? 'SEND_ICLOUD_PASSWORD_MDMICLOUD' : '';
    if (chatcone?.social_id) {
      const setInput = {
        chatcone: chatcone,
        process_type: processType,
        user_id: userId,
        ref_id: _id,
        ref_name: 'unlock_icloud',
        route_from: `${APP_URL}${pathname}`,
        inputMessage: {
          full_name: user_name,
        },
        inputUpdate: {
          // ชื่อ feild ที่ส่งไป update ตามที่ตั้งค่าไว้ที่หน้า settting ของ chatcone
          // tag name จะดึงค่ามาจาก backend
          contact_description: {
            customer_grade: grade,
            first_name: user_name
          },
        },
      };

      sendChatconeFn({...setInput});
    }
  };

  return (
    <>
      {getChatconeHistoryLoading || sendChatconeLoading ? <Spinners /> : null}
      <Modal
        title={
          <>
            <ExclamationCircleOutlined style={{color: '#faad14'}} /> {title || ''}
          </>
        }
        open={visible}
        width={840}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <div style={{textAlign: 'center', fontSize: '20px', marginBottom: '10px'}}>{content || ''}</div>
        <ContainerButton center>
          <ButtonTheme useFor="SEND_NOTI" title="ส่งแจ้งเตือนไปยังแชท" onClick={() => openConfirm()} />
        </ContainerButton>

        <DatatableLog
          style={{marginTop: '20px'}}
          columns={ChatconeHistoryColumn()}
          data={getChatconeHistoryData}
          pagination={{pageSize: 5}}
          showDivider={false}
        />
      </Modal>
    </>
  );
};
