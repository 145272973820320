import { useEffect, useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import ActionLogTable from '../../table/ActionLog';
import { actionLog } from '../../../redux/api/icloud';

export default function ActionLog(props) {
  const { Text, Title } = Typography;
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.visible) {
      fetchLogs();
    }
  }, [props.visible]);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const logs = await actionLog(props.contract_no);
      if (logs.data) {
        const { success, result } = logs.data;
        if (success) {
          setLogs(result);
        } else {
          Modal.error({
            title: "Error",
            content: "Failed to fetch logs",
          });
        }
      } else {
        throw new Error('ไม่สามารถดึงข้อมูล Action Log ได้');
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={props.visible} onCancel={props.onCancel} width={900} closable={false} footer={null}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
        <Title level={5} strong>
          Action Log:
        </Title>
        <Button type="primary" onClick={() => fetchLogs()} loading={loading}>
          Refresh
        </Button>
      </div>
      <ActionLogTable logs={logs} />
    </Modal>
  );
}
