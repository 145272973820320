import { Joi } from 'joi-auto-admin';
import { loadBarcodeName, uploadContractImg, updateProductImg } from './api';
import CardReader from './card_reader';
import SameAddress from './same_address';
import SameAddressEdit from './same_address_edit';
import MailIcloudOld from './use_mail_icloud_old';
import PeriodsOption from './period_option';
import PackagesOption from './packages_option';
import ProductSearch from './product_search';
import AutoCal from './auto_cal';
import CustomerInfoWp from './customer_info';
import CustomerInfoWpEdit from './customer_info_edit';
import messageMachineDetailImage from './message_input';
// import { Button, ButtonWrap } from './styled-component';
import ModalDetail from './modal_detail';
import messageSerialInput from './message_input_serial';
import MessageNoPrice2 from './message_no_price2';

import ModalPackageDetail from './modal_package_detail';
import SameContactPoints from './same_contact_points';
import PriceGetPeriod from './price_get_period';
import DownPayment from './down_payment';
import BranchsOption from './branch_option';
import CouponCodeInput from './coupon_code_input';
import FGFCodeInput from './fgf_code_input'
import MDMProviderOption from './mdm_provider_option';
import MDMSiteOption from './mdm_site_option';
import CalculateAge from './calculateAge';
import companyNameLabel from './company_name_label';
import workTelLabel from './work_tel_label';
import workAddressLabel from './work_address_label';

const step1a = (a) => a.customer.job_cat !== 'employed';
const step1a2 = (a) => a.customer2.job_cat !== 'employed';
const step1b = (a) => a.customer.job_cat !== 'student';
const step1b2 = (a) => a.customer2.job_cat !== 'student';

const admin_branch = localStorage.getItem('admin_branch');
// const branch = JSON.parse(localStorage.getItem('branch')) || [];
const branchSpecific = JSON.parse(localStorage.getItem('branchSpecific')) || [];
const valueBranch = branchSpecific.map((el) => {
  return el.value;
});
// const labelBranch = branchSpecific.map((el) => {
//   return el.label;
// });

// const labelBranchFilter =
//   (branch.length > 0 &&
//     branch.filter((item) => item.value === admin_branch).length > 0 &&
//     branch.filter((item) => item.value === admin_branch)[0].label) ||
//   ''; 
export const customer = Joi.object({
  //tableOnly
  code: Joi.string().label('เลขบัตรปชช').meta({ fieldHide: true, cellShow: true, cellWidth: 60 }),

  channel: Joi.string().label('channel').meta({ fieldHide: true }),
  chatcone: Joi.object({
    token: Joi.string().label('chatcone_token'),
    social_id: Joi.string().label('chatcone_social_id'),
    channel_id: Joi.string().label('chatcone_channel_id')
  }).meta({ fieldHide: true }),
  platform: Joi.string().label('platform').meta({ fieldHide: true }),
  amplitudeId: Joi.string().label('amplitudeId').meta({ fieldHide: true }),
  walkin: Joi.boolean().label('walkin').meta({ fieldHide: true }),
  //step0
  picture_id_card: Joi.string()
    .label('เครื่องอ่านบัตร')
    .meta({
      containerStyle: { marginBottom: 30 },
      step: 0,
      onFieldRender: CardReader,
    }),
  general: Joi.object({
    prefix: Joi.string().required().label('คำนำหน้า').meta({ step: 0, cellShow: false, placeholder: 'เช่น นาย' }),
    fullname: Joi.string().required().label('ชื่อ-นามสกุล').meta({ step: 0, cellShow: true, placeholder: 'เช่น สมชาย รักแมว' }),
    gender: Joi.string()
      .valid('m', 'f')
      .required()
      .label('เพศ')
      .meta({
        containerStyle: { marginBottom: 30 },
        step: 0,
        validLabel: ['ชาย', 'หญิง'],
        // onFieldRender: CardReader,
      }),
    dob: Joi.date().required().label('วันเกิด').meta({ twoColumn: true, step: 0, placeholder: 'เลือก' }),
    age: Joi.string().label('อายุ').meta({
      twoColumn: true, step: 0, placeholder: 'เลือก', disabled: true,
      onFieldRender: CalculateAge
    }),
  }),
  address: Joi.object({
    full: Joi.string().required().label('ที่อยู่บัตรปชช').meta({ step: 0, placeholder: 'กรอกให้เหมือนในบัตรปชช' }),
    zipcode: Joi.string()
      .required()
      .pattern(/^\d{5}$/)
      .label('รหัสไปรษณีย์')
      .meta({ step: 0, twoColumn: true, placeholder: 'เช่น 10210' }),
  }),
  grade_after2: Joi.string()
    .label('เกรด')
    .default('')
    .meta({
      cellShow: true,
      cellWidth: 90,
      fieldHide: true,
      cellFormat: (a, b) => (b?.customer?.grade_after ? b?.customer?.grade_after.replace('-', '') : ''),
    }),
  id_card: Joi.string()
    .required()
    .custom((id, helper) => {
      if (!/^\d{13}$/.test(id)) throw new Error('ต้องเป็นตัวเลข 13 หลักไม่มีขีดคั่น');
      var digits = [];
      for (let i = 0; i < 13; i++) digits.push(parseInt(id[i]));
      var checksum = 0;
      for (let i = 0; i < 12; i++) checksum += (13 - i) * digits[i];
      var digit13 = checksum % 11;
      digit13 = digit13 > 1 ? 11 - digit13 : 1 - digit13;
      if (digit13 !== digits[12]) throw new Error('เลขประชาชนไม่ถูกต้อง');
      return id;
    })
    .label('เลขบัตรปชช')
    .meta({
      step: 0,
      twoColumn: true,
      placeholder: 'ไม่มีขีดคั่น',
    }),

  //step1
  tel: Joi.string()
    .required()
    .label('เบอร์โทรศัพท์')
    .meta({
      step: 1,
      twoColumn: true,
      cellShow: true,
      // fieldType: 'InputPhone',
      cellWidth: 150,
      cellFormat: (data) => {
        if (/^\d{9}$/.test(data)) return [data.substring(0, 2), data.substring(2, 5), data.substring(5, 9)].join('-');
        if (/^\d{10}$/.test(data)) return [data.substring(0, 3), data.substring(3, 6), data.substring(6, 10)].join('-');
        return data;
      },
      onFieldRender: CustomerInfoWp,
    }),
  social_media: Joi.object({
    line: Joi.string().label('ไลน์ไอดี').meta({ step: 1, twoColumn: true, placeholder: 'เช่น 0811111111 หรือ abcd' }),
    facebook: Joi.string().required().label('เฟซบุ๊ค').meta({ step: 1 }),
  }),
  address2: Joi.object({
    full: Joi.string().required().label('ที่อยู่อาศัยปัจจุบัน').meta({ step: 1 }),
  }),
  $address2_same: Joi.bool().label('ที่อยู่ในบัตรปชช').meta({
    step: 1,
    onFieldRender: SameAddress,
  }),
  job_cat: Joi.string()
    .required()
    .valid('employed', 'student')
    .default('employed')
    .label('อาชีพ')
    .meta({
      step: 1,
      validLabel: ['ทำงาน', 'นักเรียน'],
      containerStyle: { marginTop: 30 },
    }),

  //step1a
  work: Joi.object({
    career_name: Joi.string().required().label('ลักษณะอาชีพ').meta({ step: 1, fieldHide: step1a, twoColumn: true }),
    position: Joi.string().label('ตำแหน่งงาน').meta({ step: 1, fieldHide: step1a, twoColumn: true }),
    experience: Joi.string().label('อายุการทำงาน').required().meta({ step: 1, fieldHide: step1a, twoColumn: true }),
    salary: Joi.number().label('รายได้ต่อเดือน').required().meta({ step: 1, fieldHide: step1a, twoColumn: true }),
    company_name: Joi.string().label('สถานที่ทำงาน').meta({ step: 1, fieldHide: step1a, twoColumn: true }),
    work_tel: Joi.string().label('เบอร์ที่ทำงาน').meta({
      step: 1,
      fieldHide: step1a,
      twoColumn: true,
      // fieldType: 'InputPhone',
      placeholder: 'เช่น 021111111',
    }),
    company_name_label: Joi.string()
      .label(' ')
      .meta({
        containerStyle: { marginTop: -30 },
        step: 1, fieldHide: step1a,
        twoColumn: true,
        onFieldRender: companyNameLabel,
      }),
    work_tel_label: Joi.string()
      .label(' ')
      .meta({
        containerStyle: { marginTop: -30 },
        step: 1, fieldHide: step1a,
        twoColumn: true,
        onFieldRender: workTelLabel,
      }),
    work_address: Joi.object({
      full: Joi.string().required().label('ที่อยู่ที่ทำงาน').meta({ step: 1, fieldHide: step1a }),
    }),
    work_address_label: Joi.string()
      .label(' ')
      .meta({
        containerStyle: { marginTop: -30 },
        step: 1, fieldHide: step1a,
        onFieldRender: workAddressLabel,
      }),
  }),


  //step1b
  education: Joi.object({
    degree: Joi.string()
      .required()
      .valid('ม.ปลาย', 'ปวช', 'ปวส', 'ป.ตรี', 'สูงกว่าป.ตรี')
      .default('ป.ตรี')
      .label('ระดับการศึกษา')
      .meta({
        step: 1,
        fieldHide: step1b,
        twoColumn: true,
        validLabel: ['ม.ปลาย', 'ปวช', 'ปวส', 'ป.ตรี', 'สูงกว่าป.ตรี'],
      }),
    year: Joi.number().required().min(1).max(6).label('ชั้นปีที่').meta({ step: 1, fieldHide: step1b, twoColumn: true }),
    college: Joi.string().required().label('ชื่อมหาวิทยาลัย').meta({ step: 1, fieldHide: step1b, twoColumn: true }),
    faculty: Joi.string().required().label('คณะ').meta({ step: 1, fieldHide: step1b, twoColumn: true }),
    get_student_loan: Joi.bool().default(false).label('กู้กยศ./กรอ.หรือไม่').meta({ step: 1, fieldHide: step1b, twoColumn: true }),
    branch: Joi.string().required().label('สาขาวิชา').meta({ step: 1, fieldHide: step1b, twoColumn: true }),
  }),
  block_payment: Joi.bool().default(false).label('ล็อคการชำระ').meta({ step: 1, twoColumn: true }),
  grade_before: Joi.string()
    .required()
    .valid('-', 'A', 'B', 'C', 'D', '?', 'V', 'X')
    .label('เกรดลูกค้า')
    .meta({
      step: 1,
      twoColumn: true,
      validLabel: ['', 'A (0-5)', 'B (6-15)', 'C (16-59)', 'D (60+)', '? (ยังไม่ระบุ)', 'V (VIP)', 'X (Blocked)'],
    }),
  grade_after: Joi.string()
    .label('เกรดหลังทำสัญญา')
    .valid('-', 'A', 'B', 'C', 'D', '?', 'V', 'X')
    .meta({
      fieldHide: (a) => !a._id,
      step: 1,
      twoColumn: true,
      validLabel: ['', 'A (0-5)', 'B (6-15)', 'C (16-59)', 'D (60+)', '? (ยังไม่ระบุ)', 'V (VIP)', 'X (Blocked)'],
    }),

  //step2
  // contact_points: Joi.object({
  //   person1: Joi.object({
  //     firstname: Joi.string().required().label('ชื่อจริง1').meta({ twoColumn: true, step: 2, placeholder: 'เช่น สมชาย' }),
  //     lastname: Joi.string().required().label('นามสกุล1').meta({ twoColumn: true, step: 2, placeholder: 'เช่น รักแมว' }),
  //     tel: Joi.string().required().label('เบอร์โทรศัพท์1').meta({
  //       twoColumn: true,
  //       step: 2,
  //       fieldType: 'InputPhone',
  //       placeholder: 'เช่น 0811111111',
  //     }),
  //     relationship: Joi.string()
  //       .required()
  //       .label('ความสัมพันธ์1')
  //       .meta({
  //         twoColumn: true,
  //         step: 2,
  //         containerStyle: { marginBottom: 30 },
  //         placeholder: 'เช่น พ่อ แม่ เพื่อน แฟน',
  //       }),
  //   }),
  //   person2: Joi.object({
  //     firstname: Joi.string().required().label('ชื่อจริง2').meta({ twoColumn: true, step: 2 }),
  //     lastname: Joi.string().required().label('นามสกุล2').meta({ twoColumn: true, step: 2 }),
  //     tel: Joi.string().required().label('เบอร์โทรศัพท์2').meta({
  //       twoColumn: true,
  //       step: 2,
  //       fieldType: 'InputPhone',
  //       placeholder: '',
  //     }),
  //     relationship: Joi.string()
  //       .required()
  //       .label('ความสัมพันธ์2')
  //       .meta({
  //         twoColumn: true,
  //         step: 2,
  //         containerStyle: { marginBottom: 30 },
  //       }),
  //   }),
  //   person3: Joi.object({
  //     firstname: Joi.string().required().label('ชื่อจริง3').meta({ twoColumn: true, step: 2 }),
  //     lastname: Joi.string().required().label('นามสกุล3').meta({ twoColumn: true, step: 2 }),
  //     tel: Joi.string().required().label('เบอร์โทรศัพท์3').meta({
  //       twoColumn: true,
  //       step: 2,
  //       fieldType: 'InputPhone',
  //       placeholder: '',
  //     }),
  //     relationship: Joi.string()
  //       .required()
  //       .label('ความสัมพันธ์3')
  //       .meta({
  //         twoColumn: true,
  //         step: 2,
  //         containerStyle: { marginBottom: 30 },
  //       }),
  //   }),
  //   same_contact_points: Joi.string()
  //     .label('.')
  //     .meta({
  //       containerStyle: { marginTop: -30 },
  //       oneColumn: true,
  //       step: 2,
  //       onFieldRender: SameContactPoints,
  //       // fieldHide: (a) => a.contract.$totalAmount !== '-' || a.contract.contract.promotion_code !== 'price2',
  //     }),
  // }),
  contact_points: Joi.object({
    person1: Joi.object({
      firstname: Joi.string().required().label('ชื่อจริง1').meta({ twoColumn: true, step: 2, placeholder: 'เช่น สมชาย' }),
      lastname: Joi.string().required().label('นามสกุล1').meta({ twoColumn: true, step: 2, placeholder: 'เช่น รักแมว' }),
      tel: Joi.string().required().label('เบอร์โทรศัพท์1').meta({
        twoColumn: true,
        step: 2,
        fieldType: 'InputPhone',
        placeholder: 'เช่น 0811111111',
      }),
      relationship: Joi.string()
        .required()
        .label('ความสัมพันธ์1')
        .meta({
          twoColumn: true,
          step: 2,
          containerStyle: { marginBottom: 30 },
          placeholder: 'เช่น พ่อ แม่ เพื่อน แฟน',
        }),
    }),
    person2: Joi.object({
      firstname: Joi.string().label('ชื่อจริง2').meta({ twoColumn: true, step: 2 }),
      lastname: Joi.string().label('นามสกุล2').meta({ twoColumn: true, step: 2 }),
      tel: Joi.string().label('เบอร์โทรศัพท์2').meta({
        twoColumn: true,
        step: 2,
        fieldType: 'InputPhone',
        placeholder: '',
      }),
      relationship: Joi.string()
        .label('ความสัมพันธ์2')
        .meta({
          twoColumn: true,
          step: 2,
          containerStyle: { marginBottom: 30 },
        }),
    }),
    person3: Joi.object({
      firstname: Joi.string().label('ชื่อจริง3').meta({ twoColumn: true, step: 2 }),
      lastname: Joi.string().label('นามสกุล3').meta({ twoColumn: true, step: 2 }),
      tel: Joi.string().label('เบอร์โทรศัพท์3').meta({
        twoColumn: true,
        step: 2,
        fieldType: 'InputPhone',
        placeholder: '',
      }),
      relationship: Joi.string()
        .label('ความสัมพันธ์3')
        .meta({
          twoColumn: true,
          step: 2,
          containerStyle: { marginBottom: 30 },
        }),
    }),
    same_contact_points: Joi.string()
      .label('.')
      .meta({
        containerStyle: { marginTop: -30 },
        oneColumn: true,
        step: 2,
        onFieldRender: SameContactPoints,
        // fieldHide: (a) => a.contract.$totalAmount !== '-' || a.contract.contract.promotion_code !== 'price2',
      }),
  }),

  //tableOnly
  otp: Joi.string().label('OTP').meta({ fieldHide: true, cellShow: true, cellWidth: 100 }),
  contract: Joi.array()
    .label('สัญญา')
    .meta({
      fieldHide: true,
      cellShow: true,
      cellWidth: 80,
      cellFormat: (data) =>
        data &&
        data.map((a, i) => (
          <a key={a.contract_no} target="_blank" rel="noreferrer" style={{ marginRight: 5 }} href={'/contract/' + a.contract_no}>
            {'สัญญา' + (i + 1)}
          </a>
        )),
    }),
});

export const customer2 = Joi.object({
  customer2: Joi.object({
    //tableOnly
    code: Joi.string().label('เลขบัตรปชช').meta({
      fieldHide: true,
      // cellShow: true, cellWidth: 60
    }),

    //step0
    picture_id_card: Joi.string()
      .label('เครื่องอ่านบัตร')
      .meta({
        containerStyle: { marginBottom: 30 },
        step: 0,
        onFieldRender: CardReader,
      }),
    general: Joi.object({
      prefix: Joi.string().required().label('คำนำหน้า').meta({
        step: 0,
        // cellShow: false,
        placeholder: 'เช่น นาย',
      }),
      fullname: Joi.string().required().label('ชื่อ-นามสกุล').meta({
        step: 0,
        //  cellShow: true,
        placeholder: 'เช่น สมชาย รักแมว'
      }),
      gender: Joi.string()
        .valid('m', 'f')
        .required()
        .label('เพศ')
        .meta({
          twoColumn: true,
          step: 0,
          validLabel: ['ชาย', 'หญิง'],
          placeholder: 'เลือก',
        }),
      dob: Joi.date().required().label('วันเกิด').meta({ twoColumn: true, step: 0, placeholder: 'เลือก' }),
    }),
    address: Joi.object({
      full: Joi.string().required().label('ที่อยู่บัตรปชช').meta({ step: 0, placeholder: 'กรอกให้เหมือนในบัตรปชช' }),
      zipcode: Joi.string()
        .required()
        .pattern(/^\d{5}$/)
        .label('รหัสไปรษณีย์')
        .meta({ step: 0, twoColumn: true, placeholder: 'เช่น 10210' }),
    }),
    grade_after2: Joi.string()
      .label('เกรด')
      .meta({
        cellShow: true,
        cellWidth: 90,
        fieldHide: true,
        cellFormat: (a, b) => (b?.customer2?.grade_after ? b?.customer2?.grade_after.replace('-', '') : ''),
      }),
    id_card: Joi.string()
      .required()
      .custom((id, helper) => {
        if (!/^\d{13}$/.test(id)) throw new Error('ต้องเป็นตัวเลข 13 หลักไม่มีขีดคั่น');
        var digits = [];
        for (let i = 0; i < 13; i++) digits.push(parseInt(id[i]));
        var checksum = 0;
        for (let i = 0; i < 12; i++) checksum += (13 - i) * digits[i];
        var digit13 = checksum % 11;
        digit13 = digit13 > 1 ? 11 - digit13 : 1 - digit13;
        if (digit13 !== digits[12]) throw new Error('เลขประชาชนไม่ถูกต้อง');
        return id;
      })
      .label('เลขบัตรปชช')
      .meta({
        // disabled: true,
        step: 0,
        twoColumn: true,
        placeholder: 'ไม่มีขีดคั่น',
      }),

    //step1
    tel: Joi.string()
      .required()
      .label('เบอร์โทรศัพท์')
      .meta({
        step: 1,
        twoColumn: true,
        // cellShow: true,
        // fieldType: 'InputPhone',
        cellWidth: 150,
        cellFormat: (data) => {
          if (/^\d{9}$/.test(data)) return [data.substring(0, 2), data.substring(2, 5), data.substring(5, 9)].join('-');
          if (/^\d{10}$/.test(data)) return [data.substring(0, 3), data.substring(3, 6), data.substring(6, 10)].join('-');
          return data;
        },
        onFieldRender: CustomerInfoWpEdit,
      }),
    social_media: Joi.object({
      line: Joi.string().required().label('ไลน์ไอดี').meta({ step: 1, twoColumn: true, placeholder: 'เช่น 0811111111 หรือ abcd' }),
      facebook: Joi.string().required().label('เฟซบุ๊ค').meta({ step: 1 }),
    }),
    address2: Joi.object({
      full: Joi.string().required().label('ที่อยู่ปัจจุบัน').meta({ step: 1 }),
    }),
    $address2_same: Joi.bool().label('ที่อยู่ในบัตรปชช').meta({
      step: 1,
      onFieldRender: SameAddressEdit,
    }),
    job_cat: Joi.string()
      .required()
      .valid('employed', 'student')
      .default('employed')
      .label('ประเภทงาน')
      .meta({
        step: 1,
        validLabel: ['ทำงาน', 'นักเรียน'],
        containerStyle: { marginTop: 30 },
      }),

    //step1a2
    work: Joi.object({
      career_name: Joi.string().required().label('อาชีพปัจจุบัน').meta({ step: 1, fieldHide: step1a2, twoColumn: true }),
      position: Joi.string().label('ตำแหน่งงาน').meta({ step: 1, fieldHide: step1a2, twoColumn: true }),
      experience: Joi.string().label('อายุการทำงาน').required().meta({ step: 1, fieldHide: step1a2, twoColumn: true }),
      salary: Joi.number().label('รายได้ต่อเดือน').required().meta({ step: 1, fieldHide: step1a2, twoColumn: true }),
      company_name: Joi.string().label('บริษัทปัจจุบัน').meta({ step: 1, fieldHide: step1a2, twoColumn: true }),
      work_tel: Joi.string().label('เบอร์โทรที่ทำงาน').meta({
        step: 1,
        fieldHide: step1a2,
        twoColumn: true,
        // fieldType: 'InputPhone',
        placeholder: 'เช่น 021111111',
      }),
      work_address: Joi.object({
        full: Joi.string().required().label('ที่อยู่ที่ทำงาน').meta({ step: 1, fieldHide: step1a2 }),
      }),
    }),

    //step1b
    education: Joi.object({
      degree: Joi.string()
        .required()
        .valid('ม.ปลาย', 'ปวช', 'ปวส', 'ป.ตรี', 'สูงกว่าป.ตรี')
        .default('ป.ตรี')
        .label('การศึกษาปัจจุบัน')
        .meta({
          step: 1,
          fieldHide: step1b2,
          twoColumn: true,
          validLabel: ['ม.ปลาย', 'ปวช', 'ปวส', 'ป.ตรี', 'สูงกว่าป.ตรี'],
        }),
      year: Joi.number().required().min(1).max(6).label('ชั้นปีที่').meta({ step: 1, fieldHide: step1b2, twoColumn: true }),
      college: Joi.string().required().label('ชื่อมหาวิทยาลัย').meta({ step: 1, fieldHide: step1b2, twoColumn: true }),
      faculty: Joi.string().required().label('คณะ').meta({ step: 1, fieldHide: step1b2, twoColumn: true }),
      get_student_loan: Joi.bool().default(false).label('กู้กยศ./กรอ.หรือไม่').meta({ step: 1, fieldHide: step1b2, twoColumn: true }),
      branch: Joi.string().required().label('สาขาวิชา').meta({ step: 1, fieldHide: step1b2, twoColumn: true }),
    }),
    block_payment: Joi.bool().default(false).label('ล็อคการชำระ').meta({ step: 1, twoColumn: true }),
    grade_before: Joi.string()
      .required()
      .valid('-', 'A', 'B', 'C', 'D', '?', 'V', 'X')
      .label('เกรดลูกค้า')
      .meta({
        step: 1,
        twoColumn: true,
        validLabel: ['', 'A (0-5)', 'B (6-15)', 'C (16-59)', 'D (60+)', '? (ยังไม่ระบุ) (VIP)', 'V', 'X (Blocked)'],
      }),
    grade_after: Joi.string()
      .label('เกรดหลังทำสัญญา')
      .valid('-', 'A', 'B', 'C', 'D', '?', 'V', 'X')
      .meta({
        fieldHide: (a) => !a._id,
        step: 1,
        twoColumn: true,
        validLabel: ['', 'A (0-5)', 'B (6-15)', 'C (16-59)', 'D (60+)', '? (ยังไม่ระบุ)', 'V (VIP)', 'X (Blocked)'],
      }),

    //step2
    contact_points: Joi.object({
      person1: Joi.object({
        firstname: Joi.string().required().label('ชื่อจริง1').meta({ twoColumn: true, step: 2, placeholder: 'เช่น สมชาย' }),
        lastname: Joi.string().required().label('นามสกุล1').meta({ twoColumn: true, step: 2, placeholder: 'เช่น รักแมว' }),
        tel: Joi.string().required().label('เบอร์โทรศัพท์1').meta({
          twoColumn: true,
          step: 2,
          fieldType: 'InputPhone',
          placeholder: 'เช่น 0811111111',
        }),
        relationship: Joi.string()
          .required()
          .label('ความสัมพันธ์1')
          .meta({
            twoColumn: true,
            step: 2,
            containerStyle: { marginBottom: 30 },
            placeholder: 'เช่น พ่อ แม่ เพื่อน แฟน',
          }),
      }),
      person2: Joi.object({
        firstname: Joi.string().required().label('ชื่อจริง2').meta({ twoColumn: true, step: 2 }),
        lastname: Joi.string().required().label('นามสกุล2').meta({ twoColumn: true, step: 2 }),
        tel: Joi.string().required().label('เบอร์โทรศัพท์2').meta({
          twoColumn: true,
          step: 2,
          fieldType: 'InputPhone',
          placeholder: '',
        }),
        relationship: Joi.string()
          .required()
          .label('ความสัมพันธ์2')
          .meta({
            twoColumn: true,
            step: 2,
            containerStyle: { marginBottom: 30 },
          }),
      }),
      person3: Joi.object({
        firstname: Joi.string().required().label('ชื่อจริง3').meta({ twoColumn: true, step: 2 }),
        lastname: Joi.string().required().label('นามสกุล3').meta({ twoColumn: true, step: 2 }),
        tel: Joi.string().required().label('เบอร์โทรศัพท์3').meta({
          twoColumn: true,
          step: 2,
          fieldType: 'InputPhone',
          placeholder: '',
        }),
        relationship: Joi.string()
          .required()
          .label('ความสัมพันธ์3')
          .meta({
            twoColumn: true,
            step: 2,
            containerStyle: { marginBottom: 30 },
          }),
      }),
    }),

    //tableOnly
    otp: Joi.string().label('OTP').meta({ fieldHide: true, cellShow: true, cellWidth: 100 }),
    contract: Joi.array()
      .label('สัญญา')
      .meta({
        fieldHide: true,
        // cellShow: true,
        // cellWidth: 80,
        cellFormat: (data) =>
          data &&
          data.map((a, i) => (
            <a key={a.contract_no} target="_blank" rel="noreferrer" style={{ marginRight: 5 }} href={'/contract/' + a.contract_no}>
              {'สัญญา' + (i + 1)}
            </a>
          )),
      }),
  }),
});
//TODO PS
export const contract = Joi.object({
  type: Joi.string()
    .valid('INSTALLMENT', 'REFINANCE', 'REFINPACKAGE')
    .default('INSTALLMENT')
    .required()
    .label('ประเภทสัญญา')
    .meta({
      step: 3,
      validLabel: ['ผ่อนสินค้า', 'ไอโฟนแลกเงิน', 'ไอโฟนแลกเงิน + ของแถม'],
    }),
  serial_number: Joi.array()
    .required()
    .min(1)
    .label('บาร์โค้ดสินค้า')
    .meta({
      loadBarcodeName,
      step: 3,
      fieldHide: (a) => ['REFINANCE', 'REFINPACKAGE'].includes(a.contract.type),
      placeholder: 'พิมพ์แล้วกด enter เช่น G6TF21DT0D95',
      containerStyle: { marginBottom: 20 },
    }),
  product_code: Joi.string()
    .required()
    .label('ค้นหาสินค้า')
    .meta({
      onFieldRender: ProductSearch,
      step: 3,
      fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type),
    }),
  serial_number2: Joi.string()
    .required()
    .label('ซีเรียลเครื่อง')
    .default('')
    .meta({
      fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type),
      oneColumn: true,
      step: 3,
      autoComplete: 'off',
    }),
  textMessagePreventSerial: Joi.string()
    .label(' ')
    .meta({
      containerStyle: { marginTop: '-1%' },
      oneColumn: true,
      onFieldRender: messageSerialInput,
      fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type),
    }),
  branch:
    admin_branch === 'CENTER'
      ? Joi.string()
        .required()
        .valid(...valueBranch)
        .default('CENTER')
        .label('สาขา')
        .meta({
          step: 3,
          oneColumn: true,
          onFieldRender: BranchsOption,
          // validLabel: [...labelBranch],
        })
      : Joi.string()
        .required()
        .valid(admin_branch)
        .default(admin_branch)
        .label('สาขา')
        .meta({
          step: 3,
          oneColumn: true,
          onFieldRender: BranchsOption,
          // validLabel: [labelBranchFilter],
        }),
  price: Joi.number()
    .required()
    .default(0)
    .label('ราคา')
    .meta({
      fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type),
      twoColumn: true,
      step: 3,
      //containerStyle: { marginBottom: 30 },
      onFieldRender: PriceGetPeriod,
    }),
  contract: Joi.object({
    downpayment_amount: Joi.string().label('เงินออมดาวน์')
      .meta({
        disabled: true,
        twoColumn: true,
        onFieldRender: DownPayment,
        fieldHide: (a) => ['REFINANCE', 'REFINPACKAGE'].includes(a.contract.type)
      }),
    down_amount: Joi.number()
      .required()
      .min(0)
      .label('เงินดาวน์')
      .meta({
        step: 3,
        twoColumn: true,
        fieldHide: (a) => ['REFINANCE', 'REFINPACKAGE'].includes(a.contract.type),
      }),
    periods_fin: Joi.number()
      .required()
      .label('จำนวนงวด')
      .min(3)
      .meta({
        onFieldRender: PeriodsOption,
        step: 3,
        twoColumn: true,
        fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type) || !a.contract.product_code,
      }),
    package: Joi.string()
      .required()
      .label('แพคเก็จ')
      .default('')
      .meta({
        onFieldRender: PackagesOption,
        step: 3,
        oneColumn: true,
        fieldHide: (a) => ['INSTALLMENT', 'REFINANCE'].includes(a.contract.type) || !a.contract.contract.periods_fin,
      }),
    price_package: Joi.number().label('ราคาแพคเก็จ').default(0).meta({
      step: 3,
      //oneColumn: false,
      fieldHide: true,
    }),
    view_package: Joi.string()
      .label('.')
      .meta({
        containerStyle: { marginLeft: 0, marginTop: '-3vh', textAlign: 'right', display: 'flex', flexDirection: 'column-reverse' },
        //twoColumn: true,
        onFieldRender: ModalPackageDetail,
        fieldHide: (a) => !a.contract.contract.package,
      }),
    promotion_code: Joi.string()
      .valid('price2', 'price3', 'price4', 'none',)
      .default('none')
      .label('รหัสโปรโมชั่น')
      .meta({
        step: 3,
        twoColumn: true,
        validLabel: ['ราคา2', 'ราคา3', 'ราคา4', 'ไม่มี',],
        fieldHide: (a) => ['REFINANCE', 'REFINPACKAGE'].includes(a.contract.type),
      }),
    promotion_price2_warning: Joi.string()
      .label(' ')
      .meta({
        containerStyle: { marginTop: -20 },
        oneColumn: true,
        onFieldRender: MessageNoPrice2,
        fieldHide: (a) => a.contract.$totalAmount !== '-' || a.contract.contract.promotion_code !== 'price2',
      }),
    couponCode: Joi.string()
      .label('Code คูปอง')
      .meta({
        step: 3,
        twoColumn: true,
        onFieldRender: CouponCodeInput,
        // fieldHide: (a) => a.contract.type === 'REFINANCE',
      }),
    periods: Joi.number()
      .required()
      .label('จำนวนงวด')
      .min(3)
      .meta({
        step: 3,
        twoColumn: true,
        fieldHide: (a) => ['REFINANCE', 'REFINPACKAGE'].includes(a.contract.type),
      }),

    type_lock: Joi.string()
      .required()
      .valid('MDM', 'ICLOUD', 'MDMICLOUD', '')
      .default('')
      .label('การล็อคเครื่อง')
      .meta({
        step: 3,
        validLabel: ['MDM', 'iCloud', 'MDM+iCloud', 'ไม่ระบุ'],
        twoColumn: true,
      }),
    mdm_provider_code: Joi.string()
      .required()
      .label('ผู้ให้บริการ MDM')
      .meta({
        step: 3,
        twoColumn: true,
        fieldHide: (a) => ['ICLOUD', ''].includes(a?.contract?.contract?.type_lock),
        onFieldRender: MDMProviderOption,
      }),
    mdm_site_code: Joi.string()
      .required()
      .label('MDM Site')
      .meta({
        step: 3,
        twoColumn: true,
        fieldHide: (a) => ['ICLOUD', ''].includes(a?.contract?.contract?.type_lock),
        onFieldRender: MDMSiteOption,
      }),
    fgf_code: Joi.string()
      .label('รหัสเพื่อนชวนเพื่อน')
      .meta({
        step: 3,
        twoColumn: true,
        disabled: true,
        onFieldRender: FGFCodeInput,
      }),
    document_no: Joi.string()
      .trim()
      .length(9)
      .pattern(/^\S*$/, 'no spaces')
      .default(null)
      .label('อ้างอิงเลขรับซื้อ')
      .meta({
        step: 3,
        twoColumn: true,
        fieldHide: (a) => a.contract.type !== 'INSTALLMENT',
      })
      .messages({
        'string.length': '{{#label}} ต้องมีความยาว 9 ตัวอักษรพอดี',
        'string.pattern.name': '{{#label}} ต้องไม่มีช่องว่าง'
      }),
    checkMail: Joi.bool()
      .label('อีเมล์ ไอคลาวน์')
      .default(false)
      .meta({
        twoColumn: true,
        step: 3,
        label: 'ใช้บัญชีเดิม',
        fieldHide: (a) => a.contract.type === 'INSTALLMENT' || !a.contract.contract.type_lock || a.contract.contract.type_lock === 'MDM',
        onFieldRender: MailIcloudOld,
      }),
    mail: Joi.string()
      .label('อีเมล์')
      .meta({
        step: 3,
        twoColumn: true,
        disabled: true,
        fieldHide: (a) => a.contract.type === 'INSTALLMENT' || !a.contract.contract.checkMail || !a.contract.contract.mail,
      }),
    image: Joi.object({
      url: Joi.string().required().label('รูปผู้ทำสัญญา').meta({
        step: 3,
        multiple: false,
        uploadFileType: 'image',
        accept: '.jpg,.png,.jpeg',
        uploadFile: uploadContractImg,
      }),
    }),
    machineDetailImage: Joi.object({
      url: Joi.string()
        .required()
        .label('รูปรายละเอียด')
        .meta({
          step: 3,
          multiple: false,
          uploadFileType: 'image',
          accept: '.jpg,.png,.jpeg',
          uploadFile: uploadContractImg,
          twoColumn: true,
          fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type),
        }),
    }),
    exemple: Joi.string()
      .label('.')
      .meta({
        twoColumn: true,
        onFieldRender: ModalDetail,
        fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type),
      }),
    textMessage: Joi.string()
      .label('.')
      .meta({
        containerStyle: { marginTop: '-3%' },
        oneColumn: true,
        onFieldRender: messageMachineDetailImage,
        fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type),
      }),
    imeiImage: Joi.object({
      url: Joi.string()
        .required()
        .label('รูป IMEI')
        .meta({
          step: 3,
          multiple: false,
          uploadFileType: 'image',
          accept: '.jpg,.png,.jpeg',
          uploadFile: uploadContractImg,
          fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type),
        }),
    }),
    otherImage: Joi.object({
      url: Joi.string()
        .label('รูปอื่นๆ')
        .meta({
          step: 3,
          multiple: false,
          uploadFileType: 'image',
          accept: '.jpg,.png,.jpeg',
          uploadFile: uploadContractImg,
          fieldHide: (a) => ['INSTALLMENT'].includes(a.contract.type),
        }),
    }),
  }),
  $discount: Joi.string().label('ส่วนลด').meta({ step: 3, twoColumn: true, disabled: true }),
  $interestAmount: Joi.string().label('ดอกเบี้ยรวม').meta({ step: 3, twoColumn: true, disabled: true, onFieldRender: AutoCal }),
  $totalAmount: Joi.string().label('ยอดชำระรวม').meta({ step: 3, disabled: true, twoColumn: true }),
  $periodAmount: Joi.string().label('ยอดผ่อนต่อเดือน').meta({ step: 3, twoColumn: true, disabled: true }),
  $capitalAmount: Joi.string()
    .label('ยอดจัดสินเชื่อ')
    .meta({
      step: 3,
      twoColumn: true,
      disabled: true,
      fieldHide: (a) => a.contract.type !== 'INSTALLMENT',
    }),
  $totalPrice: Joi.string()
    .label('ราคารวม')
    .meta({
      step: 3,
      twoColumn: true,
      disabled: true,
      fieldHide: (a) => a.contract.type !== 'INSTALLMENT',
    }),
  remark: Joi.string().min(0).label('หมายเหตุ').meta({ autoComplete: 'off' }),
  isOldContract: Joi.bool()
    .label('สร้างสัญญาแบบเก่า')
    .default(false)
    .meta({
      oneColumn: true,
      step: 1,
      containerStyle: { marginRigth: 50 },
    }),
});

const getNotificationStatus = (status) => {
  switch (status) {
    case 'PENDING':
      return 'รอส่ง';
    case 'SENT':
      return 'แจ้งเตือนแล้ว';
    case 'ERROR':
      return 'พบข้อผิดพลาด';
    default:
      return 'ผิดพลาด';
  }
};

export const notification = Joi.object({
  user: Joi.object({
    general: Joi.object({
      fullname: Joi.string().required().label('ชื่อ-นามสกุล').meta({ cellShow: true, disableFilter: true, disableSorting: true, cellWidth: 250 }),
    }),
  }),
  message: Joi.object({
    body: Joi.string().label('ข้อความ').meta({ cellShow: true, cellWidth: 250, disableFilter: true, disableSorting: true }),
  }),
  status: Joi.string()
    .label('สถานะ')
    .meta({ cellShow: true, disableSorting: true, cellWidth: 150, cellFormat: (data) => getNotificationStatus(data) }),
  channel: Joi.string().label('ช่องทาง').meta({ cellShow: true, disableSorting: true, cellWidth: 115 }),
  sent_by: Joi.string()
    .label('แจ้งโดย')
    .meta({ cellShow: true, disableSorting: true, cellWidth: 115, cellFormat: (data) => (data ? data : '-') }),
  sent_date: Joi.date()
    .label('วันที่แจ้งเตือน')
    .meta({
      cellShow: true,
      disableFilter: true,
      cellWidth: 160,
      cellFormat: (data) =>
        data ? new Intl.DateTimeFormat('th-TH', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(data)) : '-',
    }),
});

const getTrackStatus = (status) => {
  switch (status) {
    case 'PENDING':
      return 'รอการติดตาม';
    case 'TRACKED':
      return 'ติดตามแล้ว';
    case 'NO_OVERDUED':
      return 'ไม่มีงวดค้าง';
    default:
      return 'ผิดพลาด';
  }
};

const getStageRange = (stage) => {
  switch (stage) {
    case 5:
      return '60+';
    case 4:
      return '46-60';
    case 3:
      return '31-45';
    case 2:
      return '15-30';
    case 1:
      return '1-14';
    default:
      return '-';
  }
};

export const tracks = Joi.object({
  user: Joi.object({
    general: Joi.object({
      fullname: Joi.string().required().label('ชื่อ-นามสกุล').meta({ cellShow: true, cellWidth: 230, disableFilter: true, disableSorting: true }),
    }),
  }),
  status: Joi.string()
    .label('สถานะ')
    .meta({ cellShow: true, disableSorting: true, cellWidth: 120, cellFormat: (data) => getTrackStatus(data) }),
  amount: Joi.number().label('ยอดชำระ').meta({ cellShow: true, cellWidth: 130, disableFilter: true }),
  stage: Joi.number()
    .label('เกินกำหนด(วัน)')
    .meta({ cellShow: true, cellWidth: 150, cellFormat: (data) => (data ? getStageRange(data) : '-') }),
  action_count: Joi.number()
    .label('ติดตาม(ครั้ง)')
    .meta({ cellShow: true, disableFilter: true, cellFormat: (data) => (data ? data : '-') }),
  action_date: Joi.date()
    .label('ติดตามล่าสุด')
    .meta({
      cellShow: true,
      disableFilter: true,
      cellFormat: (data) =>
        data ? new Intl.DateTimeFormat('th-TH', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(data)) : '-',
    }),
  next_action_date: Joi.date()
    .label('ติดตามครั้งต่อไป')
    .meta({
      cellShow: true,
      disableFilter: true,
      cellFormat: (data) =>
        data ? new Intl.DateTimeFormat('th-TH', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(data)) : '-',
    }),
  channel: Joi.string()
    .label('ช่องทางล่าสุด')
    .meta({ cellShow: true, disableSorting: true, cellWidth: 120, cellFormat: (data) => (data ? data : '-') }),
  remark: Joi.string()
    .label('หมายเหตุล่าสุด')
    .meta({ cellShow: true, disableFilter: true, disableSorting: true, cellWidth: 150, cellFormat: (data) => (data ? data : '-') }),
});

export const productSerials = Joi.object({
  code: Joi.string().label('รหัสสินค้า').meta({ cellShow: true, disableFilter: true, cellWidth: 150 }),
  name: Joi.string().label('ชื่อ').meta({ cellShow: true, disableFilter: true, cellWidth: 480 }),
  promisenumber: Joi.string().label('เลขที่สัญญา').meta({ cellShow: true, disableFilter: true, cellWidth: 150 }),
  serialnumber: Joi.string().label('serial number').meta({ cellShow: true, disableFilter: true }),
  statuscase: Joi.string().label('สถานะ').meta({ cellShow: true, disableSorting: true }),
  price: Joi.number().label('ราคา').meta({ cellShow: false, disableFilter: true }),
});

export const products = Joi.object({
  code: Joi.string().label('รหัสสินค้า').meta({ cellShow: true, disableFilter: true, cellWidth: 150, disabled: true }),
  name: Joi.string().label('ชื่อ').meta({ cellShow: true, disableFilter: true, cellWidth: 480, disabled: true }),
  plu: Joi.string().label('PLU').meta({ cellShow: true, disableFilter: true, cellWidth: 150, disabled: true }),
  image: Joi.object({
    url: Joi.any()
      .label('รูป')
      .meta({ cellShow: true, disableFilter: true, uploadFileType: 'image', multiple: false, accept: '.jpg,.png,.jpeg', uploadFile: updateProductImg }),
  }),
});

export const thaiqrLogs = Joi.object({
  user: Joi.object({
    general: Joi.object({
      fullname: Joi.string()
        .required()
        .label('ชื่อ-นามสกุล')
        .meta({ cellShow: false, cellWidth: 230, disableFilter: true, disableSorting: true, disabled: true }),
    }),
  }),
  from_name: Joi.string().label('ชื่อ').meta({ cellShow: true, disableFilter: true, cellWidth: 200, disabled: true }),
  biller_id: Joi.string().label('Biller ID').meta({ cellShow: false, disabled: true }),
  transaction_date: Joi.string().label('วันที่').meta({ cellShow: true, disableFilter: true, disabled: true }),
  transaction_time: Joi.string().label('เวลา').meta({ cellShow: true, disableFilter: true, disabled: true }),
  term_type: Joi.string().label('Term type').meta({ cellShow: false, disabled: true }),
  amount: Joi.number().label('จำนวนเงิน').meta({ cellShow: true, disableFilter: true, cellWidth: 150, disabled: true }),
  reference1: Joi.string().label('บัตรประชาชน(ref1)').meta({ cellShow: true, disableFilter: true, disabled: true }),
  reference2: Joi.string().label('ref2').meta({ cellShow: true, disableFilter: true, disabled: true }),
  from_bank: Joi.string().label('From Bank').meta({ cellShow: false, disabled: true }),
  bank_ref: Joi.string().label('Bank Ref').meta({ cellShow: false, disabled: true }),
  approval_code: Joi.string().label('Approval Code').meta({ cellShow: false, disabled: true }),
  retry_flag: Joi.string().label('Retry Flag').meta({ cellShow: false, disabled: true }),
});
