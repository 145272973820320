import styled from 'styled-components';
import colors from '../../styles/colors';

export const WrapperLoading = styled.div`
  width: ${(props) => (props.inline ? '' : '100%')};
  text-align: center;
`;

export const LoadingItem = styled.div`
  color: ${colors.blueTine};
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  margin: ${(props) => (props.inline ? '0' : '3rem auto')};
`;
