import * as TYPES from '../types/transaction';
import * as API from '../api/transaction';

export const getTransaction = (params) => async (dispatch) => {
  dispatch({type: TYPES.LIST_TRANSACTION_REQ});
  try {
    const response = await API.getTransaction(params);
    if (response.status === 200) {
      dispatch({type: TYPES.LIST_TRANSACTION_SUCCESS, payload: response});
    } else {
      dispatch({type: TYPES.LIST_TRANSACTION_FAIL, payload: response});
    }
  } catch (error) {
    dispatch({type: TYPES.LIST_TRANSACTION_FAIL, payload: error});
  }
};

export const getTransactionInfo = (id) => async (dispatch) => {
  dispatch({type: TYPES.GET_TRANSACTION_REQ});
  try {
    const response = await API.getTransactionInfo(id);
    if (response.status === 200) {
      dispatch({type: TYPES.GET_TRANSACTION_SUCCESS, payload: response});
    } else {
      dispatch({type: TYPES.GET_TRANSACTION_FAIL, payload: response});
    }
  } catch (error) {
    dispatch({type: TYPES.GET_TRANSACTION_FAIL, payload: error});
  }
};
export const updateTransactionStatus = (id, body) => async (dispatch) => {
  dispatch({type: TYPES.UPDATE_TRANSACTION_REQ});
  try {
    const response = await API.updateTransactionStatus(id, body);
    if (response.status === 200) {
      dispatch({type: TYPES.UPDATE_TRANSACTION_SUCCESS, payload: response});
    } else {
      dispatch({type: TYPES.UPDATE_TRANSACTION_FAIL, payload: response.response.data.error});
    }
  } catch (error) {
    dispatch({type: TYPES.UPDATE_TRANSACTION_FAIL, payload: error});
  }
};

export const exportTransaction = (params) => async (dispatch) => {
  dispatch({type: TYPES.EXPORT_TRANSACTION_REQ});
  try {
    const response = await API.exportTransaction(params);
    if (response.status === 200) {
      dispatch({type: TYPES.EXPORT_TRANSACTION_SUCCESS, payload: response});
    } else {
      dispatch({type: TYPES.EXPORT_TRANSACTION_FAIL, payload: response});
    }
  } catch (error) {
    dispatch({type: TYPES.LIST_TRANSACTION_FAIL, payload: error});
  }
};

export const updateTransaction = (id, body) => async (dispatch) => {
  dispatch({type: TYPES.UPDATE_TRANSACTION_INFO_REQ});
  try {
    const response = await API.updateTransaction(id, body);
    if (response.status === 200) {
      dispatch({type: TYPES.UPDATE_TRANSACTION_INFO_SUCCESS, payload: response});
    } else {
      dispatch({type: TYPES.UPDATE_TRANSACTION_INFO_FAIL, payload: response.response.data.error});
    }
  } catch (error) {
    dispatch({type: TYPES.UPDATE_TRANSACTION_INFO_FAIL, payload: error});
  }
};
