import { useDispatch, useSelector } from 'react-redux';
import * as todoActions from '../redux/actions/down-payment';

export const useDownPayment = () => {
    const dispatch = useDispatch();

    async function dispatchExportDownPayment(downPaymentNumber) {
        dispatch(todoActions.exportDownPayment(downPaymentNumber));
    }

    return {
        dispatchExportDownPayment
    };
};