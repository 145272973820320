const workTelLabel = (prop) => {
  return (
    <>
      <div>
        <p className="text-remark-gray">{"Ex. เบอร์ที่ทำงาน/เบอร์ติดต่อกิจการ"}</p>
      </div>
    </>
  );
};

export default workTelLabel;
