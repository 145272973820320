import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  chip: {
    // fontSize: '10px',
  },
  pending: {
    color: '#F5B813',
    backgroundColor: 'rgba(245, 184, 19, 0.15)',
  },
  confirmed: {
    color: '#01B927',
    backgroundColor: 'rgba(1, 185, 39, 0.15)',
  },
  canceled: {
    color: '#E01E01',
    backgroundColor: 'rgba(224, 30, 1, 0.15)',
  },
}));

const ChipStatus = (props) => {
  const { status } = props;
  const classes = useStyles();

  const Status = () => {
    return status ? <Chip className={`${classes.chip} ${classes.confirmed}`} label="On app" /> : <Chip className={`${classes.chip} ${classes.pending}`} label="Tradesys" />;
  };

  return <Status />;
};

ChipStatus.propTypes = {
  status: PropTypes.bool,
};

ChipStatus.defaultProps = {
  status: false,
};

export default ChipStatus;
