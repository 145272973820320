import {Spin, Card, Modal} from 'antd';
import React, {useState, useContext, useEffect, useCallback} from 'react';
import {TrackingTaskDashboardSearchFrom} from '../../constants/global/SearchForm';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {TrackingTaskColumn} from '../../constants/global/columnTableForm';
import {ButtonTheme} from '../../components/buttons';
import {TeamOutlined} from '@ant-design/icons';
import TrackerListContext from './TrackingTaskProvider';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import AddSpecialTaskModal from '../../components/modal/TrackingTask/AddSpecialTaskModal';
import EditTrackingTaskModal from '../../components/modal/TrackingTask/EditTrackingTaskModal';
import TrackingHistoryModal from '../../components/modal/TrackingTask/TrackingHistoryModal';
import ChangeTrackerModal from '../../components/modal/TrackingTask/ChangeTrackerModal';
import classes from './trackingTaskStyles.module.css';
import {TRACKING_STATUS} from '../../constants/status/status';
import Wrapper from '../../views/wrapper/Wrapper';
import {useGetExportTrackingTasks} from './api';
import {GET, GET_JOBTYPE, GET_TRACKERS} from '../../services';

const TrackingTaskInfo = () => {
  const [dataPass, setDataPass] = useState();
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [visibleEditWage, setVisibleEditWage] = useState(false);
  const [visibleAddSpecialTask, setVisibleAddSpecialTask] = useState(false);
  const [visibleChangeTracker, setVisibleChangeTracker] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [optionTracker, setOptionTracker] = useState([]);
  const [jobTypeOption, setJobTypeOption] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const res = await GET(GET_TRACKERS);
      const {success, result} = res;
      if (success) {
        const option = result.map((item) => ({
          label: item.name,
          value: item.trackerID,
        }));
        setOptionTracker(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  }, []);

  const fetchJobType = useCallback(async () => {
    try {
      const res = await GET(GET_JOBTYPE);
      const {success, result} = res;
      if (success) {
        const option = result.map((item) => ({
          label: item.label,
          value: item.value,
        }));
        setJobTypeOption(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  }, []);

  useEffect(() => {
    fetchData();
    fetchJobType();
  }, []);

  const ctx = useContext(TrackerListContext);

  const [resultReport, loadingReport, errorReport, setInputReport] = useGetExportTrackingTasks();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        closeModal('report');
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;

      Modal.error({title: 'ดึงข้อมูลไม่สำเร็จ', content: message});
      closeModal('report');
    }
  }, [errorReport]);

  const onExportExcelReport = async () => {
    console.log('ctx?.paramsUrl: ', ctx?.paramsUrl);
    ctx?.paramsUrl !== null && setInputReport(ctx?.paramsUrl);
  };

  const openModal = (ref, record) => {
    switch (ref) {
      case 'details':
        setVisibleDetails(true);
        break;
      case 'edit':
        setVisibleEditWage(true);
        break;
      case 'change':
        setVisibleChangeTracker(true);
        break;
      case 'report':
        setVisibleModal(true);
        break;
      default:
        break;
    }
    ref !== 'report' && setDataPass(record);
  };

  const closeModal = (ref) => {
    switch (ref) {
      case 'details':
        setVisibleDetails(false);
        break;
      case 'edit':
        setVisibleEditWage(false);
        break;
      case 'change':
        setVisibleChangeTracker(false);
        break;
      case 'add_task':
        setVisibleAddSpecialTask(false);
        break;
      case 'report':
        setVisibleModal(false);
        break;
      default:
        break;
    }
    ref !== 'report' && ctx.getFilteredData({});
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={ctx.isLoading || loadingReport}>
          <PageLayout
            searchLayout={{
              title: 'รายงานการจ่ายงานภาคสนาม',
              icon: <TeamOutlined />,
              spanSearch: 24,
              formSearch: TrackingTaskDashboardSearchFrom({
                data: ctx.data,
                option: {trackingOptions: ctx.trackingOptions, contractOptions: ctx.optionContractNo, terminateOption: ctx.terminateOption},
              }),

              onSearch: (data) => ctx.getFilteredData(data),
            }}
            tableLayout={{
              columns: TrackingTaskColumn({openModal}),
              dataSource: ctx.dataSource,
              rowClassName: (record, _) =>
                record.durationAfterTrackingTaskCreated >= 14 && record.trackingTaskStatus === TRACKING_STATUS.field_collection.en ? classes.red : '',
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => openModal('report')} />
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>

      {visibleDetails && <TrackingHistoryModal visible={visibleDetails} onClose={() => closeModal('details')} data={dataPass} />}
      {visibleEditWage && <EditTrackingTaskModal visible={visibleEditWage} onClose={() => closeModal('edit')} data={dataPass} />}
      {visibleChangeTracker && (
        <ChangeTrackerModal
          visible={visibleChangeTracker}
          onClose={() => closeModal('change')}
          data={dataPass}
          options={{optionTracker, jobTypeOption}}
        />
      )}
      {visibleAddSpecialTask && <AddSpecialTaskModal visible={visibleAddSpecialTask} onClose={() => closeModal('add_task')} title="จ่ายงานพิเศษ" />}

      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={() => closeModal('report')}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => closeModal('report')} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default TrackingTaskInfo;
