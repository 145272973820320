import PropTypes from 'prop-types';
import { Table, Td, Th } from './styled-component';
import { convertStrToFormat } from '../../functions/fn';

const CustomerOtherTelLogTable = (props) => {
  const { logs } = props;
  logs.map((el, i) => {
    return {
      ...el,
      key: i + 1,
    }
  })

  return (
    <>
      <Table style={{ width: '100%', alignSelf: 'center', marginBlock: '3%' }}>
        <thead>
          <tr>
            <Th>เบอร์โทรศัพท์</Th>
            <Th>วันที่บันทึก</Th>
            <Th>สถานะ</Th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={logs?.key}>
              <Td>{log?.tel}</Td>
              <Td>{new Intl.DateTimeFormat('th-TH', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(log?.datetime))}</Td>
              <Td>{log?.status !== 'ACTIVE' ? 'เบอร์เก่า' : 'เบอร์ใหม่'}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

CustomerOtherTelLogTable.propTypes = {
  logs: PropTypes.array,
};

CustomerOtherTelLogTable.defaultProps = {
  logs: [],
};

export default CustomerOtherTelLogTable;
