import {API_ADMIN_SERVICE, APP_API_KEY, API_PRODUCT_SERVICE} from '../../../env';
import {axiosInstance, decodeUserAdmin} from '../../../oauth';
import {GET_MASTER_DATA, GET_PRODUCT_MASTER_DATA} from './graphql/Query';

export const getAdminMasterData = async (input) => {
  try {
    const userAdminV2 = await decodeUserAdmin();
    if (!userAdminV2) return;

    const {accessToken, clientKey} = userAdminV2;

    const response = await axiosInstance.post(
      API_ADMIN_SERVICE,
      {
        query: GET_MASTER_DATA,
        variables: {...input},
      },
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': APP_API_KEY,
          'client-key': clientKey,
        },
      },
    );

    return response?.data?.data?.getMasterData;
  } catch (error) {
    return null;
  }
};

export const getProductFeatureMasterDt = async (input) => {
  try {
    const userAdminV2 = await decodeUserAdmin();
    if (!userAdminV2) return;

    const {accessToken, clientKey} = userAdminV2;

    const response = await axiosInstance.post(
      API_PRODUCT_SERVICE,
      {
        query: GET_PRODUCT_MASTER_DATA,
        variables: {...input},
      },
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': APP_API_KEY,
          'client-key': clientKey,
        },
      },
    );

    return response?.data?.data?.getProductFeatureMaster;
  } catch (error) {
    return error;
  }
};
