export const GET_MASTER_DATA = `
  query GetMasterData($input: MasterDataInp!) {
    getMasterData(input: $input) {
      code
      name
      is_active
      is_visible
    }
  }
`;

export const GET_MDM_SETTING_DEFAULT_LIST = `
  query GetMDMSettingDefaultList($input: MDMSettingDefaultListInp!) {
    getMDMSettingDefaultList(input: $input) {
      result {
        type_lock
        mdm_site_code
        mdm_provider_code
      }
    }
  }
`;

