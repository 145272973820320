// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Spin, Table, Typography } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { ReportTrackingFeeSearchForm } from '../../constants/global/SearchForm';
import { ReportTrackingFeeColumn } from '../../constants/global/columnTableForm';
import { GET, POST, GET_REPORT_TRACKING_FEE, GET_TRACKERS_ID } from '../../services/index';
import { auth } from '../../utils/firebase';
import { useCurrentState } from './../../utils/hook';
import { SummaryRow } from '../../components/summary-row';
import { convertStrToFormat } from '../../functions/fn';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../components/buttons';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const { Title } = Typography

const ReportTrackingFee = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [dataExport, setDataExport] = useState([])
    const [defaultValue, setDefaultValue] = useState({});
    const { user } = useCurrentState(auth);
    // const [summary, setSummary] = useState(null)
    // const [summaryData, setSummaryData] = useState(null)
    const [totalRecord, setTotalRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(null)
    const [optionTrackers, setOptionTrackers] = useState([]);

    useEffect(async () => {
        await getIdTrackersOption()
        await getDataReportTrackingFee({});
    }, []);

    const getIdTrackersOption = async () => {
        try {
            const res = await GET(GET_TRACKERS_ID);
            const { success, data } = res;
            if (success) {
                const result = data.map((el) => {
                    return {
                        label: el.value,
                        value: el._id
                    }
                })
                setOptionTrackers(result)
            }
        } catch (err) {
            const { message, error } = err;
            Modal.error({
                title: message,
                content: error || '',
            });
        }
    }

    const getDataReportTrackingFee = async (value) => {

        value.startDate = value.startDate ? moment(value.startDate).format('YYYY-MM-DD') : '';
        value.endDate = value.endDate ? moment(value.endDate).format('YYYY-MM-DD') : '';
        value.trackers = value.trackers || '';
        value.name = value.name || '';
        value.status = value.status || '';

        const { startDate, endDate, } = value;

        setLoading(true);

        let params = [];

        try {
            Object.keys(value).forEach((key) => {

                if (value[key]) {
                    if (key === 'startDate') {
                        params.push('startDate=' + convertStrToFormat(startDate, 'dateAD'));
                    } else if (key === 'endDate') {
                        if (value[key]) {
                            params.push('endDate=' + convertStrToFormat(endDate, 'dateAD'));
                        } else if (!value[key]) {
                            params.push('endDate=' + convertStrToFormat(new Date(), 'dateAD'));
                        }
                    } else {
                        params.push(key + '=' + value[key]);
                    }
                }
            });

            let url = GET_REPORT_TRACKING_FEE;
            if (params.length !== 0) {
                url += '?' + params.join('&');
            }

            const res = await POST(url);
            const { success, TerminationOfContractInfo, total_amount, total_paid_amount, total_people, total_remain_amount } = res;

            if (success) {
                const result = TerminationOfContractInfo.map((el) => {
                    return {
                        trackers: el.TrackersName,
                        name: el.customerName,
                        paymentRecentDate: el?.payments[0]?.latest_payment_date || '',
                        amount: el?.payments[0]?.amount,
                        paid_amount: el?.payments[0]?.paid_amount,
                        remain_amount: el?.payments[0]?.remain_amount,
                        status: el?.payments[0]?.status,
                        ...el,
                    }
                })

                let sumDataRow = {
                    index: total_people,
                    trackers: 'รวม',
                    name: '',
                    contract_no: '',
                    paymentRecentDate: `${total_people} คน`,
                    amount: total_amount, paid_amount: total_paid_amount, remain_amount: total_remain_amount,
                    status: '',
                }

                const dataEx = TerminationOfContractInfo.map((el, i) => {
                    return {
                        '#': i + 1,
                        'ชื่อผู้ติดตาม': el.TrackersName,
                        'ชื่อ-สกุล (ลูกค้า)': el.customerName,
                        'เลขที่สัญญา': el.contract_no,
                        'วันที่จ่าย(ล่าสุด)': el?.payments[0]?.latest_payment_date || '',
                        'ยอดรวม': el?.payments[0]?.amount,
                        'จ่ายแล้ว': el?.payments[0]?.paid_amount,
                        'ค้างจ่าย': el?.payments[0]?.remain_amount,
                        'สถานะ': el?.payments[0]?.status === 'COMPLETED' ? 'จ่ายแล้ว' : el?.payments[0]?.status === 'CHARGE_OVERDUED' ? 'ค้างจ่าย' : '',
                    }
                })


                setDataExport(dataEx)

                let data = [...result, sumDataRow]
                setTotalRecord(total_people)
                setLastRecord(data.length - 1)
                setDataSource(data)
            }
        } catch (err) {
            const { message, error } = err;
            Modal.error({
                title: message,
                content: error || '',
            });
        } finally {
            setLoading(false);
        }
    };

    // const summary = () => {
    //     let data = null
    //     if (summaryData) {
    //         data = SummaryRow({ size: 6, colSpanIndex: [{ span: 3, index: 0 }], data: summaryData })
    //     }
    //     return data
    // }

    const optionStatus = [
        { value: 'CHARGE_OVERDUED', label: 'ค้างจ่าย' },
        { value: 'COMPLETED', label: 'จ่ายแล้ว' },
    ];

    return (
        <Wrapper>
            <Card>
                <Spin tip="Loading..." spinning={loading}>
                    <PageLayout
                        searchLayout={{
                            title: 'รายงานค่าติดตามภาคสนามรายเดือน',
                            icon: <BookOutlined />,
                            spanSearch: 24,
                            formSearch: ReportTrackingFeeSearchForm({ option: { optionStatus, optionTrackers } }),
                            onSearch: (data) => getDataReportTrackingFee(data),
                        }}
                        tableLayout={{
                            columns: ReportTrackingFeeColumn({ option: { optionStatus }, data: { totalRecord, lastRecord, dataSource } }),
                            dataSource: dataSource,
                            extraContent: (
                                <ContainerButton right>
                                    <CSVLink data={dataExport} filename={"รายงานค่าติดตามภาคสนามรายเดือน"}><ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} /></CSVLink>
                                </ContainerButton>),
                            // summary: summary || null,
                        }}
                    />
                </Spin>
            </Card>
        </Wrapper>
    );
};

export default ReportTrackingFee;