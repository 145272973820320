export const color = {
  theme: '#0751A9',
  tracking: '#F0246D',
  danger: '#dc3545',
  red: '#ff0000',
  success: '#009000',
  edit: '#ffc107',
  start: '#1cb99a',
  submit: '#28a745',
  success2: '#93c47d',
  clear: '#707070',
  remove: '#FF698A',
  search: '#213BD1',
  create: '#6f42c1',
  logout: '#737373',
  load: '#87D068',
  white: '#ffffff',
  manage: '#fd7e14',
  on_process: '#40a9ff',
  tranfer: '#6f42c1',
  disabled: '#d9d9d9',
  gray: '#dce0e8',
  light_gray: '#efefef',
  light_gray2: '#f9fbfa',
  black: '#000000',
  light_yellow: '#ffd966',
  view: '#156900'
};
