// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect, useRef } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Spin, Space, Button, Row, Select, DatePicker } from 'antd';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../components/buttons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { ManageDEPColumn } from '../../constants/global/columnTableForm';
import { ManageDEPSearchForm } from '../../constants/global/SearchForm';
import { GET_MANAGE_DEP_DATA, PUT, EDIT_MANAGE_DEP, GET_MANAGE_DEP_EXPORT } from '../../services/index';
import { CSVLink } from "react-csv";
import { convertStrToFormat, exportDefault, generatedOptionWithData } from "../../functions/fn"
import { color } from '../../resources/color';
import { Icon } from '../../resources/icon';
// import { buildArrayOfObjectWithNoDulplicateKey } from '../../functions/fn';
import {
  SettingOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {useGetProductFeature} from './api/api';

const ManageDEPScreen = () => {

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([])
  const [dataSearch, setDataSearch] = useState({ docNo: "", appleStatus: "", productName: "", serialNumber: "", serialStatus: '', type: '' })
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selected, setSelected] = useState([]);
  const [docNoSearch, setDocNoSearch] = useState('')
  const [enableEditOneRow, setEnableEditOneRow] = useState(false)
  const [enableEditIndex, setEnableEditIndex] = useState(null)
  const [enableEditMutlpleRow, setEnableEditMultipleRow] = useState(false)
  const [valueChangeSelectMulti, setValueChangeSelectMulti] = useState(null)
  // const [valueChangeSelectMultiMDMDate, setValueChangeSelectMultiMDMDate] = useState(null)
  const [disabledCheckbox, setDisabledCheckBox] = useState(false)
  const [optionProduct, setOptionProduct] = useState([])

  const [docNoState, setDocNoState] = useState(null)
  const [appleStatusState, setAppleStatusState] = useState(null)
  const [productNameState, setProductNameState] = useState(null)
  const [serialNumberState, setSerialNumberState] = useState(null)
  const [serialStatusState, setSerialStatusState] = useState(null)
  const [typeState, setTypeState] = useState(null)
  const pageRef = useRef(1)
  const sizeRef = useRef(10)
  const [totalSize, setTotalSize] = useState(0)
  // const [totalPage, setTotalPage] = useState(0)
  const [visibleModal, setVisibleModal] = useState(false)
  const [optionItemType, setOptionItemType] = useState([{value: 'TOTAL', label: 'ทั้งหมด'}]);


  let optionDocNo = []
  let optionProductName = []
  let optionSerialNumber = []

  useEffect(async () => {
    const params = new URLSearchParams(window.location.search);
    const docNoParams = params.get("docNo")

    // setDocNo(docNoParams)
    // await getOption()

    if (docNoParams) {
      setDataSearch({ docNo: docNoParams, appleStatus: "", productName: '', serialNumber: '', docNoManageDEP: docNoParams, serialStatus: '', type: '' })
      await getManageDEPData({ page: pageRef.current, size: sizeRef.current, docNo: docNoParams, appleStatus: "", productName: '', serialNumber: '', serialStatus: '', type: '' })
      // await getManageDEPData({ docNo: docNoParams, appleStatus: "", productName: '', serialNumber: '', serialStatus: '', type: '' })
    } else {
      setDataSearch({ docNo: null, appleStatus: "", productName: "", serialNumber: "", docNoManageDEP: null, serialStatus: '', type: '' })
      await getManageDEPData({ page: pageRef.current, size: sizeRef.current, docNo: '', appleStatus: '', productName: '', serialNumber: '', serialStatus: '', type: '' })
      // await getManageDEPData({ docNo: '', appleStatus: '', productName: '', serialNumber: '', serialStatus: '', type: '' })
    }
  }, []);

  const [resultProductFeature, loadingProductFeature] = useGetProductFeature();
  useEffect(() => {
    if (resultProductFeature) {
      getOptionProductFeature(resultProductFeature);
    }
  }, [resultProductFeature]);

  const getOptionProductFeature = async (productFeatureDt) => { 
    if (productFeatureDt?.length > 0) {
      const productFeatureArray = productFeatureDt?.map((item) => ({label: item?.name, value: item?.code}));
      setOptionItemType([...optionItemType, ...productFeatureArray]);
    }
  };

  const optionAppleStatus = [
    { label: 'DEP', value: 'DEP' },
    { label: 'Non-DEP', value: 'NON_DEP' },
    { label: 'TBD', value: 'TBD' },
  ]

  
  // const createParams = (value) => {
  //   let params = []
  //   let result = ''

  //   Object.keys(value).forEach((key) => {
  //     if (value[key]) {
  //       params.push(key + '=' + value[key]);
  //     }
  //   });
  //   if (params.length !== 0) {
  //     result += params.join('&');
  //   }
  //   // }

  //   return result
  // }

  // const getOption = async () => {
  //   try {
  //     const res = await PUT(GET_MANAGE_DEP_DATA, { docNo: '', appleStatus: '', productName: '', serialNumber: '', serialStatus: '', type: '' });
  //     const { success, data } = res;
  //     if (success) {

  //       buildArrayOfObjectWithNoDulplicateKey('docNo', data).map((val, i) => {
  //         optionDocNo.push({
  //           value: val.docNo,
  //           label: val.docNo
  //         })
  //       });

  //       // buildArrayOfObjectWithNoDulplicateKey('productName', data).map((val, i) => {
  //       //   optionProductName.push({
  //       //     value: val.productName,
  //       //     label: val.productName
  //       //   })
  //       // });
  //       let optionPro = generatedOptionWithData(data, 'productName')

  //       setOptionProduct(optionPro)

  //       buildArrayOfObjectWithNoDulplicateKey('serialNumber', data).map((val, i) => {
  //         optionSerialNumber.push({
  //           value: val.serialNumber,
  //           label: val.serialNumber
  //         })
  //       });

  //       return optionDocNo, optionProductName, optionSerialNumber
  //     }
  //   } catch (err) {
  //     const { message, error } = err;
  //     Modal.error({
  //       title: message,
  //       content: error || '',
  //     });
  //   }
  // }


  const getManageDEPData = async (value) => {

    value.page = value?.page || 1
    value.size = value?.size || 10

    pageRef.current = value.page
    sizeRef.current = value.size

    value.docNo = value?.docNo || ''
    value.appleStatus = value?.appleStatus || ''
    value.productName = value?.productName || ''
    value.serialNumber = value?.serialNumber || ''
    value.serialStatus = value?.serialStatus || ''
    value.type = value?.type ? parseInt(value?.type) : ''

    const { page, size, docNo, appleStatus, productName, serialNumber, serialStatus, type } = value

    setDocNoState(docNo)
    setAppleStatusState(appleStatus)
    setProductNameState(productName)
    setSerialNumberState(serialNumber)
    setSerialStatusState(serialStatus)
    setTypeState(type)

    if (docNo === '') {
      setDataSearch({
        docNo: null,
        appleStatus: value?.appleStatus || '',
        productName: value?.productName || '',
        serialNumber: value?.serialNumber || '',
        serialStatus: value?.serialStatus || '',
        type: value?.type || '',
        docNoManageDEP: null,
      })
    } else {
      setDataSearch({
        docNo: docNo,
        appleStatus: value?.appleStatus || '',
        productName: value?.productName || '',
        serialStatus: value?.serialStatus || '',
        type: value?.type || '',
        docNoManageDEP: docNo
      })
    }
    setLoading(true);
    try {
      let obj = {
        page: pageRef.current,
        size: sizeRef.current,
        docNo: docNo,
        appleStatus: appleStatus,
        productName: productName,
        serialNumber: serialNumber,
        serialStatus: serialStatus,
        type: type
      }
      let res = await PUT(GET_MANAGE_DEP_DATA, obj);
      // let res = await PUT(GET_MANAGE_DEP_DATA, { docNo: docNo, appleStatus: appleStatus, productName: productName, serialNumber: serialNumber, serialStatus: serialStatus, type: type });

      const { success, data, total_item, total_page } = res;
      if (success) {

        if (total_item > 0) {
          setDocNoSearch(docNo)
          // setTotalPage(total_page)
          setTotalSize(total_item)

          const report = data.map((el, i) => {
            return {
              key: i + 1,
              oldAppleStatus: el?.appleStatus || '',
              oldMDM: el?.mdm || null,
              ...el,
            };
          });
          const dataEx = data.map((el, i) => {
            return {
              'เลขที่ใบสั่งซื้อ': el.docNo,
              'ชื่อสินค้า': el.productName,
              'S/N': el.serialNumber || '',
              'Apple Status': el.appleStatus,
              'Serial Status': el.serialStatus && el.serialStatus === 'NOT_READY' ? 'ไม่พร้อมขาย' : el.serialStatus === 'READY' ? 'พร้อมขาย' : '-',
              'วันที่ MDM': el.mdm,
            }
          });

          const dataExportDefault = exportDefault(['เลขที่ใบสั่งซื้อ', 'ชื่อสินค้า', 'S/N', 'Apple Status'])
          setDataExport(dataEx.length > 0 && dataEx || dataExportDefault)
          setDataSource(report);

        } else {
          setDataSource([])
        }

      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDataForExport = async () => {
    setLoading(true)

    let obj = {
      docNo: docNoState,
      appleStatus: appleStatusState,
      productName: productNameState,
      serialNumber: serialNumberState,
      serialStatus: serialStatusState,
      type: typeState,
    }

    try {
      const res = await PUT(GET_MANAGE_DEP_EXPORT, obj)
      const { success, data } = res

      if (success) {
        const result = data.map((el, i) => {
          return {
            'เลขที่ใบสั่งซื้อ': el.docNo,
            'ชื่อสินค้า': el.productName,
            'S/N': el.serialNumber || '',
            'Apple Status': el.appleStatus,
            'Serial Status': el.serialStatus && el.serialStatus === 'NOT_READY' ? 'ไม่พร้อมขาย' : el.serialStatus === 'READY' ? 'พร้อมขาย' : '-',
            'วันที่ MDM': el.mdm !== null ? moment(el.mdm).format('DD/MM/YYYY') : '',
          }
        })
        setDataExport(result)
      }

    } catch (err) {
      const { message, error } = err;

      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false)
      setVisibleModal(true)
    }
  }

  const onEditOnRow = (index) => {
    setEnableEditOneRow(true)
    setEnableEditIndex(index)
    setDisabledCheckBox(true)
  }

  const onCancelEditOnRow = (index) => {
    let data = [...dataSource]
    let selectedIndex = data.findIndex((el) => el.key === index)

    data[selectedIndex].appleStatus = data[selectedIndex].oldAppleStatus

    data[selectedIndex].mdm = data[selectedIndex].oldMDM
    setDataSource(data)
    setEnableEditOneRow(false)
    setEnableEditIndex(null)
    setDisabledCheckBox(false)
  }

  const onEditMultipleRow = () => {
    if (selected && selected.length !== 0) {
      setEnableEditMultipleRow(true)
    } else {
      Modal.warning({
        title: 'กรุณาเลือกข้อมูลที่ต้องการแก้ไข',
      });
    }
  }

  const onCancelEditMultipleRow = () => {
    setValueChangeSelectMulti(null)
    setEnableEditMultipleRow(false)
    setSelectedRowKeys([]);
    setSelected([])
  }

  const openConfirm = (ref, data) => {
    if (!!ref && !!data) {
      if (ref === 'edit_one') {
        Modal.confirm({
          title: 'ยืนยัน',
          icon: <Icon.warningAlert />,
          content: 'ยืนยันการแก้ไขข้อมูล',
          onOk() {
            upDateAppleStatus(data)
          },
          okText: 'ยืนยัน',
          cancelText: 'ยกเลิก',
        });
      }
    } else {
      if (selected && selected.length !== 0) {
        if (valueChangeSelectMulti !== null) {
          Modal.confirm({
            title: 'ยืนยัน',
            icon: <Icon.warningAlert />,
            content: 'ยืนยันการแก้ไข Apple Status',
            onOk() {
              upDateAppleStatusMulti()
            },
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
          });
        } else {
          Modal.warning({
            title: 'กรุณาเลือกสถานะที่ต้องการแก้ไข',
          });
        }
      } else {
        Modal.warning({
          title: 'กรุณาเลือกข้อมูลที่ต้องการแก้ไข',
        });
      }
    }

  };

  const upDateAppleStatus = async (record) => {
    setLoading(true);
    try {
      const res = await PUT(EDIT_MANAGE_DEP, [{ serialNumber: record.serialNumber, appleStatus: record.appleStatus, mdm: moment(record.mdm).format('DD/MM/YYYY') }]);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            if (docNoSearch !== '') {
              getManageDEPData({ docNo: docNoSearch });
            } else {
              getManageDEPData({});
            }
            setEnableEditOneRow(false)
            setEnableEditIndex(null)
            setDisabledCheckBox(false)
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
    finally {
      setLoading(false);
    }
  };

  const upDateAppleStatusMulti = async () => {

    setLoading(true);
    try {
      const res = await PUT(EDIT_MANAGE_DEP, selected);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            if (docNoSearch !== '') {
              getManageDEPData({ docNo: docNoSearch });
            } else {
              getManageDEPData({});
            }
            clearCheckbox()
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
    finally {
      setLoading(false);
    }
  };

  const updateDropdown = () => {
    let data = selected

    const handleChange = (value) => {
      if (value !== null) {
        setValueChangeSelectMulti(value)
        data.map((el) => {
          el.appleStatus = value

        })
        setSelected(data)
      }
    }

    // const handleChangeMDMDate = (value) => {
    //   if (value !== null) {
    //     let formatDate = moment(value).format('DD/MM/YYYY')

    //     // setValueChangeSelectMultiMDMDate(formatDate)
    //     data.map((el) => {
    //       el.mdm = formatDate
    //     })

    //     setSelected(data)
    //   }
    // }

    return (
      <>
        <Select
          style={{ width: 180, textAlign: 'center' }}
          placeholder='กรุณาเลือกสถานะ'
          onChange={handleChange}
          options={[
            { label: 'DEP', value: 'DEP' },
            { label: 'Non-DEP', value: 'NON_DEP' },
            { label: 'TBD', value: 'TBD' },
          ]}
          value={valueChangeSelectMulti}
        />
        {/* <DatePicker
          placeholder={'เลือกวันที่'}
          style={{ width: 250 }}
          onChange={(e) => handleChangeMDMDate(e)}
          format={"DD/MM/YYYY"}
        /> */}
        <div style={{ display: 'inline-block' }}>
          <Button
            style={{ backgroundColor: color.danger, color: color.white, borderRadius: 60 }}
            className="ant-btn-customize"
            icon={<CloseOutlined />}
            onClick={() => onCancelEditMultipleRow()}
          />
          <Button
            style={{ backgroundColor: color.submit, color: color.white, borderRadius: 60, marginLeft: 10 }}
            className="ant-btn-customize"
            icon={<CheckOutlined />}
            onClick={() => openConfirm()}
          />
        </div>
      </>
    )
  }

  // const clearRow = () => {
  //   setSelectedRowKeys([])
  //   setSelected([])
  // }

  const clearCheckbox = () => {
    setLoading(true);
    setTimeout(() => {
      setEnableEditMultipleRow(false)
      setValueChangeSelectMulti(null)
      setSelectedRowKeys([]);
      setSelected([])
      setLoading(false);
    }, 600);
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.map(val => {
      setValue(val.name, null)
    })
    if (window.location.href.includes('?')) {
      window.history.pushState({}, null, window.location.href.split('?')[0]);
    }
    setDataSearch({ docNo: null, appleStatus: "", productName: "", serialNumber: "", docNoManageDEP: null, type: '' })
  }

  const onClearCustomField = () => {
    setDataSearch({ docNo: null, appleStatus: "", productName: "", serialNumber: "", docNoManageDEP: null, type: '' })
  }

  const onChangeSelectedAppleStatus = (value, index) => {
    let data = [...dataSource]
    let selectedIndex = data.findIndex((el) => el.key === index)

    data[selectedIndex].appleStatus = value
    setDataSource(data)
  };

  const onChangeSelectedMDMDate = (value, index) => {
    let data = [...dataSource]
    let selectedIndex = data.findIndex((el) => el.key === index)
    data[selectedIndex].mdm = moment(value)
    setDataSource(data)
  };

  const onClose = () => {
    setVisibleModal(false)
  }

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      // setPageState(1)
      pageRef.current = 1
    } else {
      // setPageState(pageData)
      pageRef.current = pageData
    }
    sizeRef.current = sizeData

    let docNo = docNoState
    let appleStatus = appleStatusState
    let productName = productNameState
    let serialNumber = serialNumberState
    let serialStatus = serialStatusState
    let type = typeState

    const body = {
      page: pageRef.current,
      size: sizeRef.current,
      docNo: docNo || '',
      appleStatus: appleStatus || '',
      productName: productName || '',
      serialNumber: serialNumber || '',
      serialStatus: serialStatus || '',
      type: type || '',
    };

    getManageDEPData(body);
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingProductFeature}>
          <PageLayout
            searchLayout={{
              title: 'จัดการ DEP',
              icon: <SettingOutlined />,
              spanSearch: 24,
              formSearch: ManageDEPSearchForm(dataSearch, onClearCustomField, {
                option: {
                  optionAppleStatus, optionDocNo, optionProductName, optionSerialNumber, optionProduct, optionItemType
                }
              }),
              onSearch: (data) => getManageDEPData(data),
              dataSearchDefault: dataSearch,
              onClearCustom
            }}
            tableLayout={{
              columns: ManageDEPColumn({ option: { optionAppleStatus }, openConfirm, onEditOnRow, onCancelEditOnRow, onChangeSelectedAppleStatus, enableEditOneRow, enableEditIndex, selected, onChangeSelectedMDMDate }),
              dataSource: dataSource,
              rowSelection: {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(
                    selectedRows.map((row) => {
                      return { serialNumber: row.serialNumber, appleStatus: row.appleStatus, mdm: row.mdm !== null ? moment(row.mdm).format('DD/MM/YYYY') : null };
                    }),
                  );
                  setSelectedRowKeys(selectedRowKeys)
                },
                getCheckboxProps: () => {
                  if (disabledCheckbox) {
                    return {
                      disabled: true,
                    };
                  }
                },
              },
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="space-between">
                  <Space>
                    {((selected && selected.length !== 0) && enableEditMutlpleRow) ?
                      updateDropdown()
                      : <></>}
                    {!enableEditMutlpleRow ?
                      <Button
                        type="primary"
                        style={{ background: color.tranfer, borderColor: color.tranfer }}
                        size="normal"
                        icon={<Icon.edit />}
                        // onClick={() => openConfirm()}
                        onClick={() => onEditMultipleRow()}
                      >
                        แก้ไขข้อมูลหลายแถว
                      </Button> :
                      <></>}
                  </Space>
                  <Space>
                    <ContainerButton right>
                      <ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} onClick={() => fetchDataForExport()} />
                    </ContainerButton>
                    {/* <ContainerButton right>
                      <CSVLink data={dataExport} filename={"รายงานจัดการ DEP"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink>
                    </ContainerButton> */}
                  </Space>
                </Row>
              ),
              pagination: {
                showSizeChanger: true,
                current: pageRef.current,
                pageSize: sizeRef.current,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef.current,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <Modal title={'ต้องการดาวน์โหลด Excel?'} open={visibleModal} width={550} onCancel={onClose} footer={null} destroyOnClose={true} maskClosable={false}>
        <ContainerButton right>
          <CSVLink data={dataExport} filename={"รายงานจัดการ DEP"}><ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={() => onClose()} /></CSVLink>
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default ManageDEPScreen;