/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Grid, Radio, RadioGroup, FormControlLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, CheckCircle, Cancel, ArrowBack } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { toastContext } from '../../utils/toastProvider';
import { useTransaction } from '../../hook/useTransaction';
import Wrapper from '../../views/wrapper/Wrapper';
import Loading from '../../components/loading/Loading';
import { Div, Background, Table, Th, Td, WrapTable, HeaderInfo, FormUpdateWrap, Button, ImagePlaceholder } from './styled-component';
import ChipStatus from '../../components/chip/ChipStatus';
import Modal from '../../components/modal';
import { auth } from '../../utils/firebase';
import { useAuthState } from '../../utils/hook';
import { formatDate } from '../../utils/date';
import { updateTransactionStatus } from '../../redux/api/transaction';
import { color } from '../../resources/color';
import { Icon } from '../../resources/icon';
import { Modal as ModalAntd, Button as ButtonAntd } from 'antd';
import { ButtonTheme } from '../../components/buttons';
import { ContainerButton } from '../../styles/styledComponentGlobal';

const useStyles = makeStyles({
  icon_btn: {
    cursor: 'pointer',
    '&.save': {
      color: '#01D827',
    },
    '&.save:hover': {
      color: '#01B927',
    },
    '&.cancel': {
      color: '#E01E01',
    },
    '&.edit': {
      color: '#999999',
    },
  },
});

const getPaymentChannel = (info) => {
  if (info.admin_paid) return "-";
  if (info.slip) return "อัพโหลดสลิป";
  if (info.qrcode && info.qrcode.payload) return "QR code";
  return "-"
};

const TransactionInfo = () => {
  const { infoLoading, transactionInfo, infoError, dispatchGetInfo, dispatchUpdateInfoAndGet } = useTransaction();
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isEditInfo, setIsEditInfo] = useState(false);
  const [status, setStatus] = useState('');
  const [remark, setRemark] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCustomer, setOpenModalCustomer] = useState(false);
  const [config] = useContext(toastContext);
  const classes = useStyles();
  const { user } = useAuthState(auth);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    dispatchGetInfo(id);
  }, []);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('th-TH').format(amount);
  };

  const transactionPaymentType = (type) => {
    let translated = '';
    switch (type) {
      case 'DOWN': {
        translated = 'ชำระดาวน์';
        break;
      }
      case 'CHARGE_OVERDUED': {
        translated = 'ชำระค่าปรับ';
        break;
      }
      case 'CHARGE_WARNING': {
        translated = 'ชำระค่าทวงถาม';
        break;
      }
      case 'OTHER': {
        translated = 'ค่าใช้จ่ายอื่นๆ';
        break;
      }
      case 'STORAGE_COST': {
        translated = 'ค่าดูแลรักษาเครื่อง';
        break;
      }
      case 'TRACKING_COST': {
        translated = 'ค่าติดตาม';
        break;
      }
      default: {
        translated = 'ชำระยอดผ่อน';
      }
    }
    return translated;
  };

  const translateInfoType = (type) => {
    switch (type) {
      case 'INSTALLMENT':
        return 'ชำระค่างวด';
      case 'DOWN_PAYMENT':
        return 'ออมดาวน์';
      case 'COUPON':
        return 'คูปอง';
      default:
        return 'ทั้งหมด';
    }
  };

  const SlipImage = (props) => {
    if (!props.url) {
      return <ImagePlaceholder>No image</ImagePlaceholder>;
    }
    return <img width="300px" src={props.url} alt="" />;
  };

  const toggleUpdateStatus = () => {
    setIsEdit(!isEdit);
  };

  const updateStatus = (e) => {
    setStatus(e.target.value);
  };

  const updateRemark = (e) => {
    setRemark(e.target.value);
  };

  const updateDiscount = (e) => {
    setDiscount(e.target.value);
  };

  const showModalUpdateStatus = () => {
    setOpenModal(true);
  };

  const saveStatus = async (ignoreBlock) => {
    if (ignoreBlock) {
      setIsDisabled(true)
    }
    const data = {};
    if (status !== '') {
      data.status = status;
      data.updated_by = user.displayName;
      data.ignore_block = ignoreBlock;
    }
    const response = await updateTransactionStatus(id, data);
    if (response.status === 200) {
      toast.success('update success', config);
      dispatchGetInfo(id);
      setStatus('');
      setIsEdit(false);
      setOpenModalCustomer(false);
    } else {
      if (response.response.data.error === "user is not blocked payment") {
        setOpenModalCustomer(true);
      } else {
        toast.error(response.response.data.error, config);
        setStatus('');
        setIsEdit(false);
        setOpenModalCustomer(false);
      }
    }
    setOpenModal(false);
  };

  const saveInfo = () => {
    const data = {};
    if (remark !== null && remark !== '') {
      data.remark = remark;
      data.updated_by = user.displayName;
    }
    if (discount !== null && discount !== '') {
      data.discount = discount;
      data.updated_by = user.displayName;
    }
    dispatchUpdateInfoAndGet(id, data);
    setIsEditInfo(false);
  };

  const isHasUpdate = () => {
    return status !== '' || (remark !== null && remark !== '') || (discount !== null && discount !== '');
  };

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const onCloseModalCustomer = () => {
    setOpenModalCustomer(false)
  };


  return (
    <Wrapper>
      <Div>
        <Grid container item style={{ height: '100%' }}>
          <Background>
            <Link to="/transaction">
              <ArrowBack /> Back
            </Link>
            {transactionInfo && (
              <div>
                <HeaderInfo>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <ModalAntd
                        open={openModal}
                        onCancel={onCloseModal} footer={null} destroyOnClose={true} maskClosable={false}
                        // setOpen={setOpenModal}
                        // heightAuto
                        // handleSubmit={() => saveStatus(false)}
                        // noButton={!isHasUpdate()}
                        style={{ padding: '1rem' }}>
                        {!isHasUpdate() ? <p>ไม่มีข้อมูลอัพเดท</p> : <p>ต้องการ{status === 'CONFIRMED' ? 'ยืนยัน' : 'ยกเลิก'} รายการชำระนี้ ?</p>}
                        {!isHasUpdate() ? <></> : <ContainerButton center>
                          <ButtonTheme useFor="CUSTOM" title="ยืนยัน" onClick={() => saveStatus(false)} />
                          <ButtonTheme useFor="CANCEL" bgcolor={color.danger} color={'white'} onClick={() => onCloseModal()} />

                        </ContainerButton>}
                      </ModalAntd>
                      {/* <Modal
                        open={openModalCustomer}
                        setOpen={setOpenModalCustomer}
                        heightAuto
                        labelSubmit='ดำเนินการต่อ'
                        labelClose='ยกเลิก'
                        handleSubmit={() => saveStatus(true)}
                        actions={[
                          (<Button key="cancel-to-customer" varint="contained" color="secondary" className={classes.button}>
                            <Link to={{
                              pathname: "/customer",
                              search: `?id_card=${transactionInfo?.user.id_card}`
                            }}>ไปหน้าลูกค้า</Link>
                          </Button>)
                        ]}
                        style={{ padding: '1rem' }}>
                        <p>ลูกค้ายังไม่ได้ถูกล็อคการชำระ ต้องการดำเนินการต่อหรือไม่?</p>
                      </Modal> */}
                      <ModalAntd title={'ลูกค้ายังไม่ได้ถูกล็อคการชำระ ต้องการดำเนินการต่อหรือไม่?'} open={openModalCustomer} width={550} onCancel={onCloseModalCustomer} footer={null} destroyOnClose={true} maskClosable={false}>
                        <ContainerButton center>
                          <ButtonAntd type="primary" disabled={isDisabled} onClick={() => saveStatus(true)} style={isDisabled ? { background: color.disabled, borderColor: color.disabled } : { background: color.create, borderColor: color.create }}>ดำเนินการต่อ</ButtonAntd>
                          <ButtonTheme useFor="CANCEL" bgcolor={color.danger} color={'white'} onClick={() => onCloseModalCustomer()} />
                          <ButtonTheme useFor="CUSTOM" title="ไปหน้าลูกค้า" onClick={() => window.open(`/customer?id_card=${transactionInfo?.user.id_card}`)} />
                        </ContainerButton>
                      </ModalAntd>
                      <h1>{transactionInfo && transactionInfo.user?.general?.fullname}</h1>
                      <p>วันที่ชำระ: {formatDate(new Date(transactionInfo.transaction_date), { dateStyle: 'medium', timeStyle: 'short' })}</p>
                      <p>วันที่ทำรายการ: {formatDate(new Date(transactionInfo.created_date), { dateStyle: 'medium' })}</p>
                      <p>จำนวนเงิน: {formatAmount(transactionInfo.total_amount)} บาท</p>
                      {transactionInfo.admin_paid && (<p>จ่ายโดย: {transactionInfo.admin_paid}</p>)}
                      <p>ช่องทางการจ่ายเงิน : {getPaymentChannel(transactionInfo)}</p>
                      <p>ประเภท : {translateInfoType(transactionInfo.type)}</p>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                          <div style={{ marginRight: '20px', marginTop: '1rem' }}>
                            สถานะ: <ChipStatus status={transactionInfo.status} />
                          </div>
                        </div>

                        <div>
                          {!isEdit && transactionInfo.can_update && ["PENDING", "CONFIRMED"].includes(transactionInfo.status) && (
                            <Button onClick={() => toggleUpdateStatus()}>
                              <Edit className={`edit ${classes.icon_btn}`} />
                            </Button>
                          )}
                          {isEdit && (
                            <Button onClick={() => toggleUpdateStatus()} style={{ marginRight: 0 }}>
                              <Cancel className={`cancel ${classes.icon_btn}`} />
                            </Button>
                          )}
                          {isEdit && (
                            <Button onClick={() => showModalUpdateStatus()}>
                              {/* <Button onClick={() => showModalUpdateStatus()}> */}
                              <CheckCircle className={`save ${classes.icon_btn}`} />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>

                    <FormUpdateWrap style={{ flex: '1' }}>
                      {isEdit && (
                        <div style={{ marginTop: '1rem' }}>
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue={transactionInfo.status}
                            style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <FormControlLabel
                              value="CONFIRMED"
                              control={<Radio color="primary" onChange={updateStatus} />}
                              label="ยืนยัน"
                              labelPlacement="end"
                              style={{ marginBottom: 0 }}
                            />
                            <FormControlLabel
                              value="CANCELED"
                              control={<Radio color="primary" onChange={updateStatus} />}
                              label="ยกเลิก"
                              labelPlacement="end"
                              style={{ marginBottom: 0 }}
                            />
                          </RadioGroup>
                        </div>
                      )}
                    </FormUpdateWrap>

                    <div>
                      {isEditInfo ? (
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                          <p style={{ marginBottom: 0, marginRight: '1rem' }}>ส่วนลด:</p>
                          <TextField label={transactionInfo.discount} onChange={updateDiscount} />
                        </div>
                      ) : (
                        <p style={{ marginBottom: 0 }}>ส่วนลด: {transactionInfo.discount ? transactionInfo.discount : '-'}</p>
                      )}

                      {isEditInfo ? (
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                          <p style={{ marginBottom: 0, marginRight: '1rem' }}>หมายเหตุ:</p>
                          <TextField label={transactionInfo.remark} onChange={updateRemark} />
                        </div>
                      ) : (
                        <p style={{ marginTop: '10px' }}>หมายเหตุ: {transactionInfo.remark ? transactionInfo.remark : '-'}</p>
                      )}
                      {transactionInfo?.updated_by && (<p>อัพเดทข้อมูลโดย: {transactionInfo.updated_by}</p>)}
                      <div style={{ marginTop: '2rem' }}>
                        {!isEditInfo && <Button className="edit" onClick={() => setIsEditInfo(true)}>แก้ไขข้อมูล</Button>}
                        {isEditInfo && (<Button className="cancel-edit" onClick={() => setIsEditInfo(false)}>ยกเลิก</Button>)}
                        {isEditInfo && (<Button className="save" onClick={() => saveInfo()}>บันทึก</Button>)}
                      </div>
                    </div>
                  </div>
                  <div>
                    <SlipImage url={transactionInfo.slip} />
                  </div>
                </HeaderInfo>

                {
                  transactionInfo.receipt_no && (
                    <WrapTable>
                      <h6 style={{ backgroundColor: '#ddd', padding: '1rem' }}>
                        รายการที่ชำระ {!transactionInfo.receipt_no ? '' : transactionInfo.receipt_no}
                      </h6>
                      <Table>
                        <thead>
                          <tr>
                            <Th border_bottom>ลำดับ</Th>
                            <Th left border_bottom style={{ width: '400px' }}>
                              ชื่อรายการ
                            </Th>
                            <Th colSpan={3} border_right border_bottom>
                              ยอดชำระ
                            </Th>
                            <Th colSpan={3} border_bottom>
                              เหลือที่ต้องชำระ
                            </Th>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <Th border_bottom colSpan={3} style={{ textAlign: 'right', paddingRight: '10px' }}>
                              เงินต้น
                            </Th>
                            <Th border_bottom>ดอกเบี้ย</Th>
                            <Th border_bottom border_right highlight>
                              ทั้งหมด
                            </Th>
                            <Th border_bottom>เงินต้น</Th>
                            <Th border_bottom>ดอกเบี้ย</Th>
                            <Th border_bottom highlight>
                              ทั้งหมด
                            </Th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionInfo.info.length > 0 &&
                            transactionInfo.info.map((item, index) => (
                              <tr key={item._id}>
                                <Td>{index + 1}</Td>
                                <Td left>
                                  {`[${item.contract_no} - ${item?.payment?.period !== undefined ? item?.payment?.period : "x"}] ${item.payment?.paid_date ? formatDate(new Date(item.payment?.paid_date)) : "x"} - ${transactionPaymentType(
                                    item.paid_type,
                                  )}`}
                                </Td>
                                <Td>{formatAmount(item.capital_amount)}</Td>
                                <Td>{formatAmount(item.interest_amount)}</Td>
                                <Td border_right highlight>
                                  {formatAmount(item.amount)}
                                </Td>
                                <Td>{formatAmount(item?.payment?.remain_capital_amount ? item?.payment?.remain_capital_amount : 0)}</Td>
                                <Td>{formatAmount(item?.payment?.remain_interest_amount ? item?.payment?.remain_interest_amount : 0)}</Td>
                                <Td highlight>{formatAmount(item?.payment?.remain_amount ? item?.payment?.remain_amount : 0)}</Td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </WrapTable>
                  )
                }
              </div>
            )}
            {infoLoading && <Loading />}
          </Background>
        </Grid>
      </Div>
    </Wrapper>
  );
};

export default TransactionInfo;
