import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { PUT, EDIT_UNLOCK_APPROVE_STATUS } from '../../../services/index';
import { Icon } from '../../../resources/icon';
import { toast } from 'react-toastify';

export const ApproveUnlockStatus = ({ title, visible, onClose, data }) => {

  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (JSON.stringify(data && data.record) !== "{}" && data.record) {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setRecord(data.record)
    }

  }, [data]);

  const openConfirm = (params,) => {
    Modal.confirm({
      title: "ยืนยัน",
      icon: <Icon.warningAlert />,
      content: "ยืนยันการขอรหัสไอคลาวน์",
      onOk() {
        onSubmit(params);
        onCloseMain()
      },
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    try {
      let res = await PUT(EDIT_UNLOCK_APPROVE_STATUS(record.contract_no));
      const { success, message } = res;

      if (success) {

        toast.success(message, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            data.reApi({});
          },
        });
        // Modal.success({
        //   title: message,
        //   afterClose: () => {
        //     data.reApi({});
        //     onCloseMain();
        //   },
        // });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }

  };

  const onCloseMain = () => {
    clearErrors();
    onClose();
  };
  return (
    <Modal title={title} open={visible} width={350} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={() => openConfirm()} loading={loading} />
        <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
      </ContainerButton>
    </Modal>
  );
};
