import {Col, Layout} from 'antd';
import styled from 'styled-components';

export const ContainerButton = styled(Col)`
  text-align-last: ${(props) => (props.left ? 'start' : props.center ? 'center' : props.right ? 'end' : '')};
  width: 100%;
`;
// export const Input = styled(Col)`
// 	text-align-last: ${props =>
//         props.left ? 'start' :
//             props.center ? 'center' :
//                 props.right ? 'end' : ''
//     };
// 	width: 100%;
// `

export const LayoutStyle = styled(Layout)`
  padding: 20px 50px;
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-color: white
`;
