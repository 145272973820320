export const formatNumber = (number) => {
  return new Intl.NumberFormat('th-TH', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(number);
};

export const formatComma = (number, defaultValue = '') => {
  if (!number) {
    return defaultValue + '';
  }
  return new Intl.NumberFormat('th-TH').format(number);
};
